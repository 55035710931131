<div class="mapImageDialog" *ngIf="data.dialogParam=='mapImage' || data.dialogParam=='reMapImage'">
  <div class="row modal-header dialog-header">
    <div class="col-md-6 no_m_p">
      <p *ngIf="data.dialogParam=='mapImage'" class="dialog-title">Map Image</p>
      <p *ngIf="data.dialogParam=='reMapImage'" class="dialog-title">Remap Image</p>
    </div>
    <div class="col-md-6 no_m_p dn">
      <i class="fa fa-times rightfloat" (click)="closeDialog()"></i>
    </div>
  </div>
  <div class="container dialog-container modal-body">
    <form [formGroup]="mapForm">
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>Study Name<span class="mandatory">*</span></mat-label>
            <mat-select formControlName="studyGuId" (selectionChange)="studySelectionChanged($event)">
              <mat-option *ngFor="let studyData of studyListArr" [value]="studyData.guid">{{studyData.studyNo}}
              </mat-option>
            </mat-select>
            <!-- <mat-error>{{mapF.studyGuId.invalid | inputError:'mapStudy' | translate}}</mat-error> -->
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>Cassette<span class="mandatory">*</span></mat-label>
            <mat-select formControlName="cassetteId" (selectionChange)="cassetteSelectionChanged($event)">
              <mat-option *ngFor="let cassetteData of cassetteArr" [value]="cassetteData.id">{{cassetteData.cassetteId}}
                ({{cassetteData.organs}})</mat-option>
            </mat-select>
            <!-- <mat-error>{{mapF.cassetteId.invalid | inputError:'mapCassette' | translate}}</mat-error> -->
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>Stain<span class="mandatory">*</span></mat-label>
            <mat-select formControlName="stainId" (selectionChange)="stainSelectionChanged($event)">
              <mat-option *ngFor="let stainData of stainArr" [value]="stainData.id">{{stainData.stain}}</mat-option>
            </mat-select>
            <!-- <mat-error>{{mapF.stainId.invalid | inputError:'mapStain' | translate}}</mat-error> -->
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>Subject Id<span class="mandatory">*</span></mat-label>
            <mat-select formControlName="animalId">
              <mat-option *ngFor="let animalData of animalArr" [value]="animalData.animalId">{{animalData.animalNo}}
              </mat-option>
            </mat-select>
            <!-- <mat-error>{{mapF.animalId.invalid | inputError:'mapAnimal' | translate}}</mat-error> -->
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-form-field class="example-full-width">
          <mat-label>Comment</mat-label>
          <textarea formControlName="comment" matInput placeholder="Comment..." maxlength="250"></textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="actions dialog-actions modal-footer unmapbtn">
    <button mat-button class="btn btn-default" (click)="closeDialog()">Close</button>
    <button *ngIf="data.dialogParam=='mapImage'" mat-button class="btn searchbtn" (click)="mapImageData()"
      [ngClass]="{ 'disablebtn_func' : mapForm.invalid}">Map Image</button>
    <button *ngIf="data.dialogParam=='reMapImage'" mat-button class="btn searchbtn" (click)="mapImageData()"
      [ngClass]="{ 'disablebtn_func' : mapForm.invalid}">Re-Map Image</button>
  </div>
</div>
<div class="unMapImageDialog" *ngIf="data.dialogParam=='unMapImage'">
  <div class="row modal-header dialog-header">
    <!-- <div class="col-md-6 no_m_p"> -->
    <p class="dialog-title">Reject Image</p>
    <!--  </div> -->
    <!-- <div class="col-md-6 no_m_p"> -->
    <i class="fa fa-times rightfloat cursorPointer dn" (click)="closeDialog()"></i>
    <!-- </div> -->
  </div>
  <div class="container dialog-container modal-body modalContainer rejectcontent">
    <p *ngIf="data.count == 1">Are you sure you want to exclude this image?</p>
    <p *ngIf="data.count > 1">Are you sure you want to exclude these images?</p>
    <br />
    <div>
      <mat-form-field class="example-full-width commonMatFormField_textarea" appearance="outline">
        <mat-label class="labelbg">Comment
          <!-- [(ngModel)]="discardImageComment" -->
        </mat-label>
        <textarea matInput [(ngModel)]="unMapImageComment" placeholder="Comment" maxlength="250"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="actions dialog-actions modal-footer unmapbtn">
    <button type="button" class="btn-default" aria-label="cancelbtn" (click)="closeDialog()">Cancel</button>
    <button type="button " class="btn-default searchbtn_add " (click)="unMapImageData()">Yes</button>
  </div>
</div>
<div class="discardImageDialog" *ngIf="data.dialogParam=='discardImage'">
  <div class="row modal-header dialog-header">
    <div class="col-md-6 no_m_p">
      <p class="dialog-title">Discard Image</p>
    </div>
    <div class="col-md-6 no_m_p">
      <i class="fa fa-times rightfloat dn" (click)="closeDialog()"></i>
    </div>
  </div>
  <div class="container dialog-container modal-body modalContainer">
    <p>Are you sure you want to discard this image?</p>
    <br />
    <div>
      <mat-form-field class="example-full-width">
        <mat-label>Comment
          <!-- <span class="mandatory">*</span> -->
        </mat-label>
        <textarea [(ngModel)]="discardImageComment" matInput placeholder="Comment..." maxlength="250"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="actions dialog-actions modal-footer unmapbtn">
    <button mat-button class="btn btn-default" (click)="closeDialog()">Cancel</button>
    <button mat-button class="btn btn-default searchbtn" (click)="disacardImageData()">Yes</button>
  </div>
</div>
<div class="uploadImageDialog" *ngIf="data.dialogParam=='uploadImage'">
  <div class="row modal-header dialog-header">
    <div class="col-md-6 no_m_p">
      <p class="dialog-title">Upload Images</p>
    </div>
    <div class="col-md-6 no_m_p">
      <i class="fa fa-times rightfloat dn" (click)="closeDialog()"></i>
    </div>
  </div>
  <div class="container dialog-container modal-body">
    <form [formGroup]="uploadForm">
      <div class="row">
        <!-- <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>Study Name</mat-label>
            <mat-select formControlName="studyGuId" (selectionChange)="uploadStudySelectionChanged($event)">
              <mat-option *ngFor="let studyData of studyListArr" [value]="studyData.guid">{{studyData.studyNo}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <!-- <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>Cassette<span class="mandatory">*</span></mat-label>
            <mat-select formControlName="cassetteId" (selectionChange)="cassetteSelectionChanged($event)">
              <mat-option *ngFor="let cassetteData of cassetteArr" [value]="cassetteData.id">{{cassetteData.cassetteId}}
                ({{cassetteData.organs}})</mat-option>
            </mat-select>
            <mat-error>{{uploadF.cassetteId.invalid | inputError:'mapCassette' | translate}}</mat-error>
          </mat-form-field>
        </div> -->
      </div>
      <!--<div class="row">
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>Stain<span class="mandatory">*</span></mat-label>
            <mat-select formControlName="stainId" (selectionChange)="stainSelectionChanged($event)">
              <mat-option *ngFor="let stainData of stainArr" [value]="stainData.id">{{stainData.stain}}</mat-option>
            </mat-select>
            <mat-error>{{mapF.stainId.invalid | inputError:'mapStain' | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field class="example-full-width">
            <mat-label>Animal No.<span class="mandatory">*</span></mat-label>
            <mat-select formControlName="animalId">
              <mat-option *ngFor="let animalData of animalArr" [value]="animalData.animalId">{{animalData.animalNo}}</mat-option>
            </mat-select>
            <mat-error>{{mapF.animalId.invalid | inputError:'mapAnimal' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>
        <mat-form-field class="example-full-width">
          <mat-label>Comment</mat-label>
          <textarea formControlName="comment" matInput placeholder="Comment..."></textarea>
        </mat-form-field>
      </div>-->
      <div>
        <input id="file" type="file" name="files[]" id="files" multiple style="display: none;"
          (change)="onFileSelect($event)" placeholder="Upload Images" #uploadImageFileInput />
        <button type="button" class="uploaddoc_btn" (click)="uploadImageFileInput.click()">
          Browse Images
        </button>
        <!-- <mat-error>{{uploadF.uploadedImages.invalid | inputError:'imageUpload' | translate}}</mat-error> -->
      </div>
    </form>
  </div>
  <div class="actions dialog-actions modal-footer">
    <button mat-button class="btn btn-default" (click)="closeDialog()">Cancel</button>
    <button mat-button class="btn btn-default searchbtn" (click)="uploadImageData()"
      [ngClass]="{ 'disablebtn_func' : !enableUploadButton}">Upload</button>
  </div>
</div>