import { HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { BehaviorSubject, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CommonDialogComponent } from './common-dialog/common-dialog.component';
import { CommonService } from '../common.service';
import * as saveAs from 'file-saver';
import { StateChange } from 'ng-lazyload-image';
import { forkJoin } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  config: any;
  downloadProgressValue: any = [];
  isTemplateTurnOn = true;
  callbackFunc: any;
  existingUsersList: any = [];
  otherUserList: any = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  refreshPopIsOpened: false;
  constructor(private http: HttpClient, private appConfig: AppConfigService, public dialog: MatDialog, public commonService: CommonService) {
    this.config = appConfig.config;

  }
  private selectedPreviewAlgoTypeSrc = new BehaviorSubject(null);
  selectedPreviewAlgoType = this.selectedPreviewAlgoTypeSrc.asObservable();
  getProject() {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/project?landingPage=true`);
  }
  getTemplateList() {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/metaDataTemplate`);
  }
  createProject(payload: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/project`, payload);
  }
  getAllMetaDataTemplate(templateId: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/metaDataTemplate/${templateId}`);
  }

  addMetaData(body: any, opration: any) {
    if (opration == 'add') {
      return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/metadata`, body);
    } else {
      return this.http.put(`${this.config.apiConfig.apiUrl}slide-image/metadata`, body);
    }
  }

  trackMetaData(folderId: any, imageId: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/image/imageAccessed?imageId=${imageId}&folderId=${folderId}`);
  }

  getMetadataTemplateList() {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/metaDataTemplate`);
  }

  getMetadataTemplateDetails(id: any, templateVersion: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/metaDataTemplate/${id}?showValues=true&version=${templateVersion}`);
  }

  OverRiderFolderData(payloadData: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/metadata/copyDataToImages`, payloadData);
  }

  changeMetaDataTemplate(payload: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/project/changeTemplate`, payload);
    // project/changeTemplate
  }

  editProject(payload: any) {
    return this.http.put(`${this.config.apiConfig.apiUrl}slide-image/project/${payload.id}`, payload);
  }

  deleteProject(projectId: any) {
    return this.http.delete(`${this.config.apiConfig.apiUrl}slide-image/project/${projectId}`);
  }

  // api in folder listing

  getListOfMappedIds(folderId: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/folder/mappedIds?folderId=${folderId}`);
  }

  getListOfSlideIds(folderId: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/folder/imageIds?folderId=${folderId}`);
  }

  getAllFilters(folderId: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/search/filter-metadata?folderId=${folderId}`);
  }

  getSearchData(payload: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/search`, payload);
  }

  getFoldersForProjects(projectId: any, flag: any, pageIndex: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/project/projectView?projectId=${projectId}&pageSize=${this.config.webAppConfig.projectContentSize}&pageNumber=${pageIndex}`);
  }

  getImagesForProject(projectId: any, flag: any, pageIndex: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/project/projectImageView?projectId=${projectId}&pageSize=${this.config.webAppConfig.projectContentSize}&pageNumber=${pageIndex}`);
  }

  getImageByFolderId(folderId: any, flag: any, pageIndex: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/folder/subfolderImages?folderId=${folderId}&pageSize=${this.config.webAppConfig.folderImagesPageSize}&pageNumber=${pageIndex}&shared=0`);
  }

  getFoldersOrImages(payload: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/folder/search`, payload);
  }

  getMetaData(id: any, imageType: any, folderId: any, operation: any) {
    if (imageType == 'FOLDER') {
      if (operation == 'edit') {
        return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/metadata?type=${imageType}&folderId=${folderId}&isMetaDataEdit=${this.isTemplateTurnOn}`);
      } else return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/metadata?type=${imageType}&folderId=${folderId}`);

    } else if (imageType == 'IMAGE') {
      if (operation == 'edit') {
        return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/metadata?type=${imageType}&id=${id}&isMetaDataEdit=${this.isTemplateTurnOn}`);
      } else return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/metadata?type=${imageType}&id=${id}`);

    } else if (operation == 'edit') {
      return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/metadata?type=${imageType}&folderId=${folderId}&id=${id}&isMetaDataEdit=${this.isTemplateTurnOn}`);
    } else {
      return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/metadata?type=${imageType}&folderId=${folderId}&id=${id}`);
    }

  }

  getSchemagJSON(imagePath: any) {
    const encodedPath = btoa(imagePath)
    return this.http.get(`${this.config.apiConfig.apiUrl}${this.config?.tileGateway ? 'ts' : 'tiles'}/schema?query=${encodedPath}`)
  }

  getFileListing() {
    return this.http.get(`${this.config.apiConfig.apiUrl}`);
  }


  getHeatMapImg(imgInfo: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}ts/heatmap?query=${imgInfo}`, { observe: 'body', responseType: 'blob' as 'json' });
  }

  createdFolder(payload: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/folder`, payload);
  }

  shareProject(body: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/share`, body);
  }

  getUserListForSharing(body: any) {
    // return this.http.post(`${this.config.apiUrl}myproject/master/getUserList`, body);
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/share/getUserList`, body);
  }

  getRoleWiseAllUsers(roleArr: any[]) {
    const rolesString = roleArr.join(',');
    const params = new HttpParams().set('requiredRoles', rolesString);
    const encodedRolesString = encodeURIComponent(rolesString);
    return this.http.get(`${this.config.apiConfig.apiUrl}user/userHavingRoles?requiredRoles=${encodedRolesString}`);
  }

  shareFolder(body: any) {
    return this.http.post(`${this.config.apiUrl}slide-image/folder/share`, body);
  }

  getStudyShareDetails(rootId: any) {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}study/sharemetadata?rootId=${rootId}&type=STUDY`);
  }

  getProjectShareDetails(rootId: any) {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}study/sharemetadata?rootId=${rootId}&type=PROJECT`);
  }

  shareImage(body: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/image/share`, body);
  }

  stopSharingFolder(body: any, id: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/folder/${id}/unlink`, body)
  }

  stopSharingImage(body: any,) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/image/unlink`, body);
  }

  unmapIndImage(listOfMappedId: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/image/unmap-image`, listOfMappedId);
  }

  deleteFolder(FolderId: any) {
    return this.http.delete(`${this.config.apiConfig.apiUrl}slide-image/folder/${FolderId}`);
  }

  getUsers() {
    return this.http.get(`${this.config.apiConfig.apiUrl}user`)
  }

  downloadImages(imageid: string, imageName: string): Observable<any> {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/download-image?slideId=${imageid}&appCode=AIRADHI_PROJECT`, { responseType: 'blob', reportProgress: true, observe: 'events', });
  }

  downloadSelectedImage(body: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/folder/download`, body, { responseType: 'blob', reportProgress: true, observe: 'events', });
  }

  cancelDownload(Obj: any) {
    Obj.request.unsubscribe();
    Obj.isCancelled = true;
  }

  syncResults(payload: any) {
    // return this.http.post(`${this.config.apiUrl}myproject/analysis/sync/folder/${folderId}`,{});
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/analysis/sync/folder`, payload);
  }

  startAnalysis(payload: any) {
    // return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/analysis/submit`, payload);
    return this.http.post(`${this.config?.apiConfig?.apiUrl}slide-image/analysis/submit?create=true`, payload);

  }

  openAddFolderDialog(projectId: any, callBackFunc: any) {
    this.callbackFunc = callBackFunc;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "20%";
    dialogConfig.panelClass = "confirmDialog";
    dialogConfig.data = {
      isCreateFolder: true,
      openPopUpFor: 'addProject',
      projectName: '',
      headerTitle: 'New Folder',
      confirmButtonText: 'Create',
      projectId: projectId,
      callBackFunc: this.createNewFolder.bind(this)
    };
    this.dialog.open(CommonDialogComponent, dialogConfig);
  }

  createNewFolder(result: any, dialogRef: any) {
    this.createdFolder({
      id: "",
      folderName: result?.projectName,
      projectId: result.projectId,
      metaData: [],
      sharedCount: 0,
      users: []
    }).subscribe((response: any) => {
      if (response.success) {
        this.commonService.showToastMsg(response.message);
        dialogRef.close();
        this.callbackFunc();

      }
    })
  }


  shareAndStopsharing(entityId: any, callbackFunction: any, type: any) {
    this.callbackFunc = callbackFunction;
    let sources = {
      allUsers: this.getRoleWiseAllUsers(['PATHOLOGIST,PEER_REVIEWER,EXTERNAL_PATHOLOGIST']),
      // sharedUsersList: type == 'STUDY' ? this.getStudyShareDetails(entityId) : this.getUserListForSharing({ id: entityId, type: type })
      sharedUsersList: type == 'STUDY' ? this.getStudyShareDetails(entityId) : this.getProjectShareDetails(entityId)
    };
    forkJoin(sources).subscribe((resp: any) => {
      const dialogConfig = new MatDialogConfig();
      let allUserList: any = [];
      let existingUsersList: any = [];

      existingUsersList = resp['sharedUsersList']?.data?.users?.filter((userItem: any) => {
        return userItem?.user != this.commonService.ProjectLSData.currentUser?.email
      });

      let duplicateUsers: any = [];
      resp['sharedUsersList']?.data?.users?.forEach((item: any) => {
        duplicateUsers.push(item?.user);
      });

      resp['allUsers']?.data?.forEach((user: any) => {
        if (duplicateUsers?.indexOf(user?.emailId) < 0 && user?.emailId != this.commonService.ProjectLSData.currentUser?.email) {
          allUserList.push(user);
        }
      });

      allUserList.forEach((item: any) => {
        item['access'] = {
          "DOWNLOAD": this.config?.shareConfig?.downloadConfig?.downloadPermission,
          "ANNOTATION_IMPORT_EXPORT": this.config?.shareConfig?.annotationConfig?.annotationPermission,
          "SHARE_ANALYSIS_RESULT": this.config?.shareConfig?.shareReviewConfig?.shareReviewPermission,
          "READ": true
        }
      })

      existingUsersList.forEach((element: any) => {
        element['emailId'] = element?.user
      });

      dialogConfig.width = "60vw";
      dialogConfig.panelClass = "confirmDialog";
      dialogConfig.data = {
        openPopUpFor: type == 'STUDY' ? 'shareStudy' : 'shareProject',
        userList: [...allUserList],
        selectedUsers: [...existingUsersList],
        selectedUsersLength: existingUsersList?.length ?? 0,
        confirmButtonText: 'Share',
        indvProject: entityId,
        headerTitle: `Share ${(type == 'STUDY') ? 'Study' : 'Project'}`,
        type: type,
        functionCall: this.shareStudyWithAllSelectedUsers.bind(this),
        studySharedWithUser: existingUsersList,
        statePreseve: {
          user: [...allUserList],
          selectUser: [...existingUsersList]
        },
        userSearchText: ""
      }

      this.dialog.open(CommonDialogComponent, dialogConfig);

    });
  }

  getUserList(callback: any, body: any) {
    this.getUserListForSharing(body).pipe(map((response: any) => {
      response.data.otherUsers.map((item: any) => {
        item.access = {
          "DOWNLOAD": this.config?.shareConfig?.downloadConfig?.downloadPermission,
          "ANNOTATION_IMPORT_EXPORT": this.config?.shareConfig?.annotationConfig?.annotationPermission,
          "SHARE_ANALYSIS_RESULT": this.config?.shareConfig?.shareReviewConfig?.shareReviewPermission,
          "READ": false
        }
      }
      )
      return response;
    }), takeUntil(this.destroy$)).subscribe({
      next: (res: any) => {
        this.existingUsersList = [...res.data.users];
        this.otherUserList = [...res.data.otherUsers];
      },
      complete: () => {
        callback();
      }
    })
  }

  openUserListDialog(entityId: any, type: any) {
    const dialogConfig = new MatDialogConfig();
    // this.listSortedByNames(this.otherUserList);
    // this.listSortedByNames(this.existingUsersList);
    dialogConfig.width = "60vw";
    dialogConfig.panelClass = "confirmDialog";
    dialogConfig.data = {
      openPopUpFor: 'shareProject',
      userList: [...this.otherUserList],
      selectedUsers: [...this.existingUsersList],
      selectedUsersLength: this.existingUsersList.length,
      confirmButtonText: 'Share',
      indvProject: entityId,
      headerTitle: `Share ${(type == 'STUDY') ? 'Study' : 'Project'}`,
      type: type,
      functionCall: this.callShareProjectService.bind(this),
      projectSharedWithUser: this.existingUsersList,
      statePreseve: {
        user: [...this.otherUserList],
        selectUser: [...this.existingUsersList]
      },
      userSearchText: ""
    }

    this.dialog.open(CommonDialogComponent, dialogConfig);
  }

  updateAccess(body: any) {
    return this.http.put(`${this.config.apiConfig.apiUrl}slide-image/master/updateAccess`, body);
  }


  getAlgorithmList() {
    return this.http.get(`${this.config.apiConfig.apiUrl}analysis/algorithms`);
    // return this.http.get(`http://172.28.43.130:9070/airadhi/analysis/algorithms`);
  }

  submitAnalysis(payload: any) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}slide-image/analysis/submit?create=true`, payload);
    // return this.http.post(`http://172.28.43.154:9070/airadhi/slide-image/analysis/submit?create=true`,payload)
  }


  saveDraft(payload: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}myproject/analysis/draft`, payload);
  }

  getDataOfValidateMetaData(payload: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/analysis/validate-metadata`, payload);
  }

  getAnalysisStatusUpdates(payload: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/image/analysis-updates`, payload);
  }

  getfolderReportData(folderId: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/report/folder/detail?folderId=${folderId}`);
  }


  getImageQuantificationResult(payload: any) {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}slide-image/analysis/results`, { params: payload });
  }

  removeAnalysisResult(payload: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/analysis/result`, payload);
  }

  listSortedByNames(inputArray: any) {
    inputArray.sort(function (a: any, b: any) {
      const aFullName = (a.firstName + ' ' + a.lastName);
      const bFullName = (b.firstName + " " + b.lastName);
      if (aFullName < bFullName) {
        return -1;
      }
      if (aFullName > bFullName) {
        return 1;
      }
      return 0
    });
  }

  callShareProjectService(indvProjectId: any, result: any, dialogRef: any) {
    let userEmailId = [];
    for (const element of result.selectedUsers) {
      userEmailId.push({
        user: element.emailId,
        access: element.access
      });
    }
    let payload: any = {
      id: indvProjectId,
      type: result.type,
      users: [...userEmailId]
    }
    // console.log('Final Data', payload);
    this.shareProject(payload).subscribe((response: any) => {
      if (response?.success) {
        this.commonService.showToastMsg(response?.message);
        dialogRef.close();
      }
    })
  }


  shareStudyWithAllSelectedUsers(studyId: any, shareData: any, dialogRef: any) {
    let allUsersForStudy: any = {
      "type": dialogRef?.componentInstance?.data?.type?.toUpperCase(),
      "rootId": studyId,
      "mappedId": studyId,
      sharedMetadataModelList: []
    };
    if (shareData?.selectedUsers?.length) {
      shareData?.selectedUsers.forEach((item: any) => {
        allUsersForStudy['sharedMetadataModelList'].push({
          'user': item?.user ?? item?.emailId,
          // 'role': item?.id ? item?.role : 'ADDITIONAL_PATHOLOGIST',
          'role': item?.id ? item?.role : (item?.roles?.includes("EXTERNAL_PATHOLOGIST") ? 'EXTERNAL_PATHOLOGIST' : 'ADDITIONAL_PATHOLOGIST'),
          "readPermission": true,
          // "downloadPermission": dialogRef?.componentInstance?.data?.type?.toUpperCase() == 'STUDY' ? item?.downloadPermission : item?.access?.DOWNLOAD,
          // "annotationPermission": dialogRef?.componentInstance?.data?.type?.toUpperCase() == 'STUDY' ? item?.annotationPermission : item?.access?.ANNOTATION_IMPORT_EXPORT,
          // "shareReviewPermission": dialogRef?.componentInstance?.data?.type?.toUpperCase() == 'STUDY' ? item?.shareReviewPermission : item?.access?.SHARE_ANALYSIS_RESULT,
          "downloadPermission": item?.downloadPermission,
          "annotationPermission": item?.annotationPermission,
          "shareReviewPermission": item?.shareReviewPermission,
          "completed": false,
          "type": dialogRef?.componentInstance?.data?.type?.toUpperCase(),
          "rootId": studyId,
          "mappedId": studyId,
          'id': item?.id
        });
      });
    }
    console.log('allUsersForStudy', allUsersForStudy);
    this.shareCallAfterStudyEdit(allUsersForStudy).subscribe((finalResponse: any) => {
      if (finalResponse.success) {
        this.commonService.showToastMsg(finalResponse?.message);
        dialogRef.close();
      }
    });
  }

  returnUserRole(role: any) {
    if (role == 'Additional Pathologist') {
      return 'ADDITIONAL_PATHOLOGIST';
    } else if (role == 'Pathologist') {
      return 'PATHOLOGIST';
    } else if (role == 'Peer Reviewer') {
      return 'PEER_REVIEWER';
    }
  }

  shareCallAfterStudyEdit(usersData: any) {
    return this.http.put(`${this.config?.apiConfig?.apiUrl}study/sharemetadata`, usersData);
  }

  downloadZip(body: any, fileSize: any, projectName: any) {
    let obj: any = {
      fileName: projectName,
      value: 0,
      isDownloaded: false,

    }
    this.downloadProgressValue.unshift(obj);
    obj['request'] = this.downloadSelectedImage(body).subscribe((data: any) => {
      if (data.type === HttpEventType.DownloadProgress) {
        const percentDone = Math.round(((100 * data.loaded) / fileSize) + 2);
        obj.value = percentDone;
        // console.log(percentDone)
      }
      if (data.type === HttpEventType.Response) {
        saveAs(data.body, projectName);
      }

      obj.isDownloaded = obj.value >= 100;

    }
    )
  }

  getAllreadyShareduserList(body: any) {
    return this.http.post(`${this.config.apiUrl}myproject/master/getUserListForStopSharing`, body);
  }


  reloadFailedLazyLoadImg(event: StateChange, slideData: any, refEl: any, pathVar: any) {
    if (event.reason == 'loading-succeeded') {
      refEl.classList.remove("ng-failed-lazyloaded");
    } else if ((event.reason == 'loading-failed') && (this.config.galleryConfig.failedLazyloadInfo) && (slideData.retryAttempted < this.config.galleryConfig.failedLazyloadInfo.maxRetryAttempt)) {
      setTimeout(() => {
        var timestamp = new Date().getTime();
        let imgPathVar = slideData[pathVar].split('?')[0];
        slideData[pathVar] = imgPathVar + '?t=' + timestamp;
        slideData.retryAttempted++;
      }, this.config.galleryConfig.failedLazyloadInfo.retryDelay);
    }
  }

  markComplete(indvProject: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/share/completeData`,
      {
        type: indvProject.type,
        name: indvProject.name,
        id: indvProject.type == 'IMAGE' ? indvProject.mappedId : indvProject.id,
      }
    )
  }

  getSharedProjecwithMe() {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/share`);
  }

  metaDataOverride(payload: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/metadata/override`, payload);
  }

  getAnalysisReport(projectId: any, folderId: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/project/report/qc?projectId=${projectId}&folderId=${folderId}`);
  }
}
