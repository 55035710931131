<div class="popup-cont">
  <div class="popup-header">
    <div class="popup-HeaderTitle">
      {{ data.userType === 'Edit' ? 'Edit User': 'Add User' }}
    </div>
  </div>

  <!-- <div class="dispText">{{showData.confirmMsg}}</div> -->
  <div class="popup-body">
    <form [formGroup]="userFrom">
      <div class="form-row">
        <div class="inputDiv_user">
          <mat-form-field class="commonMatFormField" appearance="outline">
            <mat-label class="labelbg">First Name<span class="mandatory">*</span></mat-label>
            <input matInput maxlength="25" type="text" (input)="isFormValueChanged= true" formControlName="firstName"
              class="form-control" />
          </mat-form-field>
        </div>
        <div class="inputDiv_user">
          <mat-form-field class="commonMatFormField" appearance="outline">
            <mat-label class="labelbg">Last Name</mat-label>
            <input matInput maxlength="25" type="text" formControlName="lastName" (input)="isFormValueChanged= true"
              class="form-control" />
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <div class="inputDiv_user">
          <mat-form-field class="commonMatFormField" appearance="outline">
            <mat-label class="labelbg">Email<span class="mandatory">*</span></mat-label>
            <input matInput maxlength="50" type="text" formControlName="email" class="form-control"
              (input)="isFormValueChanged= true" />
          </mat-form-field>
        </div>
      </div>
      <div class="form-row">
        <fieldset class="inputDiv_user roleBorder">
          <legend>Roles<span class="mandatory">*</span></legend>
          <div class="">
            <div class="userInputBox" formArrayName="roles">
              <ng-container *ngFor="let role of rolesArr; let i = index">
                <div class="checkBoxDiv">
                  <mat-checkbox color="primary" [formControlName]="i" disableRipple [disabled]="checkFieldEnabled(role)"
                    (change)="isFormValueChanged=true">{{rolesDisplayed[i]}}</mat-checkbox>
                </div>
              </ng-container>
            </div>
          </div>
        </fieldset>
      </div>
      <ng-container *ngIf="config?.webAppConfig?.newUserPasswordRequired">
        <app-password-generation [passwordFormGroup]="userFrom" [isFromUserCreation]="true"
          [passwordPolicyArr]="passwordPolicyArr"></app-password-generation>
      </ng-container>

      <div class="popup-actions">
        <button mat-flat-button class="btn-default marginRight" (click)="closeDialog()">Cancel</button>
        <button mat-flat-button *ngIf="savingStatus" class="btn-default searchbtn_add cursorPointer " type="button"
          disabled>
          <span class="spinner-border spinner-border-sm spinner-class" role="status" aria-hidden="true"></span>
          <span>Saving</span>
        </button>
        <button *ngIf="!savingStatus" mat-flat-button color="primary" class="btn-default searchbtn cursorPointer"
          (click)="saveUserData()"
          [disabled]="((data.userType=='Create' && userFrom.invalid && !userFrom['mustMatch'] ) || (data.userType=='Edit'&& (!isFormValueChanged || userFrom.invalid)))">Save</button>
      </div>
    </form>
  </div>
</div>