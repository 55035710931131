import { Component, ElementRef, Inject, OnInit, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AddMetadataDialogComponent } from '../add-metadata-dialog/add-metadata-dialog.component';
import { CommonService } from 'src/app/common.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from 'src/app/app-config.service';
import { UsersService } from 'src/app/users/users.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-common-dialog',
  templateUrl: './common-dialog.component.html',
  styleUrls: ['./common-dialog.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CommonDialogComponent implements OnInit {
  invalidCredential = false;
  config: any;
  userCreationInProgress: boolean = false;
  regexLen: any = /^.{2,60}$/;
  @ViewChild('input')
  input!: ElementRef<HTMLInputElement>;
  constructor(@Optional() private dialogRef: MatDialogRef<CommonDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UsersService,
    public dialog: MatDialog,
    public commonService: CommonService,
    public translate: TranslateService,
    public configService: AppConfigService) {
    dialogRef.disableClose = true;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.config = configService.config;
  }

  ngOnInit(): void {

  }

  closePopUp() {
    this.dialogRef.close();
  }

  opentemplateListingPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'confirmDialog';
    dialogConfig.data = {
      isTemplateListingPage: true,
      templateListing: this.data.templateList
    }
    const dialogref = this.dialog.open(AddMetadataDialogComponent, dialogConfig);

    dialogref.afterClosed().subscribe((result: any) => {
      if (result) {
        if (result.id == -1) {
          this.data.selectedTemplateId == null
        } else {
          this.data.selectedTemplateId = result.id
        }
        this.OnSubmitAddProject();
      }
    });
  }

  OnSubmitAddProject() {
    if (this.data.openPopUpFor == 'unShareProject') {
      if (this.data.selectedUsersForUnshare.length) {
        this.dialogRef.close(this.data);
      } else {
        this.commonService.showToastMsg(this.translate.instant("COMMONDIALOG.shareProjectValidataion"), 'warn');
      }
    } else if (!this.regexLen.test(this.data.projectName.trim()) && this.data.openPopUpFor == 'addProject') {
      this.commonService.showToastMsg(this.translate.instant('COMMONDIALOG.characterLimitsWarnMsg'), 'warn');
      return;
    } else if (this.data.openPopUpFor === 'deleteProject' || this.data.openPopUpFor === 'deleteFolder') {
      this.dialogRef.close(this.data);
    }
    this.validateInput(this.data.projectName) ? (this.data.isCreateFolder ? this.data.callBackFunc(this.data, this.dialogRef) : this.dialogRef.close(this.data)) : this.invalidCredential = true;
  }


  validateInput(inputName: any) {
    return inputName.trim();
  }


  listSortedByNames(inputArray: any) {
    inputArray = inputArray.sort(function (a: any, b: any) {
      const aFullName = (a.firstName + ' ' + a.lastName);
      const bFullName = (b.firstName + " " + b.lastName);
      if (aFullName < bFullName) {
        return -1;
      }
      if (aFullName > bFullName) {
        return 1;
      }
      return 0
    })
  }

  onUserSearchChange() {
    if (this.data.userSearchText != '') {
      this.data.userList = this.data.statePreseve.user.filter((item: any) => item?.emailId?.toLowerCase().match(this.data?.userSearchText?.toLowerCase()));
    } else {
      this.data.userList = [...this.data.statePreseve.user];
      this.data.selectedUsers = [...this.data.statePreseve.selectUser];
    }
  }


  enableAddExternalUserIcon() {
    return this.commonService.validateEmail(this.data.userSearchText);
  }

  saveExtrenalUser() {
    if (!this.commonService.validateEmail(this.data.userSearchText)) {
      this.commonService.showToastMsg("Email ID is not valid", 'warn');
      return;
    }
    let newUserPayload: any = {
      email: this.data.userSearchText,
      firstName: "",
      lastName: "",
      password: "",
      roles: ["EXTERNAL_PATHOLOGIST"],
      isExternalUser: true
    }
    this.userCreationInProgress = true;
    this.userService.createUser(newUserPayload).pipe(finalize(() => this.userCreationInProgress = false)).subscribe((res: any) => {
      if (res.success) {
        // this.data?.userList.push({
        //   email: this.data.userSearchText,
        //   firstName: "",
        //   lastName: "",
        //   password: "",
        //   roles: ["EXTERNAL_PATHOLOGIST"]
        // });
        this.data.statePreseve?.user?.push({
          emailId: this.data.userSearchText,
          firstName: "",
          lastName: "",
          password: "",
          roles: ["EXTERNAL_PATHOLOGIST"]
        });
        this.data.userList = [...this.data.statePreseve.user];
        this.data.userSearchText = '';
      }
    });
  }


  onAccessChange(event: any, user: any) {
    if (event.checked) {
      user.access[event.source.value] = true;
      // event.preventDefault(); 
    } else
      user.access[event.source.value] = false;
    // user.access=event.source.value;
  }

  userPermissionChange(event: any, user: any,) {
    if (event.checked) {
      user[event.source.value] = true;
    } else {
      user[event.source.value] = false;
    }
  }

  onCheckOfUserForUnShare(email: any) {
    const index: number = this.data.selectedUsersForUnshare.indexOf(email);
    if (index !== -1) {
      this.data.selectedUsersForUnshare.splice(index, 1);
    } else {
      this.data.selectedUsersForUnshare.push(email);
    }
  }

  OnSubmitShareProject() {
    if (this.data.selectedUsers.length || this.data.selectedUsersLength) {
      let variable = this.data.functionCall(this.data.indvProject, this.data, this.dialogRef);
    } else {
      this.commonService.showToastMsg(this.translate.instant("COMMONDIALOG.shareProjectValidataion"), 'warn');
    }
  }

  // selected(emailId: any) {
  //   let user = this.data.userList.filter((u: any) => { return u.user == emailId });
  //   this.onCheckSelectUser(user[0]);
  //   if (this.input) {
  //     this.input.nativeElement.value = "";
  //   }
  // }

  selected(userData: any) {
    // if (this.data.openPopUpFor == 'shareStudy') {
    //   this.onCheckSelectUser(userData);
    // } else {
    //   this.onCheckSelectUserForProject(userData);
    // }
    this.onCheckSelectUser(userData);
    if (this.input) {
      this.input.nativeElement.value = "";
    }
  }

  onCheckSelectUser(checkedUser: any) {
    this.data.userSearchText = '';
    checkedUser.downloadPermission = this.config?.shareConfig?.downloadConfig?.downloadPermission;
    checkedUser.annotationPermission = this.config?.shareConfig?.annotationConfig?.annotationPermission;
    checkedUser.shareReviewPermission = this.config?.shareConfig?.shareReviewConfig?.shareReviewPermission;
    const indexInUserList = this.data.userList.findIndex((u: any) => { return u.emailId === checkedUser.emailId });
    let user = this.data.userList.splice(indexInUserList, 1);
    let indexOfUser = this.data.statePreseve.user.findIndex((u: any) => { return u.emailId == user[0].emailId });
    let previousSelectionId: any[] = this.data?.studySharedWithUser?.filter((prviousItm: any) => {
      return prviousItm?.emailId == checkedUser?.emailId;
    })
    if (previousSelectionId?.length) {
      user[0]['id'] = previousSelectionId[0]?.id;
    }
    this.data.selectedUsers.push(user[0]);
    this.data.statePreseve.selectUser.push(user[0]);
    this.data.statePreseve.user.splice(indexOfUser, 1);
    this.data.userList = [...this.data.statePreseve.user];
  }

  onCheckSelectUserForProject(checkedUser: any) {
    this.data.userSearchText = '';
    checkedUser['access'] = {
      "DOWNLOAD": this.config?.shareConfig?.downloadConfig?.downloadPermission,
      "ANNOTATION_IMPORT_EXPORT": this.config?.shareConfig?.annotationConfig?.annotationPermission,
      "SHARE_ANALYSIS_RESULT": this.config?.shareConfig?.shareReviewConfig?.shareReviewPermission,
      "READ": true
    }
    const indexInUserList = this.data.userList.findIndex((u: any) => { return u.emailId === checkedUser.emailId });
    let user = this.data.userList.splice(indexInUserList, 1);
    let indexOfUser = this.data.statePreseve.user.findIndex((u: any) => { return u.emailId == user[0].emailId });
    let previousSelectionId: any[] = this.data?.studySharedWithUser?.filter((prviousItm: any) => {
      return prviousItm?.emailId == checkedUser?.emailId;
    })
    if (previousSelectionId?.length) {
      user[0]['id'] = previousSelectionId[0]?.id;
    }
    this.data.selectedUsers.push(user[0]);
    this.data.statePreseve.selectUser.push(user[0]);
    this.data.statePreseve.user.splice(indexOfUser, 1);
    this.data.userList = [...this.data.statePreseve.user];
  }

  onUnCheckSelectUser(uncheckedUser: any) {
    uncheckedUser.downloadPermission = this.config?.shareConfig?.downloadConfig?.downloadPermission;
    uncheckedUser.annotationPermission = this.config?.shareConfig?.annotationConfig?.annotationPermission;
    uncheckedUser.shareReviewPermission = this.config?.shareConfig?.shareReviewConfig?.shareReviewPermission;
    const indexInSelectedList = this.data.selectedUsers.findIndex((u: any) => { return u.emailId === uncheckedUser.emailId });
    let user = this.data.selectedUsers.splice(indexInSelectedList, 1);
    const indexOfUser = this.data.statePreseve.selectUser.findIndex((u: any) => {
      return u.emailId == user[0].emailId;
    });
    this.data.statePreseve.selectUser.splice(indexOfUser, 1);
    this.data.statePreseve.user.push(user[0]);
    this.onUserSearchChange();
    this.data.userSearchText = '';
  }

  onUnCheckSelectUserProject(uncheckedUser: any) {
    uncheckedUser.access = {
      "DOWNLOAD": this.config?.shareConfig?.downloadConfig?.downloadPermission,
      "ANNOTATION_IMPORT_EXPORT": this.config?.shareConfig?.annotationConfig?.annotationPermission,
      "SHARE_ANALYSIS_RESULT": this.config?.shareConfig?.shareReviewConfig?.shareReviewPermission,
      "READ": false
    }
    const indexInSelectedList = this.data.selectedUsers.findIndex((u: any) => { return u.emailId === uncheckedUser.emailId });
    let user = this.data.selectedUsers.splice(indexInSelectedList, 1);
    const indexOfUser = this.data.statePreseve.selectUser.findIndex((u: any) => {
      return u.emailId == user[0].emailId;
    });
    this.data.statePreseve.selectUser.splice(indexOfUser, 1);
    this.data.statePreseve.user.push(user[0]);
    this.onUserSearchChange();
    this.data.userSearchText = '';
  }

}
