<div class="modal-header dialog-header">
  <div class="headerTitle">
    <div class="dialog-title">{{data.headerTitle}}</div>
  </div>
</div>
<ng-container *ngIf="data.openPopUpFor == 'addProject'">
  <div class="folderNameInput">
    <mat-form-field appearance="outline" class="commonMatFormField">
      <input matInput [placeholder]="data.headerTitle == 'New Folder'? 'Enter Folder Name' : 'Enter Project Name'"
        (keydown.enter)="data.templateList?.length? opentemplateListingPopup(): OnSubmitAddProject()" maxlength="60"
        [(ngModel)]="data.projectName" cdkFocusInitial>
    </mat-form-field>
    <div class="alertMessage" *ngIf="invalidCredential && !data.projectName">
      Text required.
    </div>
  </div>
</ng-container>

<div *ngIf="data.openPopUpFor == 'shareProject' || data.openPopUpFor == 'shareStudy'" class="shareProjectDialogBody">
  <div class="sharePopUpContentBody">
    <ng-container>
      <div class="leftSideDiv">
        <mat-form-field appearance="outline" class="commonMatFormField">
          <mat-label class="labelbg">Add User</mat-label>
          <input matInput [(ngModel)]="data.userSearchText" (input)="onUserSearchChange()">
          <mat-icon *ngIf="(data.userSearchText && (data.userList.length == 0))"
            [ngClass]="{'disableAddIcon':!enableAddExternalUserIcon()}" class="addExternalUser" matSuffix
            aria-label="Add" (click)="saveExtrenalUser()">add</mat-icon>
        </mat-form-field>
        <ng-container>
          <div class="unSelectedUsersDiv">
            <div *ngFor="let user of this.data?.userList" class="userName">
              <div class="externalUserFlag"
                [title]="user?.roles?.includes('EXTERNAL_PATHOLOGIST') ? 'External User' : 'Internal User' ">
                {{user?.roles?.includes('EXTERNAL_PATHOLOGIST') ? 'E' : 'I'}}</div>
              <div class="userNameLeftDiv">{{user?.emailId}}</div>
              <mat-icon (click)="selected(user)" class="shareCrossIcon">add</mat-icon>
            </div>
          </div>
        </ng-container>

      </div>
      <div [style.visibility]="(data?.selectedUsers?.length)? 'visible': 'hidden' " class="rightSideDiv">
        <div class="accessOptions">
          <span class="accessOptionsTitle" style="margin-left: 0.5vw !important;"
            *ngIf="config?.shareConfig?.downloadConfig?.downloadColumnDisplay">
            {{"DOWNLOAD IMAGE" | titlecase}}
          </span>
          <span class="accessOptionsTitle" *ngIf="config?.shareConfig?.annotationConfig?.annotationColumnDisplay">
            {{"VIEW ANNOTATIONS" | titlecase}}
          </span>
          <span class="accessOptionsTitle" *ngIf="config?.shareConfig?.shareReviewConfig?.shareReviewColumnDisplay">
            {{ data.openPopUpFor == 'shareProject' ? ("SHARE RESULT" | titlecase) : "VIEW REVIEWS" | titlecase}}
          </span>
        </div>
        <div class="selecetedUserDiv">
          <ul *ngFor="let user of data?.selectedUsers" class="selectedUser">
            <div class="divInsideCheckbox unselectedUserName">
              <div class="innerCheckBoxDiv">
                <div class="crossIconDiv">
                  <div class="externalUserFlag"
                    [title]="(user?.role == 'EXTERNAL_PATHOLOGIST' || user?.roles?.includes('EXTERNAL_PATHOLOGIST')) ? 'External User' : 'Internal User' ">
                    {{(user?.role == 'EXTERNAL_PATHOLOGIST' || user?.roles?.includes('EXTERNAL_PATHOLOGIST')) ? 'E' :
                    'I'}}</div>
                  <mat-icon class="shareCrossIcon cursorPointer" (click)="onUnCheckSelectUser(user)"
                    svgIcon="unSelectIcon"></mat-icon>
                </div>
                <div class="elipsisApplied uName">{{user?.emailId ?? user?.user}}</div>
              </div>

            </div>
            <div class="divInsideCheckbox accessPermissionDiv">
              <span class="accessPermission" *ngIf="config?.shareConfig?.downloadConfig?.downloadColumnDisplay">
                <mat-checkbox color="primary" (change)="userPermissionChange($event,user)" value="downloadPermission"
                  [checked]="user.downloadPermission">
                </mat-checkbox>
              </span>
              <span class="accessPermission" *ngIf="config?.shareConfig?.annotationConfig?.annotationColumnDisplay">
                <mat-checkbox color="primary" (change)="userPermissionChange($event,user)" value="annotationPermission"
                  [checked]="user.annotationPermission">
                </mat-checkbox>
              </span>
              <span class="accessPermission" *ngIf="config?.shareConfig?.shareReviewConfig?.shareReviewColumnDisplay">
                <mat-checkbox color="primary" (change)="userPermissionChange($event,user)" value="shareReviewPermission"
                  [checked]="user.shareReviewPermission">
                </mat-checkbox>
              </span>
            </div>
          </ul>
        </div>
      </div>
    </ng-container>
    <div>
    </div>
  </div>
</div>


<div *ngIf="userCreationInProgress" class="userCreationInProgress">
  <div class="userCreationInProgressInnerDiv">
    <div class="userCreationLoaderMessageDiv">
      <mat-spinner style="width: 5vw;
              height: 5vw;" class="dataLoadingSpinner"></mat-spinner>
      <div class="userCreationLoaderMsg">Creating User...</div>
    </div>
  </div>
</div>

<div class="unSelectedUsersDiv unshareList" *ngIf="data.openPopUpFor == 'unShareProject'">
  <ul *ngFor="let user of data.projectSharedWithUser" class="unshareList">
    <mat-checkbox color="primary" (change)="onCheckOfUserForUnShare(user.emailId)">
      {{user.firstName}} {{user.lastName}}
    </mat-checkbox>
  </ul>
</div>
<div class="dispText folderNameInput" *ngIf="data.openPopUpFor ==='deleteProject'">Are you sure you want to delete
  project ?</div>
<div class="dispText folderNameInput" *ngIf="data.openPopUpFor ==='deleteFolder'">Are you sure you want to delete folder
  ?</div>


<ng-container *ngIf="data.openPopUpFor !='AnalysisInfo' ">
  <ng-container *ngIf="data.openPopUpFor !='algorithmListing'">
    <div class="actions dialog-actions modal-footer popUpFooter">
      <button mat-flat-button type="button" class="btn-default cursorPointer" (click)="closePopUp()">Cancel</button>
      <button mat-flat-button type="button" class="searchbtn cursorPointer" cdkFocusInitial
        (click)="OnSubmitAddProject()"
        *ngIf="(data.openPopUpFor == 'addProject' && !data.templateList?.length) || data.openPopUpFor == 'unShareProject' || data.openPopUpFor ==='deleteProject' || data.openPopUpFor ==='deleteFolder'">{{data.confirmButtonText}}</button>

      <button mat-flat-button type="button" class="searchbtn cursorPointer" cdkFocusInitial
        *ngIf="data.templateList?.length" (click)="opentemplateListingPopup()" [disabled]="data.projectName.length <=2">
        Select Template
      </button>

      <button mat-flat-button type="button" class="searchbtn cursorPointer" (click)="OnSubmitShareProject()"
        *ngIf="data.openPopUpFor == 'shareProject' || data.openPopUpFor == 'shareStudy'">{{data.confirmButtonText}}</button>
    </div>
  </ng-container>

  <!-- <ng-container *ngIf="data.openPopUpFor =='algorithmListing'">
      <div class="actions dialog-actions modal-footer popUpFooter">
        <button mat-flat-button type="button" class="searchbtn cursorPointer" cdkFocusInitial
          (click)="startAnalysis()">Analyse</button>
      </div>
    </ng-container> -->
</ng-container>