import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChangePasswordService } from '../change-password/change-password.service';
import { AuthService } from '../auth/auth.service';
import { Router } from '../../../node_modules/@angular/router';
import { CommonService } from '../common.service';
import { MustMatch } from '../Input.Validators'
import { AppConfigService } from '../app-config.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.less']
})
export class ChangePasswordComponent implements OnInit {

  oHide: boolean = true;
  nHide: boolean = true;
  cnHide: boolean = true;
  passChngSucs: boolean = false;
  msg: any = 'Password changed successfully.';
  beError: any = '';
  isFirstLogin: boolean = false;
  showChangepasswordLoader: boolean = false;
  logoutTimer: number = 3;

  changePwdform: FormGroup;
  passwordPolicyArr: any = [];
  passwordCheckMsg: any;
  newPasswordCheckMsg: any;

  constructor(@Optional() private dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, public appConfig: AppConfigService,
    private fb: FormBuilder, public translate: TranslateService, private changePasswordService: ChangePasswordService,
    private commonService: CommonService,
    private authService: AuthService, private router: Router) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    dialogRef.disableClose = true;

  }

  ngOnInit(): void {
    this.isFirstLogin = this.commonService.ProjectLSData?.isfirstLogin;
    this.changePwdform = this.fb.group({
      'oldPwd': ['', Validators.required],
      'newPwd': ['', [Validators.required]],
      'confirmPwd': ['', [Validators.required]]
    }, {
      validator: MustMatch('newPwd', 'confirmPwd')
    });
    this.passwordCheckMsg = this.translate.instant("ERROR.NEWPWD.NEWPASSD_OLDPASSD_CHECK");
    this.newPasswordCheckMsg = this.translate.instant("ERROR.CONFIRMPWD.MUSTMATCH"),
      this.getPasswordPolicy();
  }


  closeDialog() {
    if (this.isFirstLogin) {
      this.authService.logout().subscribe((response) => {
        this.router.navigate(['/login']);
      });
    } else {
      this.dialogRef.close();
    }
  }

  get formControls() { return this.changePwdform.controls; }


  getPasswordPolicy() {
    this.changePasswordService.getPassPolicy().subscribe((response: any) => {
      if (response.success) {
        this.passwordPolicyArr = response.data?.passwordPolicies?.filter((item: any) => {
          return item['regex'];
        });
        this.passwordPolicyArr.forEach((element: any) => {
          element['verified'] = false;
        });
      }
    })
  }

  get username() {
    return this.changePwdform.get('newPwd');
  }



  changePaswrd() {
    this.msg = '';
    this.beError = '';
    if (this.changePwdform.valid) {
      this.showChangepasswordLoader = true;
      let data = {
        "oldPassword": this.changePwdform.value.oldPwd.trim(),
        "newPassword": this.changePwdform.value.newPwd.trim()
      }
      this.changePasswordService.changePassword(data).subscribe((response: any) => {
        if (response.success) {
          this.authService.logoutAPICall().subscribe((response) => {
            this.authService.clearLocalStorage();
            this.authService.removeTokens();
            this.passChngSucs = true;
            this.msg = 'Password changed successfully.';
            // this.dialogRef.close('success');
            this.setLogoutTimer();
          });
        } else {
          this.passChngSucs = false;
          this.showChangepasswordLoader = false
          //this.beError = response.message;
          this.commonService.showToastMsg(response.message, 'error');
        }
      }, (err: any) => {
        this.showChangepasswordLoader = false;
      });
    } else {
      //  handled error through formbulider
    }
  }

  setLogoutTimer() {
    setInterval(() => {
      if (this.logoutTimer > 0) {
        this.logoutTimer -= 1;
      }
      if (this.logoutTimer == 0) {
        this.dialogRef.close('success');
      }
    }, 1000);
  }

  // redirectToLogin() {
  //   this.closeDialog();
  //   this.router.navigate(['/login']);
  // }
}
