<div class="auditReviewMainDiv">
    <div class="filterViewPopupMainDiv" [ngClass]="{'dn': !showFilterView}">
        <div class="filterViewPopup">
            <div class="filterViewPopupHeader">
                <span>Selected Filter View</span>
                <mat-icon class="closeFilterViewPopup" (click)="showFilterView = false">clear</mat-icon>
            </div>
            <div class="filterViewPopupBody">
                <div class="filterViewTopDiv dispFlex">
                    <div class="filterViewTopSubDiv auditType"
                        [ngClass]="{'defaultAlign': !headerFields.selectedTimeStamp}">
                        <div class="topSubDivTitle">Audit Type</div>
                        <div class="topSubDivValue">{{filterPopupHeader}}</div>
                    </div>
                    <div class="filterViewTopSubDiv timeStamp" *ngIf="headerFields.selectedTimeStamp">
                        <div class="topSubDivTitle">Timestamp</div>
                        <div class="topSubDivValue">{{ headerFields.selectedTimeStamp | date:'dd-MMM-yyyy, h:mm a'}}
                        </div>
                    </div>
                    <div class="filterViewTopSubDiv">
                        <ng-container *ngIf="auditView">
                            <button mat-flat-button color="primary" class="indvAuditHeaderIcon viewBtn"
                                [disabled]="!(headerFields.selectedTimeStampStartDate && headerFields.selectedTimeStampEndDate)"
                                (click)="fetchAuditData()">
                                <span class="commonHeight">View</span>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="annotationView">
                            <button mat-flat-button color="primary" class="indvAuditHeaderIcon viewBtn"
                                [disabled]="!(headerFields.selectedStudyName && headerFields.selectedSlides?.length)"
                                (click)="fetchAuditData()">
                                <span class="commonHeight">View</span>
                            </button>
                        </ng-container>
                        <ng-container *ngIf="slideView">
                            <button mat-flat-button color="primary" class="indvAuditHeaderIcon viewBtn"
                                [disabled]="!(headerFields.selectedStudyName)" (click)="fetchAuditData()">
                                <span class="commonHeight">View</span>
                            </button>
                        </ng-container>

                    </div>
                    <div class="filterViewTopSubDiv filterBtn">
                        <button mat-mini-fab color="primary" class="clearFilter mat-elevation-z0">
                            <mat-icon class="customMatIcon"
                                (click)="clearAllFilter('clearFilters')">delete_sweep</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="filterViewScrollDiv">
                    <div class="filterViewAccordianDiv">
                        <mat-accordion class="filterMainMatAccordian" multi>
                            <ng-container
                                *ngFor="let indvField of headerFieldsOptions.selectedAuditFields[headerFields.selectedAuditType]; let i = index; ">
                                <mat-expansion-panel class="indvFilterAccordianExpensionPanel" [expanded]="true"
                                    *ngIf="headerFields[indvField.selectedValue] && (headerFields[indvField.selectedValue].length > 0)">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <span>{{indvField.fieldDispName}}</span>
                                            <span class="indvFilterFieldBadge">
                                                {{headerFields[indvField.selectedValue].length}}
                                            </span>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <mat-chip-list>
                                        <ng-container
                                            *ngIf="getTypeOf(headerFields[indvField.selectedValue]) == 'string'">
                                            <mat-chip class="indvChip">
                                                {{headerFields[indvField.selectedValue]}}
                                                <mat-icon class="removeChip"
                                                    (click)="removeHeaderField(i, indvField.selectedValue)">
                                                    clear</mat-icon>
                                            </mat-chip>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="getTypeOf(headerFields[indvField.selectedValue]) != 'string'">
                                            <ng-container
                                                *ngFor="let indvChipName of headerFields[indvField.selectedValue]; let i = index;">
                                                <mat-chip class="indvChip">
                                                    <ng-container *ngIf="indvField.filterChipName == 'userName'">
                                                        {{this.headerFieldsOptions.auditStudyObj[indvChipName].firstName
                                                        +'
                                                        '+this.headerFieldsOptions.auditStudyObj[indvChipName].lastName}}
                                                    </ng-container>
                                                    <ng-container *ngIf="indvField.filterChipName == 'default'">
                                                        {{indvChipName}}
                                                    </ng-container>
                                                    <ng-container *ngIf="indvField.filterChipName == 'tissue'">
                                                        {{headerFieldsOptions.tissueObj[indvChipName]}}
                                                    </ng-container>
                                                    <ng-container *ngIf="indvField.filterChipName == 'species'">
                                                        <!-- {{headerFieldsOptions.speciesObj[i]}} -->
                                                        {{indvChipName}}
                                                    </ng-container>
                                                    <mat-icon class="removeChip"
                                                        (click)="removeHeaderField(i, indvField.selectedValue)">
                                                        clear</mat-icon>
                                                </mat-chip>
                                            </ng-container>
                                        </ng-container>
                                    </mat-chip-list>
                                </mat-expansion-panel>
                            </ng-container>
                        </mat-accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="auditHeader">
        <div class="auditHeaderText">Audit</div>
        <div class="auditDownload">
            <ng-container
                *ngIf="auditView || annotationView || slideView || headerFields.selectedAuditType =='SlideReview'">
                <button mat-flat-button mat-mini-fab color="primary" (click)="downloadReport()"
                    [disabled]="!downloadEnabled" class="mat-elevation-z0 downloadRptBtn">
                    <i class="fa fa-download" aria-hidden="true"></i>
                </button>
            </ng-container>

            <ng-container *ngIf="auditView">
                <button mat-flat-button color="primary" class="indvAuditHeaderIcon viewBtn"
                    [disabled]="!(headerFields.selectedTimeStampStartDate && headerFields.selectedTimeStampEndDate)"
                    (click)="fetchAuditData()">
                    <span class="commonHeight">View</span>
                </button>
            </ng-container>
            <ng-container *ngIf="annotationView">
                <button mat-flat-button color="primary" class="indvAuditHeaderIcon viewBtn"
                    [disabled]="!(headerFields.selectedStudyName && headerFields.selectedSlides?.length && headerFields.selectedTimeStampStartDate && headerFields.selectedTimeStampEndDate)"
                    (click)="fetchAuditData()">
                    <span class="commonHeight">View</span>
                </button>
            </ng-container>
            <ng-container *ngIf="slideView">
                <button mat-flat-button color="primary" class="indvAuditHeaderIcon viewBtn"
                    [disabled]="!(headerFields.selectedStudyName)" (click)="fetchAuditData()">
                    <span class="commonHeight">View</span>
                </button>
            </ng-container>

            <ng-container *ngIf="headerFields.selectedAuditType =='SlideReview'">
                <button mat-flat-button color="primary" class="indvAuditHeaderIcon viewBtn"
                    [disabled]="!(headerFields.selectedStudyType && headerFields.selectedStudyName)"
                    (click)="fetchAuditData()">
                    <span class="commonHeight">View</span>
                </button>
            </ng-container>
        </div>
    </div>
    <div class="auditHeaderFilterMainDiv">
        <div class="auditHeaderFilter">
            <div class="auditHeaderFilterLeftDiv">
                <div class="indvAuditHeaderFilter">
                    <mat-form-field appearance="outline" class="commonMatFormField">
                        <mat-label class="mandatoryLabel">Audit Type</mat-label>
                        <mat-select [(ngModel)]="headerFields.selectedAuditType"
                            (selectionChange)="auditTypeSelectionChanged(headerFields.selectedAuditType)"
                            (openedChange)="auditHeaderSelectPopupClosed('auditType')">
                            <div class="auditTypeSearch">
                                <input matInput placeholder="Audit Type Search"
                                    [(ngModel)]="searchFields.searchAuditType"
                                    (keyup)="onKeyPressSearch($event.target.value, 'auditType')">
                            </div>
                            <mat-option *ngFor="let indvAuditType of headerFieldsOptions.searchedAuditTypeDataArr"
                                [value]="indvAuditType.auditType">
                                {{indvAuditType.auditTypeDispName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <!-- <button mat-button [matMenuTriggerFor]="roles">Audit Type</button> -->
                    <!-- <mat-form-field appearance="outline">
                        <mat-label class="mandatoryLabel">Audit Type</mat-label>
                        <mat-select [matMenuTriggerFor]="roles" [matMenuTriggerFor]="roles"
                            #levelOneTrigger="matMenuTrigger" (click)="levelOneTrigger.openMenu()" >
                            {{headerFields.selectedAuditType}}
                        </mat-select>
                    </mat-form-field> -->

                    <!-- <ng-container *ngIf="headerFields.selectedAuditType">
                        <span>{{headerFields.selectedAuditType}}</span>
                    </ng-container> -->
                    <mat-menu #roles="matMenu">
                        <button mat-menu-item [matMenuTriggerFor]="applicationAdmin">Application Admin </button>
                        <button mat-menu-item [matMenuTriggerFor]="studyDirector">Study Director</button>
                        <button mat-menu-item [matMenuTriggerFor]="technician">Technician</button>
                        <button mat-menu-item [matMenuTriggerFor]="pathologist">Pathologist</button>
                    </mat-menu>

                    <mat-menu #applicationAdmin="matMenu">
                        <button mat-menu-item (click)="auditSelected('Fieldd Configuration')">Field
                            Configuration</button>
                        <button mat-menu-item (click)="auditSelected('Data Configuration')">Data Configuration</button>
                        <button mat-menu-item (click)="auditSelected('Folder Location')">Folder Location</button>
                        <button mat-menu-item (click)="auditSelected('User')">User</button>
                    </mat-menu>

                    <mat-menu #studyDirector="matMenu">
                        <button mat-menu-item (click)="auditSelected('Study Creation')">Study Creation</button>
                    </mat-menu>
                    <mat-menu #technician="matMenu">
                        <button mat-menu-item (click)="auditSelected('Image Mapping')">Image Mapping</button>
                    </mat-menu>

                    <mat-menu #pathologist="matMenu">
                        <button mat-menu-item (click)="auditSelected('Annotation')">Annotation</button>
                        <button mat-menu-item (click)="auditSelected('Slide Status')">Slide Status</button>
                        <button mat-menu-item (click)="auditSelected('Slide Review')">Slide Review</button>
                    </mat-menu>

                </div>

                <ng-container *ngIf="headerFields.selectedAuditType">

                    <div class="indvAuditHeaderFilter"
                        [class.seperateField]="(indvField.dependentOn != '') && (headerFieldsOptions.selectedAuditFields[headerFields.selectedAuditType][i-1].dependentOn == '')"
                        *ngFor="let indvField of headerFieldsOptions.selectedAuditFields[headerFields.selectedAuditType]; let i = index;">

                        <ng-container
                            *ngIf="indvField.fieldType == 'datetime' && headerFields.selectedAuditType != 'SlideReview'">
                            <mat-form-field appearance="outline">
                                <mat-label class="mandatoryLabel">{{indvField.fieldDispName}}</mat-label>
                                <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date"
                                    [(ngModel)]="headerFields[indvField.selectedValue]" [max]="today">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #picker [color]="'warn'" [enableMeridian]="true">
                                </ngx-mat-datetime-picker>
                            </mat-form-field>
                        </ng-container>

                        <ng-container
                            *ngIf="indvField.fieldType == 'dateRange'  && headerFields.selectedAuditType != 'SlideReview'  && headerFields.selectedAuditType != 'SlideStatus'">
                            <mat-form-field appearance="outline" class="dateRangeFormField commonMatFormField">
                                <mat-label class="mandatoryLabel">{{indvField.fieldDispName}}</mat-label>
                                <mat-date-range-input [rangePicker]="picker" [max]="today">
                                    <input matStartDate [(ngModel)]="headerFields.selectedTimeStampStartDate"
                                        placeholder="Start date" [max]="today">
                                    <input matEndDate [(ngModel)]="headerFields.selectedTimeStampEndDate"
                                        placeholder="End date">
                                </mat-date-range-input>
                                <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
                                <mat-datepicker-toggle matSuffix [for]="picker"
                                    class="dateRangeIcon"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="indvField.fieldType == 'selectBox'">
                            <mat-form-field appearance="outline" class="commonMatFormField">
                                <!-- <mat-label class="mandatoryLabel">{{indvField.fieldDispName}}</mat-label> -->
                                <mat-label
                                    [class.mandatoryLabel]="indvField.dependentOn == ''">{{indvField.fieldDispName}}</mat-label>
                                <mat-select [(ngModel)]="headerFields[indvField.selectedValue]"
                                    [multiple]="indvField.isMulti"
                                    (openedChange)="auditHeaderSelectPopupClosed(indvField)"
                                    (selectionChange)="chipSetDecoration(indvField.selectedValue)">
                                    <div class="auditTypeSearch" *ngIf="indvField.isOptionSearchable">
                                        <input matInput [placeholder]="indvField.searchFieldDispName"
                                            [(ngModel)]="searchFields[indvField.searchField]"
                                            (keyup)="onKeyPressSearch($event.target.value, indvField.fieldName)">
                                    </div>

                                    <ng-container *ngIf="indvField.isDefaultOptionsArr">
                                        <mat-option
                                            *ngFor="let indvOption of (headerFieldsOptions[indvField.optionArr][headerFields.selectedAuditType] ? headerFieldsOptions[indvField.optionArr][headerFields.selectedAuditType] : headerFieldsOptions[indvField.optionArr])"
                                            [value]="indvOption">
                                            {{indvOption}}
                                        </mat-option>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="!indvField.isDefaultOptionsArr&& headerFields.selectedAuditType=='Annotation' && indvField.fieldName =='slide'">
                                        <!-- <mat-option #matOption (click)="selectAllSlides(matOption.selected)">
                                            Select All
                                        </mat-option> -->
                                        <div class="selectAllCheckBox" headerFi><mat-checkbox
                                                (change)="selectAllSlides($event)" class="mat-option"
                                                [checked]="headerFields?.selectedSlides?.length == headerFieldsOptions?.slideArr?.length"
                                                [(color)]="matCheckBoxColor" *ngIf="">
                                                Select All
                                            </mat-checkbox></div>
                                        <ng-container *ngFor="let indvOption of headerFieldsOptions.searchedSlideArr">
                                            <mat-option [value]="indvOption">
                                                {{indvOption}}
                                            </mat-option>
                                        </ng-container>

                                    </ng-container>

                                    <ng-container
                                        *ngIf="!indvField.isDefaultOptionsArr && indvField.fieldName == 'tissue'">
                                        <mat-option *ngFor="let indvTissue of headerFieldsOptions.searchedTissueArr"
                                            [value]="indvTissue.organ">
                                            {{indvTissue.organ}}
                                        </mat-option>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="!indvField.isDefaultOptionsArr && indvField.fieldName == 'userName'">
                                        <mat-option
                                            *ngFor="let indvAuditUser of headerFieldsOptions.searchedAuditUsersArr"
                                            [value]="indvAuditUser.emailId">
                                            {{indvAuditUser.firstName+' '+indvAuditUser.lastName}}
                                        </mat-option>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="!indvField.isDefaultOptionsArr && indvField.fieldName == 'species'">
                                        <mat-option *ngFor="let indvSpecies of headerFieldsOptions.searchedSpeciesArr"
                                            [value]="indvSpecies.species">
                                            {{indvSpecies.species}}
                                        </mat-option>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="!indvField.isDefaultOptionsArr && indvField.fieldName == 'studyName'">
                                        <mat-option
                                            *ngFor="let indvStudyName of headerFieldsOptions.searchedAuditStudyArr"
                                            [value]="indvStudyName">
                                            {{indvStudyName}}
                                        </mat-option>
                                    </ng-container>
                                    <!-- <ng-container
                                        *ngIf="!indvField.isDefaultOptionsArr && indvField.fieldName == 'studyType'">
                                        <mat-option                                           
                                            *ngFor="let indvStudyType of (headerFieldsOptions[indvField.optionArr][indvField.fieldName] ? headerFieldsOptions[indvField.optionArr][headerFields.selectedAuditType] : headerFieldsOptions[indvField.optionArr])"
                                            [value]="indvStudyType">
                                            {{indvStudyType}}
                                        </mat-option>
                                    </ng-container> -->
                                    <!-- *ngFor="let indvStudyType of headerFieldsOptions.searchedStudyTypeArr" -->
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                    </div>

                    <div class="indvAuditHeaderFilter filterPopup"
                        [ngStyle]="{'order': headerFieldsOptions.selectedAuditVisibleFields[headerFields.selectedAuditType]?.length }">
                        <button mat-mini-fab color="primary" (click)="showFilterView = true">
                            <mat-icon class="filterIcon">filter_list</mat-icon>
                        </button>
                    </div>
                </ng-container>

            </div>
        </div>

        <div class="chipsetMainDiv dispFlex" [hidden]="isSubHeaderContainerHidden">
            <div class="chipsetSection userNameChipSet"
                *ngIf="isViewRequested && headerFields.selectedUser && (headerFields.selectedUser?.length > 0)">
                <div class="chipsetTitle">Username: </div>
                <mat-chip-list>
                    <mat-chip class="indvChip" *ngFor="let indvUserName of headerFields.selectedUser; let i = index;">
                        <div class="chipText">{{this.headerFieldsOptions.auditStudyObj[indvUserName].firstName +'
                            '+this.headerFieldsOptions.auditStudyObj[indvUserName].lastName}}</div>
                        <mat-icon class="removeChip" (click)="removeHeaderField(i, 'selectedUser')">clear</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
            <div class="chipsetSection actionChipSet"
                *ngIf="isViewRequested && headerFields.chipSetAction && (headerFields.selectedAction?.length > 0)">
                <div class="chipsetTitle">Action: </div>
                <mat-chip-list>
                    <mat-chip class="indvChip"
                        *ngFor="let indvActionName of headerFields.selectedAction; let i = index;">
                        <div class="chipText">{{indvActionName}}</div>
                        <mat-icon class="removeChip" (click)="removeHeaderField(i, 'selectedAction')">clear
                        </mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </div>
            <div class="moreCount"><button (click)="showFilterView = true" [hidden]="(hiddenElemCount == 0)" mat-button
                    color="primary">+ {{hiddenElemCount}} more</button></div>
        </div>
    </div>

    <ng-container *ngIf="isTableDisplayed">
        <div class="auditDispTableData">
            <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>

                <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions" class="iconsHeader">
                        <!-- <mat-checkbox (change)="selectAllRows($event)" color="primary" [checked]="isAllRowSelected"
                            [indeterminate]="headerFields.selectedRow.length > 0 && !isAllRowSelected">
                        </mat-checkbox> -->
                    </th>
                    <td mat-cell *matCellDef="let element,let i=index">
                        <div class="iconsDiv dispFlex">
                            <mat-checkbox (click)="$event.stopPropagation()" color="primary" class="cursorPointer"
                                [checked]="element.isChecked" (change)="toggleRowSelection($event, element)">
                            </mat-checkbox>

                            <button mat-icon-button aria-label="expand row"
                                [class.hiddenRow]="element.details.length == 0"
                                *ngIf="element.details.length || element.code || element.colour || element.dataType"
                                (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()"
                                class="cursorPointer">
                                <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                                <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                            </button>
                            <!-- <div class="dsIcon">
                                <mat-icon svgIcon="dsIcon" *ngIf="element.dsComments"></mat-icon>
                            </div> -->

                        </div>
                    </td>
                </ng-container>
                <!-- User Name -->
                <ng-container matColumnDef="actionBy">
                    <th mat-header-cell *matHeaderCellDef class="userNameHeader"> Username
                    </th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied" title="{{element.actionBy}}">
                        {{element.actionBy}} </td>
                </ng-container>
                <!-- field Data -->
                <ng-container matColumnDef="fieldName">
                    <th mat-header-cell *matHeaderCellDef class="fieldNameHeader"> Field Name
                    </th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied" title="{{element.dataType}}">
                        {{element.dataType}} </td>
                </ng-container>
                <!-- fieldName -->
                <ng-container matColumnDef="fieldData">
                    <th mat-header-cell *matHeaderCellDef class="fieldDataHeader"> Field Data
                    </th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied" title="{{element.description}}">
                        {{element.description}} </td>
                </ng-container>

                <!-- 'templateType' -->
                <ng-container matColumnDef="templateType">
                    <th mat-header-cell *matHeaderCellDef class="templateTypeHeader">Template type
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.dataType}} </td>
                </ng-container>
                <!-- 'templateName' -->
                <ng-container matColumnDef="templateName">
                    <th mat-header-cell *matHeaderCellDef class="studyMappingNameHeader"> Name
                    </th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied">{{element.templateName}} </td>
                </ng-container>
                <!-- 'templateDesc' -->
                <ng-container matColumnDef="templateDesc">
                    <th mat-header-cell *matHeaderCellDef class="studyMappingNameHeader"> Description
                    </th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied">{{(element.description) ?
                        element.description : '--'}} </td>
                </ng-container>
                <!--  'folderName, 'folderLocation' -->
                <ng-container matColumnDef="folderName">
                    <th mat-header-cell *matHeaderCellDef class="folderNameHeader"> Folder Name
                    </th>
                    <td mat-cell *matCellDef="let element" classs="ellipsisApplied" title="{{element.name}}">
                        {{element.name}} </td>
                </ng-container>
                <!-- 'folderLocation' -->
                <ng-container matColumnDef="folderLocation">
                    <th mat-header-cell *matHeaderCellDef class="folderLocationHeader"> Folder Location
                    </th>
                    <td mat-cell *matCellDef="let element" classs="ellipsisApplied" title="{{element.location}}">
                        {{element.location}}</td>
                </ng-container>
                <!-- firstName -->
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef> First Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
                </ng-container>
                <!-- lastName -->
                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef> Last Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
                </ng-container>

                <!-- lastName -->
                <ng-container matColumnDef="fullName">
                    <th mat-header-cell *matHeaderCellDef class="userAuditNameHeader"> Name
                    </th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied"
                        title="{{element.firstName}} {{element.lastName}} ">{{element.firstName}} {{element.lastName}}
                    </td>
                </ng-container>

                <!--EmailId -->
                <ng-container matColumnDef="EmailId">
                    <th mat-header-cell *matHeaderCellDef class="userAuditEmailHeader"> Email
                    </th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied" title="{{element.email}}">
                        {{element.email}} </td>
                </ng-container>

                <!-- 'createdBy','stateTimeStamp','Role' -->
                <!-- createdBy -->
                <ng-container matColumnDef="createdBy">
                    <th mat-header-cell *matHeaderCellDef class="actionByHeader"> Action By
                    </th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied" title="{{element.actionBy}}">
                        {{element.actionBy}} </td>
                </ng-container>
                <!-- state -->
                <ng-container matColumnDef="TimeStamp">
                    <th mat-header-cell *matHeaderCellDef> State </th>
                    <td mat-cell *matCellDef="let element"> {{element.action}} </td>
                </ng-container>
                <!-- Enabled/Disabled -->
                <ng-container matColumnDef="details">
                    <th mat-header-cell *matHeaderCellDef class="actionHeader">Action</th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied">{{element.groupAction}}</td>
                </ng-container>

                <!-- Action -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef>Action
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.action}}
                    </td>

                </ng-container>
                <!-- Timestamp -->
                <!-- Role -->
                <ng-container matColumnDef="Role">
                    <th mat-header-cell *matHeaderCellDef class="userAuditRoleHeader"> Roles
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="assignRoles" *ngFor="let item of element.roles" title="{{item}}">
                            {{item | slice:0:1}}</div>
                    </td>
                </ng-container>
                <!-- Timestamp -->
                <ng-container matColumnDef="timeStamp">
                    <th mat-header-cell *matHeaderCellDef> Timestamp </th>
                    <!-- <td mat-cell *matCellDef="let element"> {{element.timeStamp | date:'dd-MMM-yyyy, h:mm a' }} </td> -->
                    <td mat-cell *matCellDef="let element"> {{element.timeStamp}} </td>
                </ng-container>

                <!-- new Fields added from herer -->

                <!--slideNo status  -->
                <ng-container matColumnDef="slideNo">
                    <th mat-header-cell *matHeaderCellDef class="slideNoHeader">
                        Slide No. </th>
                    <td mat-cell *matCellDef="let element"> {{element.slideNo}} </td>
                </ng-container>
                <!-- status -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef class="userAuditStatusHeader">
                        Status</th>
                    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>
                <!-- studyName -->
                <ng-container matColumnDef="studyName">
                    <th mat-header-cell *matHeaderCellDef class="studyNameHeader">
                        Study Name</th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied" title="{{element.study_title}} ">
                        {{element.study_title}} </td>
                </ng-container>
                <!-- studyName   slideId findings tissue -->
                <!-- slideId -->
                <ng-container matColumnDef="slideId">
                    <th mat-header-cell *matHeaderCellDef class="slideIdHeader">
                        Slide Id</th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied"> {{element.slideNo}} </td>
                </ng-container>
                <!-- finding -->
                <ng-container matColumnDef="finding">
                    <th mat-header-cell *matHeaderCellDef class="findingHeader">
                        Finding</th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied" title="{{element.status}}">
                        {{element.status}} </td>
                </ng-container>
                <!-- tissue -->
                <ng-container matColumnDef="tissue">
                    <th mat-header-cell *matHeaderCellDef class="tissueHeader">
                        Tissue</th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied" title="{{element.organ}}">
                        {{element.organ}} </td>
                </ng-container>
                <!-- studyNo studyType species technicial -->
                <!-- studyNo-->
                <ng-container matColumnDef="studyNo">
                    <th mat-header-cell *matHeaderCellDef class="studyNoHeader">
                        Study No.</th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied" title="{{element.study_no}}">
                        {{element.study_no}} </td>
                </ng-container>
                <!-- studyType -->
                <ng-container matColumnDef="studyType">
                    <th mat-header-cell *matHeaderCellDef class="studyTypeColumnHeader">
                        Study Type</th>
                    <td mat-cell *matCellDef="let element"> {{element.studyCat}} </td>
                </ng-container>
                <!-- species -->
                <ng-container matColumnDef="species">
                    <th mat-header-cell *matHeaderCellDef>
                        Species</th>
                    <td mat-cell *matCellDef="let element"> {{element.species}} </td>
                </ng-container>
                <!-- technician -->
                <ng-container matColumnDef="technician">
                    <th mat-header-cell *matHeaderCellDef class="technicianHeader">
                        Technician</th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied" title="{{element.technician}}">
                        {{element.technician}} </td>
                </ng-container>
                <!-- pathalogiest -->
                <ng-container matColumnDef="pathologist">
                    <th mat-header-cell *matHeaderCellDef class="pathologistHeader">
                        Pathologist</th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied" title="{{element.pathologist}}">
                        {{element.pathologist}} </td>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="!element.pathologist">
                            --
                        </span>
                    </td>
                </ng-container>
                <!-- annnotation -->
                <ng-container matColumnDef="annotation">
                    <th mat-header-cell *matHeaderCellDef class="annotationHeader">
                        Annnotation</th>
                    <td mat-cell *matCellDef="let element"> {{element.annotationType}} </td>
                </ng-container>
                <!-- study -->
                <ng-container matColumnDef="study">
                    <th mat-header-cell *matHeaderCellDef class="imageMappingStudyHeader">
                        Study</th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied" title="{{element.name}}">
                        {{element.name}} </td>
                </ng-container>
                <!-- count -->
                <ng-container matColumnDef="count">
                    <th mat-header-cell *matHeaderCellDef class="countHeader">
                        Count</th>
                    <td mat-cell *matCellDef="let element"> {{element.mapCount}}/ {{element.totalImageCount}} </td>
                </ng-container>
                <!-- DS Comment -->
                <ng-container matColumnDef="dsComment">
                    <th mat-header-cell *matHeaderCellDef>
                        DS Comment
                    </th>
                    <td mat-cell *matCellDef="let element" title="{{element.dsComments}}">{{element.dsComments}}
                        <ng-container *ngIf="!element.dsComments" class="ellipsisApplied">
                            <div>
                                --
                            </div>
                        </ng-container>
                    </td>
                </ng-container>
                <!-- description -->
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef class="descriptionHeader">
                        Description</th>
                    <td mat-cell *matCellDef="let element" class="ellipsisApplied" title="{{element.description}}">
                        {{element.description}}
                        <ng-container *ngIf="!element.description">
                            <div>
                                --
                            </div>
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element"
                        [attr.colspan]="headerFieldsOptions.tableFieldDisplay[selectedAuditForTable].length">
                        <div class="expansionData">
                            <!-- <div class="dsCommentDiv auditTableElementDetail"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                <div class="dsComment" *ngIf="element.dsComments">
                                    <span class="tileTitle">DS Comments:</span>
                                    <small>
                                        {{element.dsComments}}
                                    </small>
                                </div>
                            </div> -->


                            <div class="tableElementsDiv">
                                <ng-container *ngIf="headerFields.selectedAuditType == 'DataConfiguration'">
                                    <div class="auditTableElementDetail"
                                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <ng-container *ngIf="element.dataType">
                                            <div class="updateElementDetail">
                                                <div class="expandDetailsTitle">Data Type</div>
                                                <div class="expandData">
                                                    <div class="enabledData">
                                                        {{element.dataType}}
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="headerFields.selectedAuditType == 'DataConfiguration'">
                                    <div class="auditTableElementDetail"
                                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <ng-container *ngIf="element.code">
                                            <div class="updateElementDetail">
                                                <div class="expandDetailsTitle">Code</div>
                                                <div class="expandData">
                                                    <div class="enabledData">
                                                        {{element.code}}
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>


                                <ng-container *ngIf="headerFields.selectedAuditType == 'DataConfiguration'">
                                    <div class="auditTableElementDetail"
                                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <ng-container *ngIf="element.colour">
                                            <div class="updateElementDetail">
                                                <div class="expandDetailsTitle">Color</div>
                                                <div class="expandData">

                                                    <div class="colorSelect" title="{{element.colour}}">
                                                        <input class="grading gradingColorPicker cssTooltip" matInput
                                                            [style.background]="element.colour" readonly />
                                                    </div>

                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </ng-container>

                            </div>

                            <div class="tableElementsDiv"
                                *ngIf="headerFields.selectedAuditType == 'FieldConfiguration' || headerFields.selectedAuditType == 'StudyCreation' || headerFields.selectedAuditType == 'UsersAudit' || headerFields.selectedAuditType == 'StudyMappingStructure' || headerFields.selectedAuditType == 'SlideReview'">
                                <div class="auditTableElementDetail"
                                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                                    *ngFor="let item of element.details">
                                    <div class="updateElementDetail"
                                        *ngIf="headerFields.selectedAuditType != 'UsersAudit'  && !(headerFields.selectedAuditType == 'SlideReview' && element.studyCat == 'TCR')">
                                        <div class="expandDetailsTitle">{{item.field}}</div>
                                        <!-- <ng-container
                                            *ngIf="headerFields.selectedAuditType == 'StudyCreation' || headerFields.selectedAuditType == 'UsersAudit' || headerFields.selectedAuditType == 'StudyMappingStructure'">
                                            <div><small class="expandDetailsData">Previous: {{item.previous}}</small></div>
                                            <div><small class="expandDetailsData">Updated: {{item.updated}}</small></div>
                                        </ng-container> -->
                                        <div class="expandData"
                                            *ngIf="headerFields.selectedAuditType == 'FieldConfiguration'">
                                            <ng-container *ngIf="item.previous != '-'">
                                                <div
                                                    [ngClass]="item.previous == 'Enabled' ? 'enabledData' : 'disabledData'">
                                                    {{item.previous}}</div>
                                                <mat-icon class="indicator">arrow_right</mat-icon>
                                            </ng-container>
                                            <div [ngClass]="item.updated == 'Enabled' ? 'enabledData' : 'disabledData'">
                                                {{item.updated}}</div>
                                        </div>
                                        <div class="expandData"
                                            *ngIf="headerFields.selectedAuditType == 'StudyCreation'">
                                            <ng-container *ngIf="item.previous != '-'">
                                                <div [ngClass]="item.previous != '-' ? 'disabledData' : 'disabledData'">
                                                    {{item.previous}}</div>
                                                <mat-icon class="indicator">arrow_right</mat-icon>
                                            </ng-container>
                                            <div [ngClass]="item.updated != '-' ? 'enabledData' : 'disabledData'">
                                                {{item.updated}}</div>
                                        </div>
                                        <div class="expandData"
                                            *ngIf="headerFields.selectedAuditType == 'StudyMappingStructure'">
                                            <ng-container *ngIf="item.previous != '-'">
                                                <div
                                                    [ngClass]="item.previous != '' && item.updated == '-' ? 'deleteStudyMapping' : 'disabledData'">
                                                    {{item.previous}}</div>
                                                <mat-icon class="indicator"
                                                    *ngIf="item.updated != '-'">arrow_right</mat-icon>
                                            </ng-container>
                                            <ng-container *ngIf="item.updated != '-'">
                                                <div
                                                    [ngClass]="item.previous == '-' && item.updated != '' ? 'enabledData' : 'enabledData'">
                                                    {{item.updated}}</div>
                                            </ng-container>
                                        </div>
                                        <ng-container *ngIf="headerFields.selectedAuditType == 'SlideReview'">
                                            <div class="expandData">
                                                <!-- <div class="slideReviewData">
                                                    <div class="slideReviewDataFont">
                                                        Observation:
                                                    </div>
                                                    <div class="slideReviewDataFont">
                                                        Grade:
                                                    </div>
                                                </div>

                                                <div *ngIf="item.action == 'Updated'" class="slideUpdatedDetails">
                                                    <div class="disabledData">
                                                        <p class="slideReviewDetails">{{item.previousObservation}}</p>
                                                        <p class="slideReviewDetails">{{item.previousGrade}}</p>
                                                    </div>
                                                    <mat-icon class="indicator">arrow_right</mat-icon>
                                                    <div class="enabledData">
                                                        <p class="slideReviewDetails">{{item.observation}}</p>
                                                        <p class="slideReviewDetails">{{item.grade}}</p>
                                                    </div>
                                                </div>
                                                <div *ngIf="item.action == 'Deleted'" class="deleteStudyMapping">
                                                    <p class="slideReviewDetails">{{item.previousObservation}} </p>
                                                    <p class="slideReviewDetails">{{item.previousGrade}} </p>
                                                </div>
                                                <div *ngIf="item.action == 'Created'" class="enabledData">
                                                    <p class="slideReviewDetails">{{item.observation}} </p>
                                                    <p class="slideReviewDetails">{{item.grade}} </p>
                                                </div> -->


                                                <!--  -->
                                                <ng-container *ngIf="element.studyCat =='TOX'">
                                                    <div *ngIf="item.action == 'Updated'" class="slideUpdatedDetails">
                                                        <div class="disabledData">
                                                            <p class="slideReviewDetails"> {{item.previousObservation}}
                                                                -
                                                                {{item.previousGrade}}</p>
                                                        </div>
                                                        <mat-icon class="indicator">arrow_right</mat-icon>
                                                        <div class="enabledData">
                                                            <p class="slideReviewDetails">{{item.observation}} -
                                                                {{item.grade}}</p>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="item.action == 'Deleted'" class="deleteStudyMapping">
                                                        <p class="slideReviewDetails">{{item.previousObservation}} -
                                                            {{item.previousGrade}}</p>
                                                    </div>
                                                    <div *ngIf="item.action == 'Created'" class="enabledData">
                                                        <p class="slideReviewDetails">{{item.observation}} -
                                                            {{item.grade}}
                                                        </p>
                                                    </div>
                                                </ng-container>

                                                <!-- <ng-container *ngIf="element.studyCat =='TCR'">
                                                <div *ngIf="element.action == 'Edited'" class="slideUpdatedDetails">
                                                    <div class="disabledData">
                                                        <p class="slideReviewDetails"> {{item.previous}} -
                                                            {{item.previousGrade}}</p>
                                                    </div>
                                                    <mat-icon class="indicator">arrow_right</mat-icon>
                                                    <div class="enabledData">
                                                        <p class="slideReviewDetails">{{item.observation}} -
                                                            {{item.grade}}</p>
                                                    </div>
                                                </div>
                                                <div *ngIf="item.action == 'Deleted'" class="deleteStudyMapping">
                                                    <p class="slideReviewDetails">{{item.previousObservation}} -
                                                        {{item.previousGrade}}</p>
                                                </div>
                                                <div *ngIf="item.action == 'Created'" class="enabledData">
                                                    <p class="slideReviewDetails">{{item.observation}} - {{item.grade}}
                                                    </p>
                                                </div>
                                            </ng-container> -->

                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="updateElementDetailUser"
                                        *ngIf="headerFields.selectedAuditType == 'UsersAudit' || (headerFields.selectedAuditType == 'SlideReview' && element.studyCat == 'TCR')">
                                        <div class="expandDetailsTitle">{{item.field}}</div>
                                        <div class="expandData"
                                            *ngIf="headerFields.selectedAuditType == 'UsersAudit' || (headerFields.selectedAuditType == 'SlideReview' && element.studyCat == 'TCR')">
                                            <ng-container *ngIf="item.previous != '-'">
                                                <div [ngClass]="item.previous != '-' ? 'disabledData' : 'disabledData'">
                                                    {{item.previous}}</div>

                                                <mat-icon class="indicator">arrow_right</mat-icon>
                                            </ng-container>
                                            <div [ngClass]="item.updated != '-' ? 'enabledData' : 'disabledData'">
                                                {{item.updated}}</div>

                                            <div class="expandData" *ngIf="item.field == 'Colour'">
                                                <div class="colorSelectAnnotation" title="{{item.updated}}">
                                                    <input class="grading gradingColorPicker cssTooltip colorBoxHeight"
                                                        matInput [style.background]="item.updated" readonly />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="auditTableElementDetail"
                                *ngIf="headerFields.selectedAuditType == 'SlideReview' && element.studyCat!='TCR'"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                <div class="commentsSpacing"> <span class="expandDetailsTitle">Comments:
                                    </span>
                                    <span class="expandDetailsDataDetails">
                                        <small>{{element.comments}}</small>
                                    </span>
                                </div>
                            </div>

                            <div class="auditTableElementDetail"
                                *ngIf="headerFields.selectedAuditType == 'FolderLocation'"
                                [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                <div class="commentsSpacing" *ngIf="element.description"> <span
                                        class="expandDetailsTitle">Description:
                                    </span>
                                    <span class="expandDetailsDataDetails">
                                        <small>{{element.description}}</small>
                                    </span>
                                </div>
                            </div>



                            <div class="tableElementsDiv" *ngIf="headerFields.selectedAuditType == 'Annotation'">
                                <div class="auditTableElementDetail"
                                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                                    *ngFor="let item of element.details">
                                    <div class="updateElementDetail"
                                        *ngIf="(item.updated != '-'  && item.updated.trim() != '' )">
                                        <div class="expandDetailsTitle">{{item.field}}</div>
                                        <div class="expandData" *ngIf="item.field != 'Colour'"><small
                                                class="enabledData">{{item.updated}}</small></div>
                                        <div class="expandData" *ngIf="item.field == 'Colour'">
                                            <div class="colorSelectAnnotation" title="{{item.updated}}">
                                                <input class="grading gradingColorPicker cssTooltip colorBoxHeight"
                                                    matInput [style.background]="item.updated" readonly />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tableElementsDiv" *ngIf="(headerFields.selectedAuditType == 'ImageMapping')">
                                <div class="auditTableElementDetail"
                                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                                    *ngFor="let item of element.details">
                                    <div class="updateElementDetailImage">
                                        <!-- <div class="expandDetailsTitle">Image Name:</div> -->
                                        <div>
                                            <div class="expandDetailsDataTitle ellipsisApplied"
                                                [title]="item.imageName">
                                                <!-- <small>{{item.imageName}}</small> -->
                                                <!-- <span class="imageNameInMapping">{{item.imageName}}</span> -->
                                                {{item.imageName}}
                                            </div>
                                            <div class="imgMappingComments">
                                                <span *ngIf="element.action =='Unmapped'"
                                                    class="expandDetailsTitle ImgMappingCmtTtl">Comments:
                                                    <!-- <div *ngIf="element.action =='Unmapped'" class="expandDetailsTitle"> -->
                                                </span>
                                                <span class="expandDetailsDataDetails"
                                                    *ngIf="element.action =='Unmapped'">
                                                    <small class="commentSpan ellipsisApplied"
                                                        [title]="item.comments">{{item.comments}}</small>
                                                </span>
                                            </div>
                                        </div>
                                        <!-- <div>
                                            <span *ngIf="element.action =='Unmapped'"
                                                class="expandDetailsTitle">Comments:
                                             
                                            </span>
                                            <span class="expandDetailsDataDetails" *ngIf="element.action =='Unmapped'">
                                                <small>{{item.comments}}</small>
                                            </span>
                                        </div> -->
                                        <div *ngIf="element.action =='Mapped'">
                                            <span *ngIf="element.action =='Mapped'" class="expandDetailsTitle">Location:
                                                <!-- <div *ngIf="element.action =='Mapped'" class="expandDetailsTitle"> -->
                                            </span>
                                            <span class="expandDetailsDataDetails">
                                                <small class="ellipsisApplied imgMappingPath"
                                                    [title]="item.location">{{item.location}}</small>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="tableElementsDiv" *ngIf="(headerFields.selectedAuditType == 'StudyCreation')">
                                <div class="auditTableElementDetail tableWidth"
                                    [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                    <ng-container *ngIf="element.action == 'Created'">
                                        <mat-accordion [togglePosition]="'before'">
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        Cassettes
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>
                                                <mat-panel-description>
                                                    <table class="scrollDown">
                                                        <thead>
                                                          
                                                            <tr class="tableHeader">
                                                                <th> Cassette Id </th>
                                                                <th>Tissue</th>
                                                                <th>Stain</th>
                                                            </tr>
                                                          

                                                        </thead>
                                                        <tbody>
                                                          
                                                            <ng-container
                                                                *ngFor="let item of element.cassettes | keyvalue">
                                                                <tr class="tableData">
                                                                    <td> {{item.key}} </td>
                                                                    <td>{{item.value.tissue}}</td>
                                                                    <td>{{item.value.stain}}</td>
                                                                </tr>
                                                            </ng-container>
                                                           
                                                        </tbody>
                                                    </table>
                                                </mat-panel-description>
                                            </mat-expansion-panel>
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        Dosages
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>
                                                <mat-panel-description>
                                                    <table class="scrollDown">
                                                        <thead>
                                                           
                                                            <tr class="tableHeader">
                                                                <th>Control Group</th>
                                                                <th> Dosage Group</th>
                                                                <th>Dose</th>
                                                                <th>Dosage Unit</th>
                                                                <th>Female</th>
                                                                <th>Male</th>
                                                                <th>Not Known</th>
                                                                <th>Type</th>
                                                            </tr>
                                                           
                                                        </thead>
                                                        <tbody>
                                                           
                                                            <ng-container *ngFor="let item of element.dosages">
                                                                <tr class="tableData">
                                                                    <td>{{item.controlGroup}}</td>
                                                                    <td>{{item.dosageGroup}}</td>
                                                                    <td>{{item.dose}}</td>
                                                                    <td>{{item.doseUnit}}</td>
                                                                    <td>{{item.female}}</td>
                                                                    <td>{{item.male}}</td>
                                                                    <td>{{item.notKnown}}</td>
                                                                    <td>{{item.type}}</td>
                                                                </tr>
                                                            </ng-container>
                                                           
                                                        </tbody>

                                                    </table>
                                                </mat-panel-description>
                                            </mat-expansion-panel>
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>
                                                        Slide Details
                                                    </mat-panel-title>
                                                </mat-expansion-panel-header>
                                                <mat-panel-description>
                                                    <table class="scrollDown">
                                                        <thead>
                                                           
                                                            <tr class="tableHeader">
                                                                <th>Slide Id</th>
                                                                <th>Image name</th>
                                                                <th>Instrument</th>
                                                                <th>Magnification</th>
                                                                <th>Scan date</th>
                                                                <th>Slide Control</th>
                                                                <th>Tissue Section</th>
                                                            </tr>
                                                           
                                                        </thead>
                                                        <tbody>
                                                            
                                                            <ng-container *ngFor="let item of element.slideDetails">
                                                                <tr class="tableData">
                                                                    <td>{{item.slideId}}</td>
                                                                    <td>{{item.imageName}}</td>
                                                                    <td>{{item.instrument}}</td>
                                                                    <td>{{item.magnification}}</td>
                                                                    <td>{{item.scanDate}}</td>
                                                                    <td>{{item.slideControl}}</td>
                                                                    <td>{{item.tissueSection}}</td>
                                                                </tr>
                                                            </ng-container>
                                                          
                                                        </tbody>

                                                    </table>
                                                </mat-panel-description>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                    </ng-container>

                                </div>
                            </div> -->
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row
                    *matHeaderRowDef="headerFieldsOptions.tableFieldDisplay[selectedAuditForTable]; sticky: true"
                    class="auditTableHeader"></tr>
                <ng-container>
                    <tr mat-row
                        *matRowDef="let element;let i= index; columns:headerFieldsOptions.tableFieldDisplay[selectedAuditForTable];"
                        class="auditTableElementRow" [class.auditTableDetailExpanded]="expandedElement === element">
                    </tr>
                </ng-container>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="auditTableDetailRow"></tr>
            </table>
        </div>
    </ng-container>
    <!-- <ng-container>
        <div class="infoColorsMainDiv">
            <div class="infoColorsDiv">
                <input matInput [style.background]="updatedColor" readonly class="infoColors" /> : Updated value
            </div>
            <div class="infoColorsDiv">
                <input matInput [style.background]="previousColor" readonly class="infoColors" /> : Previous value
            </div>
        </div>
    </ng-container> -->

    <ng-container *ngIf="emptyState">
        <div class="emptyStateDivWrapper">
            <div class="emptyStateDiv">
                <img _ngcontent-lfg-c389=""
                    [src]="'../../../' + appConfig.config?.webAppConfig?.appBaseName + 'assets/images/viewer_icon/no_study_project.png'">
            </div>
            <!-- <div class="noDataMessage" *ngIf="noAuditData">No data to display.</div> -->
        </div>
    </ng-container>

    <!-- <ng-container > -->
    <div class="matSpinnerDivWrapper" *ngIf="loaderState">
        <div class="matSpinnerDiv">
            <mat-spinner color="primary"></mat-spinner>
        </div>
    </div>
    <!-- </ng-container> -->
</div>