import { unique, prop, alphaNumeric, required, minLength, maxLength } from '@rxweb/reactive-form-validators';
export class cassetteModel {
    @required()
    @unique()
    @maxLength({value:255})
    cassetteId: any;
    // @alphaNumeric()
    // private _cassetteId: any = '';
    private _stains : Array<String> = [];
    private _organs: Array<String> = [];
    private _slideUniqueIds: Array<String> = [];
    
    @prop()
    newCassette: Boolean;

    @prop()
    rowType: String;

    @prop()
    initCassetteId: string;

    @required()
    @minLength({value:1})
    @prop()
    set stains(value: any) {
        this._stains = value;
    }

    get stains() {
        return this._stains;
    }

    @required()
    @minLength({value:1})
    @prop()
    set organs(value: any){
        this._organs = value
    }

    get organs() {
        return this._organs;
    }

    @prop()
    set slideUniqueIds(value: any) {
        this._slideUniqueIds = value;
    }

    get slideUniqueIds() {
        return this._slideUniqueIds;
    }

    /* @required()
    @unique()
    @prop()
    set cassetteId(value: any) {
        this._cassetteId = value;
    }
    get cassetteId() {
        return this._cassetteId;
    } */

}