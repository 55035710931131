<section class="pageContent">
  <div class="pageheader">
    <!-- <div class="pageheadtitle">
      <h4>Digital Signature</h4>
    </div> -->
    <div class="float-right dsEnableHeaders">
      <mat-slide-toggle class="matDsToggle matResponsiveToggle" [color]="'primary'" [checked]="isDSEnable"
        (change)="enableDigitalSignature()">
      </mat-slide-toggle>
      <div *ngIf="toggleDSEnable" class="matDsSave">
        <button type="button" mat-mini-fab color="primary" (click)="openDialog()" [disabled]="getLength() === 0"
          [ngClass]="{ disabledButton: getLength() === 0 }">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <mat-accordion multi class="accContainer" *ngIf="toggleDSEnable">
    <mat-expansion-panel *ngFor="let role of newRoles;" [expanded]="true" class="dsMatPanel"
      [togglePosition]="'before'">
      <mat-expansion-panel-header class="dsMatHeader">
        <mat-panel-title class="dsRoleTitle">
          {{ role == 'StudyDirector' ? 'Study Director' : role}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div matExpansionPanelContent class="dsMatContent">
        <ng-container *ngFor="let authorization of actions">
          <div class="subModulesContainer" *ngIf="authorization.actions[0].roles == role">  <!-- && !(authorization.topic == 'Annotation') -->
            <p *ngIf="authorization.actions[0].roles == role && authorization.topic != role" class="moduleTitle">
              {{authorization.topic}}
            </p>
            <span class="subModulesContainerData" *ngIf="authorization.topic == 'Annotation'">
              <div class="subModulesContainerDetails">
                <mat-label class="label label-bold lblGroup">Single</mat-label>
                <mat-slide-toggle [color]="'primary'" class="matResponsiveToggle" [checked]="singleAnnotation"
                  (change)="onClickAnnotation(authorization.actions)">
                </mat-slide-toggle>
              </div>
            </span>
            <ng-container *ngFor="let action of authorization.actions">
              <div class="subModulesContainerData" *ngIf="action.roles == role">
                <div class="subModulesContainerDetails"
                  [ngClass]="{'noBorder' : (action.actions == authorization.actions[authorization.actions.length -1].actions)}">
                  <mat-label class="label label-bold">{{ action.actions }}</mat-label>
                  <mat-slide-toggle [color]="'primary'" class="matResponsiveToggle" [checked]="action.dvenabled"
                    (click)="onClickAction(action)">
                  </mat-slide-toggle>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </mat-accordion>