import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/common.service';
import { ConfigurationService } from 'src/app/configuration/configuration.service';

@Component({
  selector: 'app-missing-stain-tissue',
  templateUrl: './missing-stain-tissue.component.html',
  styleUrls: ['./missing-stain-tissue.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class MissingStainTissueComponent implements OnInit {
  organArr: any = [];
  stainArr: any = [];
  dosageArr: any[] = [];
  organAddedArr: any = [];
  stainAddedArr: any = [];
  dosageAddedArr: any[] = [];
  isUserAdmin: boolean = false;
  constructor(@Optional() private dialogRef: MatDialogRef<MissingStainTissueComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private configService: ConfigurationService,
    private commonService: CommonService) { }

  ngOnInit(): void {
    for (let x = 0; x < this.commonService?.ProjectLSData?.userRoleList?.length; x++) {
      if (this.commonService.ProjectLSData.userRoleList[x].role == "APPLICATION_ADMIN") {
        this.isUserAdmin = true;
        break;
      }
    }
    this.organArr = this.data?.missingData?.missingOrgans;
    this.stainArr = this.data?.missingData?.missingStains;
    this.dosageArr = this.data?.missingData?.missingDosages;
  }

  addMissingMetaData(code: any, dataType: string) {
    let classCode = '';
    if (dataType === 'organ') {
      classCode = 'tissues.class';
    } else if (dataType === 'stain') {
      classCode = 'stain.class';
    } else {
      classCode = 'dosage.class';
    }
    this.configService.addDataConfigurationDetails({ classCode, code }).subscribe(() => {
      this[(dataType == 'organ') ? 'organAddedArr' : dataType === 'stain' ? 'stainAddedArr' : 'dosageAddedArr'].push(code);
      this.data[(dataType == 'organ') ? 'organOptions' : dataType === 'stain' ? 'stainsOptions' : 'dosageOptions'].push(code);
      if ((this.organArr?.length == this.organAddedArr?.length) && (this.stainArr?.length == this.stainAddedArr?.length) && (this.dosageArr?.length === this.dosageAddedArr?.length)) {
        this.dialogRef.close("success");
      }
    });
  }

  cancelDialog() {
    this.dialogRef.close("cancel");
  }

}
