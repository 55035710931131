<!--Tab and Search-->
<div class="page-content">
  <div *ngIf="activeRole !== 'TECHNICIAN' && activeRole !== 'STUDY_DIRECTOR'">
    <div class="myStudybar" *ngIf="(activeRole == 'APPLICATION_ADMIN')">
      <div (click)="getStudyDirectorStudies()" *ngIf="(activeRole != 'APPLICATION_ADMIN')">
        <h6 class="tabliketitle headerTabText" [ngClass]="{'active': mainActiveTab == 'study_listing'}">Study
          Listing
        </h6>
      </div>
      <div class="studySearchInputDiv" *ngIf="(activeRole == 'APPLICATION_ADMIN' && !shouldHideSearch)">
        <div class="input-group StudiesListinInAdminHeader">
          <div class="studySearchMainDiv">

            <mat-form-field appearance="outline" class="searchBox commonInputBox">
              <input matInput id="studysearch" placeholder="Search" [(ngModel)]="search">

             <mat-icon matPrefix class="searchIcon cursorPointer">search</mat-icon>
              <mat-icon matSuffix *ngIf="search " (click)="clearSearchText()" class="cursorPointer">close</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  code ends here -->
  <div *ngIf="(activeRole != 'APPLICATION_ADMIN')" class="studyListingHeader">
    <div class="filterinlineview">
      <ul class="dropdown-menu multiselectfilter" *ngIf="activeRole == 'PATHOLOGIST' || activeRole == 'pathologist'">
        <form [formGroup]="searchForm">
          <li class="form-control-li">
            <label class="form-control-label" for="studytitle">Study No.</label>
            <input type="text" class="form-control-field" id="studytitle" formControlName="title" />
          </li>
          <li class="form-control-li">
            <label class="form-control-label" for="species">Species</label>
            <select name="species" id="species" class="form-control-field" formControlName="species">
              <option *ngFor="let species of speciesArr" [ngValue]="species.species">{{species.species}}</option>
            </select>
          </li>
          <li class="form-control-li">
            <label class="form-control-label" for="creationDate">Date Created</label>
            <input type="date" name="creationDate" id="creationDate" class="form-control-field"
              formControlName="creationDate">
          </li>
          <li>
            <div class="row filterbtn">
              <!-- <div class="col-md-3 col-lg-3 col-xl-3"></div> -->
              <div class="col-sm-12 filterbtn">
                <button type="button" (click)="onSearch()" class="btn-default searchbtn"
                  aria-label="searchbtn">Search</button>
                <button type="button" (click)="onCancel()" class="btn-default" aria-label="cancelbtn">Close</button>
                <button type="button" (click)="onReset()" class="btn-default" aria-label="resetbtn">Reset</button>
              </div>
            </div>
          </li>
        </form>
      </ul>
      <div (click)="$event.stopPropagation();" *ngIf="!noTableData && !showLoader && activeRole !== 'PATHOLOGIST'">
        <mat-form-field class="commonMatFormField commonMatFormField_selectdropdown" appearance="outline">
          <mat-label class="labelbgViewBy">Status</mat-label>
          <mat-select multiple [(ngModel)]="selectedStatus" (click)="$event.stopPropagation();"
            (selectionChange)="applyFilter()">
            <mat-option *ngFor="let status of studyStatuses" [title]="status.title"
              [value]="status.value">{{status.title }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="studySearchMainDiv" *ngIf="!noTableData && !showLoader"
        [ngClass]="{'pathologistStudySearch': activeRole == 'PATHOLOGIST' || activeRole == 'pathologist'}">
        <mat-form-field appearance="outline" class="searchBox commonInputBox">
          <input matInput placeholder="Search" id="studysearch" placeholder="Search" [(ngModel)]="search">

          <mat-icon matPrefix class="">search</mat-icon>
          <mat-icon matSuffix *ngIf="search " (click)="clearSearchText()" class="cursorPointer">close</mat-icon>
        </mat-form-field>
      </div>
      <button *ngIf="activeRole?.toLowerCase() === 'technician' && !(noTableData && !showLoader)" title="{{ CREATE_STUDY }}"
        class="searchbtn studylistingBtn" (click)="openCreateStudyDialog()"><i
          class="fa fa-plus"></i></button>
    </div>
  </div>
  <div *ngIf="showLoader" class="studyListingSpinner">
    <mat-spinner class="dataLoadingSpinner"></mat-spinner>
  </div>
  <div *ngIf="showLoaderForMapping" class="studyListingSpinnerForMapping">
    <div class="mappingLoaderDivInner">
      <div class="loaderMessageDiv">
        <mat-spinner style="width: 5vw;
                height: 5vw;" class="dataLoadingSpinner"></mat-spinner>
        <div class="mappingLoaderMsg">Loading... <br> Please Wait...</div>
      </div>
    </div>

  </div>
  <div *ngIf="noTableData && !showLoader" id="preview_hidden" class="noStudyProject">
    <img
      [src]="'../../../' + configService.config?.webAppConfig?.appBaseName + 'assets/images/viewer_icon/no_study_project.png'" />

      <button *ngIf="activeRole?.toLowerCase() === 'technician'" mat-flat-button color="primary" class="btnWhiteText" (click)="openCreateStudyDialog()">
        <!-- <mat-icon class="addIcon filterIcon">add</mat-icon> -->
        Add
      </button>
  </div>
  <div *ngIf="!noTableData && !showLoader" class="study-listing-mat-table" #matTable>
    <app-mat-data-table
      #matDataTable
      (rowClick)="handleMatDataTableClick($event)"
      (menuItemClick)="handleMatDataTableClick($event)"
      (formFieldValueChange)="onFormFieldValueChange($event)"
      (matDataTableLoaded)="onMatDataTableLoaded()"
      [dataSource]="studyDataSource" 
      [displayedColumns]="studyDisplayedColumns">
    </app-mat-data-table>
    <table id="p_study_table" #dataTable class="hover row-border">
    </table>
  </div>
</div>