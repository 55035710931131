import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomDatePipe } from '../common/custom-date.pipe';
import { MatDataTableComponent } from './mat-data-table/mat-data-table.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { AppMaterialModule } from '../app-material/app-material.module';
import { PasswordGenerationComponent } from './password-generation/password-generation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    CustomDatePipe,
    MatDataTableComponent,
    PasswordGenerationComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule],
  exports: [
    CustomDatePipe,
    MatDataTableComponent,
    PasswordGenerationComponent
  ]
})
export class SharedModule { }
