import { CommonService } from '../../common.service';
import { ConfigurationService } from './../../configuration/configuration.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators, ValidatorFn } from '@angular/forms';
import { StudyService } from '../study.service';
import { response } from 'express';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-location-mapping-dialog',
  templateUrl: './location-mapping-dialog.component.html',
  styleUrls: ['./location-mapping-dialog.component.less']
})
export class LocationMappingDialogComponent implements OnInit {
  onClose = new EventEmitter();
  folderLocationArr = [];
  folderLocationArr1 = [];
  mapForm: FormGroup;
  showLoader: boolean = true;
  filterSearchText: any = '';
  activeFolderLocationId: any;
  activeLocation: any;
  locationMappingSelectionLevel: any = [];
  loader: boolean = true;
  loader1: boolean = true;
  activeSourceSelectAll: boolean = false;
  innnerLevelSelectAll: boolean = false;
  selectedFolderLocations: any = [];
  search: any;
  lastOpenedFolder: any;
  selectedLocationIds: any = [];
  constructor(private formbuilder: FormBuilder, @Optional() private dialogRef: MatDialogRef<LocationMappingDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private configurationService: ConfigurationService,
    private commonService: CommonService,
    private studyService: StudyService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.mapForm = this.formbuilder.group({
      folderLocation: new FormArray([], this.minSelectedCheckboxes(1))
    });
    this.studyService.getStudyMappedLocation(this.data.studyData.studyGuid).subscribe((res: any) => {
      this.data.studyData.imageFolderModel = res.data;
      this.configurationService.getAllFolderLocationNonEmpty().subscribe((res: any) => {
        this.showLoader = false;
        this.loader = false;
        if (res.success) {
          this.folderLocationArr = this.commonService.sortByMultiKey(res.data, ['name']);
          this.folderLocationArr.forEach((o, i) => {
            const control = new FormControl();
            (this.mapForm.controls.folderLocation as FormArray).push(control);
            o.selection = false;

          });
          this.folderLocationArr1 = [...this.folderLocationArr];
          if (this.data.studyData.imageFolderModel.length > 0) {
            this.data.studyData.imageFolderModel.forEach(element => {
              var index = this.folderLocationArr.map(function (e) {
                return e.id;
              }).indexOf(element.id)
              this.mapForm.get('folderLocation')['controls'][index].setValue(true);
              this.mapForm.get('folderLocation')['controls'][index].disable();
            });
          }
          setTimeout(() => {
            $('#filterSearch').on('keyup click', (e) => {
              let searchVal = e.currentTarget["value"];
              this.filterSearchText = e.currentTarget["value"];
              if (searchVal) {
                $(".folderLocation_border").closest(".locationMainDiv").hide();
                $(".folderLocation_border[data-locationname*='" + searchVal.toLowerCase() + "']").closest(".locationMainDiv").show();
              } else {
                $(".folderLocation_border").closest(".locationMainDiv").show();
              }
            });
          }, 1000);
        }
      });
    }, error => {
      this.loader = false;
    });
    this.selectedFolderLocations = [];
    this.selectedLocationIds = [];
  }

  get mapF() {
    return this.mapForm.controls;
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  closeDialog() {
    this.selectedFolderLocations = [];
    this.selectedLocationIds = [];
    this.dialogRef.close();
  }

  mapLocationFolder() {
    const selectedPreferences = this.mapForm.getRawValue().folderLocation
      .map((checked, index) => checked ? this.folderLocationArr[index] : null)
      .filter(value => value !== null);
    this.mapForm.value.folderLocation = selectedPreferences;
    let folderList = this.mapForm.value.folderLocation.map(function (value) {
      return value.id;
    });
    let data = {
      "studyGuid": this.data.studyData.studyGuid,
      "folderIdList": [... new Set(this.selectedLocationIds)],
      "folderLocations": this.selectedFolderLocations
    }
    this.onClose.emit(data);
    this.dialogRef.close();
  }



  clearSearchForFolder() {
    this.search = '';
    if (this.locationMappingSelectionLevel?.length) {
      this.locationMappingSelectionLevel[this.locationMappingSelectionLevel.length - 1]['displayData'] = [...this.locationMappingSelectionLevel[this.locationMappingSelectionLevel.length - 1]['displayDataBackup']];
    } else {
      this.activeLocation['displayData'] = [...this.activeLocation['displayDataBackup']];
    }
  }

  clearSearchText() {
    // distpatch not working 
    // const event = new KeyboardEvent('keyup');
    // let inputBox:any = $('#filterSearch');
    // const input = document.getElementsByTagName("input")[0];
    // input.dispatchEvent(new KeyboardEvent('keyup'));
    let inputBox: any = $('#filterSearch');
    this.filterSearchText = '';
    this.folderLocationArr = [...this.folderLocationArr1];
    $(".folderLocation_border").closest(".locationMainDiv").show();
    $('#filterSearch').val('');
  }

  activeFolderLocation(location: any, returnHome: boolean = false) {
    if (location?.id == this.activeFolderLocationId && !returnHome) return;
    // if (location.selection) {
    //   let newEvent: any = {
    //     'checked': false
    //   }
    //   this.folderSelection(location, newEvent);
    // } else {
    //   let newEvent: any = {
    //     'checked': true
    //   }
    //   this.folderSelection(location, newEvent);
    // }
    this.activeFolderLocationId = '';
    this.activeLocation = null;
    this.lastOpenedFolder = returnHome ? this.locationMappingSelectionLevel[0] : '';
    this.locationMappingSelectionLevel = [];
    this.activeFolderLocationId = location?.id;
    this.activeLocation = location;
    this.getFolderLocationDetails(location);
  }

  getFolderLocationDetails(location: any) {
    this.loader1 = true;
    let divPlace = document.getElementsByClassName('currentOpenedFolder');
    this.studyService.getRepoFolderData(location?.folderLocation).pipe(finalize(() => this.loader1 = false)).subscribe((response: any) => {
      if (response.success) {
        this.activeLocation['displayData'] = [];
        this.activeLocation['displayDataBackup'] = [];
        this.activeLocation['foldersData'] = [];
        response.data[0]?.folderDetails.forEach((item: any) => {
          item.type = 'folder';
          if (this.selectedFolderLocations?.includes(item?.folderLocation)) {
            item.selection = true;
          } else {
            item.selection = false;
          }
          item.selectAll = false;
        });
        response.data[0]?.slideDetails.forEach((item: any) => {
          item.type = 'slide';
        });
        this.activeLocation['foldersData'] = [...response.data[0]?.folderDetails];
        this.activeLocation['displayData'] = [...response.data[0]?.folderDetails, ...response.data[0]?.slideDetails];
        this.activeLocation['displayDataBackup'] = [...this.activeLocation['displayData']];
        setTimeout(() => {
          divPlace[0]?.scrollIntoView({ block: 'center' });
        }, 100);
      }
    });
  }


  navBackToParentFolder(folder: any, index: any) {
    this.lastOpenedFolder = this.locationMappingSelectionLevel[index + 1];
    this.locationMappingSelectionLevel.splice(index + 1);
    this.getChildFolderDetails(folder, true);
  }

  getChildFolderDetails(location: any, navBack: boolean = false) {
    if (location?.type == 'folder') {
      this.search = '';
      this.loader1 = true;
      if (!navBack) {
        this.locationMappingSelectionLevel.push(location);
      }
      let newLocation: any = this.locationMappingSelectionLevel[this.locationMappingSelectionLevel?.length - 1];
      this.studyService.getRepoFolderData(location?.folderLocation).pipe(finalize(() => this.loader1 = false)).subscribe((response: any) => {
        if (response.success) {
          newLocation['displayData'] = [];
          newLocation['displayDataBackup'] = [];
          newLocation['foldersData'] = [];
          response.data[0]?.folderDetails.forEach((item: any) => {
            item.type = 'folder';
            if (this.selectedFolderLocations?.includes(item?.folderLocation)) {
              item.selection = true;
            } else {
              item.selection = false;
            }
            item.selectAll = false;
          });
          response.data[0]?.slideDetails.forEach((item: any) => {
            item.type = 'slide';
          });
          newLocation['foldersData'] = [...response.data[0]?.folderDetails];
          newLocation['displayData'] = [...response.data[0]?.folderDetails, ...response.data[0]?.slideDetails];
          newLocation['displayDataBackup'] = [...newLocation['displayData']];
        }
      });
    }
  }

  selectAllFolders(event: any, source: any) {
    if (event?.checked) {
      let folderItem: any;
      if (source == 'root') {
        this.activeLocation['displayData'].forEach((item: any) => {
          if (item.type == 'folder') {
            item.selection = true;
          }
        })
      } else {
        folderItem = this.locationMappingSelectionLevel[this.locationMappingSelectionLevel?.length - 1];
        folderItem['displayData'].forEach((item: any) => {
          if (item.type == 'folder') {
            item.selection = true;
          }
        })
      }
    } else {
      let folderItem: any;
      if (source == 'root') {
        this.activeLocation['displayData'].forEach((item: any) => {
          if (item.type == 'folder') {
            item.selection = false;
          }
        })
      } else {
        folderItem = this.locationMappingSelectionLevel[this.locationMappingSelectionLevel?.length - 1];
        folderItem['displayData'].forEach((item: any) => {
          if (item.type == 'folder') {
            item.selection = false;
          }
        })
      }
    }
  }

  folderSelection(folder: any, event: any, isParent: boolean = false) {
    if (event?.checked) {
      folder.selection = true;
      this.selectedFolderLocations.push(folder.folderLocation);
      if (isParent) {
        this.selectedLocationIds.push(folder.id);
      } else {
        this.selectedLocationIds.push(this.activeLocation?.id)
      }
    } else {
      folder.selection = false;
      let position: any;
      for (let a = 0; a < this.selectedFolderLocations?.length; a++) {
        if (this.selectedFolderLocations[a] == folder.folderLocation) {
          position = a;
          break;
        }
      }
      if (position >= 0) {
        this.selectedFolderLocations.splice(position, 1);
      }
      let parentId: any = isParent ? folder?.id : this.activeLocation?.id;
      let idPos: any;
      for (let a = 0; a < this.selectedLocationIds?.length; a++) {
        if (this.selectedLocationIds[a] == parentId) {
          idPos = a;
          break;
        }
      }
      if (idPos >= 0) {
        this.selectedLocationIds.splice(idPos, 1);
      }
    }
  }

  searchLocations() {
    if (this.filterSearchText?.length >= 3) {
      this.folderLocationArr = this.folderLocationArr1.filter((item: any) => item.name?.toLowerCase().match(this.filterSearchText?.toLowerCase()));
    } else {
      this.folderLocationArr = [...this.folderLocationArr1];
    }
  }

  searchLocationFolder() {
    if (this.search?.length >= 3) {
      if (this.locationMappingSelectionLevel?.length) {
        this.locationMappingSelectionLevel[this.locationMappingSelectionLevel.length - 1]['displayData'] = this.locationMappingSelectionLevel[this.locationMappingSelectionLevel.length - 1]['displayDataBackup'].filter((item: any) => item.name?.toLowerCase().match(this.search?.toLowerCase()));
      } else {
        this.activeLocation['displayData'] = this.activeLocation['displayDataBackup'].filter((item: any) => item.name?.toLowerCase().match(this.search?.toLowerCase()));
      }
    } else {
      if (this.locationMappingSelectionLevel?.length) {
        this.locationMappingSelectionLevel[this.locationMappingSelectionLevel.length - 1]['displayData'] = [...this.locationMappingSelectionLevel[this.locationMappingSelectionLevel.length - 1]['displayDataBackup']];
      } else {
        this.activeLocation['displayData'] = [...this.activeLocation['displayDataBackup']];
      }
    }
  }
}
