import { AppConfigService } from './../app-config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {
  private config: any;

  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.config = configService.config;
  }

  changePassword(data) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}user/changePassword`, data);
  }

  getPassPolicy() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}user/passwordPolicy`);
  }
}
