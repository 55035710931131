import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppConfigService } from '../app-config.service';
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { StudyDetailsComponent } from "./../study/study-details/study-details.component";
import { CommonService } from '../common.service';
import { Router } from '@angular/router';
import { GalleryService } from '../gallery/gallery.service';
import { UsersService } from '../users/users.service';
import { ModalDigitalSignatureComponent } from '../digital-signature/modal-digital-signature/modal-digital-signature.component';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { AuthService } from '../auth/auth.service';
// import { ImageViewerTopBarService } from '../image-viewer/image-viewer-top-bar/image-viewer-top-bar.service';

@Component({
  selector: 'app-web-viewer-frame',
  templateUrl: './web-viewer-frame.component.html',
  styleUrls: ['./web-viewer-frame.component.css']
})
export class WebViewerFrameComponent implements OnInit {
  urlSafe: SafeResourceUrl;
  id: string = '';
  appConfig: any = {};

  constructor(private sanitizer: DomSanitizer,
    private appConfigService: AppConfigService,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private galleryService: GalleryService,
    private usersService: UsersService,
    private commonService: CommonService) {
    this.appConfig = this.appConfigService.config;
    const msgEvent = (event: any) => {
      if ((event?.data?.call == 'parentComponent') && event?.data?.value?.fetchSlideInfo) {
        if (event?.data?.value?.fetchSlideInfo) {
          this.commonService.fetchProjectLSData();
        }
        this.sendDataToViewer(event?.data?.value?.postMessageCall);
      } else if (event?.data?.call == 'showStudyDetails') {
        this.openStudyDetailDialog(event?.data?.value?.slideGUID);
      } else if (event?.data?.call == 'viewerInitialized') {
        this.commonService.ProjectLSData['isViewerInitialized'] = true;
        this.commonService.setProjectLSData();
      } else if (event?.data?.call == 'updateViewerInfo') {
        let slidesInfo: any = event?.data?.value?.slidesInfo;
        this.commonService.ProjectLSData['ViewerSlides'] = slidesInfo;
        this.commonService.setProjectLSData();
      } else if (event?.data?.call == 'openImageInSameViewer') {
        let slidesInfo: any = event?.data?.value?.slidesInfo;
        this.commonService.ProjectLSData['ViewerSlides'] = slidesInfo;
        if (event?.data?.value?.mrnId) {
          this.commonService.ProjectLSData['MRNID'] = event.data.value.mrnId;
        }
        this.commonService.setProjectLSData();
        this.updateViewerImage('updateViewerImageInfo', {
          ViewerSlides: this.commonService.ProjectLSData['ViewerSlides'],
          newSlideIndex: event.data.value['newSlideIndex']
        });
      } else if (event?.data?.call == 'compareImageViewerUpdate') {
        let slidesInfo: any = event?.data?.value?.slidesInfo;
        if (event?.data?.value?.saveData) {
          this.commonService.ProjectLSData['ViewerSlides'] = slidesInfo;
          if (event?.data?.value?.mrnId) {
            this.commonService.ProjectLSData['MRNID'] = event.data.value.mrnId;
          }
          this.commonService.setProjectLSData();
        }
        this.updateViewerImage('updateCompareViewerImageInfo', {
          ViewerSlides: slidesInfo,
          slideIds: event?.data?.value?.slideIds,
          viewerUpdateInfo: event?.data?.value?.viewerUpdateInfo
        });
      } else if (event?.data?.call == 'unmapImage') {
        let unMapObj: any = event?.data?.value?.unMapObj;
        if (unMapObj) {
          this.galleryService.bulkUnmapImages(unMapObj).subscribe((res: any) => {
            this.commonService.showToastMsg(res.message);
            this.moveToGallery(msgEvent);
          });
        }
      } else if (event?.data?.call == 'compareImage') {
        let slideIds: any = event?.data?.value?.slideIds;
        this.commonService.ProjectLSData['ViewerSlides'] = event?.data?.value?.slidesInfo;
        if (!this.commonService.ProjectLSData['primaryFilter']) {
          this.commonService.ProjectLSData['primaryFilter'] = Object.assign({}, this.commonService.ProjectLSData['galleryFilters']);
          this.commonService.ProjectLSData['galleryFilters']['viewByG'] = event?.data?.value?.compareActive ? '1' : this.commonService.ProjectLSData['galleryFilters']['viewByG'];
          this.commonService.ProjectLSData['galleryFilters']['selectedAccordion'] = event?.data?.value?.compareActive ? event?.data?.value?.slidesInfo[0]?.animal?.dosageCode : this.commonService.ProjectLSData['galleryFilters']['selectedAccordion'];
        } else if (this.commonService.ProjectLSData['primaryFilter'] && event?.data?.value?.compareActive) {
          this.commonService.ProjectLSData['galleryFilters']['viewByG'] = '1';
          this.commonService.ProjectLSData['galleryFilters']['selectedAccordion'] = event?.data?.value?.slidesInfo[0]?.animal?.dosageCode;
        } else {
          this.commonService.ProjectLSData['galleryFilters'] = Object.assign({}, this.commonService.ProjectLSData['primaryFilter']);
          delete this.commonService.ProjectLSData['primaryFilter'];
        }
        this.commonService.ProjectLSData['unselectedFromCompare'] = [];
        this.commonService.setProjectLSData();
        this.commonService.changePlace('WebViewer/' + slideIds.toString());
        this.router.navigate(['WebViewer']).then(() => {
          this.sendDataToViewer();
        });
      } else if (event?.data?.call == 'updateSlideReview') {
        if (event?.data?.value?.slideReviewData) {
          event.data.value.slideReviewData['type'] = this.commonService.ProjectLSData['userActivePlace'].startsWith("study") ? 'STUDY' : 'PROJECT';
          event.data.value.slideReviewData['slideReviewData']['type'] = this.commonService.ProjectLSData['userActivePlace'].startsWith("study") ? 'STUDY' : 'PROJECT';
          event.data.value.slideReviewData['slideReviewData']['organReviewPk'] = event.data.value.slideReviewData?.organReviewPk
        }
        let id: any = event.data.value.slideReviewData['type'] === 'PROJECT' ? event?.data?.value?.slideData?.mappedId : event?.data?.value?.slideId;
        this.galleryService.updateSlideReview(event?.data?.value?.slideReviewData, event?.data?.value?.organReviewPk, id).subscribe((response: any) => {
          let iframe = document.getElementById('webViewerFrame');

          if (event?.data?.value?.slideData?.slide?.reviewStatus === 'NONE') {
            this.galleryService.changeReviewStatus({ status: 'DONE', slideIds: [event.data.value.slideData?.id] }).subscribe((res) => {
              iframe['contentWindow'].postMessage({
                call: 'slideReviewSuccess', value: response.message
              }, this.appConfig?.webAppConfig?.webViewerUrl);
            });
          } else {
            iframe['contentWindow'].postMessage({
              call: 'slideReviewSuccess', value: response.message
            }, this.appConfig?.webAppConfig?.webViewerUrl);
          }
        });
      } else if (event?.data?.call == 'saveSlideReview') {
        if (event?.data?.value?.slideReviewData) {
          event.data.value.slideReviewData['type'] = this.commonService.ProjectLSData['userActivePlace'].startsWith("study") ? 'STUDY' : 'PROJECT'
          event.data.value.slideReviewData['slideReviewData']['type'] = this.commonService.ProjectLSData['userActivePlace'].startsWith("study") ? 'STUDY' : 'PROJECT'
        }

        let id: any = event.data.value.slideReviewData['type'] === 'PROJECT' ? event?.data?.value?.slideData?.mappedId : event?.data?.value?.slideId;
        this.galleryService.saveSlideReview(event?.data?.value?.slideReviewData, id, event?.data?.value?.isReviewShareable).subscribe((response: any) => {
          let iframe = document.getElementById('webViewerFrame');
          // mark the slide as 'Review Done' if the user has entered a review
          if (event?.data?.value?.slideData?.slide?.reviewStatus === 'NONE') {
            this.galleryService.changeReviewStatus({ status: 'DONE', slideIds: [event.data.value.slideData?.id] }).subscribe((res) => {
              iframe['contentWindow'].postMessage({
                call: 'slideReviewSuccess', value: response.message
              }, this.appConfig?.webAppConfig?.webViewerUrl);
            });
          } else {
            iframe['contentWindow'].postMessage({
              call: 'slideReviewSuccess', value: response.message
            }, this.appConfig?.webAppConfig?.webViewerUrl);
          }
        }, () => {
          let iframe = document.getElementById('webViewerFrame');
          iframe['contentWindow'].postMessage({
            call: 'slideReviewError'
          }, this.appConfig?.webAppConfig?.webViewerUrl);
        });
      } else if (event?.data?.call == 'storeToken') {
        this.authService.storeTokens(event?.data?.value);
      } else if (event?.data?.call == 'reLogin') {
        this.reLogin();
      } else if (event?.data?.call == 'fetchParentLoginToken') {
        this.fetchParentLoginToken();
      } else if (event?.data?.call == 'fetchDSToken') {
        this.fetchDSToken();
      } else if (event?.data?.call == 'moveToGallery') {
        delete this.commonService.ProjectLSData['ViewerSlides'];
        this.commonService.setProjectLSData();
        this.moveToGallery(msgEvent);
      } else if (event?.data?.call == 'updateGalleryFilter') {
        // this.commonService.ProjectLSData['galleryFilters'] = event?.data?.value?.galleryFilter;
        // this.commonService.ProjectLSData['isStudyGalleryFilterApplied'] =  event?.data?.value?.isFilterApplied;
        // this.commonService.setProjectLSData();
      } else if (event?.data?.call == 'randomizationScoreUpdated') {
        this.commonService.ProjectLSData["randomizationScore"] = event?.data?.value?.randomizationScore;
        this.commonService.ProjectLSData["randomizationScoreObj"] = event?.data?.value?.randomizationScoreObj;
        this.commonService.setProjectLSData();
      } else if (event?.data?.call == 'updateCompareselection') {
        this.commonService.ProjectLSData["unselectedFromCompare"] = event?.data?.value?.unselected;
        this.commonService.setProjectLSData();
      } else if (event?.data?.call == 'emptyUnselectedCompare') {
        delete this.commonService.ProjectLSData["unselectedFromCompare"];
        this.commonService.setProjectLSData();
      } else if (event?.data?.call === 'updateViewerSlides') {
        this.commonService.ProjectLSData['ViewerSlides'] = event.data.value.viewerSlides;
        this.commonService.setProjectLSData();
      } else if (event?.data?.call === 'saveSlideTrayFilterData') {
        this.commonService.ProjectLSData['slideTrayFilterData'] = event.data.value.slideTrayFilterData;
        this.commonService.setProjectLSData();
      }
    }
    window.addEventListener("message", msgEvent, false);
  }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.appConfig?.webAppConfig?.webViewerUrl);
  }

  updateViewerImage(iFrameCall: any, callValue: any) {
    let iframe: any = document.getElementById('webViewerFrame');
    iframe['contentWindow'].postMessage({
      call: iFrameCall, value: callValue
    }, this.appConfig?.webAppConfig?.webViewerUrl);
  }

  fetchParentLoginToken() {
    console.log("fetchParentLoginToken");
    if (localStorage[this.commonService.projectName]) {
      this.commonService.ProjectLSData = JSON.parse(localStorage[this.commonService.projectName]);
    }
    let fetchParentLoginTokenObj: any = {
      accessToken: this.commonService.ProjectLSData.accessToken,
      refreshToken: this.commonService.ProjectLSData.refreshToken,
      sessionId: this.commonService.ProjectLSData.sessionId
    };
    let iframe: any = document.getElementById('webViewerFrame');
    iframe['contentWindow'].postMessage({
      call: 'fetchParentLoginTokenSuccess', value: fetchParentLoginTokenObj
    }, this.appConfig?.webAppConfig?.webViewerUrl);
  }

  fetchDSToken() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "40%";
    dialogConfig.data = {
      headerTitle: "Login",
      confirmMsg: "Session expired. Login again to continue",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes"
    };
    const dialogref = this.dialog.open(ModalDigitalSignatureComponent, dialogConfig);
    return dialogref.afterClosed().subscribe((result: any) => {
      if (result != 'Cancel Request') {
        let iframe: any = document.getElementById('webViewerFrame');
        iframe['contentWindow'].postMessage({
          call: 'fetchedDSToken', value: {
            dsToken: result.data.dsToken,
            dsComment: result.dsComment
          }
        }, this.appConfig?.webAppConfig?.webViewerUrl);
      } else {
        let iframe: any = document.getElementById('webViewerFrame');
        iframe['contentWindow'].postMessage({
          call: 'cancelledDSToken'
        }, this.appConfig?.webAppConfig?.webViewerUrl);
      }
    });
  }

  reLogin() {
    const dialogConfig = new MatDialogConfig();
    // dialogConfig.width = "50%";
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      headerTitle: "Login",
      confirmMsg: "Session expired. Login again to continue",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes",
      isAccessTokenExpired: true
    };
    const dialogref = this.dialog.open(LoginDialogComponent, dialogConfig);
    return dialogref.afterClosed().subscribe((result: any) => {
      if (result) {
        let reLoginObj: any = {
          accessToken: this.commonService.ProjectLSData['accessToken'],
          refreshToken: this.commonService.ProjectLSData['refreshToken'],
          sessionId: this.commonService.ProjectLSData['sessionId']
        };
        let iframe: any = document.getElementById('webViewerFrame');
        iframe['contentWindow'].postMessage({
          call: 'reLoginSuccess', value: reLoginObj
        }, this.appConfig?.webAppConfig?.webViewerUrl);
      }
    });
  }

  moveToGallery(msgEvent: any) {
    window.removeEventListener("message", msgEvent, false);
    if (this.commonService.ProjectLSData['parentInfo']?.['isOpenFromProjects']) {
      delete (this.commonService.ProjectLSData['parentInfo']?.['isOpenFromProjects']);
    }

    if (this.appConfig?.webAppConfig?.openViewer == "popup") {
      window.close();
      /* if (this.commonService.webViewerWindow && !this.commonService.webViewerWindow.closed) {
        this.commonService.webViewerWindow.close();
      } */
    } else {
      // this.router.navigate(['/mainboard/patient-data/' + elem["id"]]);
      this.router.navigate([this.commonService.ProjectLSData.LastParentAppUrl.replace(this.appConfig?.webAppConfig?.parentAppUrl + this.appConfig?.webAppConfig?.replacePathName, '')]);
    }
    /* let openedFrom: any = this.commonService.getViewerOpenedFrom();
    openedFrom = (openedFrom !== 'mapped') ? 'gallery' : openedFrom;
    this.commonService.changePlace(openedFrom);
    this.router.navigate(["/dashboard/" + openedFrom]); */
    // localStorage.setItem('samples', '');
  }

  sendDataToViewer(postMessageCall: any = null) {
    // let viewerSlidesIds = location.pathname.split("/")[2].split(",");
    /* let viewerSlidesIds = location.pathname.slice((location.pathname.lastIndexOf("/") + 1), location.pathname.length).split(",");
    this.viewerSlides = viewerSlidesIds.map((indvId) => {
      return { id: indvId }
    }); */
    let iframe = document.getElementById('webViewerFrame');
    if (iframe == null) return;
    let windowObj = {
      parentProj: this.commonService.projectName,
      viewerOpenedFrom: this.commonService.ProjectLSData.viewerOpenedFrom ? this.commonService.ProjectLSData.viewerOpenedFrom : 'gallery',
      activeRole: this.commonService.ProjectLSData.userActiveRole,
      labelViewRotateDegree: this.commonService['config']['labelViewRotateDegree'],
      galleryFilter: this.commonService.ProjectLSData.galleryFilters,
      loginUrl: this.appConfig?.webAppConfig?.parentAppUrl,
      parentInfo: this.commonService.ProjectLSData['parentInfo'] ? this.commonService.ProjectLSData['parentInfo'] : {},
      viewerBaseName: this.appConfig?.webAppConfig?.viewerBaseName,
      urlParent: {
        "apiUrl": this.appConfig?.apiConfig?.apiUrl,
        "thumbnailUrl": this.appConfig?.apiConfig?.thumbnailUrl,
        "tileUrl": this.appConfig?.apiConfig?.tileUrl
      },
      // viewerSlides: this.viewerSlides,
      // To add Additional user role in slide review
      currUserAdditionalPathologist: this.commonService.ProjectLSData.currUserAdditionalPathologist,
      viewerSlides: this.commonService.ProjectLSData['ViewerSlides'],
      lastParentAppUrl: this.commonService.ProjectLSData['LastParentAppUrl'],
      parentUrl: JSON.stringify(this.commonService.ProjectLSData['UrlApi']),
      currentUser: this.commonService.ProjectLSData['currentUser'],
      dsComments: this.usersService.dsComment,
      isDsTokenStored: this.usersService.isDsTokenStored,
      storedDsToken: this.usersService.getDsToken(),
      anonymisedLabel: this.appConfig?.galleryConfig?.anonymisedLabel,
      WebViewerOpenType: this.appConfig?.webAppConfig?.openViewer,
      accessToken: this.commonService.ProjectLSData['accessToken'],
      refreshToken: this.commonService.ProjectLSData['refreshToken'],
      sessionId: this.commonService.ProjectLSData['sessionId'],
      subjectIdPrefix: this.appConfig.webAppConfig.subjectIdPrefix,
      unselectedFromCompare: this.commonService.ProjectLSData['unselectedFromCompare'],
      appCode: (this.commonService.ProjectLSData['userActivePlace'].startsWith("study")) ? "AIRADHI_STUDY" : (this.commonService.ProjectLSData['userActivePlace'].startsWith("project")) ? "AIRADHI_PROJECT" : "AIRADHI_UPLOAD",
      randomisationInfo: {
        isRandomisation: this.commonService.ProjectLSData.isRandomisationStarted,
        randomisedSlideIdsArr: this.commonService.ProjectLSData.randomisedSlideIds,
        randomizationScore: this.commonService.ProjectLSData.randomizationScore,
        randomizationScoreObj: this.commonService.ProjectLSData.randomizationScoreObj
      },
      isStudyGalleryFilterApplied: this.commonService.ProjectLSData?.isStudyGalleryFilterApplied,
      slideTrayFilterData: this.commonService.ProjectLSData?.slideTrayFilterData,
      studyManualFields: this.commonService.ProjectLSData?.studyManualFields,
      activeUserPermission: this.commonService.ProjectLSData?.activeUserPermission
    }
    iframe['contentWindow'].postMessage({
      call: (postMessageCall ? postMessageCall : 'webViewerFrame'), value: windowObj
    }, this.appConfig?.webAppConfig?.webViewerUrl);
  }

  openStudyDetailDialog(slideGUID: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = "90%";
    dialogConfig.data = {
      showHeader: true,
      studyGuid: slideGUID,
      showCancelIcon: false
    };
    this.dialog.open(StudyDetailsComponent, dialogConfig);
  }
}
