import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-mat-data-table',
  templateUrl: './mat-data-table.component.html',
  styleUrls: ['./mat-data-table.component.less']
})
export class MatDataTableComponent implements AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('matTable') matTable: ElementRef<HTMLTableElement>;

  readonly NO_DATA_TO_DISPLAY = 'No data to display';

  _columnsToDisplay: string[] = [];
  _displayedColumns: DisplayedColumns[] = [];
  _dataSource: MatTableDataSource<any>;

  private _timer: any;

  get dataSource(): MatTableDataSource<any> {
    return this._dataSource;
  }
  @Input() set dataSource(value: MatTableDataSource<any>) {
    this._dataSource = value;
  }

  get displayedColumns(): DisplayedColumns[] {
    return this._displayedColumns;
  }

  @Input() set displayedColumns(value: DisplayedColumns[]) {
    this._displayedColumns = value;
    this._columnsToDisplay = this._displayedColumns?.filter(col => col['isVisible']).map(col => col['key']);
  }

  @Input() hasPagination: boolean = false;
  @Input() paginationData: PaginationDetails;

  @Output() rowClick = new EventEmitter<any>();
  @Output() formFieldValueChange = new EventEmitter<any>();
  @Output() menuItemClick = new EventEmitter<any>();
  @Output() matDataTableLoaded = new EventEmitter<void>();

  constructor() { }

  ngAfterViewInit(): void {
    if (this.matTable) {
      this._timer = setInterval(() => {
        if (this._dataSource) {
          if (this.sort && ((this.hasPagination && this.paginator) || !this.hasPagination)) {
            this._dataSource.sort = this.sort;
            if (this.paginator) {
              this._dataSource.paginator = this.paginator;
              this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
            }
  
            this.matDataTableLoaded.emit();
            clearInterval(this._timer);
          }
        }
      }, 500);
    }
  }

  applySearch(value: string) {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.dataSource.filter = value?.trim().toLowerCase();
  }

  onRowClick(event: Event, row: any) {
    this.rowClick.emit({ elementId: (event.target as HTMLElement).id, ...row });
  }

  onFormFieldChange(value: any, row: any) {
    row['fieldValue'] = value;
    this.formFieldValueChange.emit(row);
  }

  onMenuItemClick(id: string, row: any) {
    this.menuItemClick.emit({ elementId: id, ...row });
  }

  onGroupIconClick(id:string,row:any){
    this.menuItemClick.emit({ elementId: id, ...row });
  }
}

export interface DisplayedColumns {
  key: string,
  value: string,
  isVisible: boolean,
  width: string,
  cellType: 'normal' | 'template' | 'form-field' | 'menu',
  isSortDisabled?: boolean
}

export interface PaginationDetails {
  length: number,
  pageSize: number,
  pageSizeOptions: Array<number>
}