import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ProjectService } from '../project.service';
import { CommonService } from 'src/app/common.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonDialogComponent } from '../common-dialog/common-dialog.component';

@Component({
  selector: 'app-add-metadata-dialog',
  templateUrl: './add-metadata-dialog.component.html',
  styleUrls: ['./add-metadata-dialog.component.less'],
  encapsulation:ViewEncapsulation.None
})
export class AddMetadataDialogComponent implements OnInit {
  blankTemplate = {
    name: 'Blank Template',
    id: -1
  }
  // these variable are added
  templateId: any;
  templateVersion: any;
  // 
  metaDataNotvalid: boolean = false;
  regexp = /^\d{1,9}(\.?\d{1,1})?$/;
  // validateCustomField:boolean=false;
  speciesArr: any = [];
  tissueArr: any = [];
  stainArr: any = [];
  error: any;
  metaDataObj: any = {};
  customMetadata: any = {};
  metaDatalength: any = 0;
  finalMetaDataObj: any = {};
  selectedTemplate: any;
  metaDataArray = [
    {
      "fieldLabel": "Species",
      // "labelType": "textbox",
      "fieldType": "select",
      "fieldValue": "",
      "prevFieldVal": ""
    },
    {
      "fieldLabel": "Tissue",
      // "labelType": "textbox",
      "fieldType": "select",
      "fieldValue": "",
      "prevFieldVal": ""
    },
    {
      "fieldLabel": "Stain",
      // "labelType": "textbox",
      "fieldType": "select",
      "fieldValue": "",
      "prevFieldVal": ""
    }

  ]
  newFieldName: any;
  newFieldValue: any;
  customField: any = [];
  isvalidated: boolean = true;
  isSelectedTemplateIsBlank = false;
  //Template
  templateInfo: any = [];
  templateList: any = [];
  defaultTemplateData: any = [];
  defaultTemplateDataDetails: any = [];
  savedTemplateData: any = {};
  addedTemplateData: any = [];
  fields: any;
  isEdit: boolean = false;
  isTemplateTurnOff: boolean = true;
  isFolderLevel: boolean = false;
  allowOverRideFolderData: boolean = false;
  folderMetaData: any = [];
  overRidefolderMetaData: any = [];
  newFieldType: any = [];

  regexRange: any = /^[a-zA-Z][a-zA-Z0-9.,_-\s]*$/;
  // regexRange:any = /
  regexUnderScore: any = /^[_]+$/;
  regexDash: any = /^[-]+$/;
  regexUnDash: any = /^[_-]+$/;
  regexDashUn: any = /^[-_]+$/;
  regexLen: any = /^.{2,60}$/;
  constructor(@Optional() private dialogRef: MatDialogRef<AddMetadataDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private projectService: ProjectService,
    private commonService:CommonService,
    public translate: TranslateService,
    public dialog: MatDialog,
  ) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (this.data.isTemplateListingPage) {
      if (this.data.isForChange) {
        this.selectedTemplate = this.data.templateList.filter((item: any) => {
          return item.id == this.data.templateId;
        })[0];
        if (!this.selectedTemplate) {
          this.selectedTemplate = this.blankTemplate;
          this.isSelectedTemplateIsBlank = true;
        }
        this.getTemplateField(this.selectedTemplate);
      } else {
        this.selectedTemplate = this.blankTemplate
      }

      // this.configService.getAllMetaDataTemplate(1).subscribe((response: any) => {
      // this.selectedTemplate = this.blankTemplate

      // })
    } else {
      this.defaultTemplateData = this.data.metaDataTemplate;
      this.metaDataObj = this.data.metaDataObj;
      this.customMetadata = this.data.customMetadata ? this.data.customMetadata : {};
    }
  }

  getTemplateField(template: any) {
    if (template.id == -1) {
      this.selectedTemplate = this.blankTemplate
    } else {
      this.projectService.getAllMetaDataTemplate(template.id).subscribe((response: any) => {
        this.selectedTemplate = response.data;
      });
    }

  }

  submitTemplateId() {
    this.dialogRef.close(this.selectedTemplate)
  }

  allowToAddCustomField() {
    if (Object.keys(this.metaDataObj).length < 10) {
      this.addCustomField();
    }
  }
  checkFieldIsNonEmpty() {
    for (let i = 0; i < this.metaDataArray.length; i++) {
      if (this.metaDataArray[i].fieldType == 'select' && this.metaDataArray[i].fieldValue)
        return true;
    }
    return false;
  }

  validateText(event: any, fieldValue: any) {
    // console.log(event);
    if (fieldValue.length == 50) {
      event.preventDefault();
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.METADATAPOP_UP.maxTextInput"), 'warn');
    } else if (event.keyCode == 45) {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.METADATAPOP_UP.invalideInput"), 'warn');
      event.preventDefault();
    } else if ([0, 1, 2, 3, 4, 5, 6, 7, 8, 9].includes(parseInt(event.key))) {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.METADATAPOP_UP.invalideInput"), 'warn');
      event.preventDefault();
    }
  }
  validateLabel(event: any, fieldValue: any) {
    if (fieldValue.length == 60) {
      event.preventDefault();
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.METADATAPOP_UP.maxTextInput"), 'warn');
    }
  }



  addCustomField() {
    let count = Object.keys(this.metaDataObj).length + this.customField.length;
    if (count < 10) {
      this.customField.push(
        {
          "fieldLabel": "",
          "fieldValue": ""
        });
    }
    else {
      // this.openSnackBar("Maximum 10 fields are allowed");
      //this.commonService.showToastMsg("Maximum 10 fields are allowed", 'warn');
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.METADATAPOP_UP.maxFieldsAllowed"), 'warn');
    }
    this.isvalidated = true;
  }
  deleteCustomField(index: any) {
    this.customField.splice(index, 1);

  }

  addFieldToMetaData(field: any, index: any) {
    field.fieldLabel = field.fieldLabel.trim()
    if (field.fieldLabel) {
      if (this.validateFieldName(field, 'add')) {
        // this.metaDataArray.push(field);
        if (this.validateMetaDataFieldLabel(field.fieldLabel)) {
          this.metaDataObj[field.fieldLabel] = field.fieldValue;
          this.customField.splice(index, 1);
          this.metaDatalength = Object.keys(this.metaDataObj).length;
        } else {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.METADATAPOP_UP.invalidFieldName"), 'warn');
        }

      } else {
        // this.openSnackBar("Field name can't be same");
        //this.commonService.showToastMsg("Field name can't be same", 'warn');
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.METADATAPOP_UP.fieldValueCheck"), 'warn');
      }
    } else {
      // this.openSnackBar("Please select label and it's type");
      //this.commonService.showToastMsg("Please select label and it's type", 'warn');
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.METADATAPOP_UP.labelAndTypeCheck"), 'warn');
    }
  }

  onDropDownselect(field: any, event: any) {
    field.fieldType = event.source.value;
  }

  onDateSelect(event: any) {
  }
  validateInput(field: any) {
    return (field.fieldLabel && field.fieldType) ? true : false;
  }

  validateMetaData() {
    for (let i = 0; i < this.metaDataArray.length; i++) {
      if (this.metaDataArray[i].fieldType == 'textbox')
        this.metaDataArray[i].fieldValue = this.metaDataArray[i].fieldValue.trim();
      if (this.metaDataArray[i].fieldValue.toString() == '') {
        return false;
      }
    }
    return true;
  }

  validateCustomField() {
    if (this.customField.length > 0) {
      for (let i = 0; i < this.customField.length; i++) {
        if (!(this.customField[i].fieldLabel && this.customField[i].fieldType)) {
          return false;
        }
      }
    }
    return true;
  }

  validateFieldName(field: any, flow: any) {
    if (flow == 'add') {
      for (const key in this.metaDataObj) {
        if (key.toLowerCase() == field.fieldLabel.toLowerCase()) {
          return false;
        }
      }
      if (this.folderMetaData.length > 0) {
        for (const key in this.folderMetaData) {
          if (this.folderMetaData[key].name.toLowerCase() == field.fieldLabel.toLowerCase()) {
            return false;
          }
        }
      }
      return true;
    } else {
      for (const key in this.overRidefolderMetaData) {
        if (this.overRidefolderMetaData[key].name.toLowerCase() == field.key.toLowerCase()) {
          return false;
        }
      }
      return true;
    }
  }

  submitMetaData() {
    let customValidationArray: any = [];
    let mandatoryFieldArray = this.defaultTemplateData.filter((item: any) => {
      return item.mandatory && !item.selectedValue;
    });
    if (mandatoryFieldArray.length) {
      // this.commonService.showToastMsg('Enter the value in the mandatory field','warn');
      this.commonService.showToastMsg(this.translate.instant("MetaData_Oprs.requiredFiedsWarning"), 'warn')
      return;
    }

    // console.log('addedTemplateData', this.savedTemplateData);
    if ((!this.isTemplateTurnOff || this.isEdit) && this.defaultTemplateData) {
      if (this.addedTemplateData && this.addedTemplateData.length == 0 && !this.isFolderLevel) {
        this.addedTemplateData = this.defaultTemplateData;
      }
      else if (this.addedTemplateData && this.addedTemplateData.length == 0 && this.isFolderLevel) {
        this.addedTemplateData = this.folderMetaData;
      }
      if (this.addedTemplateData) {
        this.addedTemplateData.forEach((e: any) => {
          if (e.type != 'Dropdown' && e.type != 'Date') {
            let controlName = 'Control_' + e.id;
            const hightlgtControlName: any = document.querySelector('#' + controlName)?.children[0];
            hightlgtControlName.style.border = 'none'
            hightlgtControlName.style.borderRadius = 'none';
          }
        })

        this.addedTemplateData.forEach((e: any) => {
          if (e.value && e.type == 'Text') {
            if (!this.validateMetaDataText(e.value)) {
              customValidationArray.push(e.id)
            }
          }
          if (e.value && e.type == 'Date') {
            // if(!this.validateMetaDataDate(e.value)) {
            //   customValidationArray.push(e.id)
            // }
          }
          if (e.value && e.type == 'Number') {
            if (!this.validateMetaDataNumber(e.value)) {
              customValidationArray.push(e.id)
            }
          }
        })

        if (customValidationArray.length == 0) {
          this.addedTemplateData.forEach((e: any) => {
            // if (e.value) {
            this.savedTemplateData[e.name] = e.value ? e.value : '';
            // }
          })
        } else {
          customValidationArray.forEach((e: any) => {
            let controlName = 'Control_' + e;
            const hightlgtControlName: any = document.querySelector('#' + controlName)?.children[0];
            hightlgtControlName.style.border = '1px solid #ff0000'
            hightlgtControlName.style.borderRadius = '0.25vw';
          })
          "MetaData_Oprs.invalidValuesMsg"
          this.commonService.showToastMsg(this.translate.instant("MetaData_Oprs.invalidValuesMsg"), 'warn');
          return;
        }
        if (this.savedTemplateData) {
          for (const key in this.savedTemplateData) {
            this.data.payload.metadata[key] = this.savedTemplateData[key].split(',');
          }
        }
      }
    }
    if (this.metaDataObj) {
      this.data.payload.customMetadata = this.customMetadata;


      for (const key in this.metaDataObj) {
        if (typeof (this.metaDataObj[key]) == 'object')
          this.data.payload.metadata[key] = this.metaDataObj[key];
        else
          this.data.payload.metadata[key] = [this.metaDataObj[key] ? this.metaDataObj[key] : '']
      }
    }

    for (let item of this.defaultTemplateData) {
      this.data.payload.metadata[item.name] = (item?.selectedValue?.length) ? item.selectedValue : [];
    }
    // console.log('this.data.payload', this.data.payload);
    if ((!this.isTemplateTurnOff || this.isEdit) && this.defaultTemplateData) {
      this.data.payload.templateId = this.templateInfo.id ? this.templateInfo.id : this.defaultTemplateDataDetails.id;
      this.data.payload.templateVersion = this.templateInfo.version ? this.templateInfo.version : this.defaultTemplateDataDetails.version;
    }
    this.callAddMetaDataService();

  }

  prevent(e: any, label: any) {
    if (label == 'Overwrite')
      e.preventDefault();
  }

  closePopUp() {
    this.dialogRef.close();
  }
  deleteFieldInMetaData(key: any) {
    // this.metaDataArray.splice(index, 1);
    delete this.metaDataObj[key]
    this.metaDatalength = Object.keys(this.metaDataObj).length;

    // if (!this.metaDatalength) {
    //   this.addCustomField();
    // }
    // let OverIndex = this.overRidefolderMetaData.findIndex((e: any) =>
    //   e.name == key
    // )
    // if (OverIndex > -1) {
    //   this.overRidefolderMetaData.splice(OverIndex, 1)
    // }   
  }

  numPrecision(event: any, fieldIndex: any) {
    let unUsedKeyArr = ['NumpadSubtract', 'NumpadAdd', 'KeyE', 'Minus', 'ShiftRight', 'Equal'];
    let fieldValArr = ('' + this.metaDataArray[fieldIndex].fieldValue).split(".");
    if ((unUsedKeyArr.indexOf(event.code) >= 0) || (fieldValArr[0].length > 10)) {
      this.metaDataArray[fieldIndex].fieldValue = this.metaDataArray[fieldIndex].prevFieldVal;
      return;
    } else if (event.code != "Backspace") {
      if (fieldValArr.length == 2) {
        this.metaDataArray[fieldIndex].fieldValue = fieldValArr[0] + '.' + fieldValArr[1].substring(0, 2);
      }
    }
    this.metaDataArray[fieldIndex].prevFieldVal = this.metaDataArray[fieldIndex].fieldValue;
  }

  callAddMetaDataService() {
    this.projectService.addMetaData(this.data.payload, this.data.oprs).subscribe((res: any) => {
      console.log(this.data.payload);
      if (res.success) {
        if (this.data.typeOfInput == "BOTH") {
          this.projectService.trackMetaData(this.data.projectId, this.data.payload.imageId).subscribe((res: any) => {
          });
        }
        // this.openSnackBar(res.message);
        this.commonService.showToastMsg(res.message);
        this.dialogRef.close('success');
      }
      if (this.isFolderLevel) {
        this.onOverRiderFolderData();
      }
    }, error => {
      if (error.status === 409) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.panelClass = 'confirmDialog';
        dialogConfig.width = '30%'
        dialogConfig.data = {
          page: "move",
          headerTitle: 'Add Metadata',
          confirmMsg: error.error.message,
          isContinue: "",
          cancelButtonText: 'Cancel',
          confirmButtonText: "Ok",
          confirmActionData: {
            currentPage: "move"
          }
        }
        const confirmeDialogRef = this.dialog.open(CommonDialogComponent, dialogConfig);
        confirmeDialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.data.payload.allowed = true;
            this.data.payload.toCopyAllData = true;
            this.projectService.addMetaData(
              this.data.payload, this.data.oprs
            ).subscribe((response: any) => {
              if (this.data.payload.type == "IMAGE") {
                this.projectService.trackMetaData(this.data.projectId, this.data.payload.imageId).subscribe((res: any) => {
                });
              }
              // this.openSnackBar(response.message);
              this.commonService.showToastMsg(response.message);
              this.dialogRef.close('success');
            })
          }
        })
      }
    })
  }



  // 
  // if (error.status === 409) {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.panelClass = 'confirmDialog';
  //   dialogConfig.width = '30%'
  //   dialogConfig.data = {
  //     page: "move",
  //     headerTitle: 'Add Metadata',
  //     confirmMsg: error.error.message,
  //     isContinue: "",
  //     cancelButtonText: 'Cancel',
  //     confirmButtonText: "Ok",
  //     confirmActionData: {
  //       currentPage: "move"
  //     }
  //   }
  //   const confirmeDialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);
  //   confirmeDialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.data.payload.allowed = true;
  //       this.data.payload.toCopyAllData = true;
  //       this.projectService.addMetaData(
  //         this.data.payload, this.data.oprs
  //       ).subscribe((response: any) => {
  //         if (this.data.payload.type == "IMAGE") {
  //           this.projectService.trackMetaData(this.data.projectId, this.data.payload.imageId).subscribe((res: any) => {
  //           });
  //         }
  //         // this.openSnackBar(response.message);
  //         this.commonService.showToastMsg(response.message);
  //         this.enableEditMetaData = false;
  //       })
  //     }
  //   })
  // }






  // 

  dispVal(field: any) {
    this.metaDataObj[field.key] = field.value;
  }

  getMetadataTemplateList() {
    this.projectService.getMetadataTemplateList().subscribe((successMetaData: any) => {
      // console.log(successMetaData)
      if (successMetaData.success) {
        this.templateList = successMetaData.data;
        if (this.templateList.length < 2) {
          this.getMetadataTemplateDetails();
        }
      }
    })
  }
  getMetadataTemplateDetails() {
    this.projectService.getMetadataTemplateDetails(this.templateId, this.templateVersion).subscribe((successMetaData: any) => {
      if (successMetaData.success) {
        this.templateInfo = successMetaData.data;
        this.isTemplateTurnOff = successMetaData.data.turnOff;

        //  console.warn(this.templateInfo)
        if (!this.isTemplateTurnOff) {
          this.defaultTemplateData = successMetaData.data.fields;
          this.defaultTemplateData.sort((a: any, b: any) => a.position - b.position);
          // if (this.isFolderLevel && this.data.oprs == 'edit') {
          //   // this.checkIsFieldUsed();
          //   this.highlightCommonFields();
          // }
        }
        console.log(this.defaultTemplateData)
      }
    })
  }
  getMetadataNormalTemplateDetails() {
    this.projectService.getMetadataTemplateDetails(this.templateId, this.templateVersion).subscribe((successMetaData: any) => {
      if (successMetaData.success) {
        // this.templateInfo = successMetaData.data;
        this.isTemplateTurnOff = successMetaData.data.turnOff;

        // console.log(this.templateInfo)
        if (!this.isTemplateTurnOff) {
          // this.defaultTemplateData = successMetaData.data.fields;
          // this.defaultTemplateData.sort((a: any, b: any) => a.position - b.position);
        }
        // console.log(this.defaultTemplateData)
      }
    })
  }
  getFieldValue(data: any, type: any, event: any) {
    // data.value= this._decimalPipe.transform(data.value, '2.1-2');
    if (!this.isFolderLevel) {
      this.addedTemplateData = this.defaultTemplateData;
    } else {
      this.addedTemplateData = this.folderMetaData;
    }
    if (type == 'Dropdown') {
      this.savedTemplateData[data.name] = event.value;
      data.value = event.value;
    } else {
      this.savedTemplateData[data.name] = event.target.value;
      data.value = event.target.value;
      data.selectedValue = event.target.value.split(',');
    }
    this.addedTemplateData.forEach((e: any) => {
      if (e.id == data.id) {
        e.value = data.value;
      } else {
        if (!e.value && !this.isFolderLevel) {
          e.value = '';
        }
      }
    });
  }

  //foldercheck
  checkIsFieldUsed() {
    if (this.folderMetaData) {
      let result = this.folderMetaData.map((a: any) => a.name);
      let notPresentInData = this.defaultTemplateData.filter((val: any) => !result.includes(val.name));
      console.warn(this.folderMetaData);
      this.defaultTemplateData = notPresentInData;
    }
  }
  highlightCommonFields() {
    if (this.folderMetaData.length > 0) {
      let result = this.folderMetaData.map((a: any) => a.name);
      let notPresentInData = this.defaultTemplateData.filter((val: any) => result.includes(val.name));
      notPresentInData.forEach((e: any) => {
        let controlName = 'Field_' + e.id;
        const hightlgtControlName: any = document.querySelector('#' + controlName);
        hightlgtControlName.style.border = '1px solid #F47625'
        hightlgtControlName.style.borderRadius = '0.35vw';
        hightlgtControlName.style.fontWeight = '600';
      });
    }
  }
  stageorUnstageControl(data: any) {
    if (!this.folderMetaData) {
      this.folderMetaData = [];
    }
    let index = this.folderMetaData.findIndex((e: any) =>
      e.name == data.name
    )
    if (index > -1) {
      let OverIndex = this.overRidefolderMetaData.findIndex((e: any) =>
        e.id == data.id
      )
      if (OverIndex > -1) {
        this.overRidefolderMetaData.splice(OverIndex, 1)
      }
      let addedTempIndex = this.addedTemplateData.findIndex((e: any) =>
        e.id == data.id
      )
      if (addedTempIndex > -1) {
        this.addedTemplateData.splice(addedTempIndex, 1)
      }
      this.folderMetaData.splice(index, 1);
      let controlName = 'Field_' + data.id;
      const hightlgtControlName: any = document.querySelector('#' + controlName);
      hightlgtControlName.style.border = 'none'
      hightlgtControlName.style.borderRadius = 'none';
      hightlgtControlName.style.fontWeight = 'normal';
    } else {
      this.folderMetaData.push(data)
      console.log(this.folderMetaData)
      // this.checkIsFieldUsed()
      let controlName = 'Field_' + data.id;
      const hightlgtControlName: any = document.querySelector('#' + controlName);
      hightlgtControlName.style.border = '1px solid #F47625'
      hightlgtControlName.style.borderRadius = '0.35vw';
      hightlgtControlName.style.fontWeight = '600';
    }
  }

  //Teamplate Validation
  validateMetaDataText(fieldValue: any) {
    var myRegxp = /^.{1,60}$/;
    if (myRegxp.test(fieldValue) == false) {
      return false;
    } else {
      return true;
    }
  }

  // for field value ------------------

  validateMetaDataFieldLabel(fieldValue: any) {
    if (!this.regexRange.test(fieldValue)
      // || this.regexUnderScore.test(fieldValue)
      // || this.regexDash.test(fieldValue)
      // || this.regexUnDash.test(fieldValue)
      // || this.regexDashUn.test(fieldValue)
    ) {
      return false;
    } else {
      return true;
    }
  }

  validateMetaDataNumber(fieldValue: any) {
    var myRegxp = /^[0-9]*\.[0-9]{2}$/;
    var myRegxpNormal = /^[0-9]*$/;
    if (myRegxp.test(fieldValue) == true || myRegxpNormal.test(fieldValue) == true) {
      return true;
    } else {
      return false;
    }
  }
  onOverRiderFolderData() {
    let selectedMetadata: any = {};
    let savedoverrideData: any = {};
    if (this.overRidefolderMetaData.length > 0) {

      this.overRidefolderMetaData.forEach((e: any) => {
        if (e.value) {
          savedoverrideData[e.name] = e.value;
        }
      })
      for (const key in savedoverrideData) {
        selectedMetadata[key] = savedoverrideData[key].split(',');
      }
    }
    let payloadData = {
      "folderId": this.data.payload.folderId,
      "type": "FOLDER",
      "allowed": this.data.payload.allowed,
      "toCopyAllData": this.allowOverRideFolderData,
      "selectedMetadata": selectedMetadata ? selectedMetadata : {}
    }
    this.projectService.OverRiderFolderData(payloadData).subscribe((res: any) => {
      if (res.success) {
        this.commonService.showToastMsg(res.message);
      }
    })
  }
  addtoOverRide(data: any) {
    this.allowOverRideFolderData = false;
    console.log(data)
    if (data.name) {
      let index = this.overRidefolderMetaData.findIndex((e: any) =>
        e.name == data.name
      )

      console.log(index)
      if (index == -1) {
        this.overRidefolderMetaData.push(data)
        let controlName = 'overRideControl_' + data.id;
        const hightlgtControlName: any = document.querySelector('#' + controlName)?.children[0];
        hightlgtControlName.style.fontWeight = '600';
      } else {
        this.overRidefolderMetaData.splice(index, 1)
        let controlName = 'overRideControl_' + data.id;
        const hightlgtControlName: any = document.querySelector('#' + controlName)?.children[0];
        hightlgtControlName.style.fontWeight = 'normal';
      }
    } else {
      if (data.key) {
        if (this.validateFieldName(data, 'override')) {
          let id = (this.overRidefolderMetaData.length + 100)
          this.overRidefolderMetaData.push({ 'id': id, 'name': data.key, 'value': data.value })

          let controlName = 'overRideControl_' + data.key;
          const hightlgtControlName: any = document.querySelector('#' + controlName)?.children[0];
          hightlgtControlName.style.fontWeight = '600';
        } else {
          let keyIndex = this.overRidefolderMetaData.findIndex((e: any) =>
            e.name == data.key
          )
          this.overRidefolderMetaData.splice(keyIndex, 1)
          let controlName = 'overRideControl_' + data.key;
          const hightlgtControlName: any = document.querySelector('#' + controlName)?.children[0];
          hightlgtControlName.style.fontWeight = 'normal';
        }
      }
    }

  }

  deleteTemplateField(data: any, index: any) {
    // console.warn(this.addedTemplateData)
    this.defaultTemplateData.push(data)
    let OverIndex = this.overRidefolderMetaData.findIndex((e: any) =>
      e.id == data.id
    )
    if (OverIndex > -1) {
      this.overRidefolderMetaData.splice(OverIndex, 1)
    }
    let addedTempIndex = this.addedTemplateData.findIndex((e: any) =>
      e.id == data.id
    )
    if (addedTempIndex > -1) {
      this.addedTemplateData.splice(addedTempIndex, 1)
    }
    this.folderMetaData.splice(index, 1);
  }
  deleteOverRideField(index: any) {
    this.overRidefolderMetaData.splice(index, 1);
  }
  allowAlltoOverrideOnFields() {
    if (this.allowOverRideFolderData) {
      this.overRidefolderMetaData.forEach((e: any) => {
        let controlName = 'overRideControl_' + e.id;
        let hightlgtControlName: any = document.querySelector('#' + controlName)?.children[0];
        if (hightlgtControlName === undefined) {
          controlName = 'overRideControl_' + e.name;
          hightlgtControlName = document.querySelector('#' + controlName)?.children[0];
        }
        hightlgtControlName.style.fontWeight = 'normal';
      })
      this.overRidefolderMetaData = [];
    }
  }

  addCustomFieldInMetaData() {
    if (this.metaDataObj.hasOwnProperty(this.newFieldName.trim())) {
      this.commonService.showToastMsg(this.translate.instant("MetaData_Oprs.fieldAlreadyPresent"), 'warn');
      return;
    }
    if (!this.validateMetaDataFieldLabel(this.newFieldName)) {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.METADATAPOP_UP.invalidFieldName"), 'warn');
      return;
    }
    if (Object.keys(this.metaDataObj).length >= 10) {
      this.commonService.showToastMsg(this.translate.instant('MetaData_Oprs.maxFieldLimitWarn'), 'warn');
      return;
    }
    this.metaDataObj[this.newFieldName] = this.newFieldValue;
    this.customMetadata[this.newFieldName] = this.newFieldType;
    this.newFieldName = '';
    this.newFieldValue = '';
    this.newFieldType = ''
  }

  transformDecimal(data: any) {
    // data.value = this._decimalPipe.transform(data.value, '2.1-2');
  }

  decimalFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  returnTypeOfCustomField(customFieldName: any) {
    return this.customMetadata[customFieldName];
  }

  optionDisabledCheck(item: any, value: any): boolean {
    if ((item.maxSelectionCount) && (!item.selectedValue?.includes(value) && item.selectedValue?.length >= item.maxSelectionCount)) {
      return true;
    }
    else {
      return false;
    }

  }

  changeTemplate() {

    this.projectService.changeMetaDataTemplate({
      projectId: this.data.projectId,
      templateId: this.selectedTemplate.id == -1 ? null : this.selectedTemplate.id
    }).subscribe((res: any) => {
      if (res.success) {
        this.commonService.showToastMsg(res.message)
        this.dialogRef.close('success');
      }
    })
  }

}
