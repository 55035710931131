import { AuthService } from './../auth/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import {
  FormBuilder,
  FormGroup,
  Validators
} from "@angular/forms";
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.less']
})
export class LoginDialogComponent implements OnInit {
  showData: any;
  public email: String;
  public password: String;
  loginForm: FormGroup;
  loginServerError: String;
  submitted = false;
  hide = true;
  currentEmail: any;
  constructor(
    @Optional() private dialogRef: MatDialogRef<LoginDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    private router: Router,
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private authService: AuthService) {
    dialogRef.disableClose = true;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.currentEmail = this.commonService.ProjectLSData?.currentUser?.email;
  }

  ngOnInit(): void {
    this.showData = {
      'headerTitle': this.data.headerTitle ? this.data.headerTitle : 'Title',
      'confirmMsg': this.data.confirmMsg ? this.data.confirmMsg : 'Are You Sure You want to delete this?',
      'cancelButtonText': this.data.cancelButtonText ? this.data.cancelButtonText : 'Cancel',
      'confirmButtonText': this.data.confirmButtonText ? this.data.confirmButtonText : 'Ok'
    }
    this.loginForm = this.formBuilder.group({
      email: [this.currentEmail],
      password: ["", [Validators.required]]
    });

    this.loginForm.get("email").valueChanges.subscribe(value => {
      this.loginServerError = '';
    });

    this.loginForm.get("password").valueChanges.subscribe(value => {
      this.loginServerError = '';
    })
  }

  get f() {
    return this.loginForm.controls;
  }

  closeDialog() {
    this.dialogRef.close('');
    this.authService.logout().subscribe((response) => {
      this.commonService.stopSessionActiveStatus();
      this.router.navigate(['/login']);
    });
  }

  onLogin() {
    this.loginServerError = "";
    this.submitted = true;
    if (this.loginForm.valid) {
      return this.authService.login({ email: this.currentEmail, password: this.loginForm.controls.password.value }).subscribe(response => {
        if (response == "true") {
          this.commonService.ProjectLSData = this.authService.ProjectLSData;
          this.dialogRef.close(true);
        } else {
          if (response == "invalid") {
            this.loginServerError = "Please enter a valid login credentials";
          }
        }
      });
    }
  }

}
