import { StudyService } from './../study.service';
import { CommonService } from '../../common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Inject, Optional, ElementRef, ViewChild } from '@angular/core';
import { ConfirmDialogComponent } from "./../../common/confirm-dialog/confirm-dialog.component";
import { MatDialogConfig, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DatePipe } from '@angular/common';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { TranslateService } from '@ngx-translate/core';
import { DisplayedColumns } from 'src/app/shared/mat-data-table/mat-data-table.component';
import { MatTableDataSource } from '@angular/material/table';
import { AppConfigService } from 'src/app/app-config.service';
declare var $: any;

@Component({
  selector: 'app-study-details',
  templateUrl: './study-details.component.html',
  styleUrls: ['./study-details.component.less']
})
export class StudyDetailsComponent implements OnInit {
  datepipe = new DatePipe('en-US');
  isDownloadBtnClicked: boolean = false;
  isTCRStudy: boolean = false;
  @ViewChild('matTableElement', { static: false })
  public matTableElement!: ElementRef;
  studyGuid: any;
  studyDetailData = {
    studyNo: '',
    studyTitle: '',
    projectNo: '',
    accessionNo: '',
    startDate: '',
    endDate: '',
    treatment: '',
    studyDomain: '',
    studyType: '',
    sacrifice: '',
    species: '',
    strain: '',
    route: '',
    cassettes: [],
    dosages: [],
    studyDirector: '',
    pathologist: '',
    reAssignedpathologistArr: [],
    peerReviewer: '',
    reAssignedPeerReviewerArr: [],
    technician: '',
    reAssignedTechnicianArr: [],
    primaryInvestigator: '',
    reAssignedPrimaryInvestigatorArr: [],
    slideCount: 0,
    cro: '',
    testItem: '',
    imageDetailsModel: [],
    imageDetailsModelBackUp: [],
    isTCRStudy: false,
    additionalPathologist: [],
    reAssignedAdditionalPathologistArr: [],
    reviewSharable: false,
    manualFields: '',
    minimal: false
  };
  studyStatus: any;
  activeRole: any;
  showSlideDataLoader: boolean = false;

  // MAT TABLE DATA CONFIGURATION
  cassetteDataSource: MatTableDataSource<any>;
  cassetteDisplayedColumns: DisplayedColumns[] = [];
  dosageDataSource: MatTableDataSource<any>;
  dosageDisplayedColumns: DisplayedColumns[] = [];
  minimalStudyDataSource: MatTableDataSource<any>;
  minimalStudyDisplayedColumns: DisplayedColumns[] = [];
  scrollBottom: boolean = false;
  pageIndex = 0;
  slideDetailsSize: number;
  appConfig: any;
  constructor(
    @Optional() private dialogRef: MatDialogRef<StudyDetailsComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private studyDetailsService: StudyService,
    private router: Router,
    public commonService: CommonService,
    private dialog: MatDialog,
    public configService: AppConfigService
  ) {
    this.appConfig = configService.config;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.route.paramMap.subscribe((paramsData: any) => {
      this.studyGuid = paramsData.params['studyGuId'];
    });

    if (!this.studyGuid && data) {
      this.studyGuid = data.studyGuid;
    }
  }

  ngOnInit() {
    this.isTCRStudy = this.commonService.ProjectLSData.isTCRStudy;
    this.slideDetailsSize = this.appConfig.webAppConfig?.pageSize ?? 50;
    this.commonService.setProjectLSData();
    if (this.commonService.ProjectLSData['studyStaus']) {
      this.studyStatus = this.commonService.ProjectLSData['studyStaus'];
    }
    this.activeRole = this.commonService.ProjectLSData.userActiveRole;
    this.studyDetailsService.getStudyDetails(this.studyGuid).subscribe((response: any) => {
      this.studyDetailData = response.data;
      let additionalPath = [], reassignedPathologist = [], reassignedTechnician = [],
        reassignedPeerReviewer = [], reassignedPrimaryInvestigator = [], reassignedAdditionalPathologist = [];
      if (this.studyDetailData['studyUsers'].length) {
        for (let i = 0; i < this.studyDetailData['studyUsers'].length; i++) {
          if (this.studyDetailData['studyUsers'][i].role.toLowerCase() == "director") {
            this.studyDetailData['studyDirector'] = this.studyDetailData['studyUsers'][i].displayName;
          } else if (this.studyDetailData['studyUsers'][i].role.toLowerCase() == "pathologist") {
            if (this.studyDetailData['studyUsers'][i].reassigned) {
              reassignedPathologist.push(this.studyDetailData['studyUsers'][i].displayName);
            } else {
              this.studyDetailData['pathologist'] = this.studyDetailData['studyUsers'][i].displayName;
            }
          } else if (this.studyDetailData['studyUsers'][i].role.toLowerCase() == "peer reviewer") {
            if (this.studyDetailData['studyUsers'][i].reassigned) {
              reassignedPeerReviewer.push(this.studyDetailData['studyUsers'][i].displayName);
            } else {
              this.studyDetailData['peerReviewer'] = this.studyDetailData['studyUsers'][i].displayName;
            }
          } else if (this.studyDetailData['studyUsers'][i].role.toLowerCase() == "technician") {
            if (this.studyDetailData['studyUsers'][i].reassigned) {
              reassignedTechnician.push(this.studyDetailData['studyUsers'][i].displayName);
            } else {
              this.studyDetailData['technician'] = this.studyDetailData['studyUsers'][i].displayName;
            }
          } else if (this.studyDetailData['studyUsers'][i].role.toLowerCase() == "primary investigator") {
            if (this.studyDetailData['studyUsers'][i].reassigned) {
              reassignedPrimaryInvestigator.push(this.studyDetailData['studyUsers'][i].displayName);
            } else {
              this.studyDetailData['primaryInvestigator'] = this.studyDetailData['studyUsers'][i].displayName;
            }
          } else {
            if (this.studyDetailData['studyUsers'][i].reassigned) {
              //this.studyDetailData['reAssignedAdditionalPathologistArr'].push(this.studyDetailData['studyUsers'][i].displayName);
            } else {
              additionalPath.push(this.studyDetailData['studyUsers'][i].displayName);
            }
          }
        }
        this.studyDetailData['additionalPathologist'] = additionalPath;
        this.studyDetailData['reAssignedpathologistArr'] = reassignedPathologist;
        this.studyDetailData['reAssignedPeerReviewerArr'] = reassignedPeerReviewer;
        this.studyDetailData['reAssignedTechnicianArr'] = reassignedTechnician;
        this.studyDetailData['reAssignedPrimaryInvestigatorArr'] = reassignedPrimaryInvestigator;
        this.studyDetailData['reAssignedAdditionalPathologistArr'] = reassignedAdditionalPathologist;

      }

      /*Cassette Table*/
      this.cassetteDataSource = new MatTableDataSource(this.studyDetailData.cassettes.map(data => { return { cassetteId: data['cassetteId'], organs: data['organs'].join(', '), stains: data['stains'].join(', ') } }));
      this.cassetteDisplayedColumns = [
        { key: 'cassetteId', value: 'Cassette ID', isVisible: !this.studyDetailData?.manualFields?.includes('cassetteId'), width: '20%', cellType: 'normal' },
        { key: 'stains', value: 'Stains', isVisible: !this.studyDetailData?.manualFields?.includes('stain'), width: '15%', cellType: 'normal' },
        { key: 'organs', value: 'Tissues', isVisible: true, width: '65%', cellType: 'normal' }
      ];

      /*Dosage Table*/
      let isSubjectIdManuallyCreated = this.studyDetailData?.manualFields?.includes('subjectId');
      let subjectIdPrefix = isSubjectIdManuallyCreated ? this.configService.config.webAppConfig.subjectIdPrefix : '';

      this.dosageDataSource = new MatTableDataSource(this.studyDetailData.dosages.map(data => {
        if (data.animalSequenceType == "RANGE") {
          data.maleSubjectIds = data.animals?.maleStartIndex && data.animals?.maleEndIndex ? data.animals?.maleStartIndex + "-" + data.animals?.maleEndIndex : '';
          data.femaleSubjectIds = data.animals?.femaleStartIndex && data.animals?.femaleEndIndex ? data.animals?.femaleStartIndex + "-" + data.animals?.femaleEndIndex : '';
          data.notKnownSubjectIds = '';
        } else {
          data.maleSubjectIds = data.animals?.maleList?.length ? subjectIdPrefix + data.animals?.maleList.join(', ') : '';
          data.femaleSubjectIds = data.animals?.femaleList?.length ? subjectIdPrefix + data.animals?.femaleList.join(', ') : '';
          data.notKnownSubjectIds = data.animals?.notKnownList?.length ? subjectIdPrefix + data.animals?.notKnownList.join(', ') : '';
        }

        return data;
      }));
      this.dosageDisplayedColumns = [
        { key: 'dosageCode', value: 'Dosage Group', isVisible: true, width: '15%', cellType: 'normal' },
        { key: 'maleSubjectIds', value: 'Male', isVisible: true, width: '15%', cellType: 'normal' },
        { key: 'femaleSubjectIds', value: 'Female', isVisible: true, width: '15%', cellType: 'normal' },
        { key: 'notKnownSubjectIds', value: 'Not Known', isVisible: true, width: '15%', cellType: 'normal' },
        { key: 'dose', value: 'Dose', isVisible: true, width: '10%', cellType: 'normal' },
        { key: 'dosageUnit', value: 'Dose Unit', isVisible: true, width: '10%', cellType: 'normal' }
      ];

      /*Slide Table */
      if (this.studyDetailData.slideCount > 0) {
        this.showSlideDataLoader = true;
        this.scrollBottom = false;
        this.studyDetailData["imageDetailsModel"] = [];
        this.studyDetailData["imageDetailsModelBackUp"] = [];
        this.getSlideDetails();
      }
    });
  }


  getSlideDetails() {
    this.studyDetailsService.getSlideDeatislNew(this.commonService.ProjectLSData['studyId'], this.pageIndex, this.slideDetailsSize).subscribe((response: any) => {
      if (!response.data?.length) {
        this.scrollBottom = true;
      }
      // this.studyDetailData["imageDetailsModel"] = [...response?.data];
      this.showSlideDataLoader = false;
      if (!this.scrollBottom) {
        this.studyDetailData["imageDetailsModel"] = [...this.studyDetailData["imageDetailsModelBackUp"], ...response?.data];
        this.studyDetailData["imageDetailsModelBackUp"] = [...this.studyDetailData["imageDetailsModel"]];
      }
      if (this.studyDetailData["imageDetailsModel"]?.length < this.slideDetailsSize) {
        this.scrollBottom = true;
      }

      let isSubjectIdManuallyCreated = this.studyDetailData?.manualFields?.includes('subjectId');
      let subjectIdPrefix = isSubjectIdManuallyCreated ? this.configService.config.webAppConfig.subjectIdPrefix : '';
      this.minimalStudyDataSource = new MatTableDataSource(this.studyDetailData['imageDetailsModel'].map((imageDetails: any, index: number) => {
        return {
          imageName: imageDetails.slideName,
          cassetteId: imageDetails?.cassetteCode,
          stains: imageDetails?.cassetteStain,
          organs: imageDetails?.tissues?.join(", "),
          sex: this.displaySexTitle(imageDetails?.sex),
          dosage: imageDetails?.dosageCode,
          subjectId: subjectIdPrefix + imageDetails?.subjectId
        }
      }));

      this.minimalStudyDisplayedColumns = [
        { key: 'imageName', value: 'Image Name', isVisible: true, width: '20%', cellType: 'normal' },
        { key: 'cassetteId', value: 'Cassette ID', isVisible: !this.studyDetailData?.manualFields?.includes('cassetteId'), width: '10%', cellType: 'normal' },
        { key: 'stains', value: 'Stains', isVisible: !this.studyDetailData?.manualFields?.includes('stain'), width: '15%', cellType: 'normal' },
        { key: 'organs', value: 'Tissues', isVisible: true, width: '15%', cellType: 'normal' },
        { key: 'sex', value: 'Sex', isVisible: true, width: '10%', cellType: 'normal' },
        { key: 'subjectId', value: 'Subject ID', isVisible: true, width: '15%', cellType: 'normal' },
        { key: 'dosage', value: 'Dosage', isVisible: !this.studyDetailData?.manualFields?.includes('dosage'), width: '15%', cellType: 'normal' }
      ];
    }, (_) => {
      this.showSlideDataLoader = false;
      this.scrollBottom = false;
    });
  }

  displaySexTitle(sex: any) {
    if (sex?.toLowerCase() == 'm') {
      return 'Male';
    } else if (sex?.toLowerCase() == 'f') {
      return 'Female';
    } else {
      return 'Not Known';
    }
  }

  rowDetailsTemplate(rowInfo) {
    if (rowInfo == 'loading') {
      return "<div class='rowAdditionalInfo'>Loading...</div>";
    } else {
      var rowMainData = "<div class='rowAdditionalInfo'>";
      var templateData = '';

      /* if ((rowInfo.imageModel.scanner && (rowInfo.imageModel.scanner.instrument || rowInfo.imageModel.scanner.scanMagnification)) || rowInfo.imageModel.scanDateTime) {
        // {
        //   title: 'Scan Date', data: 'imageModel.scanDateTime', render: (data, type, row, meta) => {
        //     return (data) ? this.datepipe.transform(data, 'dd-MM-yyyy') : null;
        //   }
        // },
        let scanDateData = (rowInfo.imageModel.scanDateTime) ? this.datepipe.transform(rowInfo.imageModel.scanDateTime, 'dd-MM-yyyy') : "";
        let scanDateTemp = (scanDateData ? "<div class='inlineBlock'><span class='keyTag'>Scan Date: </span><span class='sText'>" + scanDateData + "</span></div>" : "");
        let instrumentTemp = (rowInfo.imageModel.scanner && rowInfo.imageModel.scanner.instrument ? "<div class='inlineBlock'><span class='keyTag'>Instrument: </span><span class='sText'>" + rowInfo.imageModel.scanner.instrument + "</span></div>" : "");
        let scanMagnificationTemp = (rowInfo.imageModel.scanner && rowInfo.imageModel.scanner.scanMagnification ? "<div class='inlineBlock'><span class='keyTag'>Scan Magnification: </span><span class='sText'>" + rowInfo.imageModel.scanner.scanMagnification + "</span></div>" : "");
        templateData += "<div class='slideAdditionalInfo'><div class='keyHeader'>Scanner: </div>" + instrumentTemp + scanMagnificationTemp + scanDateTemp + "</div>";
      } */
      if (rowInfo.slideInfo) {
        // {
        //   title: 'Scan Date', data: 'imageModel.scanDateTime', render: (data, type, row, meta) => {
        //     return (data) ? this.datepipe.transform(data, 'dd-MM-yyyy') : null;
        //   }
        // },
        let cassetteId = '';
        if (!this.studyDetailData.isTCRStudy) {
          cassetteId = (rowInfo.slideInfo.cassetteCode ? "<div class='inlineBlock'><span class='keyTag'>Cassette ID: </span><span class='sText'>" + rowInfo.slideInfo.cassetteCode + "</span></div>" : "");
        }
        let animalNo = (rowInfo.slideInfo.animalNo ? "<div class='inlineBlock'><span class='keyTag'>Subject ID: </span><span class='sText'>" + rowInfo.slideInfo.animalNo + "</span></div>" : "");
        let stain = (rowInfo.slideInfo.stain ? "<div class='inlineBlock'><span class='keyTag'>Stain: </span><span class='sText'>" + rowInfo.slideInfo.stain + "</span></div>" : "");
        templateData += "<div class='slideAdditionalInfo'><div class='keyHeader'>Additional Metadata: </div>" + cassetteId + animalNo + stain + "</div>";
      }

      if (rowInfo.ihcStainModel) {
        let chromogenTemp = (rowInfo.ihcStainModel.chromogen ? "<div class='inlineBlock'><span class='keyTag'>Chromogen: </span><span class='sText'>" + rowInfo.ihcStainModel.chromogen + "</span></div>" : "");
        let detectionMethodTemp = (rowInfo.ihcStainModel.detectionMethod ? "<div class='inlineBlock'><span class='keyTag'>Detection Method: </span><span class='sText'>" + rowInfo.ihcStainModel.detectionMethod + "</span></div>" : "");
        let ihcExperimentNumberTemp = (rowInfo.ihcStainModel.ihcExperimentNumber ? "<div class='inlineBlock'><span class='keyTag'>IHC Experiment Number: </span><span class='sText'>" + rowInfo.ihcStainModel.ihcExperimentNumber + "</span></div>" : "");
        let primaryAntibodyConcentrationTemp = (rowInfo.ihcStainModel.primaryAntibodyConcentration ? "<div class='inlineBlock'><span class='keyTag'>Primary Antibody Conc: </span><span class='sText'>" + rowInfo.ihcStainModel.primaryAntibodyConcentration + "</span></div>" : "");
        let primaryAntibodyConcentrationUnitTemp = (rowInfo.ihcStainModel.primaryAntibodyConcentrationUnit ? "<div class='inlineBlock'><span class='keyTag'>Primary Antibody Conc Unit: </span><span class='sText'>" + rowInfo.ihcStainModel.primaryAntibodyConcentrationUnit + "</span></div>" : "");
        let primaryAntibodyIDTemp = (rowInfo.ihcStainModel.primaryAntibodyID ? "<div class='inlineBlock'><span class='keyTag'>Primary Antibody ID: </span><span class='sText'>" + rowInfo.ihcStainModel.primaryAntibodyID + "</span></div>" : "");
        let secondayAntibodyIDTemp = (rowInfo.ihcStainModel.secondayAntibodyID ? "<div class='inlineBlock'><span class='keyTag'>Seconday Antibody ID: </span><span class='sText'>" + rowInfo.ihcStainModel.secondayAntibodyID + "</span></div>" : "");
        let targetAntigenTemp = (rowInfo.ihcStainModel.targetAntigen ? "<div class='inlineBlock'><span class='keyTag'>Target Antigen: </span><span class='sText'>" + rowInfo.ihcStainModel.targetAntigen + "</span></div>" : "");
        templateData += "<div class='slideAdditionalInfo'>" + chromogenTemp + detectionMethodTemp + ihcExperimentNumberTemp +
          primaryAntibodyConcentrationTemp + primaryAntibodyConcentrationUnitTemp + primaryAntibodyIDTemp + secondayAntibodyIDTemp + targetAntigenTemp + "</div>";
      }

      if (rowInfo.imageModel && rowInfo.imageModel.slideComments) {
        templateData += "<div class='slideAdditionalInfo'><div class='keyHeader'>Comments: </div><span class='sText'>" + rowInfo.imageModel.slideComments + "</span></div>";
      }

      if (!templateData) {
        templateData += "<div class='slideAdditionalInfo'><div class='keyHeader'> No additional data to display</div></div>";
      }
      rowMainData += templateData + "</div>";
      return rowMainData;
    }
  }

  getSlideIndex(id, arr) {
    let index = -1;
    for (let x = 0; x < arr.length; x++) {
      if (arr[x]["imageModel"] && id == arr[x]["imageModel"].slideLabel) {
        index = x;
        break;
      }
    }

    return index;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  downloadReport() {
    this.isDownloadBtnClicked = true;
    $("#downloadReportDiv").append($(".p_studydetail").clone().css("height", "auto").closest(".p_studydetail"));
    let data = $("#downloadReportDiv");

    var HTML_Width = $(data).width();
    var HTML_Height = $(data).height();
    var top_left_margin = 15;
    var PDF_Width = HTML_Width + (top_left_margin * 2);
    var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    var canvas_image_width = HTML_Width;
    var canvas_image_height = HTML_Height;

    var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    html2canvas($(data)[0], { allowTaint: true }).then(((canvas) => {
      canvas.getContext('2d');
      // console.log(canvas.height + "  " + canvas.width);

      var imgData = canvas.toDataURL("image/jpeg", 1.0);
      var pdf = new jspdf('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

      for (var i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(i.toString(), 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }

      pdf.save("Report.pdf");

      $("#downloadReportDiv").html('');
      this.isDownloadBtnClicked = false;
    }).bind(this), (() => {
      this.isDownloadBtnClicked = false;
    }).bind(this));
    return;
  }

  navigateBackToStudyListing() {
    delete this.commonService.ProjectLSData['studyId'];
    delete this.commonService.ProjectLSData['studyStaus'];
    this.commonService.setProjectLSData();
    this.commonService.changePlace("study");
    this.router.navigate(["/dashboard/study/" + this.activeRole]);
  }

  deleteStudy() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: "Delete Study",
      confirmMsg: this.translate.instant("GENERIC_MSG.STUDY.DELETE_STUDY"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        this.studyDetailsService.deleteStudy(this.studyGuid).subscribe((res: any) => {
          this.commonService.showToastMsg(res.message);
          this.navigateBackToStudyListing();
        });
      }
    });
  }

  editStudy() {
    this.commonService.changePlace("study/" + this.activeRole + "/edit/" + this.studyGuid);
    this.router.navigate(["/dashboard/study/" + this.activeRole + "/edit/" + this.studyGuid]);
  }

  openGallery() {
    this.commonService.ProjectLSData.galleryFilters.studyName = this.studyGuid;
    this.commonService.ProjectLSData.galleryFilters.isTCRStudy = this.studyDetailData.isTCRStudy;
    this.commonService.setProjectLSData();
    //this.commonService.changePlace("gallery");
    this.router.navigate(["/dashboard/gallery"]);
  }

  onScroll(event: any) {
    console.log(event);
  }


  onScrollLoadData() {
    if (this.scrollBottom) return;
    const nativeElement = this.matTableElement.nativeElement
    if (nativeElement.clientHeight + Math.round(nativeElement.scrollTop) >= (nativeElement.scrollHeight - 1)) {
      this.pageIndex += 1;
      this.getSlideDetails();
    }
  }

}