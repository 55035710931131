<div class="modal-header dialog-header">
    <div class="headerTitle">
        <div class="dialog-title">Image Details</div>
    </div>
    <div class="headerIcon float-right dn">
        <i class="fa fa-times cursorPointer" (click)="closeDialog()"></i>
    </div>
</div>
<div class="dialog-container modal-body">
    <div class="mainContainer">
        <div class="navigationBtn navBefore" *ngIf="startIndex > 0">
            <button mat-raised-button (click)="navPrev()"> <mat-icon>navigate_before</mat-icon> </button>
        </div>
        <div class="slideList">
            <ng-container *ngFor="let slide of slidelist;let i= index">
                <div *ngIf=" (i>= startIndex) && (i < endIndex)" class="indvSlide">
                    <ng-container (click)="navPrev()">
                        <div class="slidePanel">
                        <div class="macroImgDiv">
                           
                            <div *ngIf="commonService.ProjectLSData['randomizationScoreObj'] && commonService.ProjectLSData['randomizationScoreObj'][slide.slidePk]"
                                class="randomizationScoreDispDiv">
                                {{commonService.ProjectLSData['randomizationScoreObj'][slide.slidePk]}}
                            </div>
                            <img style="border: 0;" [defaultImage]="defaultMacroImagePath"
                                [lazyLoad]="thumbnailUrl+slide.identifier+'_macro.jpeg'"
                                class="img-responsive img_large" aria-label="tissuename" [alt]="">
                        </div>

                            <div class="slideDetails">
                                <!-- <ng-container *ngFor="let field of slide.detail | keyvalue">
                    <div *ngIf="field.value">
                        <span> {{field.key + " : " }} </span>
                        <span> {{ field.value}} </span>
                    </div>
                </ng-container> -->
                                <!-- 
                            <ng-container *ngFor="let row of slideInfoFieldList">
                                <div>
                                    <ng-container *ngFor="let indvField of row">
                                        <ng-container *ngIf="indvField.title != 'Tissue'">
                                            <div class="indvField" >
                                                <span>{{indvField.title}}</span>
                                                <span> {{ slide[indvField.key]? slide[indvField.key] : '-' }} </span>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="indvField.title == 'Tissue'">
                                            <div class="indvField" >
                                                <span>{{indvField.title}}</span>
                                                <span> {{ slide[indvField.key]?.length ?  slide[indvField.key].join(', ') : '-'}} </span>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </ng-container> -->


                                <div>
                                    <ng-container *ngFor="let row of slideInfoFieldList">
                                        <div class="indvRow">
                                            <ng-container *ngFor="let indvField of row">
                                                <div class="indvField tissueListing" *ngIf="indvField.key == 'scanDateTime'">
                                                    <div>{{indvField.title}}</div>
                                                    <div> {{ slide[indvField.key]? (slide[indvField.key] |date: 'medium'): '-'}} </div>
                                                </div>

                                                <div class="indvField tissueListing" *ngIf="indvField.key != 'scanDateTime'">
                                                    <div>{{indvField.title}}</div>
                                                    <div> {{ slide[indvField.key]? slide[indvField.key] : '-' }} </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- <ng-container>
                        <div class="slidePanel">
                            <div class="macroImgDiv">
                                <div *ngIf=
                                    class="randomizationScoreDispDiv">
                                    {{viewerService.randomisationInfo.randomizationScoreObj[slide.imageId]}}
                                </div>
                                <img *ngIf="!slide.isLabel"
                                    [ngStyle]="{transform: 'rotate('+slide.imageMacroRotate+'deg)'}" [alt]=''
                                    class="imgLable" [defaultImage]="defaultMacroImagePath"
                                    [lazyLoad]="parentUrl.thumbnailUrl+slide.identifier+'_macro.jpeg'">
                            </div>
                            <div class="slideDetails">
                                <div>
                                    <ng-container *ngFor="let row of slideInfoFieldList">
                                        <div class="indvRow">
                                            <ng-container *ngFor="let indvField of row">
                                                <div class="indvField" *ngIf="slide.detail[indvField]">
                                                    <div>{{indvField}}</div>
                                                    <div> {{ slide.detail[indvField]}} </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container> -->
                </div>
            </ng-container>
        </div>
        <div class="navigationBtn navNext" *ngIf="endIndex < totalImageCount">
            <button mat-raised-button (click)="navNext()">
                <mat-icon>navigate_next</mat-icon>
            </button>
        </div>
    </div>
    <div class="btnFooter">
        <button mat-flat-button color="primary" class="whiteText" cdkFocusInitial (click)="downloadExcel()">Download
            CSV</button>
    </div>
</div>