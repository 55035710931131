import { ChangePasswordComponent } from './../change-password/change-password.component';
import { AuthService } from './../auth/auth.service';
import { CommonService } from '../common.service';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AppConfigService } from '../app-config.service';
import { UsersService } from '../users/users.service';
import { map } from 'rxjs';
import { ProjectService } from '../project/project.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {

  @Input() name: string;
  defaultTimeInterval = 10000;
  roles: any;
  userRole: any;
  currentUser: any;
  firstName: any;
  lastName: any;
  activeRole: String;
  initials: String;
  circleColor: String;
  colors = ['#00AA55', '#009FD4', '#B381B3', '#939393', '#E3BC00', '#D47500', '#DC2A2A'];
  notifications = [];
  isBroswerZoomed: boolean = false;
  systemZoomed: boolean = false;
  zoomWarningMsg: String = '';
  config: any;
  downloadProgressValue: any = [];
  SlideStatusTimeOutFn: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkBrowserZoom();
  }
  @HostListener('window:focus', ['$event'])
  onSystemResize(event: any) {
    this.checkBrowserZoom();
  }
  constructor(public commonService: CommonService,
    private authService: AuthService,
    private router: Router,
    public appConfig: AppConfigService,
    public dialog: MatDialog,
    public userService: UsersService,
    public configService: AppConfigService,
    private projectService: ProjectService
  ) {
    this.checkBrowserZoom();
    this.config = this.configService.config;
    this.downloadProgressValue = this.commonService.downloadProgressValue;
  }

  ngOnInit() {
    if (!this.commonService.ProjectLSData?.userRoleList) {
      this.authService.logout();
    }
    if (this.commonService.ProjectLSData?.isfirstLogin) {
      this.showChangePasswordDialog();
    }
    if (!this.commonService?.ProjectLSData?.currentUser && this.commonService.ProjectLSData?.chooseSession) {
      this.authService.logout().subscribe((response) => {
        this.commonService.stopSessionActiveStatus();
        this.router.navigate(['/login']);
      });
    }
    this.currentUser = this.commonService?.ProjectLSData?.currentUser;
    this.firstName = this.currentUser?.firstName;
    this.lastName = this.currentUser?.lastName;
    this.initials = ((this.currentUser?.firstName?.charAt(0) || '') + (this.currentUser?.lastName?.charAt(0) || '')).toUpperCase();
    const randomIndex = Math.floor(Math.random() * Math.floor(this.colors.length));
    this.circleColor = this.colors[randomIndex];
    this.roles = this.commonService.ProjectLSData.userRoleList;
    this.activeRole = this.commonService.ProjectLSData.userActiveRole;

    if (!this.roles && !this.activeRole) {
      let meetingParam = this.commonService.ProjectLSData.meetingLoginParam;
      if (meetingParam) {
        this.router.navigate(['/login'], { queryParams: { id: meetingParam } });
      } else {
        this.router.navigate(['/login']);
      }
      this.authService.clearLocalStorage();
      this.authService.removeTokens();
      sessionStorage.setItem('meetingLoginParam', meetingParam);
      setTimeout(() => {
        if (window.location.pathname.includes("login")) {
          window.location.reload();
        }
      }, 100);
      return;
    }

    this.userRole = this.activeRole ? this.activeRole : this.roles[0].role;
    this.userService.setActiveUser(this.userRole);
    this.commonService.changeRoleId(this.userRole);
    this.getNotifications();
    // this.getNotificationsNew('init');
  }



  checkBrowserZoom() {
    let zoom = Math.ceil(((window.outerWidth - 10) / window.innerWidth) * 100);
    this.isBroswerZoomed = false;
    if ((zoom < 95) || (zoom > 105)) {
      this.isBroswerZoomed = true;
    }
    this.systemZoomed = (window.devicePixelRatio != 1);

    this.setZoomWarningMsg();
  }

  setZoomWarningMsg() {
    if (this.isBroswerZoomed && this.systemZoomed) {
      this.zoomWarningMsg = 'Please reset system display scale value & browser zoom level to 100% for better UI experience';
    } else if (this.isBroswerZoomed) {
      this.zoomWarningMsg = 'Please reset browser zoom level to 100% for better UI experience';
    } else if (this.systemZoomed) {
      this.zoomWarningMsg = 'Please set system display scale value to 100% for better UI experience';
    } else {
      this.zoomWarningMsg = '';
    }
  }

  changeRole() {
    this.commonService.changePlace('');
    this.commonService.changeRoleId(this.userRole);
    this.commonService.ProjectLSData.galleryFilters = {
      "selectedOpt": "study",
      "studyName": "",
      "isTCRStudy": false,
      "viewByG": "3",
      "viewByS": "folderLocation",
      "viewByU": "week",
      "stains": [],
      "organ": [],
      "animalNo": [],
      "control": [],
      "viewType": "grid",
      "readReviewFlag": ''
    }
    delete this.commonService.ProjectLSData['studySelectionLevel'];
    delete this.commonService.ProjectLSData['selectedStudyName'];
    delete this.commonService.ProjectLSData['selectedStudyId'];
    delete this.commonService.ProjectLSData['activeUserPermission'];
    delete this.commonService.ProjectLSData['studyManualFields'];
    this.commonService.ProjectLSData.activeImageTabIndex = 0;
    this.commonService.setProjectLSData();
    if(this.userService.timerSetTimeOut){
    clearTimeout(this.userService.timerSetTimeOut);
    this.userService.timerSetTimeOut = null;
    }
    this.userService.setActiveUser(this.userRole);
    // if (this.SlideStatusTimeOutFn) {
    //   clearTimeout(this.SlideStatusTimeOutFn);
    //   this.SlideStatusTimeOutFn = null;
    // }

    this.notifications = [];
    this.getNotifications();
    // this.getNotificationsNew();
  }

  logout() {
    this.commonService.checkFileProgress(() => {
      this.authService.logout().subscribe((response) => {
        this.commonService.stopSessionActiveStatus();
        clearTimeout(this.userService.timerSetTimeOut);
        if (this.SlideStatusTimeOutFn) {
          clearTimeout(this.SlideStatusTimeOutFn);
          this.SlideStatusTimeOutFn = null;
        }
        this.router.navigate(['/login']);
      });
    });
  }

  showChangePasswordDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "22%";
    const dialogref = this.dialog.open(ChangePasswordComponent, dialogConfig);
    dialogref.afterClosed().subscribe(res => {
      if (res === 'success') {
        if (this.SlideStatusTimeOutFn) {
          clearTimeout(this.SlideStatusTimeOutFn);
          this.SlideStatusTimeOutFn = null;
        }
        this.authService.logout();
        this.router.navigate(['/login']);
      }
    })
  }

  clearNotification() {
    let notificationIds = this.notifications.map((item: any) => {
      return item.id;
    });
    this.userService.deleteNotification(notificationIds).subscribe((res: any) => {
      if (res) {
        if(this.userService.timerSetTimeOut){
          clearTimeout(this.userService.timerSetTimeOut);
          this.userService.timerSetTimeOut = null;
          }
          this.getNotifications();
        // if (this.SlideStatusTimeOutFn) {
        //   clearTimeout(this.SlideStatusTimeOutFn);
        //   this.SlideStatusTimeOutFn = null;
        // }
        // this.getNotifications();
        // this.getNotificationsNew();
      }
    });
  }

  clearIndvNotification(indvNotificataion: any) {
    this.userService.deleteNotification([indvNotificataion.id]).pipe(map((res: any) => {
      if (res) {
        console.log(res);
      }
      return res;
    })).subscribe((res: any) => {
      if (res) {
        // if (this.SlideStatusTimeOutFn) {
        //   clearTimeout(this.SlideStatusTimeOutFn);
        //   this.SlideStatusTimeOutFn = null;
        // }
        // this.getNotifications();
        // this.getNotificationsNew();
        if(this.userService.timerSetTimeOut){
          clearTimeout(this.userService.timerSetTimeOut);
          this.userService.timerSetTimeOut = null;
          }
        this.getNotifications();
      }
    });
  }


  getNotificationsNew(call: any = '') {
    this.SlideStatusTimeOutFn = setTimeout(() => {
      this.userService.getNotifications().subscribe((response: any) => {
        this.notifications = [...response];
        this.getNotificationsNew();
      }, (error: any) => {
        if (error.status != 400 && error.status != 401) {
          this.getNotificationsNew();
        }
      });
    }, (call == 'init') ? 0 : this.config?.webAppConfig?.notificationCallInterval | this.defaultTimeInterval);
  }

  getNotifications() {
    if (!this.userService.refreshPopIsOpened) {
      this.userService.getNotifications().subscribe((res: any) => {
        this.notifications = [...res];
        for (let i = 0; i < this.notifications.length; i++) {
          this.notifications[i].createdAt = this.transform(this.notifications[i].createdAt);
        }
        if (!this.userService.refreshPopIsOpened) {
          this.userService.timerSetTimeOut = setTimeout(() => {
            this.getNotifications();
          }, this.config?.webAppConfig?.notificationCallInterval | this.defaultTimeInterval);
        }

      }, (err: any) => {
        if (err.status != 401 && err.status != 400) {
          this.userService.timerSetTimeOut = setTimeout(() => {
            this.getNotifications();
          }, this.config?.webAppConfig?.notificationCallInterval | this.defaultTimeInterval);
        }
      });
    } else {
      this.userService.timerSetTimeOut = setTimeout(() => {
        this.getNotifications();
      }, this.config?.webAppConfig?.notificationCallInterval | this.defaultTimeInterval);
    }
  }

  transform(value: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals: { [key: string]: number } = {
        'year': 31536000,
        'month': 2592000,
        'week': 604800,
        'day': 86400,
        'hour': 3600,
        'minute': 60,
        'second': 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
      }
    }
    return value;
  }

  ngOnDestroy(): void {
    if(this.userService.timerSetTimeOut){
      clearTimeout(this.userService.timerSetTimeOut);
      this.userService.timerSetTimeOut = null;
      }
 
    // if (this.SlideStatusTimeOutFn) {
    //   clearTimeout(this.SlideStatusTimeOutFn);
    //   this.SlideStatusTimeOutFn = null;
    // }
  }

  cancelDownload(indvProgressSet: any) {
    this.projectService.cancelDownload(indvProgressSet);
  }

  showLoginPopup() {
    // this.commonService.ProjectLSData['sessionId'] = "1";
    this.commonService.ProjectLSData['accessToken'] = "1";
    this.commonService.ProjectLSData['refreshToken'] = "1";
    this.commonService.setProjectLSData();
    // location.reload();
  }

}
