import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { Component, OnInit, Inject, Optional, EventEmitter, Output, Input, ViewEncapsulation, ElementRef, ViewChild, ChangeDetectorRef } from "@angular/core";
import { GalleryService } from "./gallery.service";
import { Router } from "../../../node_modules/@angular/router";
import { CommonService } from '../common.service';
import { AppConfigService } from "../app-config.service";
import { StudyService } from "../study/study.service";
// import { finalize, Subject } from "rxjs";
import { finalize, takeUntil } from 'rxjs/operators';
import { forkJoin, Subject } from "rxjs";
import { StudyDetailsComponent } from "../study/study-details/study-details.component";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmDialogComponent } from "../common/confirm-dialog/confirm-dialog.component";
import { RandomizedImageDetailComponent } from "../randomized-image-detail/randomized-image-detail.component";
import { GalleryDialogComponent } from "./gallery-dialog/gallery-dialog.component";
import { HttpEventType } from "@angular/common/http";
import * as saveAs from 'file-saver';
import { getTreeMultipleDefaultNodeDefsError } from "@angular/cdk/tree";
@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.less"],
  encapsulation: ViewEncapsulation.None,
})
export class GalleryComponent implements OnInit {
  @ViewChild('uiElement') uiElement!: ElementRef;
  activeMode: String = "gallery";
  onClose = new EventEmitter();
  SlideStatusTimeOutFn: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  finalOptions$: Subject<[]> = new Subject<[]>();
  forkArr: any = [];
  loader: boolean = false;
  appConfig: any;
  selectedStudyProj: any = "";
  galleryFilter: any;
  studyProjectList: any = [];
  selectedStudyName: any;
  selectedStudyId: any;
  totalFileSize = 0;
  selectedFileSize: any;
  studyStainList: Array<String>;
  studyOrganList: Array<String>;
  studyAnimalList: Array<String>;
  studyDosageList: Array<String>;

  folderStainList: Array<String>;
  folderOrganList: Array<String>;
  folderAnimalList: Array<String>;
  folderDosageList: Array<String>;
  studyOrganObj = {};
  stainsF: any = [];
  organsF: any = [];
  animalsF: any = [];
  controlF: any = [];
  dosageF: any = [];
  unmapFlag: boolean = false;
  slideDataObservable: any;
  @Input() dialogData: any;
  quickFilterView: any = "-2";
  rawSlidesData: any = [];
  groupBy: any = '3';
  filterBySeverityGrading: any = '';
  readStatus: any = null;
  reviewStatus: any = null;
  readReviewFlag: any = '';
  galleryFilterisVisible: Boolean = false;
  currentStudyGradingGrade: any = [];
  dosageArr = [];
  months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  slideControl: any = [
    { optionId: 'Positive', optionName: 'Positive Control Slides' },
    { optionId: 'Negative', optionName: 'Negative Control Slides' },
    { optionId: 'Test', optionName: 'Test Slides' },
  ];
  slidesData: any;
  isViewChnagedForRandomization: boolean = false;
  mappingStatus: boolean = false;
  previewP: Boolean = false;
  discardingStatus: boolean = false;
  unmappingStatus: boolean = false;
  thumbnailGenStatus: boolean = false;
  studyListingData: any = [];
  slidesArr: any = [];
  backupSlidesArr: any = [];
  allSlideIds: any = [];
  moreDataAvailable: number = 0;
  pageIndex = 1;
  studySelectionLevel: any = [];
  galleryList: any = {};
  defaultImagePath: any = '';
  defaultImagePathMacro: any = '';
  imagePreview: any = {
    state: false,
    currItemSelected: null,
    schemaInfo: null,
    previewToggleBtns: []
  };
  disablePreviewNavIcon: any = {
    prevNavIcon: false,
    naxtNavIcon: false,
  };
  activePreviewState: any = {
    iconType: 'macro',
    title: 'Macro Image',
    selectedROI: 1,
    roiPath: null,
    isSchemaFetching: true
  };
  slideDetTabSelectedIndex: number = 0;
  selectedSlideForPreview: any;
  noBarCodeText: boolean = false;
  noBarFoundText: boolean = false;
  noLabelText: boolean = false;
  previewOrgan: any = '';
  slideReviewData: any = [{ "userFk": null }];
  intensityObj = {};
  frequencyObj = {};
  algorithmList: any = [];
  activeResult: any = '';
  qcResults: any = [];
  abnormalityResults: any = [];
  abnormalityAlgoResultsList: any = [];
  allResults: any = [];
  selectedROIResult: any;
  algoROIArr: any = [];
  selectedAbnormalityAlgo: any;
  previewTableHeaders: any = ['featureName', 'foci', 'area'];
  imagesTableHeaders: any = ['iconsColumn', 'imageName', 'dosageColumn', 'sexColumn', 'tissueColumn'];
  hasSlideAccordionData = true;
  isSelectAllActive: boolean = false;
  isSelectAllActiveForFolder: boolean = false;
  userActiveRole: any;
  selectedFolder: any = [];
  hiddenOptions: any = [];
  validateMetataObj: any = {
    'algorithm': {}
  }
  validateMetadataColums: any = ['imageName', 'species', 'tissue', 'stain']
  isFilterApplied: boolean = false;
  isBookmarkActive: boolean = false;
  commonAlgoMap: { [algoID: string]: string } = {};
  selectedImagesWithReview: any = [];
  selectedFolderWithReview: any = [];
  subjectIdPrefix = '';

  constructor(
    public galleryService: GalleryService, public configService: AppConfigService, private dialog: MatDialog,
    private studyListingService: StudyService,
    private router: Router,
    @Optional() private dialogRef: MatDialogRef<GalleryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public commonService: CommonService,
    public translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    if (data && data.disableClose) {
      dialogRef.disableClose = data.disableClose;
    }
    this.appConfig = configService.config;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit() {
    this.defaultImagePath = "../../../" + this.appConfig?.webAppConfig?.appBaseName + "assets/images/viewer_icon/gallery_noimg.png";
    this.defaultImagePathMacro = "../../../" + this.appConfig?.webAppConfig?.appBaseName + "assets/images/viewer_icon/gallery_noimg.png";
    if (this.activeMode == "gallery") {
      this.commonService.ProjectLSData.tileNetworkStatus = false;
    }
    this.userActiveRole = this.commonService.ProjectLSData['userActiveRole'];
    let url = this.router.url;
    let urlarr = url.split("/");
    if (urlarr[2] == 'mapped') {
      this.activeMode = urlarr[2];
    } else {
      this.activeMode = urlarr[4];
    }

    if (this.activeMode != "gallery") {
      this.galleryService.switchGridList("grid");
    } else {
      let galleryFilter = this.commonService.ProjectLSData.galleryFilters;
      this.galleryService.switchGridList(galleryFilter.viewType);
    }

    this.galleryService.showLoader = true;
    // if (this.commonService.ProjectLSData['isStudyGalleryFilterApplied']) {
    this.isFilterApplied = this.commonService.ProjectLSData?.['isStudyGalleryFilterApplied'];
    // }

    if (this.commonService.ProjectLSData['galleryFilters']) {
      this.groupBy = this.commonService.ProjectLSData['galleryFilters']['viewByG'];
    }

    if (this.activeMode == 'gallery') {
      this.studyListingService.getAllRandomizedStudies().subscribe((response: any) => {
        this.galleryService.allRandomisedResp = [];
        if (response?.length) {
          this.galleryService.allRandomisedResp = [...response];
        }
      });
      if (this.commonService.ProjectLSData['galleryFilters']['readReviewFlag']) {
        this.readReviewFlag = this.commonService.ProjectLSData['galleryFilters']['readReviewFlag'];
      }
      if (this.commonService.ProjectLSData['galleryFilters']['filterBySeverityGrading']) {
        this.filterBySeverityGrading = this.commonService.ProjectLSData['galleryFilters']['filterBySeverityGrading'];
      }
      if (this.commonService.ProjectLSData['studySelectionLevel']?.length) {
        this.getPageWiseData(this.commonService.ProjectLSData['studySelectionLevel'][0], 'init');
      } else {
        this.selectOption(this.activeMode, 'init');
      }
    } else {
      if (this.commonService.ProjectLSData['galleryFilters']['readReviewFlag']) {
        this.readReviewFlag = this.commonService.ProjectLSData['galleryFilters']['readReviewFlag'];
      }
      if (this.commonService.ProjectLSData['galleryFilters']['filterBySeverityGrading']) {
        this.filterBySeverityGrading = this.commonService.ProjectLSData['galleryFilters']['filterBySeverityGrading'];
      }
      if (this.commonService.ProjectLSData['studySelectionLevel']?.length) {
        this.checkUnmapStatus();
        this.getPageWiseData(this.commonService.ProjectLSData['studySelectionLevel'][0], 'init');
      } else {
        this.selectOption(this.activeMode, 'init');
      }
    }
    this.getAlgorithmsList();
  }


  ngOnDestroy() {
    if (this.SlideStatusTimeOutFn) {
      clearTimeout(this.SlideStatusTimeOutFn);
    }
  }

  displaySexTitle(sex: any) {
    if (sex.toLowerCase() == 'm') {
      return 'Male'
    } else if (sex.toLowerCase() == 'f') {
      return 'Female'
    } else if (sex.toLowerCase() == 'n') {
      return 'Not Known'
    }
  }

  getPageWiseData(searchby: any, call: any, navigationOrderBy: any = null) {
    this.isSelectAllActiveForFolder = false;
    if (call != 'scroll' && call != 'filterApply') {
      this.studySelectionLevel = [];
      this.isSelectAllActive = false;
      this.selectedImagesWithReview = [];
      this.selectedFolderWithReview = [];
      this.studySelectionLevel.push(searchby);
      this.galleryService.selectedSlidesArr = [];
      if (this.studyListingData?.length) {
        this.studyListingData.forEach((study: any) => {
          study.imagesArr = [];
          study.selection = false;
        });
      }
      this.commonService.ProjectLSData['studySelectionLevel'] = [];
      this.commonService.ProjectLSData['studySelectionLevel'].push(searchby);
      this.commonService.setProjectLSData();
      if (this.commonService.ProjectLSData['selectedStudyName']) {
        this.selectedStudyName = this.commonService.ProjectLSData['selectedStudyName'];
      }
      if (this.commonService.ProjectLSData['selectedStudyId']) {
        this.selectedStudyId = this.commonService.ProjectLSData['selectedStudyId'];
      }
      this.loader = true;
    }
    this.selectedFolder = [];
    if (this.moreDataAvailable < 1) {
      this.slidesArr = [];
      this.backupSlidesArr = [];
    }
    let orderby, stain = [], tissue = [], subjectid = [], dosage = [], controlDosage = false, typeControl = [], cassetteCode = [], sex = [];
    this.galleryFilter = this.commonService.ProjectLSData.galleryFilters;
    orderby = this.galleryService.getGroupByString(this.galleryFilter.viewByG);
    tissue = this.galleryFilter.organ ?? [];
    stain = this.galleryFilter.stains ?? [];
    subjectid = this.galleryFilter.animalNo ?? [];
    typeControl = this.galleryFilter.control ?? [];
    dosage = this.galleryFilter.dosageCode ?? [];
    cassetteCode = this.galleryFilter?.cassetteCode ?? [];
    sex = this.galleryFilter?.sex ?? [];
    this.galleryFilter.readReviewFlag = this.readReviewFlag;
    this.galleryFilter.filterBySeverityGrading = this.filterBySeverityGrading;
    this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
    this.commonService.setProjectLSData();
    if (call == 'init') {
      this.clearMetadataValidate();
      let setting: any = {
        viewByG: '',
        searchby: []
      };
      if (navigationOrderBy) {
        setting['viewByG'] = navigationOrderBy,
          setting['searchby'] = [searchby]
      } else {
        let orderBy: any;
        orderBy = this.galleryService.getGroupByString(navigationOrderBy ?? this.galleryFilter?.viewByG);
        if (orderBy == 'dosage') {
          setting['searchby'] = dosage
        } else if (orderBy == 'subjectId') {
          setting['searchby'] = subjectid
        } else if (orderBy == 'tissue') {
          setting['searchby'] = tissue
        } else if (orderBy == 'cassette') {
          setting['searchby'] = cassetteCode
        } else if (orderBy == 'sex') {
          setting['searchby'] = sex
        }
      }
      this.forkArr = [];
      this.getImageLevelFilterData(this.setSelectedOption(this.galleryService.getGroupByString(navigationOrderBy ?? this.galleryFilter?.viewByG), setting?.searchby), this.galleryService.getGroupByString(navigationOrderBy ?? this.galleryFilter?.viewByG));
      this.getValidFilterOptions();
      this.getstudyStainList();
      this.getstudyOrganList();
      this.getstudyAnimalList();
      this.getDosageList();
    }

    if (call == 'navigation') {
      this.clearMetadataValidate();
      this.forkArr = [];
      this.getImageLevelFilterData(this.setSelectedOption(this.galleryService.getGroupByString(navigationOrderBy ?? this.galleryFilter?.viewByG), [searchby]), this.galleryService.getGroupByString(navigationOrderBy ?? this.galleryFilter?.viewByG));
      this.getValidFilterOptions();
      if (orderby == 'tissue' && !tissue?.length) {
        tissue.push(searchby);
      } else if (orderby == 'dosage' && !dosage?.length) {
        dosage.push(searchby);
      } else if (orderby == 'subjectId' && !subjectid?.length) {
        subjectid.push(searchby);
      } else if (orderby == 'cassette' && !cassetteCode?.length) {
        cassetteCode.push(searchby);
      } else if (orderby == 'sex' && !sex?.length) {
        sex.push(searchby);
      }
    }

    if (call == 'filterApply') {
      this.clearMetadataValidate();
    }
    let searchData = {
      "mainFilter": searchby ? [searchby] : [],
      "subfilters": {
        "tissue": tissue,
        "cassetteStain": stain,
        "subjectId": subjectid,
        "tcrSlideControl": typeControl,
        "dosage": dosage,
        "sex": sex,
        "cassetteCode": cassetteCode
      },
      "dosageControlGroup": controlDosage,
      "slidesMapped": this.galleryService.showOnlyMappedSlides,
      "viewByMore": this.galleryService.getReviewFilterString(this.galleryFilter.readReviewFlag),
      "severityGrading": this.galleryFilter.filterBySeverityGrading,
      // "bookmark": this.isBookmarkActive
      "bookmark": null,
      "currentRole": this.commonService.ProjectLSData['activeUserPermission']?.[0]?.role ?? 'PATHOLOGIST'
    }
    this.galleryService.getFilteredSlideData(this.galleryFilter.studyName, searchData, orderby, this.pageIndex).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      // this.hasSlideAccordionData = res.data.content.length !== 0;
      this.loader = false;
      res.data.content.forEach(element => {
        element.organName = [];
        element.organ.forEach(organElem => {
          element.organName.push(this.studyOrganObj[organElem]);
          let fileSplitArr = element.slidename.split(".");
          element.fileExtension = fileSplitArr[fileSplitArr.length - 1];
          element.isShowMacroOnly = (this.appConfig?.galleryConfig?.macroImgInfo[element.fileExtension]?.showMacroOnly) ? this.appConfig?.galleryConfig?.macroImgInfo[element.fileExtension]?.showMacroOnly : false;
          let macroRotation = this.appConfig?.galleryConfig?.macroImgInfo[element.fileExtension]?.macroRotation;
          element.macroRotation = macroRotation ? macroRotation : 0;
          element.macroPath = this.appConfig?.apiConfig?.thumbnailUrl + element.identifier + '_macro.jpeg';
          element.thumbnailPath = this.appConfig?.apiConfig?.thumbnailUrl + element.identifier + '_thumbnail.jpeg';
          element.labelPath = this.appConfig?.apiConfig?.thumbnailUrl + element.identifier + '_label.jpeg';
          element.retryAttempted = 0;
          element.selection = (call == 'scroll') ? ((this.galleryService.selectedSlidesArr?.length > this.slidesArr?.length || this.isSelectAllActive) ? true : false) : false;
          if (Object.keys(element?.analysisInfo ?? {})?.length) {
            element.algorithmDetails = Object.entries(element?.analysisInfo?.info).map(
              (entry) => entry[1]
            );
            this.setSlideIcons(element);
          }
        });
      });
      this.slidesArr = [...this.backupSlidesArr, ...res?.data?.content];
      this.backupSlidesArr = [...this.slidesArr];
      this.moreDataAvailable = res?.data?.pageInfo?.more;
      if (this.SlideStatusTimeOutFn) {
        clearTimeout(this.SlideStatusTimeOutFn);
      }
      if (this.slidesArr?.length) {
        for (let a = 0; a < this.slidesArr?.length; a++) {
          if (this.slidesArr[a]['analysisStatus'] == 'QUEUED' || this.slidesArr[a]['analysisStatus'] == 'IN_PROGRESS') {
            this.getSlideUpdates();
            break;
          }
        }
      }
    });
  }

  getFolderLevelFilterData() {
    if (this.activeMode == 'gallery' || this.activeMode == 'mapped') {
      this.forkArr = [];
      this.getstudyStainList();
      this.getstudyOrganList();
      this.getstudyAnimalList();
      this.getDosageList();
      this.stainsF = [];
      this.organsF = [];
      this.animalsF = [];
      this.controlF = [];
      this.dosageF = [];
      if (this.commonService.ProjectLSData['studySelectionLevel']?.length) {
        this.setSelectedOption(this.galleryService.getGroupByString(this.commonService.ProjectLSData?.galleryFilters?.viewByG), [this.commonService.ProjectLSData['studySelectionLevel'][0]]);
      }
    }
  }

  getImageLevelFilterData(callback: any, selectedType: any) {
    if (this.activeMode == 'gallery' || this.activeMode == 'mapped') {
      let stain = [], tissue = [], subjectid = [], control = [], dosage = [], controlDosage = false, callmetadaApi = false, cassetteCode = [], sex = [];
      callmetadaApi = true;
      this.galleryFilter['viewByG'] = this.groupBy;
      this.galleryFilter.readReviewFlag = this.readReviewFlag;
      this.galleryFilter.filterBySeverityGrading = this.filterBySeverityGrading;

      if (this.stainsF?.length) {
        this.galleryFilter.stains = this.stainsF;
      }
      stain = this.stainsF?.length ? this.stainsF : this.galleryFilter.stains;
      // this.stainsF = stain;

      if (this.organsF?.length) {
        this.galleryFilter.organ = this.organsF;
      }
      tissue = this.organsF?.length ? this.organsF : this.galleryFilter.organ;
      // this.organsF = tissue;

      if (this.animalsF?.length) {
        this.galleryFilter.animalNo = this.animalsF;
      }
      subjectid = this.animalsF?.length ? this.animalsF : this.galleryFilter.animalNo;
      // this.animalsF = subjectid;

      if (this.dosageF?.length) {
        this.galleryFilter.dosageCode = this.dosageF;
      }
      dosage = this.dosageF?.length ? this.dosageF : this.galleryFilter.dosageCode;
      if (selectedType == 'cassette' && !this.galleryFilter['cassetteCode']?.length) {
        this.galleryFilter['cassetteCode'] = [this.studySelectionLevel[0]];
      }
      if (selectedType == 'sex' && !this.galleryFilter['sex']?.length) {
        this.galleryFilter['sex'] = [this.studySelectionLevel[0]];
      }
      cassetteCode = this.galleryFilter?.cassetteCode ?? [];
      sex = this.galleryFilter?.sex ?? [];

      this.galleryFilter.quickFilter = this.quickFilterView;
      this.galleryFilter.control = this.controlF;
      control = this.controlF;
      this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
      this.commonService.setProjectLSData();
      let searchBy = {
        "mainFilter": [this.studySelectionLevel[0]],
        "subfilters": {
          "tissue": tissue,
          "cassetteStain": stain,
          "subjectId": subjectid,
          "tcrSlideControl": control,
          "dosage": dosage,
          "sex": sex,
          "cassetteCode": cassetteCode
        },
        "dosageControlGroup": controlDosage,
        "slidesMapped": this.galleryService.showOnlyMappedSlides,
        "viewByMore": this.galleryService.getReviewFilterString(this.readReviewFlag),
        "severityGrading": this.galleryFilter.filterBySeverityGrading,
        // "bookmark": this.isBookmarkActive
        "bookmark": null,
        "currentRole": this.commonService.ProjectLSData['activeUserPermission']?.[0]?.role ?? 'PATHOLOGIST'
      }
      for (const key in searchBy.subfilters) {
        if (key != selectedType && key != 'cassetteCode' && key != 'sex') {
          searchBy.subfilters[key] = []
        }
      }
      let newSearchBy: any = {
        "subfilters": {
          "tissue": tissue,
          "cassetteStain": stain,
          "subjectId": subjectid,
          "tcrSlideControl": control,
          "dosage": dosage,
          "sex": sex,
          "cassetteCode": cassetteCode
        }
      }
      // this.getFolderStatinList(searchBy);Not needed
      this.getFolderOrganList(searchBy, newSearchBy);
      this.getFolderAnimalList(searchBy, newSearchBy);
      this.getFolderDosageList(searchBy, newSearchBy);
      this.finalOptions$.subscribe((response: any) => {
        if (response?.length > 2) {
          if (callback) {
            callback();
          }
        }
      });
    }
  }


  getFolderOrganList(searchBy: any, newSearchBy: any) {
    this.galleryService.getSlideAccordionData(this.selectedStudyProj ? this.selectedStudyProj : this.galleryFilter?.studyName, 'tissue', searchBy).subscribe((response: any) => {
      if (response.success) {
        let organList: any = [];
        if (this.galleryFilter.organ?.length) {
          this.organsF = this.galleryFilter.organ;
          if (this.commonService.ProjectLSData['folderOrganList']?.length) {
            this.folderOrganList = this.commonService.ProjectLSData['folderOrganList'];
          } else {
            let newData: any = [];
            newData = response?.data.filter((item: any) => {
              return item.organ == newSearchBy?.subfilters?.tissue[0]
            });
            this.folderOrganList = newData;
          }
        } else {
          response?.data?.forEach((item: any) => {
            organList.push({
              'organ': item.organ,
              'organId': item.organId
            });
          });
          this.folderOrganList = organList;
          this.commonService.ProjectLSData['folderOrganList'] = this.folderOrganList;
          this.commonService.setProjectLSData();
        }
        this.forkArr.push('organ')
        this.finalOptions$.next(this.forkArr);
      }
    });
  }

  getFolderAnimalList(searchBy: any, newSearchBy: any) {
    this.galleryService.getSlideAccordionData(this.selectedStudyProj ? this.selectedStudyProj : this.galleryFilter?.studyName, 'subjectId', searchBy).subscribe((response: any) => {
      if (response.success) {
        let animalList: any = [];
        if (this.galleryFilter.animalNo?.length) {
          this.animalsF = this.galleryFilter.animalNo;
          if (this.commonService.ProjectLSData['folderAnimalList']?.length) {
            this.folderAnimalList = this.commonService.ProjectLSData['folderAnimalList'];
          } else {
            let newData: any = [];
            newData = response?.data.filter((item: any) => {
              return item.attribute == newSearchBy?.subfilters?.subjectId[0]
            });
            newData.forEach((item: any) => {
              animalList.push({
                'animalNo': item.attribute
              })
            });
            this.folderAnimalList = animalList;
          }
        } else {
          response?.data?.forEach((item: any) => {
            animalList.push({
              'animalNo': item.attribute
            });
          });
          this.folderAnimalList = animalList;
          this.commonService.ProjectLSData['folderAnimalList'] = this.folderAnimalList;
          this.commonService.setProjectLSData();
        }
        this.forkArr.push('folderAnimalList')
        this.finalOptions$.next(this.forkArr);
      }
    });
  }

  getFolderDosageList(searchBy: any, newSearchBy: any) {
    this.galleryService.getSlideAccordionData(this.selectedStudyProj ? this.selectedStudyProj : this.galleryFilter?.studyName, 'dosage', searchBy).subscribe((response: any) => {
      if (response.success) {
        let dosageList: any = [];
        if (this.galleryFilter.dosageCode?.length) {
          this.dosageF = this.galleryFilter.dosageCode
          if (this.commonService.ProjectLSData['folderDosageList']?.length) {
            this.folderDosageList = this.commonService.ProjectLSData['folderDosageList'];
          } else {
            let newData: any = [];
            newData = response?.data.filter((item: any) => {
              return item.dosageCode == newSearchBy?.subfilters?.dosage[0]
            })
            this.folderDosageList = newData;
          }
          // this.folderDosageList = this.commonService.ProjectLSData['folderDosageList'];
        } else {
          response?.data?.forEach((item: any) => {
            dosageList.push({
              'dosageCode': item.dosageCode,
              'dosageName': item.dosageName,
            });
          });
          this.folderDosageList = dosageList;
          this.commonService.ProjectLSData['folderDosageList'] = this.folderDosageList;
          this.commonService.setProjectLSData();
        }
        this.forkArr.push('folderDosageList')
        this.finalOptions$.next(this.forkArr);
      }
    });
  }

  bookmarkToggle() {
    this.isBookmarkActive = !this.isBookmarkActive;
    this.getPageWiseData(this.commonService.ProjectLSData['studySelectionLevel'][0], 'init');
  }

  setSelectedOption(orderBy: any, searchby: any) {
    this.forkArr = [];
    if (orderBy == 'dosage') {
      this.dosageF = searchby;
      if (!this.dosageF?.length) {
        this.dosageF = [...this.dosageF];
      }
    } else if (orderBy == 'tissue') {
      this.organsF = searchby;
      if (!this.organsF?.length) {
        this.organsF = [...this.organsF];
      }
    } else if (orderBy == 'subjectId') {
      this.animalsF = searchby;
      if (!this.animalsF?.length) {
        this.animalsF = [...this.animalsF];
      }
    }
  }

  onGridScroll() {
    if (this.moreDataAvailable === 0) return;
    const nativeElement = this.uiElement.nativeElement;
    if (nativeElement.clientHeight + Math.round(nativeElement.scrollTop) >= (nativeElement.scrollHeight - 1)) {
      this.pageIndex += 1;
      this.getPageWiseData(this.studySelectionLevel[0], 'scroll');
    }
  }


  getValidFilterOptions() {
    this.galleryService.getValidFilterOptions(this.selectedStudyProj ? this.selectedStudyProj : this.galleryFilter?.studyName).subscribe((response: any) => {
      if (response.success) {
        this.hiddenOptions = [];
        this.hiddenOptions = response?.data?.split(',');
        this.subjectIdPrefix = this.hiddenOptions.includes('subjectId') ? this.appConfig.webAppConfig.subjectIdPrefix : '';
      }
    })
  }

  getAccordionData(isDropDownChanged = false) {
    if (isDropDownChanged) {
      this.galleryService.activeAccordian = 0;
      this.galleryFilter.selectedAccordion = "";
      this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
      this.commonService.setProjectLSData();
    }
    this.isSelectAllActiveForFolder = false;
    this.getValidFilterOptions();
    let orderby, stain = [], tissue = [], subjectid = [], control = [], dosage = [], controlDosage = false, callmetadaApi = false, cassetteCode = [], sex = [];
    callmetadaApi = true;
    orderby = this.galleryService.getGroupByString(this.groupBy);
    this.galleryFilter.viewByG = this.groupBy;
    this.galleryFilter.readReviewFlag = this.readReviewFlag;
    this.galleryFilter.filterBySeverityGrading = this.filterBySeverityGrading;

    this.galleryFilter.stains = this.stainsF;
    stain = this.stainsF ?? [];

    this.galleryFilter.organ = this.organsF;
    tissue = this.organsF ?? [];

    this.galleryFilter.animalNo = this.animalsF;
    subjectid = this.animalsF ?? [];

    this.galleryFilter.control = this.controlF;
    control = this.controlF ?? [];

    this.galleryFilter.quickFilter = this.quickFilterView;

    this.galleryFilter.dosageCode = this.dosageF;
    dosage = this.dosageF ?? [];

    cassetteCode = this.galleryFilter?.cassetteCode ?? [];
    sex = this.galleryFilter?.sex ?? [];


    this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
    this.commonService.setProjectLSData();
    if (callmetadaApi) {
      let searchBy = {
        "mainFilter": [],
        "subfilters": {
          "tissue": (this.galleryFilter.filterBySeverityGrading == 'Show All') ? [] : tissue,
          "cassetteStain": this.galleryFilter.filterBySeverityGrading == 'Show All' ? [] : stain,
          "subjectId": this.galleryFilter.filterBySeverityGrading == 'Show All' ? [] : subjectid,
          "tcrSlideControl": this.galleryFilter.filterBySeverityGrading == 'Show All' ? [] : control,
          "dosage": this.galleryFilter.filterBySeverityGrading == 'Show All' ? [] : dosage,
          "sex": this.galleryFilter.filterBySeverityGrading == 'Show All' ? [] : sex,
          "cassetteCode": cassetteCode
        },
        "dosageControlGroup": controlDosage,
        "slidesMapped": this.galleryService.showOnlyMappedSlides,
        "viewByMore": this.galleryService.getReviewFilterString(this.readReviewFlag),
        "severityGrading": this.galleryFilter.filterBySeverityGrading,
        // "bookmark": this.isBookmarkActive
        "bookmark": null,
        "currentRole": this.commonService.ProjectLSData['activeUserPermission']?.[0]?.role ?? 'PATHOLOGIST'
      }
      this.loader = true;
      this.rawSlidesData = [];
      this.studyListingData = [];
      this.slidesArr = [];
      this.backupSlidesArr = [];
      this.moreDataAvailable = 0;
      this.studySelectionLevel = [];
      this.selectedFolder = [];
      delete this.commonService.ProjectLSData?.currentSelectedImg;
      delete this.commonService.ProjectLSData['studySelectionLevel'];
      delete this.commonService.ProjectLSData['folderOrganList'];
      delete this.commonService.ProjectLSData['folderAnimalList'];
      delete this.commonService.ProjectLSData['folderDosageList'];
      this.commonService.setProjectLSData();
      this.imagePreview.state = false;
      this.imagePreview.currItemSelected = null;
      this.imagePreview.currItemSelectedIndex = null;
      this.activeResult = '';
      this.qcResults = [];
      this.abnormalityResults = [];
      this.abnormalityAlgoResultsList = [];
      this.allResults = [];
      this.selectedROIResult = '';
      this.selectedAbnormalityAlgo = {}
      this.algoROIArr = [];
      this.noBarCodeText = false;
      this.noBarFoundText = false;
      this.noLabelText = false;
      this.selectedSlideForPreview = {};
      this.slideDataObservable = this.galleryService.getSlideAccordionData(this.selectedStudyProj ? this.selectedStudyProj : this.galleryFilter?.studyName, orderby, searchBy)
        .pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
          if (response.success) {
            this.loader = false;
            this.rawSlidesData = response.data;
            this.studyListingData = response.data;
            this.studyListingData.forEach((study: any) => {
              study.selection = false;
              study.imagesArr = []
            })
            // this.hasSlideAccordionData = response.data.length !== 0;
            this.generateAccordionObj();
          } else {
            this.rawSlidesData = [];
            this.galleryService.setSlidesList({});
          }
          this.galleryService.showLoader = false;
        });
    } else {
      this.rawSlidesData = [{ attribute: this.quickFilterView == "CD" ? "Control Group" : this.quickFilterView }];
      this.generateAccordionObj();
      this.galleryService.showLoader = false;
    }
    this.toggleFilter();
  }

  getSlideUpdates() {
    this.SlideStatusTimeOutFn = setTimeout(() => {
      let payload: any = {
        "project": [this.selectedStudyId],
        "type": "STUDY",
        "seconds": 10
      }
      this.galleryService.getSlideUpdatesForStudy(payload).subscribe((response: any) => {
        if (response?.success) {
          this.getSlideUpdates();
          if (Object.keys(response?.data).length > 0) {
            this.updateGalleryData(response?.data);
          }
        }
      }, (error: any) => {
        // if (error.status != 400 && error.status != 400) {
        //   this.getSlideUpdates();
        // }
        if (this.SlideStatusTimeOutFn) {
          clearTimeout(this.SlideStatusTimeOutFn);
        }
      });
    }, this.appConfig?.galleryConfig?.slideUpdatesTimer);
  }

  updateGalleryData(data: any) {
    let elements: any;
    if (Object.keys(data ?? {})?.length) {
      elements = Object.entries(data).map(
        (entry) => {
          entry[1]['id'] = entry[0];
          return entry[1];
        }
      );
      for (let b = 0; b < elements?.length; b++) {
        if (Object.keys(elements[b]?.info ?? {})?.length) {
          elements[b].algorithmList = Object.entries(elements[b]?.info).map(
            (entry) => entry[1]
          );
        }
      }
    }
    for (let a = 0; a < this.slidesArr?.length; a++) {
      let slideData: any = this.slidesArr[a];
      for (let b = 0; b < elements?.length; b++) {
        let newData: any = elements[b];
        if (slideData?.id == newData?.id && newData?.algorithmList?.length) {
          this.updateSlideData(newData, slideData)
        }
      }
    }
  }

  updateSlideData(updateInfo: any, slide: any) {
    if (this.commonService.ProjectLSData['userActiveRole'] == 'PATHOLOGIST') {

      let getQcAlgoList: any = updateInfo.algorithmList?.filter((item: any) => {
        return item?.algorithmId == this.appConfig?.webAppConfig?.QCAlgorithmId;
      });
      if (getQcAlgoList?.length) {
        if (getQcAlgoList[0]?.analysisStatus == "IN_PROGRESS"
          || getQcAlgoList[0]?.analysisStatus == "QUEUED"
          || getQcAlgoList[0]?.analysisStatus == "COMPLETED"
          || getQcAlgoList[0]?.analysisStatus == "FAILED") {
          if (getQcAlgoList[0]?.analysisStatus == "COMPLETED" || getQcAlgoList[0]?.analysisStatus == "FAILED") {
            slide['qcStatus'] = getQcAlgoList[0]?.analysisStatus;
          }
        }
      }

      let getAbnormalityAlgoList: any = updateInfo.algorithmList?.filter((item: any) => {
        return item?.algorithmId != this.appConfig?.webAppConfig?.QCAlgorithmId;
      });


      if (getAbnormalityAlgoList?.length) {
        let abnormalityAllStatus: any = [];
        for (let a = 0; a < getAbnormalityAlgoList?.length; a++) {
          if (abnormalityAllStatus?.indexOf(getAbnormalityAlgoList[a]?.analysisStatus) == -1) {
            abnormalityAllStatus.push(getAbnormalityAlgoList[a]?.analysisStatus);
          }
        }
        if (abnormalityAllStatus?.includes('COMPLETED') && !abnormalityAllStatus?.includes('FAILED')) {
          slide['abnormalityStatus'] = 'COMPLETED';
        } else if (!abnormalityAllStatus?.includes('COMPLETED') && abnormalityAllStatus?.includes('FAILED')) {
          slide['abnormalityStatus'] = 'FAILED';
        } else if (abnormalityAllStatus?.includes('COMPLETED') && abnormalityAllStatus?.includes('FAILED')) {
          slide['abnormalityStatus'] = 'BOTH';
        }
      }

      let allImageStatus: any = [];
      slide['analysisProgress'] = '';
      for (let a = 0; a < updateInfo.algorithmList?.length; a++) {
        if (allImageStatus?.indexOf(updateInfo.algorithmList[a]?.analysisStatus) == -1) {
          if (updateInfo.algorithmList[a]?.analysisStatus == 'IN_PROGRESS') {
            slide['analysisProgress'] = updateInfo.algorithmList[a]['extraInfo'];
          }
          allImageStatus.push(updateInfo.algorithmList[a]?.analysisStatus);
        }
      }
      slide['analysisStatus'] = '';
      if (allImageStatus?.includes('QUEUED')) {
        slide['analysisStatus'] = 'QUEUED';
      }
      if (allImageStatus?.includes('IN_PROGRESS')) {
        slide['analysisStatus'] = 'IN_PROGRESS';
      }

      slide['algorithmDetails'] = [...updateInfo.algorithmList];

    } else if (this.commonService.ProjectLSData['userActiveRole'] == 'TECHNICIAN') {
      let getQcAlgoList: any = updateInfo.algorithmList?.filter((item: any) => {
        return item?.algorithmId == this.appConfig?.webAppConfig?.QCAlgorithmId;
      });
      if (getQcAlgoList?.length) {
        if (getQcAlgoList[0]?.analysisStatus == "IN_PROGRESS"
          || getQcAlgoList[0]?.analysisStatus == "QUEUED"
          || getQcAlgoList[0]?.analysisStatus == "COMPLETED"
          || getQcAlgoList[0]?.analysisStatus == "FAILED") {
          slide['analysisStatus'] = getQcAlgoList[0]?.analysisStatus;
          if (getQcAlgoList[0]?.analysisStatus == "COMPLETED" || getQcAlgoList[0]?.analysisStatus == "FAILED") {
            slide['qcStatus'] = getQcAlgoList[0]?.analysisStatus;
          }
          if (getQcAlgoList[0]?.analysisStatus == "IN_PROGRESS") {
            slide['analysisProgress'] = getQcAlgoList[0]['extraInfo'];
          }
        }
      }
    }
  }

  setSlideIcons(image: any) {
    if (image?.algorithmDetails?.length) {
      if (this.commonService.ProjectLSData['userActiveRole'] == 'PATHOLOGIST') {
        let getQcAlgoList: any = image.algorithmDetails?.filter((item: any) => {
          return item?.algorithmId == this.appConfig?.webAppConfig?.QCAlgorithmId;
        });
        if (getQcAlgoList?.length) {
          if (getQcAlgoList[0]?.analysisStatus == "IN_PROGRESS"
            || getQcAlgoList[0]?.analysisStatus == "QUEUED"
            || getQcAlgoList[0]?.analysisStatus == "COMPLETED"
            || getQcAlgoList[0]?.analysisStatus == "FAILED") {
            if (getQcAlgoList[0]?.analysisStatus == "COMPLETED" || getQcAlgoList[0]?.analysisStatus == "FAILED") {
              image['qcStatus'] = getQcAlgoList[0]?.analysisStatus;
            }
          }
        }

        let getAbnormalityAlgoList: any = image.algorithmDetails?.filter((item: any) => {
          return item?.algorithmId != this.appConfig?.webAppConfig?.QCAlgorithmId;
        });

        if (getAbnormalityAlgoList?.length) {
          let abnormalityAllStatus: any = [];
          for (let a = 0; a < getAbnormalityAlgoList?.length; a++) {
            if (abnormalityAllStatus?.indexOf(getAbnormalityAlgoList[a]?.analysisStatus) == -1) {
              abnormalityAllStatus.push(getAbnormalityAlgoList[a]?.analysisStatus);
            }
          }
          if (abnormalityAllStatus?.includes('COMPLETED') && !abnormalityAllStatus?.includes('FAILED')) {
            image['abnormalityStatus'] = 'COMPLETED';
          } else if (!abnormalityAllStatus?.includes('COMPLETED') && abnormalityAllStatus?.includes('FAILED')) {
            image['abnormalityStatus'] = 'FAILED';
          } else if (abnormalityAllStatus?.includes('COMPLETED') && abnormalityAllStatus?.includes('FAILED')) {
            image['abnormalityStatus'] = 'BOTH';
          }
        }

        let allImageStatus: any = [];
        image['analysisProgress'] = '';
        for (let a = 0; a < image.algorithmDetails?.length; a++) {
          if (allImageStatus?.indexOf(image.algorithmDetails[a]?.analysisStatus) == -1) {
            if (image.algorithmDetails[a]?.analysisStatus == 'IN_PROGRESS') {
              image['analysisProgress'] = image.algorithmDetails[a]['extraInfo'];
            }
            allImageStatus.push(image.algorithmDetails[a]?.analysisStatus);
          }
        }
        image['analysisStatus'] = '';
        if (allImageStatus?.includes('QUEUED')) {
          image['analysisStatus'] = 'QUEUED';
        }
        if (allImageStatus?.includes('IN_PROGRESS')) {
          image['analysisStatus'] = 'IN_PROGRESS';
        }

      } else if (this.commonService.ProjectLSData['userActiveRole'] == 'TECHNICIAN') {
        let getQcAlgoList: any = image.algorithmDetails?.filter((item: any) => {
          return item?.algorithmId == this.appConfig?.webAppConfig?.QCAlgorithmId;
        });
        if (getQcAlgoList?.length) {
          if (getQcAlgoList[0]?.analysisStatus == "IN_PROGRESS"
            || getQcAlgoList[0]?.analysisStatus == "QUEUED"
            || getQcAlgoList[0]?.analysisStatus == "COMPLETED"
            || getQcAlgoList[0]?.analysisStatus == "FAILED") {
            image['analysisStatus'] = getQcAlgoList[0]?.analysisStatus;
            if (getQcAlgoList[0]?.analysisStatus == "COMPLETED" || getQcAlgoList[0]?.analysisStatus == "FAILED") {
              image['qcStatus'] = getQcAlgoList[0]?.analysisStatus;
              if (getQcAlgoList[0]?.analysisStatus == "IN_PROGRESS") {
                image['analysisProgress'] = getQcAlgoList[0]['extraInfo'];
              }
            }
          }
        }
      }
    }

  }


  selectOption(value, initiator: any) {
    this.isSelectAllActive = false;
    this.selectedImagesWithReview = [];
    this.selectedFolderWithReview = [];
    this.isSelectAllActiveForFolder = false;
    // this.selectedOpt = value;
    if (initiator === 'navigation') {
      this.isFilterApplied = false;
      delete this.commonService.ProjectLSData['isStudyGalleryFilterApplied'];
      this.clearSubFilterData();
      this.clearMetadataValidate();
      let orderBy = this.galleryService.getGroupByString(this.groupBy);
      if (orderBy === 'dosage') {
        this.commonService.ProjectLSData.galleryFilters['dosageCode'] = [];
        this.commonService.setProjectLSData();
      } else if (orderBy === 'tissue') {
        this.commonService.ProjectLSData.galleryFilters['organ'] = [];
        this.commonService.setProjectLSData();
      } else if (orderBy === 'subjectId') {
        this.commonService.ProjectLSData.galleryFilters['animalNo'] = [];
        this.commonService.setProjectLSData();
      } else if (orderBy === 'sex') {
        this.commonService.ProjectLSData.galleryFilters['sex'] = [];
        this.commonService.setProjectLSData();
      } else if (orderBy === 'cassette') {
        this.commonService.ProjectLSData.galleryFilters['cassetteCode'] = [];
        this.commonService.setProjectLSData();
      }
    }
    this.forkArr = [];
    delete this.commonService.ProjectLSData['studySelectionLevel'];
    delete this.commonService.ProjectLSData['folderOrganList'];
    delete this.commonService.ProjectLSData['folderAnimalList'];
    delete this.commonService.ProjectLSData['folderDosageList'];
    this.commonService.setProjectLSData();
    if (this.SlideStatusTimeOutFn) {
      clearTimeout(this.SlideStatusTimeOutFn);
    }
    if (value == "gallery") {
      this.galleryFilter = this.commonService.ProjectLSData.galleryFilters;
      this.galleryService.getMyStudiesP().pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        if (response.success) {
          this.studyProjectList = response.data;
          if (response.data?.length) {
            if (this.galleryFilter.studyName) {
              this.selectedStudyProj = this.galleryFilter.studyName;
            } else {
              this.selectedStudyProj = response.data[0].guid;
              this.galleryFilter.studyName = this.selectedStudyProj;
              this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
              this.commonService.setProjectLSData();
            }
            this.changeStudyProject();
            this.getFolderLevelFilterData();
            this.unSubscribeDataObservable();
            this.getSlidesData('234');
          } else {
            this.galleryService.showLoader = false;
          }
        }
      });
    } else {
      // Technician code start 
      this.galleryFilter = this.commonService.ProjectLSData.galleryFilters;
      this.galleryService.getMyStudiesT(this.activeMode).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        if (response.success) {
          if (response.data.length > 0) {
            this.studyProjectList = response.data;
            if (this.galleryFilter.studyName) {
              this.selectedStudyProj = this.galleryFilter.studyName;
            } else {
              this.selectedStudyProj = response.data[0].guid;
              this.galleryFilter.studyName = this.selectedStudyProj;
              this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
              this.commonService.setProjectLSData();
            }
            this.changeStudyProject();
            this.galleryService.setStudyListST(this.studyProjectList);
            this.getFolderLevelFilterData();
          } else {
            this.galleryService.showLoader = false;
          }
        } else {
          // console.log("error Handling");
        }
      });

    }
  }

  clearSubFilterData() {
    this.closePreviewPanel();
    this.stainsF = [];
    this.organsF = [];
    this.animalsF = [];
    this.dosageF = [];
    this.galleryFilter.animalNo = this.animalsF;
    this.galleryFilter.organ = this.organsF;
    this.galleryFilter.control = this.controlF;
    this.galleryFilter.dosageCode = this.dosageF;
    this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
    this.isSelectAllActive = false;
    this.selectedImagesWithReview = [];
    this.selectedFolderWithReview = [];
    this.isSelectAllActiveForFolder = false;
    this.galleryService.selectedSlidesArr = [];
    this.isFilterApplied = false;
    delete this.commonService.ProjectLSData['isStudyGalleryFilterApplied'];
    this.commonService.setProjectLSData();
  }

  openStudyProjectDetailDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "90%";
    dialogConfig.data = { 'showHeader': true, 'studyGuid': this.selectedStudyProj, showCancelIcon: false }
    this.dialog.open(StudyDetailsComponent, dialogConfig);

  }


  openBulkUnMapDialog() {
    let selectedSlideIdsArr: any = [];
    if (this.studySelectionLevel?.length) {
      selectedSlideIdsArr = this.galleryService.selectedSlidesArr;
    } else {
      for (let a = 0; a < this.studyListingData?.length; a++) {
        selectedSlideIdsArr = [...selectedSlideIdsArr, ...this.studyListingData[a].imagesArr];
      }
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "30%";
    dialogConfig.data = {
      dialogParam: "unMapImage",
      count: selectedSlideIdsArr.length
    }
    const dialogref = this.dialog.open(GalleryDialogComponent, dialogConfig);
    dialogref.componentInstance.onClose.subscribe((result: any) => {
      this.unmappingStatus = true;
      let unMapDataArr = [];
      selectedSlideIdsArr.forEach(slideData => {
        let unMapDataOj = {
          "studyFk": null,
          "cstCodeFk": null,
          "slideFk": slideData.slidePk,
          "animalFk": null,
          "cstStainFk": null
        }
        unMapDataArr.push(unMapDataOj);
      });
      let unMapObj = {
        "studySlideMappingModel": unMapDataArr,
        "slideComment": result
      }
      this.galleryService.bulkUnmapImages(unMapObj).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.unSubscribeDataObservable();
        if (this.studySelectionLevel?.length) {
          this.getPageWiseData(this.studySelectionLevel[0], 'init');
        } else {
          this.getSlidesData('');
        }
        this.commonService.showToastMsg(res.message);
        // this.galleryService.setSelecteSlidesList([]);
        this.unmappingStatus = false;
        this.galleryService.selectedSlidesArr = [];
        this.selectedFolder = [];
        if (this.studyListingData?.length) {
          this.studyListingData.forEach((study: any) => {
            study.imagesArr = [];
            study.selection = false;
          });
        }
        for (let a = 0; a < this.slidesArr?.length; a++) {
          this.slidesArr[a].selection = false;
        }
        this.isSelectAllActive = false;
        this.selectedImagesWithReview = [];
        this.selectedFolderWithReview = [];
        this.isSelectAllActiveForFolder = false;
      }, (error: any) => {
        this.unmappingStatus = false;
        this.commonService.showToastMsg(error.message, 'error');
        this.galleryService.selectedSlidesArr = [];
        this.selectedFolder = [];
        if (this.studyListingData?.length) {
          this.studyListingData.forEach((study: any) => {
            study.imagesArr = [];
            study.selection = false;
          });
        }
        this.isSelectAllActive = false;
        this.selectedImagesWithReview = [];
        this.selectedFolderWithReview = [];
        this.isSelectAllActiveForFolder = false;
        for (let a = 0; a < this.slidesArr?.length; a++) {
          this.slidesArr[a].selection = false;
        }
      });
    });
  }

  generateNewThumbnails() {
    this.thumbnailGenStatus = true;
    let selectedSlideIdsArr: any = [];
    if (this.studySelectionLevel?.length) {
      selectedSlideIdsArr = this.galleryService.selectedSlidesArr.map(e => { return e.slidePk });
    } else {
      let images: any = [];
      for (let a = 0; a < this.studyListingData?.length; a++) {
        images = [...images, ...this.studyListingData[a].imagesArr];
      }
      selectedSlideIdsArr = images.map(e => { return e.slidePk });
    }
    // let selectedSlideIdsArr = this.galleryService.selectedSlidesArr.map(e => { return e.slidePk });
    this.galleryService.getSlidesPhysicalLocation(selectedSlideIdsArr).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      let slidesObj = {};
      res.forEach(element => {
        slidesObj[element.identifier] = element.path;
      });
      this.galleryService.generateThumbnail(slidesObj).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.commonService.showToastMsg(res.message);
        this.unSubscribeDataObservable();
        if (this.studySelectionLevel?.length) {
          this.getPageWiseData(this.studySelectionLevel[0], 'init');
        } else {
          this.getSlidesData('');
        }
        //this.galleryService.setSelectedSlidesListForThumbnail({});
        this.thumbnailGenStatus = false;
        this.galleryService.selectedSlidesArr = [];
        this.selectedFolder = [];
        if (this.studyListingData?.length) {
          this.studyListingData.forEach((study: any) => {
            study.imagesArr = [];
            study.selection = false;
          });
        }
        this.isSelectAllActive = false;
        this.selectedImagesWithReview = [];
        this.selectedFolderWithReview = [];
        this.isSelectAllActiveForFolder = false;
        for (let a = 0; a < this.slidesArr?.length; a++) {
          this.slidesArr[a].selection = false;
        }
      }, (err: any) => {
        this.commonService.showToastMsg(err?.error?.message ? err.error.message : "Error Occurs", 'error');
        this.thumbnailGenStatus = false;
        this.galleryService.selectedSlidesArr = [];
        this.selectedFolder = [];
        if (this.studyListingData?.length) {
          this.studyListingData.forEach((study: any) => {
            study.imagesArr = [];
            study.selection = false;
          });
        }
        this.isSelectAllActive = false;
        this.selectedImagesWithReview = [];
        this.selectedFolderWithReview = [];
        this.isSelectAllActiveForFolder = false;
        for (let a = 0; a < this.slidesArr?.length; a++) {
          this.slidesArr[a].selection = false;
        }
      });
    });
  }

  addImages() {
    // this.consultationservice.setSelectedImageValueOnDialogOpen(this.galleryService.getSelectedAddImage());
    this.dialog.closeAll();
  }


  applyFilter(event: any) {
    event.stopPropagation();
    this.closePreviewPanel();
    if (this.commonService.ProjectLSData['studySelectionLevel']?.length) {
      this.isSelectAllActive = false;
      this.selectedImagesWithReview = [];
      this.selectedFolderWithReview = [];
      this.isSelectAllActiveForFolder = false;
      this.galleryService.selectedSlidesArr = [];
      this.galleryFilter.stains = this.stainsF;
      this.galleryFilter.organ = this.organsF;
      this.galleryFilter.animalNo = this.animalsF;
      this.galleryFilter.dosageCode = this.dosageF;
      this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
      if (this.stainsF?.length || this.organsF?.length || this.animalsF?.length || this.dosageF?.length) {
        this.isFilterApplied = true;
        this.commonService.ProjectLSData['isStudyGalleryFilterApplied'] = true;
      }
      this.commonService.setProjectLSData();
      this.getPageWiseData(this.commonService.ProjectLSData['studySelectionLevel'][0], 'filterApply');
    } else {
      this.isFilterApplied = true;
      this.commonService.ProjectLSData['isStudyGalleryFilterApplied'] = true;
      this.commonService.setProjectLSData();
      this.getAccordionData(true);
    }
  }

  resetFilter(event: any) {
    event.stopPropagation();
    this.closePreviewPanel();
    this.stainsF = [];
    this.organsF = [];
    this.animalsF = [];
    this.dosageF = [];
    this.galleryFilter.animalNo = this.animalsF ?? [];
    this.galleryFilter.organ = this.organsF ?? [];
    this.galleryFilter.control = this.controlF ?? [];
    this.galleryFilter.dosageCode = this.dosageF ?? [];
    this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
    this.isSelectAllActive = false;
    this.selectedImagesWithReview = [];
    this.selectedFolderWithReview = [];
    this.isSelectAllActiveForFolder = false;
    this.galleryService.selectedSlidesArr = [];
    this.isFilterApplied = false;
    delete this.commonService.ProjectLSData['isStudyGalleryFilterApplied'];
    this.commonService.setProjectLSData();
    if (this.commonService.ProjectLSData['studySelectionLevel']?.length) {
      this.getPageWiseData(this.commonService.ProjectLSData['studySelectionLevel'][0], 'init', this.galleryFilter.viewByG);
    } else {
      this.getAccordionData();
    }
  }

  closeDialog() {
    this.onClose.emit();
    this.dialogRef.close();
  }

  refreshListData() {
    this.galleryService.getFilterWiseListing();
  }


  backToStudyListing() {
    this.isSelectAllActive = false;
    this.selectedImagesWithReview = [];
    this.selectedFolderWithReview = [];
    this.isSelectAllActiveForFolder = false;
    this.hiddenOptions = [];
    delete this.commonService.ProjectLSData['studySelectionLevel'];
    delete this.commonService.ProjectLSData['selectedStudyName'];
    delete this.commonService.ProjectLSData['selectedStudyId'];
    delete this.commonService.ProjectLSData['folderOrganList'];
    delete this.commonService.ProjectLSData['folderAnimalList'];
    delete this.commonService.ProjectLSData['folderDosageList'];
    delete this.commonService.ProjectLSData['studyManualFields'];
    delete this.commonService.ProjectLSData['activeUserPermission'];
    delete this.commonService.ProjectLSData['isStudyGalleryFilterApplied'];
    this.commonService.setProjectLSData();
    this.isFilterApplied = false;
    this.clearMetadataValidate();
    if (this.commonService.ProjectLSData['userActiveRole'] == 'PATHOLOGIST') {
      this.router.navigate(['dashboard/study/PATHOLOGIST']);
    } else {
      this.router.navigate(['dashboard/study/TECHNICIAN']);
    }
  }

  getStudySharingData(studyId: any) {
    this.galleryService.getStudyShareDetails(studyId).subscribe((response: any) => {
      console.log(response);
      if (response?.success) {
        if (response?.data?.users?.length) {
          this.commonService.ProjectLSData['activeUserPermission'] = response?.data?.users?.filter((userItem: any) => {
            return userItem?.user == this.commonService.ProjectLSData?.currentUser?.userName;
          });
          this.commonService.setProjectLSData();
        }
      }
    })
  }

  changeStudyProject() {
    this.fatchStudySeverityGrading();
    let isSameStudy = true;
    if (this.galleryFilter.studyName != this.selectedStudyProj) {
      this.galleryFilter.studyName = this.selectedStudyProj;
      isSameStudy = false;
    }
    let selectedStudy = this.studyProjectList.filter(elem => {
      return elem.guid == this.selectedStudyProj
    });
    this.selectedStudyName = selectedStudy[0].studyNo;
    this.commonService.ProjectLSData['selectedStudyName'] = selectedStudy[0].studyNo;
    this.commonService.ProjectLSData['selectedStudyId'] = selectedStudy[0]?.id ?? selectedStudy[0]?.studyId;
    this.getStudySharingData(this.commonService.ProjectLSData['selectedStudyId']);
    this.commonService.setProjectLSData();
    this.processAllRandomisedResp();
    if (this.galleryService.randomisedStudies?.length) {
      for (let a = 0; a < this.galleryService.randomisedStudies?.length; a++) {
        if (this.selectedStudyProj == this.galleryService.randomisedStudies[a].study) {
          this.galleryService.isStudyRandomised = true;
          this.galleryService.randomisedSelection = this.galleryService.randomisedStudies[a]?.slides;
          this.galleryService.showLoader = true;
          this.galleryService.getSlideDetailsArr(this.galleryService.randomisedSelection).pipe(finalize(() => this.galleryService.showLoader = false)).subscribe((successData: any) => {
            this.galleryService.selectedSlidesArr = [];
            for (let x = 0; x < successData.data.length; x++) {
              successData.data[x].id = successData?.data[x].slide.slidePk;
              successData.data[x].slidePk = successData?.data[x].slide.slidePk;
              successData.data[x].identifier = successData?.data[x].slide.identifier;
              successData.data[x].slidename = successData?.data[x]?.slide.slidename;
              successData.data[x].dosageLevel = successData?.data[x]?.animal.dosageLevel;
              successData.data[x].animalNo = successData?.data[x]?.animal.animalNo;
              successData.data[x].sex = successData?.data[x]?.animal.sex;
              successData.data[x].organ = successData?.data[x]?.cassetteOrgan.organ;
              successData.data[x].cassetteCode = successData?.data[x]?.cassetteOrgan.cassetteCode;
              this.galleryService.selectedSlidesArr.push(successData.data[x]);
            }
            if (this.galleryService.isStudyRandomised) {
              this.startRandomization(true);
            }
          });
          break;
        } else {
          this.galleryService.isStudyRandomised = false;
          this.galleryService.randomisedSelection = [];
          this.galleryService.selectedSlidesArr = [];
        }
      }
    } else {
      this.galleryService.isStudyRandomised = false;
      this.galleryService.randomisedSelection = [];
      this.galleryService.selectedSlidesArr = [];
    }
    if (!this.galleryService.isStudyRandomised) {
      this.galleryFilter.isTCRStudy = selectedStudy[0].isTCRStudy;
      this.galleryFilter.stains = isSameStudy ? this.galleryFilter.stains : [];
      this.galleryFilter.organ = isSameStudy ? this.galleryFilter.organ : [];
      this.galleryFilter.animalNo = isSameStudy ? this.galleryFilter.animalNo : [];
      this.galleryFilter.control = isSameStudy ? this.galleryFilter.control : [];
      this.galleryFilter.dosageCode = isSameStudy ? this.galleryFilter.dosageCode : [];
      this.galleryFilter.filterBySeverityGrading = isSameStudy ? this.galleryFilter.filterBySeverityGrading : 'Show All';
      this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
      this.commonService.ProjectLSData.isTCRStudy = selectedStudy[0].isTCRStudy;
      delete this.commonService.ProjectLSData?.currentSelectedImg;
      this.commonService.setProjectLSData();
      this.imagePreview.state = false;
      this.activeResult = '';
      this.qcResults = [];
      this.abnormalityResults = [];
      this.abnormalityAlgoResultsList = [];
      this.allResults = [];
      this.selectedROIResult = '';
      this.selectedAbnormalityAlgo = {}
      this.algoROIArr = [];
      this.noBarCodeText = false;
      this.noBarFoundText = false;
      this.noLabelText = false;
      this.selectedSlideForPreview = {};
      this.imagePreview.currItemSelected = null;
      this.imagePreview.currItemSelectedIndex = null;
      // this.getFilterData();
      if (this.activeMode == 'mapped') {
        this.checkUnmapStatus();
      }
      this.unSubscribeDataObservable();
      if (this.activeMode == 'mapped') {
        this.getSlidesData('517');
      }
      if (!this.galleryService.isCalledFromViewer) {
        this.galleryService.activeAccordian = 0;
        // this.galleryFilter.selectedAccordion = null;
        this.galleryService.isCalledFromViewer = false;
      }
    }
  }

  getSlidesData(call: any) {
    // if there is no study present in the dropdown, disable the click action
    if (!this.studyProjectList.length) {
      return;
    }
    if (this.galleryService.isStudyRandomised) {
      return
    }
    this.galleryService.setSlidesList({});
    this.galleryService.showLoader = true;
    this.groupBy = (!this.galleryFilter.isTCRStudy && this.galleryFilter.viewByG == '6') ? '3' : this.galleryFilter.viewByG;
    this.filterBySeverityGrading = this.galleryFilter.filterBySeverityGrading ?? 'Show All';
    this.readStatus = this.galleryFilter.readStatus;
    this.reviewStatus = this.galleryFilter.reviewStatus;
    this.readReviewFlag = this.galleryFilter.readReviewFlag;
    this.stainsF = this.galleryFilter.stains ?? [];
    this.animalsF = this.galleryFilter.animalNo ?? [];
    this.organsF = this.galleryFilter.organ ?? [];
    this.controlF = this.galleryFilter.control ?? [];
    this.dosageF = this.galleryFilter.dosageCode ?? [];
    this.getAccordionData();

  }


  toggleFilter() {
    this.galleryFilterisVisible = !this.galleryFilterisVisible;
  }


  generateAccordionObj() {
    let slidesData = {};
    $.each(this.rawSlidesData, (key, value) => {
      slidesData[value.attribute] = {};
    });
  }

  togglePreviewPanel(imgCardItem: any, i: number, resultSource: any = '') {
    let divPlace = document.getElementsByClassName('currentSlidePreviewDiv');
    this.disablePreviewNavIcon.prevNavIcon = (i <= 0);
    this.disablePreviewNavIcon.nextNavIcon = (i >= (this.slidesArr?.length - 1));
    if (resultSource && this.imagePreview.currItemSelected?.slidePk == imgCardItem?.slidePk) {
      this.switchPreviewResult(resultSource);
      return;
    }
    if (this.imagePreview.state && this.imagePreview.currItemSelected?.slidePk == imgCardItem?.slidePk) {
      delete this.commonService.ProjectLSData?.currentSelectedImg;
      this.commonService.setProjectLSData();
      this.imagePreview.state = false;
      this.imagePreview.currItemSelected = null;
      this.imagePreview.currItemSelectedIndex = null;
      this.activeResult = '';
      this.qcResults = [];
      this.abnormalityResults = [];
      this.abnormalityAlgoResultsList = [];
      this.allResults = [];
      this.selectedROIResult = '';
      this.selectedAbnormalityAlgo = {}
      this.algoROIArr = [];
      this.noBarCodeText = false;
      this.noBarFoundText = false;
      this.noLabelText = false;
      this.selectedSlideForPreview = {};
    } else {
      this.imagePreview.state = true;
      if (resultSource) {
        this.activeResult = resultSource;
      }
      if (resultSource && (imgCardItem?.qcStatus == 'COMPLETED' || imgCardItem?.qcStatus == 'FAILED' || imgCardItem?.abnormalityStatus == 'COMPLETED' || imgCardItem?.abnormalityStatus == 'FAILED' || imgCardItem?.abnormalityStatus == 'BOTH')) {
        this.slideDetTabSelectedIndex = 0;
      }
      else if (!resultSource && (imgCardItem?.qcStatus == 'COMPLETED' || imgCardItem?.qcStatus == 'FAILED' || imgCardItem?.abnormalityStatus == 'COMPLETED' || imgCardItem?.abnormalityStatus == 'FAILED' || imgCardItem?.abnormalityStatus == 'BOTH') && this.slideDetTabSelectedIndex != 1) {
        this.activeResult = (imgCardItem?.qcStatus == 'COMPLETED' || imgCardItem?.qcStatus == 'FAILED') ? 'QC' : 'Abnormality';
        this.slideDetTabSelectedIndex = 0;
      }
      else {
        this.slideDetTabSelectedIndex = 1;
      }
      this.imagePreview.previewToggleBtns = [
        {
          iconType: 'macro',
          iconName: 'macro',
          title: 'Macro',
          isImgExist: !this.appConfig.galleryConfig.checkPreviewIconImgExist
        },
        {
          iconType: 'label',
          iconName: 'label',
          title: 'Label',
          isImgExist: !this.appConfig.galleryConfig.checkPreviewIconImgExist
        },
        {
          iconType: 'thumbnail',
          iconName: 'thumbnail',
          title: 'Thumbnail',
          isImgExist: !this.appConfig.galleryConfig.checkPreviewIconImgExist
        }
      ];

      if (this.appConfig.galleryConfig.checkPreviewIconImgExist) {
        this.commonService.checkIfImageExists(imgCardItem.macroPath, (isExist: any) => {
          if (isExist) {
            this.imagePreview.previewToggleBtns[0].isImgExist = true;
            if (this.activePreviewState.iconType == 'macro') {
              this.activePreviewState.iconType = 'macro';
              this.activePreviewState.iconName = 'macro';
              this.activePreviewState.title = 'Macro';
            }
          }
        });

        this.commonService.checkIfImageExists(imgCardItem.labelPath, (isExist: any) => {
          if (isExist) {
            this.imagePreview.previewToggleBtns[1].isImgExist = true;
            if (!this.imagePreview.previewToggleBtns[0].isImgExist && (this.activePreviewState.iconType == 'macro')) {
              this.activePreviewState.iconType = 'label';
              this.activePreviewState.iconName = 'label';
              this.activePreviewState.title = 'Label';
            }
          }
        });

        this.commonService.checkIfImageExists(imgCardItem.thumbnailPath, (isExist: any) => {
          if (isExist) {
            this.imagePreview.previewToggleBtns[2].isImgExist = true;
            if (!this.imagePreview.previewToggleBtns[0].isImgExist &&
              !this.imagePreview.previewToggleBtns[1].isImgExist &&
              ((this.activePreviewState.iconType == 'macro') ||
                (this.activePreviewState.iconType == 'label'))) {
              this.activePreviewState.iconType = 'thumbnail';
              this.activePreviewState.iconName = 'thumbnail';
              this.activePreviewState.title = 'Thumbnail';
            }
          }
        });
      }

      this.commonService.ProjectLSData['currentSelectedImg'] = imgCardItem;
      this.commonService.setProjectLSData();
      this.imagePreview.currItemSelected = imgCardItem;
      this.imagePreview.currItemSelected.selectedIcon = 'macro';
      this.imagePreview.currItemSelectedIndex = i;
      this.galleryService.getSelectedSlideDataForPreview(imgCardItem?.slidePk).subscribe((response: any) => {
        if (response?.success) {
          this.noBarCodeText = false;
          this.noBarFoundText = false;
          this.noLabelText = false;
          this.selectedSlideForPreview = {};
          this.selectedSlideForPreview = response?.data;
          imgCardItem['physicalLoc'] = response?.data?.physicalLoc;
          this.activePreviewState.isSchemaFetching = true;
          this.galleryService.getSchemaJSON(imgCardItem['physicalLoc']).subscribe((response: any) => {
            if (response?.slideImage?.imageProfiles?.imageProfile?.length > 1) {
              /* this.imagePreview.previewToggleBtns.push({
                iconType: 'roi',
                iconName: 'ROI',
                title: 'ROI',
                totalROIs: (response.slideImage.imageProfiles.imageProfile.length - 1),
                selectedROI: 1,
                roiPath: this.appConfig?.apiConfig?.thumbnailUrl + imgCardItem.identifier + '_roi_' + 1 + '.jpeg',
                isImgExist: true
              }); */

              this.imagePreview.previewToggleBtns[3] = {
                iconType: 'roi',
                iconName: 'ROI',
                title: 'ROI',
                totalROIs: (response.slideImage.imageProfiles.imageProfile.length - 1),
                selectedROI: 1,
                roiPath: this.appConfig?.apiConfig?.thumbnailUrl + imgCardItem.identifier + '_roi_' + 1 + '.jpeg',
                isImgExist: true
              };

              /* for (let m = 1; m < response?.slideImage?.imageProfiles?.imageProfile.length; m++) {
                this.imagePreview.previewToggleBtns.push({
                  iconType: 'roi',
                  iconName: 'ROI ' + m,
                  title: 'ROI ' + m + " Image",
                  roiPath: this.appConfig?.apiConfig?.thumbnailUrl + imgCardItem.identifier + '_roi_' + m + '.jpeg'
                  });
                  } */
            }
            this.activePreviewState.isSchemaFetching = false;
            this.lastPreviewSelection();
          }, () => {
            this.activePreviewState.isSchemaFetching = false;
            this.lastPreviewSelection();
          });
          if (this.selectedSlideForPreview?.barcodeInfo) {
            this.selectedSlideForPreview.barcodeInfo = JSON.parse(this.selectedSlideForPreview.barcodeInfo);
          };
          if (this.selectedSlideForPreview?.barcodeStatus == 'BARCODE_NOT_DETECTED') {
            this.noBarCodeText = true;
          };
          if (this.selectedSlideForPreview?.barcodeStatus == 'BARCODE_NOT_FOUND') {
            this.noBarFoundText = true;
          };
          if (this.selectedSlideForPreview?.barcodeStatus == 'LABEL_NOT_FOUND') {
            this.noLabelText = true;
          };
          if (this.activeMode == 'gallery') {
            this.previewOrgan = imgCardItem?.organ?.[0];
            this.getSlideReview();
          }
        }
      });
      if (this.algorithmList?.length) {
        this.galleryService.getImageQuantificationResult({ "mappedId": imgCardItem?.id }).subscribe((response: any) => {
          if (response?.success) {
            this.abnormalityAlgoResultsList = [];
            this.algoROIArr = [];
            this.allResults = response?.data;

            if (resultSource) {
              this.activeResult = resultSource;
            } else {
              this.allResults = response?.data;
            }

            this.qcResults = response?.data.filter((item: any) => {
              return item?.algorithmId == this.appConfig?.webAppConfig?.QCAlgorithmId;
            });

            this.abnormalityResults = response?.data.filter((item: any) => {
              return item?.algorithmId != this.appConfig?.webAppConfig?.QCAlgorithmId;
            });
            if (this.qcResults?.length > 1) {
              this.qcResults.sort((a: any, b: any) => a.roi - b.roi);
              if (this.activeResult == 'QC') {
                this.selectedROIResult = this.qcResults[0]?.roi;
              }
            } else {
              if (this.activeResult == 'QC') {
                this.selectedROIResult = this.qcResults[0]?.roi;
              }
            }
            if (this.abnormalityResults?.length) {
              if (this.abnormalityResults?.length > 1) {
                this.abnormalityResults.sort((a: any, b: any) => a.roi - b.roi);
                this.abnormalityAlgoResultsList = this.abnormalityResults;
                if (this.abnormalityAlgoResultsList?.length == 1) {
                  this.selectedAbnormalityAlgo = this.abnormalityAlgoResultsList[0];
                  if (this.activeResult == 'Abnormality') {
                    this.selectedROIResult = this.selectedAbnormalityAlgo?.roi;
                  }
                } else if (this.abnormalityAlgoResultsList?.length && this.abnormalityAlgoResultsList?.length > 1) {
                  if (this.activeResult == 'Abnormality') {
                    const ids: any[] = this.abnormalityResults.map(({ algorithmId }) => algorithmId);
                    this.abnormalityAlgoResultsList = this.abnormalityResults.filter(({ algorithmId }, index) => !ids.includes(algorithmId, index + 1));
                    this.selectedAbnormalityAlgo = this.abnormalityAlgoResultsList[0];
                    this.algoROIArr = this.abnormalityResults.filter((item: any) => {
                      return item?.algorithmId == this.selectedAbnormalityAlgo?.algorithmId && item?.organReviewId;
                    })
                    this.selectedROIResult = this.algoROIArr?.length ? this.algoROIArr[0]?.roi : this.selectedAbnormalityAlgo?.roi;
                  }
                }

              } else {
                if (this.activeResult == 'Abnormality') {
                  this.abnormalityAlgoResultsList = [...this.abnormalityResults];
                  this.selectedAbnormalityAlgo = this.abnormalityAlgoResultsList[0];
                  this.algoROIArr = this.abnormalityResults.filter((item: any) => {
                    return item?.algorithmId == this.selectedAbnormalityAlgo?.algorithmId && item?.organReviewId;
                  });
                  this.selectedROIResult = this.algoROIArr?.length ? this.algoROIArr[0]?.roi : this.selectedAbnormalityAlgo?.roi;
                }
              }
            }
            if ((this.activeResult == 'Abnormality') && (imgCardItem?.abnormalityStatus == 'FAILED' || imgCardItem?.abnormalityStatus == 'BOTH')) {
              let failedAlgos: any = [];
              failedAlgos = imgCardItem?.algorithmDetails?.filter((item: any) => {
                return item?.algorithmId != this.appConfig?.webAppConfig?.QCAlgorithmId && item?.analysisStatus == 'FAILED'
              });
              failedAlgos?.forEach((item: any) => {
                item['algorithmName'] = this.commonAlgoMap[item?.algorithmId];
              })
              // this.abnormalityAlgoResultsList = [...this.abnormalityAlgoResultsList, ...failedAlgos];
              let arr: any[] = [];
              arr = [...this.abnormalityAlgoResultsList, ...failedAlgos];
              const seenArr = new Set();
              const uniqueArray = arr.filter(item => {
                const identifier = `${item.algorithmId}-${item.analysisStatus}`;
                if (seenArr.has(identifier)) {
                  return false;
                } else {
                  seenArr.add(identifier);
                  return true;
                }
              });
              this.abnormalityAlgoResultsList = uniqueArray;
              this.selectedAbnormalityAlgo = this.abnormalityAlgoResultsList[0];
              this.selectedROIResult = this.algoROIArr?.length ? this.algoROIArr[0]?.roi : this.selectedAbnormalityAlgo?.roi;
            }
          }
        });
      }

    }
    setTimeout(() => {
      divPlace[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 100);
  }

  swithcPreviewTabs(tab: any) {
    if (!this.allResults?.length) {
      this.galleryService.getImageQuantificationResult({ "mappedId": this.imagePreview?.currItemSelected?.id }).subscribe((response: any) => {
        if (response?.success) {
          this.abnormalityAlgoResultsList = [];
          this.algoROIArr = [];
          this.allResults = response?.data;
          if (this.imagePreview.currItemSelected?.qcStatus == 'COMPLETED' || this.imagePreview.currItemSelected?.qcStatus == 'FAILED') {
            this.activeResult = 'QC';
          } else {
            this.activeResult = 'Abnormality';
          }

          this.qcResults = response?.data.filter((item: any) => {
            return item?.algorithmId == this.appConfig?.webAppConfig?.QCAlgorithmId;
          });

          this.abnormalityResults = response?.data.filter((item: any) => {
            return item?.algorithmId != this.appConfig?.webAppConfig?.QCAlgorithmId;
          });

          if (this.qcResults?.length > 1) {
            this.qcResults.sort((a: any, b: any) => a.roi - b.roi);
            if (this.activeResult == 'QC') {
              this.selectedROIResult = this.qcResults[0]?.roi;
            }
          } else {
            if (this.activeResult == 'QC') {
              this.selectedROIResult = this.qcResults[0]?.roi;
            }
          }
          if (this.abnormalityResults?.length) {
            if (this.abnormalityResults?.length > 1) {
              this.abnormalityResults.sort((a: any, b: any) => a.roi - b.roi);
              this.abnormalityAlgoResultsList = this.abnormalityResults;
              if (this.abnormalityAlgoResultsList?.length == 1) {
                this.selectedAbnormalityAlgo = this.abnormalityAlgoResultsList[0];
                if (this.activeResult == 'Abnormality') {
                  this.selectedROIResult = this.selectedAbnormalityAlgo?.roi;
                }
              } else if (this.abnormalityAlgoResultsList?.length && this.abnormalityAlgoResultsList?.length > 1) {
                this.selectedAbnormalityAlgo = this.abnormalityAlgoResultsList[0];
                if (this.activeResult == 'Abnormality') {
                  this.algoROIArr = this.abnormalityResults.filter((item: any) => {
                    return item?.algorithmId == this.selectedAbnormalityAlgo?.algorithmId && item?.organReviewId;
                  })
                  this.selectedROIResult = this.algoROIArr?.length ? this.algoROIArr[0]?.roi : this.selectedAbnormalityAlgo?.roi;
                  const ids: any[] = this.abnormalityResults.map(({ algorithmId }) => algorithmId);
                  this.abnormalityAlgoResultsList = this.abnormalityResults.filter(({ algorithmId }, index) => !ids.includes(algorithmId, index + 1));
                }
              }

            } else {
              if (this.activeResult == 'Abnormality') {
                this.abnormalityAlgoResultsList = [...this.abnormalityResults];
                this.selectedAbnormalityAlgo = this.abnormalityAlgoResultsList[0];
                this.algoROIArr = this.abnormalityResults.filter((item: any) => {
                  return item?.algorithmId == this.selectedAbnormalityAlgo?.algorithmId && item?.organReviewId;
                });
                this.selectedROIResult = this.algoROIArr?.length ? this.algoROIArr[0]?.roi : this.selectedAbnormalityAlgo?.roi;
              }
            }
          }
          if ((this.activeResult == 'Abnormality') && (this.imagePreview.currItemSelected?.abnormalityStatus == 'FAILED' || this.imagePreview.currItemSelected?.abnormalityStatus == 'BOTH')) {
            let failedAlgos: any = [];
            failedAlgos = this.imagePreview.currItemSelected?.algorithmDetails?.filter((item: any) => {
              return item?.algorithmId != this.appConfig?.webAppConfig?.QCAlgorithmId && item?.analysisStatus == 'FAILED'
            });
            failedAlgos?.forEach((item: any) => {
              item['algorithmName'] = this.commonAlgoMap[item?.algorithmId];
            })
            // this.abnormalityAlgoResultsList = [...this.abnormalityAlgoResultsList, ...failedAlgos];
            let arr: any[] = [];
            arr = [...this.abnormalityAlgoResultsList, ...failedAlgos];
            const seenArr = new Set();
            const uniqueArray = arr.filter(item => {
              const identifier = `${item.algorithmId}-${item.analysisStatus}`;
              if (seenArr.has(identifier)) {
                return false;
              } else {
                seenArr.add(identifier);
                return true;
              }
            });
            this.abnormalityAlgoResultsList = uniqueArray;
            this.selectedAbnormalityAlgo = this.abnormalityAlgoResultsList[0];
            this.selectedROIResult = this.algoROIArr?.length ? this.algoROIArr[0]?.roi : this.selectedAbnormalityAlgo?.roi;
          }
        }
      });
    } else {
      this.activeResult = tab == 0 ? 'QC' : 'Abnormality';
      if (tab == 0 && this.qcResults?.length) {
        this.activeResult = 'QC';
        this.selectedROIResult = this.qcResults[0]?.roi;
      } else {
        if (this.abnormalityResults?.length
          || this.imagePreview?.currItemSelected?.abnormalityStatus == 'FAILED'
          || this.imagePreview?.currItemSelected?.abnormalityStatus == 'BOTH') {
          this.activeResult = 'Abnormality';
          if (this.selectedAbnormalityAlgo?.roi) {
            this.selectedROIResult = this.selectedAbnormalityAlgo?.roi;
          } else {
            let failedAlgos: any = [];
            failedAlgos = this.imagePreview?.currItemSelected?.algorithmDetails?.filter((item: any) => {
              return item?.algorithmId != this.appConfig?.webAppConfig?.QCAlgorithmId && item?.analysisStatus == 'FAILED'
            });
            failedAlgos?.forEach((item: any) => {
              item['algorithmName'] = this.commonAlgoMap[item?.algorithmId];
            })
            // this.abnormalityAlgoResultsList = [...this.abnormalityAlgoResultsList, ...failedAlgos];
            let arr: any[] = [];
            arr = [...this.abnormalityAlgoResultsList, ...failedAlgos];
            const seenArr = new Set();
            const uniqueArray = arr.filter(item => {
              const identifier = `${item.algorithmId}-${item.analysisStatus}`;
              if (seenArr.has(identifier)) {
                return false;
              } else {
                seenArr.add(identifier);
                return true;
              }
            });
            this.abnormalityAlgoResultsList = uniqueArray;
            this.selectedAbnormalityAlgo = this.abnormalityAlgoResultsList[0];
            this.algoROIArr = this.abnormalityResults.filter((item: any) => {
              return item?.algorithmId == this.selectedAbnormalityAlgo?.algorithmId && item?.organReviewId;
            });
            this.selectedROIResult = this.algoROIArr?.length ? this.algoROIArr[0]?.roi : this.selectedAbnormalityAlgo?.roi;
          }
        }
      }
    }
  }



  switchPreviewResult(source: any) {
    this.activeResult = source;
    if (this.activeResult == 'QC' && this.qcResults?.length) {
      this.selectedROIResult = this.qcResults[0]?.roi;
      this.selectedAbnormalityAlgo = {}
    } else {
      if (this.activeResult == 'Abnormality' && (this.abnormalityResults?.length
        || this.imagePreview?.currItemSelected?.abnormalityStatus == 'FAILED'
        || this.imagePreview?.currItemSelected?.abnormalityStatus == 'BOTH')) {
        const ids: any[] = this.abnormalityResults.map(({ algorithmId }) => algorithmId);
        this.abnormalityAlgoResultsList = this.abnormalityResults.filter(({ algorithmId }, index) => !ids.includes(algorithmId, index + 1));
        // this.abnormalityAlgoResultsList = this.abnormalityResults;
        if ((this.activeResult == 'Abnormality')
          && (this.imagePreview?.currItemSelected?.abnormalityStatus == 'FAILED'
            || this.imagePreview?.currItemSelected?.abnormalityStatus == 'BOTH'
            || this.imagePreview?.currItemSelected?.abnormalityStatus == 'COMPLETED')) {
          let failedAlgos: any = [];
          failedAlgos = this.imagePreview?.currItemSelected?.algorithmDetails?.filter((item: any) => {
            return item?.algorithmId != this.appConfig?.webAppConfig?.QCAlgorithmId && item?.analysisStatus == 'FAILED'
          });
          failedAlgos?.forEach((item: any) => {
            item['algorithmName'] = this.commonAlgoMap[item?.algorithmId];
          })
          // this.abnormalityAlgoResultsList = [...this.abnormalityAlgoResultsList, ...failedAlgos];
          let arr: any[] = [];
          arr = [...this.abnormalityAlgoResultsList, ...failedAlgos];
          const seenArr = new Set();
          const uniqueArray = arr.filter(item => {
            const identifier = `${item.algorithmId}-${item.analysisStatus}`;
            if (seenArr.has(identifier)) {
              return false;
            } else {
              seenArr.add(identifier);
              return true;
            }
          });
          this.abnormalityAlgoResultsList = uniqueArray;
          this.selectedAbnormalityAlgo = this.abnormalityAlgoResultsList[0];
          this.algoROIArr = this.abnormalityResults.filter((item: any) => {
            return item?.algorithmId == this.selectedAbnormalityAlgo?.algorithmId && item?.organReviewId;
          });
          this.selectedROIResult = this.algoROIArr?.length ? this.algoROIArr[0]?.roi : this.selectedAbnormalityAlgo?.roi;
        }
      }
    }
  }

  chnageAlgoResultDisplay() {
    this.algoROIArr = this.abnormalityResults.filter((item: any) => {
      return item?.algorithmId == this.selectedAbnormalityAlgo?.algorithmId && item?.organReviewId;
    })
    this.selectedROIResult = this.algoROIArr?.length ? this.algoROIArr[0]?.roi : this.selectedAbnormalityAlgo?.roi;
  }

  navSlide(moveDirection: any) {
    delete this.commonService.ProjectLSData?.currentSelectedImg;
    this.commonService.setProjectLSData();
    let i = (this.imagePreview.currItemSelectedIndex + ((moveDirection == "next") ? 1 : -1));
    let imgCardItem = this.slidesArr[i];
    this.activeResult = '';
    this.qcResults = [];
    this.abnormalityResults = [];
    this.abnormalityAlgoResultsList = [];
    this.algoROIArr = [];
    this.allResults = [];
    this.selectedROIResult = '';
    this.selectedAbnormalityAlgo = {}
    this.noBarCodeText = false;
    this.noBarFoundText = false;
    this.noLabelText = false;
    this.selectedSlideForPreview = {};
    this.togglePreviewPanel(imgCardItem, i, '');
  }

  lastPreviewSelection() {
    let isLastPreviewSelectionFound: boolean = false;
    for (let a = 0; a < this.imagePreview.previewToggleBtns.length; a++) {
      if (this.activePreviewState.iconType == this.imagePreview.previewToggleBtns[a].iconType) {
        isLastPreviewSelectionFound = true;
        this.changePreviewImage(this.imagePreview.previewToggleBtns[a]);
        break;
      }
    }

    if (!isLastPreviewSelectionFound) {
      this.changePreviewImage(this.imagePreview.previewToggleBtns[0]);
    }

  }

  returnQCRoiIndex(id: any) {
    if (id != undefined && id != null) {
      for (let a = 0; a < this.qcResults?.length; a++) {
        if (this.qcResults[a]?.roi === id) {
          return a;
        }
      }
    } else {
      return 0;
    }
  }

  returnAbnormalityRoiIndex(id: any) {
    if (id != undefined && id != null) {
      for (let a = 0; a < this.abnormalityResults?.length; a++) {
        if (this.abnormalityResults[a]?.roi === id && this.abnormalityResults[a]?.algorithmId == this.selectedAbnormalityAlgo?.algorithmId) {
          return a;
        }
      }
    } else {
      return 0;
    }
  }

  chnageROIResult(roi: any) {
    this.selectedROIResult = roi;
  }

  changePreviewImage(source: any) {
    if (source.iconType == 'roi') {
      this.imagePreview.currItemSelected.roiPath = source.roiPath;
    }
    this.imagePreview.currItemSelected.selectedIcon = source.iconName;
    this.activePreviewState = source;
    this.activePreviewState.isSchemaFetching = false;
  }



  getSlideReview() {
    this.galleryService.getSlideReview(this.previewOrgan, this.selectedSlideForPreview.slidePk, this.commonService.ProjectLSData.userActiveRole).subscribe((res: any) => {
      // if (!this.commonService.ProjectLSData['activeUserPermission']?.[0]?.shareReviewPermission) {
      //   this.slideReviewData = res?.data?.filter((review: any) => {
      //     return review?.userFk === this.commonService.ProjectLSData['activeUserPermission']?.[0]?.user
      //   });
      // } else {
      //   this.slideReviewData = res?.data;
      // }
      let isPathologist = this.commonService.ProjectLSData['activeUserPermission']?.length ?  (this.commonService.ProjectLSData['activeUserPermission']?.[0]?.role == 'PATHOLOGIST') : true;
      let isReviewShared = this.commonService.ProjectLSData['activeUserPermission']?.[0]?.shareReviewPermission;
      if ((!isPathologist && isReviewShared) || isPathologist) {
        this.slideReviewData = res.data;
      } else {
        this.slideReviewData = res?.data?.filter((review: any) => {
          return review?.userFk === this.commonService.ProjectLSData['activeUserPermission']?.[0]?.user
        });
      }
    });
    if (this.galleryFilter.isTCRStudy) {
      this.galleryService.getAllIntensities().subscribe((response: any) => {
        response.data.forEach(element => {
          this.intensityObj[element.intensity] = element.description;
        });
      });

      this.galleryService.getAllFrequencies().subscribe((response: any) => {
        response.data.forEach(element => {
          this.frequencyObj[element.frequency] = element.description;
        });
      });
    }
  }

  getAlgorithmsList() {
    this.galleryService.getAlgoList().subscribe((response: any) => {
      this.algorithmList = response?.algorithms;
      this.buildAlgorithmMap();
    })
  }



  buildAlgorithmMap() {
    this.algorithmList.forEach((group: any) => {
      group.algorithms.forEach((algorithm: any) => {
        this.commonAlgoMap[algorithm.algoID] = algorithm.name;
      });
    });
  }

  closePreviewPanel() {
    delete this.commonService.ProjectLSData?.currentSelectedImg;
    this.commonService.setProjectLSData();
    this.imagePreview.state = false;
    this.activeResult = '';
    this.qcResults = [];
    this.abnormalityResults = [];
    this.abnormalityAlgoResultsList = [];
    this.algoROIArr = [];
    this.allResults = [];
    this.selectedROIResult = '';
    this.selectedAbnormalityAlgo = {}
    this.noBarCodeText = false;
    this.noBarFoundText = false;
    this.noLabelText = false;
    this.selectedSlideForPreview = {};
    this.imagePreview.currItemSelected = null;
    this.imagePreview.currItemSelectedIndex = null;
  }

  imageSelection(image: any, event: any) {
    if (event.checked) {
      image.selection = true;
      this.calculateSizeOfImages(image.fileSize, true)
      if (image?.reviewStatus != 'NONE') {
        let imgIdIndex = this.selectedImagesWithReview?.indexOf(image.id);
        if (imgIdIndex < 0) {
          this.selectedImagesWithReview.push(image.id);
          
        }
      }
      this.galleryService.selectedSlidesArr.push(image);
      if (this.galleryService.selectedSlidesArr?.length >= this.slidesArr?.length) {
        this.isSelectAllActive = true;
      }
    } else {
      image.selection = false;
      this.isSelectAllActive = false;
      let position: any;
      for (let a = 0; a < this.galleryService?.selectedSlidesArr?.length; a++) {
        if (this.galleryService?.selectedSlidesArr[a].id == image.id) {
          position = a;
          break;
        }
      }
      this.calculateSizeOfImages(image.fileSize, false);
      if (position >= 0) {
        this.galleryService?.selectedSlidesArr.splice(position, 1);
      }
      let imgIdIndex = this.selectedImagesWithReview?.indexOf(image.id);
      if (imgIdIndex >= 0) {
        this.selectedImagesWithReview.splice(imgIdIndex, 1);
      }
    }
  }

  selectAllImages(event: any) {
    if (event.checked) {
      this.galleryService.selectedSlidesArr = [];
      this.isSelectAllActive = true;
      for (let a = 0; a < this.slidesArr?.length; a++) {
        this.slidesArr[a].selection = true;
        this.calculateSizeOfImages(this.slidesArr[a].fileSize, true);
      }
      let orderby, stain = [], tissue = [], subjectid = [], control = [], dosage = [], controlDosage = false, cassetteCode = [], sex = [];
      orderby = this.galleryService.getGroupByString(this.groupBy);
      this.galleryFilter.viewByG = this.groupBy;
      this.galleryFilter.readReviewFlag = this.readReviewFlag;
      this.galleryFilter.filterBySeverityGrading = this.filterBySeverityGrading;
      this.galleryFilter.stains = this.stainsF;
      stain = this.stainsF ?? [];
      this.galleryFilter.organ = this.organsF;
      tissue = this.organsF ?? [];
      this.galleryFilter.animalNo = this.animalsF;
      subjectid = this.animalsF ?? [];
      this.galleryFilter.control = this.controlF;
      control = this.controlF ?? [];
      this.galleryFilter.quickFilter = this.quickFilterView;
      dosage = this.dosageF ?? [];
      this.galleryFilter.dosageCode = this.dosageF;
      cassetteCode = this.galleryFilter?.cassetteCode ?? [];
      sex = this.galleryFilter?.sex ?? [];
      this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
      this.commonService.setProjectLSData();
      let searchBy = {
        "mainFilter": [this.studySelectionLevel[0]],
        "subfilters": {
          "tissue": tissue,
          "cassetteStain": stain,
          "subjectId": subjectid,
          "tcrSlideControl": control,
          "dosage": dosage,
          "sex": sex,
          "cassetteCode": cassetteCode
        },
        "dosageControlGroup": controlDosage,
        "slidesMapped": this.galleryService.showOnlyMappedSlides,
        "viewByMore": this.galleryService.getReviewFilterString(this.readReviewFlag),
        "severityGrading": this.galleryFilter.filterBySeverityGrading,
        // "bookmark": this.isBookmarkActive
        "bookmark": null,
        "currentRole": this.commonService.ProjectLSData['activeUserPermission']?.[0]?.role ?? 'PATHOLOGIST'
      }
      this.galleryService.getSlidesIdentifierInfo(this.galleryFilter.studyName, this.galleryService.getGroupByString(this.groupBy), searchBy).subscribe((response: any) => {
        if (response?.length) {
          for (let a = 0; a < response?.length; a++) {
            if (response[a]?.reviewStatus != 'NONE') {
              let imgIdIndex = this.selectedImagesWithReview?.indexOf(response[a].id);
              if (imgIdIndex < 0) {
                this.selectedImagesWithReview.push(response[a].id);
              }
            }
          }
          this.galleryService.selectedSlidesArr = response;
        }
      });
    } else {
      this.isSelectAllActive = false;
      this.selectedImagesWithReview = [];
      this.galleryService.selectedSlidesArr = [];
      for (let a = 0; a < this.slidesArr?.length; a++) {
        this.slidesArr[a].selection = false;
      }
    }
  }

  selectAllFolders(event: any) {
    if (event?.checked) {
      this.isSelectAllActiveForFolder = true;
      this.selectedFolder = [];
      for (let a = 0; a < this.studyListingData?.length; a++) {
        this.folderSelection(this.studyListingData[a], event);
      }
    } else {
      this.isSelectAllActiveForFolder = false;
      this.selectedImagesWithReview = [];
      this.selectedFolderWithReview = [];
      this.selectedFolder = [];
      for (let a = 0; a < this.studyListingData?.length; a++) {
        this.studyListingData[a]['selection'] = false;
        this.studyListingData[a]['imagesArr'] = [];
      }
    }
  }

  folderSelection(folder: any, event: any) {
    if (event?.checked) {
      folder.selection = true;
      this.selectedFolder.push(folder?.attribute);
      let orderby, stain = [], tissue = [], subjectid = [], control = [], dosage = [], controlDosage = false, cassetteCode = [], sex = [];
      orderby = this.galleryService.getGroupByString(this.groupBy);
      this.galleryFilter.viewByG = this.groupBy;
      this.galleryFilter.readReviewFlag = this.readReviewFlag;
      this.galleryFilter.filterBySeverityGrading = this.filterBySeverityGrading;
      this.galleryFilter.stains = this.stainsF;
      stain = this.stainsF ?? [];
      this.galleryFilter.organ = this.organsF;
      tissue = this.organsF ?? [];
      this.galleryFilter.animalNo = this.animalsF;
      subjectid = this.animalsF ?? [];
      this.galleryFilter.control = this.controlF;
      control = this.controlF ?? [];
      this.galleryFilter.quickFilter = this.quickFilterView;
      dosage = this.dosageF ?? [];
      this.galleryFilter.dosageCode = this.dosageF;
      cassetteCode = this.galleryFilter?.cassetteCode ?? [];
      sex = this.galleryFilter?.sex ?? [];
      this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
      this.commonService.setProjectLSData();
      let searchBy = {
        "mainFilter": [folder?.attribute],
        "subfilters": {
          "tissue": tissue,
          "cassetteStain": stain,
          "subjectId": subjectid,
          "tcrSlideControl": control,
          "dosage": dosage,
          "sex": sex,
          "cassetteCode": cassetteCode
        },
        "dosageControlGroup": controlDosage,
        "slidesMapped": this.galleryService.showOnlyMappedSlides,
        "viewByMore": this.galleryService.getReviewFilterString(this.readReviewFlag),
        "severityGrading": this.galleryFilter.filterBySeverityGrading,
        "bookmark": null,
        "currentRole": this.commonService.ProjectLSData['activeUserPermission']?.[0]?.role ?? 'PATHOLOGIST'
      }
      this.galleryService.getSlidesIdentifierInfo(this.galleryFilter.studyName, this.galleryService.getGroupByString(this.groupBy), searchBy).subscribe((response: any) => {
        if (response?.length) {
          folder.imagesArr = [...response];
          for (let a = 0; a < response?.length; a++) {
            if (response[a]?.reviewStatus != 'NONE') {
              let imgIdIndex = this.selectedFolderWithReview?.indexOf(response[a] == folder?.attribute);
              if (imgIdIndex < 0) {
                this.selectedFolderWithReview.push(folder?.attribute);
              }
            }
          }
        }
      });

      let selectedFolders: any = [];
      selectedFolders = this.studyListingData.filter((item: any) => {
        return item.selection;
      });
      this.isSelectAllActiveForFolder = selectedFolders?.length == this.studyListingData?.length;
    } else {
      this.isSelectAllActiveForFolder = false;
      let position: any;
      for (let a = 0; a < this.selectedFolder?.length; a++) {
        if (this.selectedFolder[a] == folder?.attribute) {
          position = a;
          break;
        }
      }
      if (position >= 0) {
        this.selectedFolder.splice(position, 1);
      }

      let pos: any;
      for (let a = 0; a < this.selectedFolderWithReview?.length; a++) {
        if (this.selectedFolderWithReview[a] == folder?.attribute) {
          pos = a;
          break;
        }
      }
      if (pos >= 0) {
        this.selectedFolderWithReview.splice(pos, 1);
      }
      folder.selection = false;
      folder.imagesArr = [];
    }
  }


  checkSelection() {
    console.log('Selected Images', this.galleryService?.selectedSlidesArr);
  }

  clearMetadataValidate() {
    this.validateMetataObj = {
      'algorithm': {}
    }
  }

  validateMetadata(algo: any, type: any) {
    let selectedSlideIdsArr: any = [];
    let finalArr: any = [];
    if (this.studySelectionLevel?.length) {
      selectedSlideIdsArr = this.galleryService.selectedSlidesArr;
    } else {
      for (let a = 0; a < this.studyListingData?.length; a++) {
        if (this.studyListingData[a]?.selection == true) {
          selectedSlideIdsArr = [...selectedSlideIdsArr, ...this.studyListingData[a].imagesArr];
        }
      }
    }
    for (let a = 0; a < selectedSlideIdsArr?.length; a++) {
      finalArr.push(selectedSlideIdsArr[a]?.id);
    }
    let data: any = {
      "idList": finalArr,
      "algorithmId": algo?.algoID
    }
    this.galleryService.validateMetdata(data).subscribe((response: any) => {
      if (!response?.INVALID?.length && response?.VALID?.length) {
        this.validateMetataObj['validImages'] = [];
        this.validateMetataObj['validImages'] = response?.VALID;
        this.triggerAnalysis(algo, type, this.validateMetataObj['validImages']);
      } else {
        this.validateMetataObj.algorithm = algo;
        this.validateMetataObj.algorithm['algorithmId'] = algo?.algoID;
        this.validateMetataObj.algorithm['algorithmType'] = type;
        if (response?.VALID?.length) {
          this.validateMetataObj['validImages'] = [];
          this.validateMetataObj['validImages'] = response?.VALID;
        }
        if (response?.INVALID?.length) {
          this.validateMetataObj['inValidImages'] = [];
          this.validateMetataObj['inValidImages'] = response?.INVALID;
          for (let a = 0; a < this.validateMetataObj['inValidImages']?.length; a++) {
            if (!this.validateMetataObj?.algorithm?.species?.toString()?.includes(this.validateMetataObj['inValidImages'][a]?.species)) {
              this.validateMetataObj['inValidImages'][a]['invalidSpecies'] = true;
            }
            if (!this.validateMetataObj?.algorithm?.stain?.toString()?.includes(this.validateMetataObj['inValidImages'][a]?.stain)) {
              this.validateMetataObj['inValidImages'][a]['invalidStain'] = true;
            }
            if (!this.validateMetataObj?.algorithm?.tissue?.includes(this.validateMetataObj['inValidImages'][a]?.tissues?.toString())) {
              this.validateMetataObj['inValidImages'][a]['invalidTissues'] = true;
            }
          }
          console.log('Algo', this.validateMetataObj);
        }
      }
    });
  }

  triggerAnalysis(algo: any, type: any, imagesData: any = []) {
    let selectedSlideIdsArr: any = [];
    let finalArr: any = [];
    if (this.studySelectionLevel?.length) {
      selectedSlideIdsArr = imagesData?.length ? imagesData : this.galleryService.selectedSlidesArr;
      for (let a = 0; a < selectedSlideIdsArr?.length; a++) {
        finalArr.push({
          "mappedId": imagesData?.length ? selectedSlideIdsArr[a]?.mappedId : selectedSlideIdsArr[a]?.id,
          "identifier": selectedSlideIdsArr[a]?.identifier
        });
      }
    } else {
      if (!imagesData?.length) {
        for (let a = 0; a < this.studyListingData?.length; a++) {
          if (this.studyListingData[a]?.selection == true) {
            selectedSlideIdsArr = [...selectedSlideIdsArr, ...this.studyListingData[a].imagesArr];
          }
        }
        for (let a = 0; a < selectedSlideIdsArr?.length; a++) {
          finalArr.push({
            "mappedId": selectedSlideIdsArr[a]?.id,
            "identifier": selectedSlideIdsArr[a]?.identifier
          });
        }
      } else {
        selectedSlideIdsArr = imagesData;
        for (let a = 0; a < selectedSlideIdsArr?.length; a++) {
          finalArr.push({
            "mappedId": selectedSlideIdsArr[a]?.mappedId,
            "identifier": selectedSlideIdsArr[a]?.identifier
          });
        }
      }
    }
    let uniqueJobs: any[] = [...new Map(finalArr.map((item: any) => [item['mappedId'], item])).values()];
    let payload: any = {
      "createdBy": this.commonService?.ProjectLSData?.currentUser?.email,
      "algorithmId": algo?.algoID,
      "algorithmType": type,
      "jobs": uniqueJobs
    }
    console.log(payload);
    this.galleryService.analysisSubmit(payload).subscribe((response: any) => {
      if (response.success) {
        this.commonService.showToastMsg(response.message);
        if (this.studySelectionLevel?.length) {
          this.slidesArr.forEach((slide: any) => {
            slide.selection = false;
          });
        } else {
          this.studyListingData.forEach((study: any) => {
            study.selection = false;
          })
        }
        this.galleryService.selectedSlidesArr = [];
        this.isSelectAllActive = false;
        this.selectedImagesWithReview = [];
        this.selectedFolderWithReview = [];
        this.isSelectAllActiveForFolder = false;
        this.selectedFolder = [];
        if (this.studyListingData?.length) {
          this.studyListingData.forEach((study: any) => {
            study.imagesArr = [];
            study.selection = false;
          });
        }
        this.clearMetadataValidate()
        if (this.SlideStatusTimeOutFn) {
          clearTimeout(this.SlideStatusTimeOutFn);
        }
        this.getSlideUpdates();
      }
    });
  }

  removeAlgorithmsResults(algorithm: any) {
    let selectedSlideIdsArr: any = [];
    if (this.studySelectionLevel?.length) {
      selectedSlideIdsArr = this.galleryService.selectedSlidesArr;
    } else {
      for (let a = 0; a < this.studyListingData?.length; a++) {
        if (this.studyListingData[a]?.selection == true) {
          selectedSlideIdsArr = [...selectedSlideIdsArr, ...this.studyListingData[a].imagesArr];
        }
      }
    }
    let payload: any = {
      "allImages": false,
      "algorithms": [algorithm?.algoID],
      "mappedIds": selectedSlideIdsArr.map(e => { return e?.id })
    }
    this.galleryService.removeAlgorithmResult(payload).subscribe((response: any) => {
      if (response.success) {
        this.commonService.showToastMsg(response.message);
        if (this.studySelectionLevel?.length) {
          this.slidesArr.forEach((slide: any) => {
            slide.selection = false;
          });
        } else {
          this.studyListingData.forEach((study: any) => {
            study.selection = false;
          })
        }
        this.galleryService.selectedSlidesArr = [];
        this.isSelectAllActive = false;
        this.selectedImagesWithReview = [];
        this.selectedFolderWithReview = [];
        this.isSelectAllActiveForFolder = false;
        this.selectedFolder = [];
        if (this.studyListingData?.length) {
          this.studyListingData.forEach((study: any) => {
            study.imagesArr = [];
            study.selection = false;
          });
        }
      }
    });
  }

  getOrganisedData(advFilter) {
    this.galleryService.activeAccordian = 0;
    this.galleryService.showLoader = true;

    if (advFilter) {
      this.toggleFilter();
    }

    let slidesData = {};
    let checkKey = '';

    if (this.activeMode == 'scanned') {
      $.each(this.rawSlidesData, (key, value) => {
        slidesData[value.name] = {
          "alreadyrender": false,
          "slidesArr": [],
          "id": value.id
        }
      });
      this.galleryService.switchGridList('grid');
      this.galleryFilter.viewType = 'grid';
      this.galleryFilter.viewByS = this.groupBy;
    } else {
      $.each(this.rawSlidesData, (key, value) => {
        let inFlag = true;
        value.state = false;
        if (value.sex == 'M') {
          value.sexId = "1"
        } else if (value.sex == 'F') {
          value.sexId = "2"
        } else {
          value.sexId = "3"
        }

        if (this.activeMode == 'image-viewer' && ((this.quickFilterView == -1) || (this.quickFilterView == "-2"))) {
          checkKey = (this.quickFilterView == -1) ? 'Subject ID:' : 'Tissues:';
          if (this.quickFilterView == -1) {
            if (this.dialogData.organArr.length > 0 && !(this.dialogData.organArr.some(r => value.organ.includes(r)))) {
              inFlag = false;
            }
          } else {
            if (this.dialogData.animalId != value.animalId) {
              inFlag = false;
            }
          }
        } else if (this.activeMode == 'image-viewer' && this.quickFilterView == "CD") {
          checkKey = value.dosageCode;
          if (!value.dosageControlGroup) {
            inFlag = false;
          }
        } else if (this.activeMode == 'image-viewer' && this.dialogData.dialogType == 'compare' && this.quickFilterView != 'viewerCustom') {
          if (this.dialogData.studyTypeTCR) {
            checkKey = value.slideControl;
          } else {
            checkKey = value.dosageCode;
          }
          if (this.quickFilterView != checkKey) {
            inFlag = false;
          }
        } else {
          if (this.groupBy == 5) { // view by subject Id
            checkKey = value.animalNo;
          } else if (this.groupBy == 3) { // view by tissue
            checkKey = value.organ.join(", ");
          } else if (this.groupBy == 1) { // view by dosage
            checkKey = value.dosageCode;
          } else if (this.groupBy == 2) { // view by sex
            checkKey = value.sexId;
          } else if (this.groupBy == 0) { // view by cassttee
            checkKey = value.cassetteCode;
          } else if (this.groupBy == 6) { // view by targetAntigen
            checkKey = value.targetAntigen;
            if (checkKey == null) {
              checkKey = 'Not Known';
            }
          } else if (this.groupBy == 'week') {
            var d = new Date(value.uploadedDate);
            var firstday = new Date(d.setDate(d.getDate() - (d.getDay() - 1)));
            checkKey = 'MONDAY ' + firstday.getFullYear() + '-' + (firstday.getMonth() + 1) + '-' + firstday.getDate();;
          } else if (this.groupBy == 'month') {
            var d = new Date(value.uploadedDate);
            let n = d.getMonth();
            checkKey = this.months[d.getMonth()] + ' ' + d.getFullYear();
          } else if (this.groupBy == 'year') {
            var d = new Date(value.uploadedDate);
            checkKey = d.getFullYear().toString();
          } else if (this.groupBy == 'quarter') {
            var d = new Date(value.uploadedDate);
            var month = d.getMonth();
            var year = d.getFullYear();
            var quarter;
            if (month < 4)
              quarter = 1;
            else if (month < 7)
              quarter = 2;
            else if (month < 10)
              quarter = 3;
            else if (month < 13)
              quarter = 4;
            if (quarter == 1) {
              checkKey = 'JANUARY To MARCH ' + year;
            } else if (quarter == 2) {
              checkKey = 'APRIL To JUNE ' + year;
            } else if (quarter == 3) {
              checkKey = 'JULY To SEPTEMBER ' + year;
            } else if (quarter == 4) {
              checkKey = 'OCTOBER To DECEMBER ' + year;
            }
          }

          if (this.activeMode == 'gallery') {
            if (this.readReviewFlag) {
              if (this.readReviewFlag == 'READ' || this.readReviewFlag == 'UNREAD') {
                if (value.readStatus != this.readReviewFlag) {
                  inFlag = false;
                }
              } else {
                if (value.reviewStatus != this.readReviewFlag) {
                  inFlag = false;
                }
              }
            }
          }

          if (this.activeMode == 'gallery' || this.activeMode == 'mapped' || this.activeMode == 'image-viewer') {
            if (this.stainsF.length > 0 && (this.stainsF.indexOf(value.stainId) == -1)) {
              inFlag = false;
            }
            if (this.animalsF.length > 0 && (this.animalsF.indexOf(value.animalId) == -1)) {
              inFlag = false;
            }
            if (this.organsF.length > 0 && !(this.organsF.some(r => value.organ.includes(r)))) {
              inFlag = false;
            }
            if (this.controlF && this.controlF.length > 0 && !(this.controlF.some(r => value.slideControl.includes(r)))) {
              inFlag = false;
            }
            if (this.dosageF.length > 0 && (this.dosageF.indexOf(value.dosageCode) == -1)) {
              inFlag = false;
            }
          }
        }

        if (inFlag) {
          if (checkKey in slidesData) {
            slidesData[checkKey].slidesArr.push(value);
          } else {
            if (this.groupBy == 0) {
              slidesData[checkKey] = {
                "alreadyrender": false,
                "organ": value.organ,
                "slidesArr": [value]
              };
            } else {
              slidesData[checkKey] = {
                "alreadyrender": false,
                "slidesArr": [value]
              };
            }
          }
        }
      });

      if (this.activeMode == 'uploaded') {
        this.galleryFilter.viewByU = this.groupBy;
      } else {
        this.galleryFilter.viewByG = this.groupBy;
        this.galleryFilter.filterBySeverityGrading = this.filterBySeverityGrading;
        this.galleryFilter.readReviewFlag = this.readReviewFlag;
        this.galleryFilter.stains = this.stainsF;
        this.galleryFilter.organ = this.organsF;
        this.galleryFilter.animalNo = this.animalsF;
        this.galleryFilter.control = this.controlF;
        this.galleryFilter.dosageCode = this.dosageF;
      }
    }
    this.galleryService.showLoader = false;
    //updated to resolve epl sorting issue
    //sorting order is Accordion - alphanumeric, inside data - sex(M,F,N) with sorted animalno
    let ordered = Object.keys(slidesData).sort(
      (a, b) => {
        return a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      }
    ).reduce((obj, key) => {
      let a = slidesData[key].slidesArr.sort((a, b) => {
        return a.animalNo.localeCompare(b.animalNo, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      });
      slidesData[key].slidesArr = this.commonService.sortByMultiKey(a, ['sexId']);
      obj[key] = slidesData[key];
      return obj;
    }, {});
    if (this.groupBy == 2) {
      for (let key in ordered) {
        if (key == '1') {
          ordered['M'] = ordered[key];
        } else if (key == '2') {
          ordered['F'] = ordered[key];
        } else {
          ordered['N'] = ordered[key];
        }
        delete ordered[key];
      }
    }
    this.galleryService.setSlidesList(ordered);
    this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
    this.commonService.setProjectLSData();
  }

  changeStudyStatus() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: this.translate.instant("GENERIC_MSG.STUDY.STATUS_CHANGE_HEADER"),
      confirmMsg: this.translate.instant("GENERIC_MSG.STUDY.STATUS_CHANGE"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result == true) {
        this.studyListingService.changeStudyStatus(this.selectedStudyProj).subscribe((res: any) => {
          this.commonService.showToastMsg(res.message);
          this.backToStudyListing();
        });
        // console.log('Here');
      }
    });
  }

  openRandomizedImaeDetailPopup() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = "78vw";
    dialogConfig.panelClass = "confirmDialog";
    dialogConfig.data = {
      studyName: this.selectedStudyName
    }
    this.galleryService.selectedSlidesArr.map((slide: any, index: any) => {
      this.galleryService.getSelectedSlideDataForPreview(slide.slidePk).subscribe((res: any) => {
        slide.loacationPath = res?.data?.physicalLoc;
        slide.dosageLevel = res?.data?.dosageLevel;
        slide.scanMagnification = res?.data?.scanMagnification;
        slide.scanDateTime = res?.data?.scanDateTime;
        slide.dose = res?.data?.dose;
        slide.dosageUnit = res?.data?.dosageUnit;
        slide.stain = res?.data?.stain;
        if (index == (this.galleryService.selectedSlidesArr.length - 1)) {
          const dialogref = this.dialog.open(RandomizedImageDetailComponent, dialogConfig);
        }

      });
    });
  }


  stopRandomization() {
    this.galleryService.isStudyRandomised = false;
    this.galleryService.selectedSlidesArr = [];
    delete this.commonService.ProjectLSData.randomisedSlideIds;
    this.commonService.ProjectLSData.isRandomisationStarted = false;
    this.commonService.setProjectLSData();
    this.getAccordionData();
    this.galleryService.deleteRanomizationScore(this.selectedStudyProj).subscribe((res: any) => {
      this.studyListingService.getAllRandomizedStudies().subscribe((response: any) => {
        this.galleryService.allRandomisedResp = [];
        if (response?.length) {
          this.galleryService.allRandomisedResp = [...response];
        }
        this.processAllRandomisedResp();
      });
    })
    if (this.commonService.ProjectLSData["isListViewType"]) {
      this.switchGridList();
      this.isViewChnagedForRandomization = false;
      this.commonService.ProjectLSData["isListViewType"] = false;
      this.commonService.setProjectLSData();
    }
  }

  getDosageData(studyId) {
    this.galleryService.getStudyDosageList(studyId).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.dosageArr = res.data;
    })
  }

  changeReadStatus(status) {
    let selectedSlideIdsArr = this.galleryService.selectedSlidesArr.map(e => { return e.slidePk });
    let dataObj = {
      "slideIds": selectedSlideIdsArr,
      "status": status
    }
    this.galleryService.changeReadStatus(dataObj).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.commonService.showToastMsg(res.message);
      if (this.studySelectionLevel?.length) {
        this.galleryService.selectedSlidesArr = [];
        this.selectedFolder = [];
        if (this.studyListingData?.length) {
          this.studyListingData.forEach((study: any) => {
            study.imagesArr = [];
            study.selection = false;
          });
        }
        this.isSelectAllActive = false;
        this.selectedImagesWithReview = [];
        this.selectedFolderWithReview = [];
        this.isSelectAllActiveForFolder = false;
        this.getPageWiseData(this.studySelectionLevel[0], 'init');
      } else {
        this.getSlidesData('');
      }
    });
  }

  changeReviewStatus(status) {
    let selectedSlideIdsArr = this.galleryService.selectedSlidesArr.map(e => { return e.slidePk });
    let dataObj = {
      "slideIds": selectedSlideIdsArr,
      "status": status
    }
    this.galleryService.changeReviewStatus(dataObj).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
      this.commonService.showToastMsg(res.message);
      if (this.studySelectionLevel?.length) {
        this.galleryService.selectedSlidesArr = [];
        this.selectedFolder = [];
        if (this.studyListingData?.length) {
          this.studyListingData.forEach((study: any) => {
            study.imagesArr = [];
            study.selection = false;
          });
        }
        this.isSelectAllActive = false;
        this.selectedImagesWithReview = [];
        this.selectedFolderWithReview = [];
        this.isSelectAllActiveForFolder = false;
        this.getPageWiseData(this.studySelectionLevel[0], 'init');
      } else {
        this.getSlidesData('');
      }
    });
  }

  openSplitView() {
    if (this.galleryService.selectedSlidesArr.length > 4) {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.GALLERY.COMPARE"));
    } else {
      let slidesIdArr: any = this.galleryService.selectedSlidesArr.map(e => { return e.slidePk });
      this.galleryService.getSlideDetailsArr(slidesIdArr).subscribe((successData: any) => {
        let slideIds: any = [];
        for (let x = 0; x < successData.data.length; x++) {
          successData.data[x].id = successData?.data[x].slide.slidePk;
          successData.data[x].identifier = successData?.data[x].slide.identifier;
          successData.data[x].slidename = successData?.data[x]?.slide.slidename;
          slideIds.push(successData.data[x].id);

          this.galleryService.changeOpenStatus(successData.data[x].id, { "opened": true }).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
          });
        }
        this.commonService.setViewerOpenedFrom(this.activeMode);
        this.commonService.ProjectLSData['ViewerSlides'] = successData.data;
        this.router.navigate(['WebViewer']);
      });
    }
  }

  unSubscribeDataObservable() {
    if (this.slideDataObservable) {
      this.slideDataObservable.unsubscribe();
    }
  }

  checkUnmapStatus() {
    if (this.commonService.ProjectLSData?.galleryFilters?.studyName) {
      this.galleryService.checkUnmapFlag(this.commonService.ProjectLSData?.galleryFilters?.studyName).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        this.unmapFlag = res.success;
        this.galleryService.setunMapFLag(this.unmapFlag);
      });
    }
  }

  getDosageList() {
    this.galleryService.getstudyDossageList(this.commonService.ProjectLSData?.galleryFilters?.studyName).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.success) {
        this.studyDosageList = response.data;
      } else {
        this.studyDosageList = [];
      }
    });
  }

  getstudyAnimalList() {
    this.galleryService.getstudyAnimalList(this.commonService.ProjectLSData?.galleryFilters?.studyName).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.success) {
        this.studyAnimalList = response.data;
      } else {
        this.studyAnimalList = [];
      }
    });
  }

  getstudyOrganList() {
    this.galleryService.getstudyOrganList(this.commonService.ProjectLSData?.galleryFilters?.studyName).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.success) {
        this.studyOrganList = response.data;
        this.createStudyOrganObj();
      } else {
        this.studyOrganList = [];
      }
    });
  }

  createStudyOrganObj() {
    this.studyOrganList.forEach((organElem: any) => {
      if (!this.studyOrganObj[organElem.organId]) {
        this.studyOrganObj[organElem.organId] = organElem.organ;
      }
    });
    this.galleryService.setStudyOrganObj(this.studyOrganObj);
  }

  getstudyStainList() {
    this.galleryService.getStudyStainList(this.commonService.ProjectLSData?.galleryFilters?.studyName).pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
      if (response.success) {
        this.studyStainList = response.data;
      } else {
        this.studyStainList = [];
      }
    });
  }

  fatchStudySeverityGrading() {
    this.studyListingService.getStudySeverityGrading(this.commonService.ProjectLSData?.galleryFilters?.studyName).subscribe((response: any) => {
      if (response.success) {
        this.currentStudyGradingGrade = response.data;
      }
    });
  }

  processAllRandomisedResp() {
    this.commonService.ProjectLSData.randomisedSlideIds = [];
    this.commonService.ProjectLSData.randomizationScoreObj = {};
    this.commonService.ProjectLSData.randomizationScore = [];
    this.galleryService.isStudyRandomised = false;
    this.commonService.ProjectLSData.isRandomisationStarted = false;
    for (let a = 0; a < this.galleryService.allRandomisedResp.length; a++) {
      if (!this.galleryService.allRandomisedResp[a].initSlides) {
        this.galleryService.allRandomisedResp[a].initSlides = this.galleryService.allRandomisedResp[a].slides ? [...this.galleryService.allRandomisedResp[a].slides] : this.galleryService.allRandomisedResp[a].slides;
      }
      this.galleryService.allRandomisedResp[a].slides = this.galleryService.allRandomisedResp[a].initSlides.map((item: any) => {
        if (this.selectedStudyProj == this.galleryService.allRandomisedResp[a].study) {
          this.galleryService.isStudyRandomised = true;
          this.commonService.ProjectLSData.isRandomisationStarted = true;
          this.commonService.ProjectLSData.randomizationScoreObj[item.slideId] = item.score ? item.score : null;
          this.commonService.ProjectLSData.randomisedSlideIds.push(item.slideId);
          this.commonService.setProjectLSData();
          this.galleryService.setSlidesList({ 'Randomised Slides': {} });

        }
        return item.slideId;
      });
    }
    this.galleryService.randomisedSelection = this.commonService.ProjectLSData.randomisedSlideIds;
    this.commonService.ProjectLSData.randomizationScore = this.galleryService.randomisedSelection.map((item: any) => {
      return {
        slideId: item,
        score: this.commonService.ProjectLSData.randomizationScoreObj[item]
      }
    });
    this.galleryService.randomisedStudies = this.galleryService.allRandomisedResp;
  }

  startRandomization(isCalledFromViewer: boolean = false) {
    let filterCriteria: any = {}
    this.galleryService.showLoader = false;
    this.galleryService.isStudyRandomised = true;
    if (!isCalledFromViewer) {
      this.galleryService.selectedSlidesArr = this.galleryService.selectedSlidesArr.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value);
      let slides: any = [];
      let randomizationScoreObj: any = {}
      this.galleryService.selectedSlidesArr.forEach((element: any) => {
        slides.push({
          slideId: element.slidePk,
          score: null
        });
        randomizationScoreObj[element.slidePk] = null;
      });
      this.galleryService.setRandomizedScore({
        study: this.selectedStudyProj,
        slides: slides,
        seed: 'XYZ',
        filterCriteria: this.commonService.ProjectLSData['galleryFilters']
      }).subscribe((res: any) => {
        this.studyListingService.getAllRandomizedStudies().subscribe((response: any) => {
          this.galleryService.allRandomisedResp = [];
          if (response?.length) {
            this.galleryService.allRandomisedResp = [...response];
          }
          this.processAllRandomisedResp();

          /* for (let a = 0; a < response.length; a++) {
            response[a].slides = response[a].slides.map((item: any) => {
              return item.slideId;
            });
          }
          this.galleryService.randomisedStudies = [...response]; */
        });
        // this.commonService.ProjectLSData.randomizationScore = slides;
        // this.commonService.ProjectLSData.randomizationScoreObj = randomizationScoreObj;
      })
    }
    let selectedSlideIdsArr = this.galleryService.selectedSlidesArr.map(e => { return e.slidePk });
    let randomisedStudy: any = {
      'studyName': this.selectedStudyProj,
      'selectedSlides': selectedSlideIdsArr
    }
    this.commonService.ProjectLSData.randomisedSlideIds = selectedSlideIdsArr;
    // this.commonService.ProjectLSData.randomisedStudies?.push(randomisedStudy);
    // this.commonService.ProjectLSData.randomisedSlideIds?.push(selectedSlideIdsArr);
    this.commonService.ProjectLSData.isRandomisationStarted = true;
    this.commonService.setProjectLSData();
    this.galleryService.setSlidesList({ 'Randomised Slides': {} });
    if (!this.galleryFilter) {
      this.galleryFilter = this.commonService.ProjectLSData.galleryFilters
    }
    if ((this.galleryFilter?.viewType == 'list')) {
      this.commonService.ProjectLSData["isListViewType"] = true;
      this.commonService.setProjectLSData();
      // this.isViewChnagedForRandomization = true;
      this.switchGridList();
    }

  }

  switchGridList() {
    let galleryFilterObj = this.commonService.ProjectLSData.galleryFilters;
    this.galleryFilter.selectedAccordion = galleryFilterObj.selectedAccordion;

    let gridListP;
    // this.galleryService.setSelecteSlidesList([]);
    this.galleryService.gridList.pipe(takeUntil(this.destroy$)).subscribe((gridList) => {
      gridListP = gridList;
    });
    if (gridListP == 'grid') {
      this.galleryService.switchGridList('list');
    } else {
      this.galleryService.switchGridList('grid');
    }
    this.galleryFilter.viewType = gridListP;
    this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
    this.commonService.setProjectLSData();
  }

  returnValue(o: any, call: boolean) {
    return (call) ? parseFloat(o.value)?.toFixed(2) : o.value;
  }
  returnUnitSign(o: any) {
    return o.unitSign;
  }




  openViewer(slideInfo: any, groupIn, selectedAccordionText) {
    let currSlideInfo = slideInfo;
    this.galleryService.getSlideDetailsArr([slideInfo.slidePk]).subscribe((successData: any) => {
      let slideIds: any = [];
      for (let x = 0; x < successData.data.length; x++) {
        successData.data[x].id = successData?.data[x].slide.slidePk;
        successData.data[x].identifier = successData?.data[x].slide.identifier;
        successData.data[x].slidename = successData?.data[x]?.slide.slidename;
        successData.data[x].slide['bookmark'] = currSlideInfo?.bookmark;
        successData.data[x].slide.mappedId = currSlideInfo.id;
        slideIds.push(successData.data[x].id);

        this.galleryService.changeOpenStatus(successData.data[x].id, { "opened": true }).pipe(takeUntil(this.destroy$)).subscribe((res: any) => {
        });
      }
      let parentUrl = {
        apiUrl: this.appConfig?.apiConfig?.apiUrl,
        thumbnailUrl: this.appConfig?.apiConfig?.thumbnailUrl,
        tileUrl: this.appConfig?.apiConfig?.tileUrl
      };
      this.commonService.setViewerOpenedFrom(this.activeMode);
      this.commonService.ProjectLSData['ViewerSlides'] = successData.data;

      this.commonService.ProjectLSData['UrlApi'] = parentUrl;
      this.commonService.ProjectLSData['ParentProject'] = this.commonService.projectName;
      this.commonService.ProjectLSData['LastParentAppUrl'] = location.href;
      this.commonService.ProjectLSData['studyManualFields'] = this.hiddenOptions;

      // To track previous page
      this.galleryService.isCalledFromViewer = true;
      this.galleryFilter.selectedAccordion = this.studySelectionLevel[this.studySelectionLevel?.length - 1];
      this.galleryFilter['subFilterOptions'] = {
        // 'organ': this.folderOrganList,
        // 'stains': this.folderStainList,
        // 'animalNo': this.folderAnimalList,
        // 'dosage': this.folderDosageList
        'organ': this.studyOrganList,
        'stains': this.studyStainList,
        'animalNo': this.studyAnimalList,
        'dosage': this.studyDosageList
      }
      this.commonService.ProjectLSData.galleryFilters = this.galleryFilter;
      this.commonService.setProjectLSData();


      if (this.appConfig?.webAppConfig?.openViewer && this.appConfig?.webAppConfig?.openViewer == 'popup') {
        var newViewerParams = ['height=' + screen.height, 'width=' + screen.width, 'status=no', 'fullscreen=yes'].join(',');
        if (this.commonService.ProjectLSData.isViewerInitialized && this.commonService.webViewerWindow) {
          let iframe = this.commonService.webViewerWindow.document.getElementById('webViewerFrame');
          if (iframe['contentWindow']) {
            iframe['contentWindow'].postMessage({
              call: 'openGalleryImg', value: {}
            }, this.appConfig?.webAppConfig?.webViewerUrl);
            this.commonService.webViewerWindow.focus();
          } else {
            this.commonService.webViewerWindow = window.open(`${this.appConfig?.webAppConfig?.parentAppUrl}${this.appConfig?.webAppConfig?.replacePathName}/WebViewer`, 'popup', newViewerParams);
            // this.commonService.webViewerWindow.moveTo(0, 0);
            setTimeout(() => {
              this.commonService?.webViewerWindow?.focus();
            }, 50);
          }
        } else {
          this.commonService.webViewerWindow = window.open(`${this.appConfig?.webAppConfig?.parentAppUrl}${this.appConfig?.webAppConfig?.replacePathName}/WebViewer`, 'popup', newViewerParams);
          // this.commonService.webViewerWindow.moveTo(0, 0);
          setTimeout(() => {
            this.commonService?.webViewerWindow?.focus();
          }, 50);
        }
      } else {
        // this.router.navigate(['WebViewer/' + slideItem.id]);
        this.router.navigate(['WebViewer']);
      }

    });
  }

  navigateROI(navDirection: any) {
    this.activePreviewState.selectedROI += (navDirection == 'prev') ? -1 : 1;
    this.imagePreview.currItemSelected.roiPath = this.activePreviewState.roiPath = this.appConfig?.apiConfig?.thumbnailUrl + this.imagePreview.currItemSelected.identifier + '_roi_' + this.activePreviewState.selectedROI + '.jpeg';
  }

  downaloadImage(slide: any) {
    if (!slide.downloProgressValue || (slide.downloProgressValue && slide.downloProgressValue == 100)) {
      slide.downloProgressValue = 0
      this.galleryService.downloadImages(slide.slidePk, slide.slideName).subscribe((data: any) => {
        if (data.type === HttpEventType.DownloadProgress) {
          const percentDone = Math.round(((100 * data.loaded) / data.total));
          slide.downloProgressValue = percentDone;
        }
        if (data.type === HttpEventType.Response) {
          saveAs(data.body, slide.slideName);
          slide.downloProgressValue = 0;
        }
        // slide.downloProgressValue = 0;
      })
    }
  }

  calculateSizeOfImages(imageSize: any, eventChecked: any) {
    let size = 0;
    let breakPoint = /[a-z A-Z]/;
    // if (imageSize.includes('KB')) {
    //   size = parseInt(imageSize.split(breakPoint)[0]) * 1024;
    // } else if (imageSize.includes('MB')) {
    //   size = parseInt(imageSize.split(breakPoint)[0]) * 1048576;
    // } else if (imageSize.includes('GB')) {
    //   size = parseInt(imageSize.split(breakPoint)[0]) * 1073741824;
    // }
    this.totalFileSize = eventChecked ? this.totalFileSize + imageSize : this.totalFileSize - imageSize;
    this.selectedFileSize = this.formatBytes(this.totalFileSize);
  }

  formatBytes(bytes: any) {
    let decimals = 2
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }


  DownloadZip() {
    if (this.totalFileSize > 10 * 1073741824) {
      this.commonService.showToastMsg(this.translate.instant('PROJECT_NAME.folderSizeWarnMsg'), 'warn');
      return
    }
    this.commonService.downloadZip(
      {
        name: this.selectedStudyName ,
        imageIds:   this.galleryService.selectedSlidesArr.map(slide=> slide.slidePk),
        appCode: "AIRADHI_STUDY"
      }, this.totalFileSize,this.studySelectionLevel[0]
    )
  }
}

