<form [formGroup]="passwordFormGroup">
    <div class="passwordInputsDiv" [ngClass]="{'userCreation' : isFromUserCreation}">
        <mat-form-field *ngIf="isOldPasswordRequired"
            class="commonMatFormField commonMatFormFieldBottomMargin newwidth mbot_1 commonMatFormFieldBottomMargin"
            appearance="outline">
            <mat-label class="labelbg">Old Password<span class="mandatory">*</span></mat-label>
            <input matInput placeholder="Old Password" [type]="hideOldPassword ? 'password' : 'text'"
                formControlName="oldPwd" (keydown.space)="$event.preventDefault();">
            <mat-icon matSuffix class="hidePasswordIon" (click)="hideOldPassword = !hideOldPassword" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hideOldPassword">
                {{hideOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        <mat-form-field
            class="commonMatFormField commonMatFormFieldBottomMargin newwidth mbot_1 commonMatFormFieldBottomMargin"
            appearance="outline">
            <mat-label class="labelbg" *ngIf="isOldPasswordRequired  || isFromUserCreation">New Password<span
                    class="mandatory">*</span></mat-label>
            <input matInput placeholder="New Password" [type]="hideNewPassword ? 'password' : 'text'"
                formControlName="newPwd" (keydown.space)="$event.preventDefault();">
            <mat-icon matSuffix class="hidePasswordIon" (click)="hideNewPassword = !hideNewPassword" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hideNewPassword">
                {{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        <mat-form-field class="commonMatFormField newwidth mbot_1" appearance="outline">
            <mat-label class="labelbg" *ngIf="isOldPasswordRequired || isFromUserCreation">Confirm Password<span
                    class="mandatory">*</span></mat-label>
            <input matInput placeholder="Confirm Password" [type]="hideConfirmPassword ? 'password' : 'text'"
                formControlName="confirmPwd" (keydown.space)="$event.preventDefault();">
            <mat-icon matSuffix class="hidePasswordIon" (click)="hideConfirmPassword = !hideConfirmPassword" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hideConfirmPassword">
                {{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
    </div>
    <div class="passwordPolicyDiv" [ngClass]="{'userCreationPolicy' : isFromUserCreation}">
        <div class="indvPasswordPolicy" *ngIf="isOldPasswordRequired">
            <mat-icon class="customMatIconPassword policyCheckGreen" *ngIf="(passwordFormGroup?.value?.newPwd?.trim()?.length && passwordFormGroup?.value?.oldPwd?.trim()?.length) &&
          passwordFormGroup?.value?.oldPwd != passwordFormGroup?.value?.newPwd">verified_user</mat-icon>
            <mat-icon class="customMatIconPassword policyCheckRed"
                *ngIf="!passwordFormGroup?.value?.newPwd?.trim()?.length  || !passwordFormGroup?.value?.oldPwd?.trim()?.length || (passwordFormGroup?.value?.oldPwd?.trim() == passwordFormGroup?.value?.newPwd?.trim())">cancel</mat-icon>
            <span>{{passwordCheckMsg}}</span>
        </div>
        <div class="indvPasswordPolicy" *ngFor="let msg of passwordPolicyArr">
            <mat-icon class="customMatIconPassword policyCheckGreen" *ngIf="msg?.verified">verified_user</mat-icon>
            <mat-icon class="customMatIconPassword policyCheckRed" *ngIf="!msg?.verified">cancel</mat-icon>
            <span>{{msg?.displayText}}</span>
        </div>
        <div class="indvPasswordPolicy">
            <mat-icon class="customMatIconPassword policyCheckGreen" *ngIf="(passwordFormGroup?.value?.newPwd?.trim()?.length && passwordFormGroup?.value?.confirmPwd?.trim()?.length) &&
          passwordFormGroup?.value?.confirmPwd == passwordFormGroup?.value?.newPwd">verified_user</mat-icon>
            <mat-icon class="customMatIconPassword policyCheckRed"
                *ngIf="!passwordFormGroup?.value?.newPwd?.trim()?.length  || !passwordFormGroup?.value?.confirmPwd?.trim()?.length || (passwordFormGroup?.value?.confirmPwd?.trim() != passwordFormGroup?.value?.newPwd?.trim())">cancel</mat-icon>
            <span>{{newPasswordCheckMsg}}</span>
        </div>
    </div>
</form>