import { AppConfigService } from './../app-config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudyMappingStructureService {
  private config: any;
  private showStudyMappingStructure: BehaviorSubject<boolean>;
  private showProjectTemplate: BehaviorSubject<boolean>;


  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.config = configService.config;
    this.showStudyMappingStructure = new BehaviorSubject<boolean>(false);
    this.showProjectTemplate = new BehaviorSubject<boolean>(false);
  }

  setShowStudyMappingStructureValue(newValue): void {
    this.showStudyMappingStructure.next(newValue);
  }

  setShowProjectTemplateValue(newValue): void {
    this.showProjectTemplate.next(newValue);
  }

  getShowStudyMappingStructureValue(): Observable<boolean> {
    return this.showStudyMappingStructure.asObservable();
  }

  getShowProjectTemplateValue(): Observable<boolean> {
    return this.showProjectTemplate.asObservable();
  }

  /* Study Mapping Structure Starts Here */

  getAllStudyTemplates() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}study/data/configuration/template/all`);
    // return this.http.get(`${this.config?.apiConfig?.apiUrl}slide-image/data/configuration/template/all`);
  }

  getStudyFields() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}study/data/configuration/labels/latest`);
  }

  addStudyMappingStructure(studyFieldObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/data/configuration/template`, studyFieldObj);
  }

  editStudyMappingStructure(templateId, studyFieldObj) {
    return this.http.put(`${this.config?.apiConfig?.apiUrl}study/data/configuration/template?templateId=` + templateId, studyFieldObj);
  }

  deleteStudyTemplate(studyTemplateId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}study/data/configuration/template?templateId=${studyTemplateId}`);
  }

  viewStudyTemplate(studyTemplateId) {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}study/data/configuration/template?templateId=${studyTemplateId}`);
    // return this.http.get(`http://172.28.43.141:9070/airadhi/study/data/configuration/template?templateId=3`);
  }

  /* Study Mapping Structure Ends Here */

}
