<!--<div class="mdl-layout mdl-js-layout mdl-layout--fixed-header">
  <app-header></app-header>
  <main id="mainContainer" class="mdl-layout__content">
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--1-col-desktop mdl-cell--1-col-tablet sidebar">
        <app-sidebar></app-sidebar>
      </div>
      <div class="mdl-cell mdl-cell--11-col-desktop mdl-cell--7-col-tablet contenttab"
        [ngClass]="{ 'viewercontent' : router.url.includes('image-viewer')}">
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
  <app-footer *ngIf="!router.url.includes('image-viewer')"></app-footer>
</div>
-->
<div class="dashBoardMainDiv">
    <app-header></app-header>
    <div class="dashBoardBody" [ngClass]="{ 'viewerBody' : router.url.includes('image-viewer')}">
        <div class="sidebar float-left">
            <app-sidebar></app-sidebar>
        </div>
        <div class="contenttab float-left" [ngClass]="{ 'viewercontent' : router.url.includes('image-viewer')}">
            <router-outlet></router-outlet>
        </div>
    </div>
    <!-- <app-footer *ngIf="!router.url.includes('image-viewer')" class="footer-align"></app-footer> -->
</div>