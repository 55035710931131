import { AppConfigService } from './../app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private config: any;
  constructor(private http: HttpClient, private cookieService: CookieService, private configService: AppConfigService) {
    this.config = configService.config;
  }

  getUserInfo() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}user/userinfo`);
  }

  getUserRoles() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}user/roles`);
  }

  sendEmailForVerification(email: string) {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}user/forgetUserPassword?userId=${email}`);
  }

  sendVerificationCode(userdata: any) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}user/verifyCode`, userdata);
  }

  resetPassword(user: any) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}user/resetPassword`, user);
  }

  updateUserProfile(user: any) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}user/updateUserProfile`, user);
  }

  isUserNameRequired(emailId: any) {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}user/isUserNameRequired?userId=${emailId}`);
  }

  getPassPolicy() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}user/passwordPolicy`);
  }
}