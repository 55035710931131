import { LoaderComponent } from './../../common/loader/loader.component';
import { StudyService } from './../study.service';
import { CommonService } from "./../../common.service";
import { ConfirmDialogComponent } from "./../../common/confirm-dialog/confirm-dialog.component";
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { Component, OnInit, ViewChild, OnDestroy, HostListener } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder } from "@angular/forms";
import { DatePipe } from '@angular/common';
import { LocationMappingDialogComponent } from './../location-mapping-dialog/location-mapping-dialog.component';
import { MappingReportDialogComponent } from './../mapping-report-dialog/mapping-report-dialog.component';
import { AppConfigService } from './../../app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, map } from 'rxjs';
import { GalleryService } from 'src/app/gallery/gallery.service';
import { AutoCreateDialogComponent } from '../auto-create-dialog/auto-create-dialog.component';
import { ProjectService } from 'src/app/project/project.service';
import { MatTableDataSource } from '@angular/material/table';
import { DisplayedColumns, MatDataTableComponent } from 'src/app/shared/mat-data-table/mat-data-table.component';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: "app-study-listing",
  templateUrl: "./study-listing.component.html",
  styleUrls: ["./study-listing.component.less"]
})
export class StudyListingComponent implements OnInit, OnDestroy {

  config: any;
  constructor(
    private projectService: ProjectService,
    private router: Router,
    private formBuilder: FormBuilder,
    private studyListingService: StudyService,
    public galleryService: GalleryService,
    public configService: AppConfigService,
    private dialog: MatDialog,
    private commonService: CommonService,
    public translate: TranslateService,
    private activeRoute: ActivatedRoute,
    public datepipe: DatePipe,
    private sanitizer: DomSanitizer,
    private appConfig: AppConfigService
  ) {
    this.config = configService.config;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }
  studySearchText = '';
  observable: any;
  activeRole: any;
  searchForm: any;
  searchItems: any = {};
  mainActiveTab: any;
  activeTab: any;
  dataTable: any;
  dtOptions: any;
  tableData = [];
  search: any;
  showLoader: boolean = true;
  showLoaderForMapping: boolean = false;
  noTableData: boolean = true;
  speciesArr = [];
  pathologistDataArr = [];
  selectedParam: any;
  filterData = [];
  tagsInput: any;
  myStudyCount = 0;
  peerStudyCount = 0;
  pathoCompletedStudyCount = 0;
  pathoArchivedStudyCount = 0;
  createdStudyCount = 0;
  inprogressStudyCount = 0;
  completedStudyCount = 0;
  archievedStudyCount = 0;
  technicianStudyCount = 0;
  inactiveUserStudyCount = 0;
  messageDuration = 2000;
  techinicainStudyNos: Array<any> = [];
  technicianInterval: any;
  inActiveUserList: any;
  pathologists: any;
  technicians: any;
  studyDirectors: any;
  listOfRandomizedStudy: any;
  // displayedColumns = ["studyNo", "studyTitle", "studyType", "species", "technician", "studyDirector", "studyDirectorlisting"];
  readonly CREATE_STUDY = 'Create Study';
  @ViewChild("dataTable", { static: false }) table;

  selectedStudyType = [];
  selectedStatus = [];
  tableDataBackup = [];
  studyStatuses = [
    { title: 'Completed', value: "COMPLETED" },
    { title: 'In Progress', value: "IN_PROGRESS" },
    { title: 'Created', value: "CREATED" }
  ];
  studyTypes = [
    { title: 'TCR', value: 'tcr' },
    { title: 'TOX', value: 'tox' }
  ];
  shouldHideSearch = false;

  // MAT DATA TABLE CONFIGURATIONS
  studyDataSource: MatTableDataSource<any>;
  studyDisplayedColumns: DisplayedColumns[] = [];
  @ViewChild('matDataTable') matDataTable: MatDataTableComponent;

  ngOnInit() {
    this.commonService.resetGalleryFilters();
    if (this.commonService.ProjectLSData.studyCreationType) {
      delete this.commonService.ProjectLSData.studyCreateType;
    }

    if (this.commonService.ProjectLSData.isTCRStudy) {
      delete this.commonService.ProjectLSData.isTCRStudy;
    }

    if (this.commonService.ProjectLSData.creationType) {
      delete this.commonService.ProjectLSData.creationType;
    }

    this.commonService.setProjectLSData();

    this.activeRoute.paramMap.subscribe((paramsData: any) => {
      this.selectedStatus = [];
      this.selectedStudyType = [];
      if (this.technicianInterval) {
        clearInterval(this.technicianInterval);
      }
      this.activeRole = paramsData.params.role;

      if (this.activeRole.toLowerCase() == "pathologist" || this.activeRole.toLowerCase() == "external_pathologist") {

        this.searchForm = this.formBuilder.group({
          title: [""],
          species: [""],
          /*scarifice: [''],
          stain: [""],*/
          creationDate: [""]
        });
        this.studyListingService.getAllSpecies().subscribe((res: any) => {
          this.speciesArr = res.data;
          if (this.config?.webAppConfig?.isForEpl) {
            this.studyListingService.getAllRandomizedStudies().pipe(map((res: any) => {
              let studyList = res.map((item: any) => {
                return { 'studyName': item.study, 'selectedSlides': item.slides }
              });
              return studyList;
            })).subscribe((raodomizedStudyList: any) => {
              this.listOfRandomizedStudy = raodomizedStudyList;
              this.getPathologistStudies("myStudy");
            })
          } else {
            this.getPathologistStudies("myStudy");
          }
        });
      } else if (this.activeRole.toLowerCase() == "study_director" || this.activeRole == "APPLICATION_ADMIN") {
        this.studyListingService.getAllPathologist().subscribe((response: any) => {
          this.pathologists = this.commonService.sortByMultiKey(response.data, ['displayName']);
        });
        this.studyListingService.getAllTechnician().subscribe((response: any) => {
          this.technicians = this.commonService.sortByMultiKey(response.data, ['displayName']);
        });
        this.studyListingService.getAllStudyDirector().subscribe((response: any) => {
          this.studyDirectors = this.commonService.sortByMultiKey(response.data, ['displayName']);

          // for async operation 
          let isInActive = this.activeRole === 'APPLICATION_ADMIN';
          this.getStudyDirectorStudies(isInActive);
        });
        $("#p_study_table").off();
        $("#p_study_table").on("click", "#deleteStudyIcon", e => {
          let table = this.dataTable.DataTable();
          var data = table.row($(e.currentTarget).parents("tr")).data();
          this.showDeleteStudyModel(data);
        });

        $("#p_study_table").on("click", "#editStudyIcon", e => {
          let table = this.dataTable.DataTable();
          let data = table.row($(e.currentTarget).parents("tr")).data();
          this.onEditStudyClick(data);
        });

        if (this.activeRole.toLowerCase() == "study_director") {
          $("#p_study_table").on("click", "#studyDetailIcon", e => {
            let table = this.dataTable.DataTable();
            var data = table.row($(e.currentTarget).parents("tr")).data();
            this.showDetails(data);
          });
        }

        $("#p_study_table").on("click", "#completeSudyIcon", e => {
          let table = this.dataTable.DataTable();
          var data = table.row($(e.currentTarget).parents("tr")).data();
          this.changeStudyStatus(data.studyGuid, 'COMPLETED');
        });

        $("#p_study_table").on("click", "#archiveSudyIcon", e => {
          let table = this.dataTable.DataTable();
          var data = table.row($(e.currentTarget).parents("tr")).data();
          this.changeStudyStatus(data.studyGuid, 'ARCHIVED');
        });

        $("#p_study_table").on("click", "#inProgressSudyIcon", e => {
          let table = this.dataTable.DataTable();
          var data = table.row($(e.currentTarget).parents("tr")).data();
          this.changeStudyStatus(data.studyGuid, 'IN_PROGRESS');
        });
      } else if (this.activeRole.toLowerCase() == "technician") {
        this.getTechnicianStudies();
      }
    });
  }

  onEditStudyClick(rowData: any) {
    this.commonService.ProjectLSData.isAdmin = this.activeRole == 'APPLICATION_ADMIN';
    this.commonService.isMinimalTemplate = rowData.minimal;
    this.commonService.ProjectLSData.inActiveRoles = rowData?.inActiveRoles;
    this.commonService.setProjectLSData();
    this.commonService.changePlace("study/" + this.activeRole + "/edit/" + rowData.studyGuid);
    this.router.navigate(["/dashboard/study/" + this.activeRole + "/edit/" + rowData.studyGuid]);
  }

  navigateToCreateStudy(paramValue, manualAuto) {
    if (paramValue) {
      this.commonService.ProjectLSData.studyCreationType = paramValue;
    }
    if (manualAuto) {
      this.commonService.ProjectLSData.creationType = manualAuto;
    }
    this.commonService.setProjectLSData();
    this.commonService.changePlace("study/" + this.activeRole + "/create");
    this.router.navigate(["/dashboard/study/" + this.activeRole + "/create"]);
  }

  showDeleteStudyModel(studyData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: "Delete Study",
      confirmMsg: this.translate.instant("GENERIC_MSG.STUDY.DELETE_STUDY"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        dialogConfig.width = "auto";
        dialogConfig.height = "auto";
        dialogConfig.disableClose = true;
        dialogConfig.data = {
          'Msg': "Deleting..."
        };
        const loaderDialogRef = this.dialog.open(LoaderComponent, dialogConfig);
        this.studyListingService.deleteStudy(studyData.studyGuid).subscribe((res: any) => {
          loaderDialogRef.close();
          this.commonService.showToastMsg(res.message);
          this.commonService.ProjectLSData.userActiveRole?.toLowerCase() === 'technician' ? this.getTechnicianStudies() : this.getStudyDirectorStudies();
          //});
        }, (err) => {
          loaderDialogRef.close();
        });
      }
    });
  }

  showHideFilter() {
    if ($(".multiselectfilter").is(":visible")) {
      $(".multiselectfilter").hide();
      this.clearFilterFields();
      if (this.tagsInput.tagsinput("items").length == 0) {
        this.tagsInput.tagsinput("destroy");
      }
    } else {
      $(".multiselectfilter").show();
      this.tagsInput = $(".tags");
      if ($('.bootstrap-tagsinput').length > 0) {
        $('.bootstrap-tagsinput').css('display', 'block');
        $('#studysearch').css('display', 'none');
      } else {
        this.tagsInput.tagsinput({
          itemValue: "criteria",
          itemText: "value",
          maxChars: 100,
          trimValue: false
        });
      }
      this.setData();
      this.generateFilterDataTable();
      this.tagsInput.on("itemRemoved", event => {
        if (this.tagsInput.tagsinput("items").length == 0) {
          //this.tagsInput.tagsinput("destroy");
          $('.bootstrap-tagsinput').css('display', 'none');
          $('#studysearch').css('display', 'block');
        }
        this.setData();
        this.generateFilterDataTable();
      });
    }
  }

  onMatDataTableLoaded() {
    $("#studysearch").on("keyup click", e => {
      this.matDataTable.applySearch((e.target as HTMLInputElement).value);
    });
  }

  onSearch() {
    this.tagsInput.tagsinput("removeAll");
    for (var prop in this.searchForm.value) {
      if (
        this.searchForm.value.hasOwnProperty(prop) &&
        this.searchForm.value[prop]
      ) {
        var innerObj = {};
        innerObj["criteria"] = prop;
        innerObj["value"] = this.searchForm.value[prop];
        this.tagsInput.tagsinput("add", innerObj);
      }
    }
    if (this.tagsInput.tagsinput("items").length == 0) {
      this.tagsInput.tagsinput("destroy");
    }
    this.generateFilterDataTable();
    this.clearFilterFields();
    $(".multiselectfilter").toggle();
  }

  generateFilterDataTable() {
    this.showLoader = true;
    if (this.dataTable) {
      this.dataTable.DataTable().destroy();
      this.dataTable.empty();
    }
    let formValues = this.searchForm.value;
    if (formValues.title && formValues.species && formValues.creationDate) {
      this.filterData = this.pathologistDataArr.filter(elem => {
        let currCreationDate = this.datepipe.transform(elem.creationDate, 'yyyy-MM-dd');
        return elem.studyNo && elem.studyNo.toLowerCase().includes(formValues.title.toLowerCase()) &&
          elem.species && elem.species.toLowerCase() == formValues.species.toLowerCase() &&
          new Date(currCreationDate).getTime() == new Date(formValues.creationDate).getTime();
      });
    } else if (formValues.title && formValues.species) {
      this.filterData = this.pathologistDataArr.filter(elem => {
        return elem.studyNo && elem.studyNo.toLowerCase().includes(formValues.title.toLowerCase()) &&
          elem.species && elem.species.toLowerCase() == formValues.species.toLowerCase()
      });
    } else if (formValues.title && formValues.creationDate) {
      this.filterData = this.pathologistDataArr.filter(elem => {
        let currCreationDate = this.datepipe.transform(elem.creationDate, 'yyyy-MM-dd');
        return elem.studyNo && elem.studyNo.toLowerCase().includes(formValues.title.toLowerCase()) &&
          new Date(currCreationDate).getTime() == new Date(formValues.creationDate).getTime();
      });
    } else if (formValues.species && formValues.creationDate) {
      this.filterData = this.pathologistDataArr.filter(elem => {
        let currCreationDate = this.datepipe.transform(elem.creationDate, 'yyyy-MM-dd');
        return elem.species && elem.species.toLowerCase() == formValues.species.toLowerCase() &&
          new Date(currCreationDate).getTime() == new Date(formValues.creationDate).getTime();
      });
    } else if (formValues.title || formValues.species || formValues.creationDate) {
      this.filterData = this.pathologistDataArr.filter(elem => {
        let currCreationDate = this.datepipe.transform(elem.creationDate, 'yyyy-MM-dd');
        return ((formValues.title && elem.studyNo && elem.studyNo.toLowerCase().includes(formValues.title.toLowerCase())) ||
          (formValues.species && elem.species && elem.species.toLowerCase() == formValues.species.toLowerCase()) ||
          (formValues.creationDate && new Date(currCreationDate).getTime() == new Date(formValues.creationDate).getTime()));
      });
    } else {
      this.filterData = this.pathologistDataArr;
    }
    this.dtOptions = {
      "bDestroy": true,
      data: this.filterData,
      responsive: true,
      paging: false,
      info: false,
      scrollCollapse: true,
      order: [],
      language: {
        zeroRecords: 'No data to display'
      },
      columns: [
        {
          title: "<span style='color:#10458b'>#</span>",
          data: "",
          sWidth: "3%",
          bSearchable: false,
          bSortable: false,
          render: function (data: any, type: any, row: any, meta: any) {
            if (row.status == "IN_PROGRESS") {
              return "<a class='imgicontable' id='galleryIcon'><i class='fa fa-picture-o imageicon' title='Open Gallery'></i></a>";
            } else {
              return "";
            }
          }
        },
        {
          title: "Study No.", data: "studyNo", sWidth: "13%",
          render: (data) => {
            let escapeData: any = this.commonService.escapeOutput(data);
            return "<div id='studyDetailIcon' class='orangeText' title='" + escapeData + "'>" + escapeData + "</div>"
          }
        },
        /* {
          title: "Study Title", data: "studyTitle", sWidth: "12%",
          render: (data) => {
            if (data) {
              let escapeData: any = this.commonService.escapeOutput(data);
              return "<div class='studyTitle' title='" + escapeData + "'>" + escapeData + "</div>"
            } else {
              return "--"
            }
          }
        }, */
        {
          title: "Status", data: "status", sWidth: "12%",
          render: (data: any) => {
            //return this.commonService.camelCase(data).replace("_", " ");
            if (data == 'CREATION_DONE') {
              return (
                '<span title="Created">Created</span>'
              );
            } else if (data == 'CREATION_ONGOING') {
              return (
                '<span title="Draft">Draft</span>'
              );
            } else if (data == 'IN_PROGRESS') {
              return (
                '<span title="In Progress">In Progress</span>'
              );
            } else {
              return (
                '<span title="' + this.commonService.camelCase(data).replace("_", " ") + '">' + this.commonService.camelCase(data).replace("_", " ") + '</span>'
              );
            }
          }
        },
        /* { title: 'Sacrifice', data: 'sacrifice' }, */
        {
          title: "Creation Date", sWidth: "15%",
          data: "creationDate",
          render: (data) => {
            return "<span style='display:none'>" + this.datepipe.transform(data, 'yyyyMMddHHmmss') + "</span>" + this.datepipe.transform(data, 'dd/MM/yyyy hh:mm a');
          }
        },
        {
          title: "Slides Mapped", data: "mappedImagesCount", sWidth: "14%",
          render: function (data: any, type: any, row: any, meta: any) {
            return "<span>" + row.mappedImagesCount + " / " + (row.mappedImagesCount + row.unmappedImagesCount) + "</span>"
          }
        },
        /* { title: "Unmapped", data: "unmappedImagesCount", sWidth: "12%" }, */
        { title: "Species", data: "species", sWidth: "13%" },
        /* { title: 'Stain', data: 'stain' }, */
        {
          title: "Peer Reviewer",
          data: "peerReviewer",
          sWidth: "18%",
          visible: this.selectedParam == "myStudy",
          render: (data) => {
            if (data) {
              let escapeData: any = this.commonService.escapeOutput(data);
              return "<div class='textEllipsis' title='" + escapeData + "'>" + escapeData + "</div>";
            }
            else return "";
          }
        },
        {
          title: "Pathologist",
          data: "pathologist",
          visible: this.selectedParam == "peerReviewer",
          sWidth: "15%",
          render: (data) => {
            if (data) {
              let escapeData: any = this.commonService.escapeOutput(data);
              return "<div class='textEllipsis' title='" + escapeData + "'>" + escapeData + "</div>";
            }
            else return "";
          }
        }
      ]
    }
    if (this.filterData.length > 0) {
      this.noTableData = false
      this.dataTable = $(this.table.nativeElement);
      this.dataTable.DataTable(this.dtOptions);
    } else {
      this.noTableData = true;
      this.dataTable = '';
    }
    this.showLoader = false;
  }

  onCancel() {
    if ($(".tags").tagsinput("items").length == 0) {
      $(".tags").tagsinput("destroy");
    }
    this.clearFilterFields();
    $(".multiselectfilter").toggle();
  }

  onReset() {
    this.clearFilterFields();
    this.tagsInput.tagsinput("removeAll");
    this.generateFilterDataTable();
  }

  setData() {
    this.searchItems = {};
    let tagArr = $(".tags").tagsinput("items");
    for (let i = 0; i < tagArr.length; i++) {
      this.searchItems[tagArr[i].criteria] = tagArr[i].value;
    }
    this.searchForm.setValue({
      title: this.searchItems.title ? this.searchItems.title : "",
      species: this.searchItems.species ? this.searchItems.species : "",
      /*scarifice: this.searchItems.scarifice ? this.searchItems.scarifice : '',*/
      /* stain: this.searchItems.stain ? this.searchItems.stain : "", */
      creationDate: this.searchItems.creationDate
        ? this.searchItems.creationDate
        : ""
    });
  }

  clearFilterFields() {
    this.searchForm.setValue({
      title: "",
      species: "",
      /*scarifice: '',
      stain: "",*/
      creationDate: ""
    });
  }

  getPathologistStudies(paramValue) {
    this.showLoader = true;
    this.studyListingService.getPathologistStudyCount().subscribe((res: any) => {
      this.myStudyCount = res.data.myStudy;
      this.peerStudyCount = res.data.peerReviewStudy;
      this.pathoCompletedStudyCount = res.data.completed;
      this.pathoArchivedStudyCount = res.data.archived;
      this.selectedParam = paramValue;
      if ($('.bootstrap-tagsinput').length > 0) {
        if ($(".multiselectfilter").is(":visible")) {
          $(".multiselectfilter").hide();
          this.clearFilterFields();
        }
        this.tagsInput.tagsinput('removeAll');
        $('.bootstrap-tagsinput').css('display', 'none');
        $('#studysearch').css('display', 'block');
      }
      $("#studysearch").val("");

      if (paramValue == "myStudy") {
        this.activeTab = "myStudy";
        this.observable = this.studyListingService.getMyStudies(this.activeRole);
      } else if (paramValue == "peerReviewer") {
        this.activeTab = "peerReview";
        this.observable = this.studyListingService.getPeerStudies();
      } else if (paramValue == "pathoCompleted") {
        this.activeTab = "pathoCompleted";
        this.observable = this.studyListingService.getStudylisting(10, 'PATHOLOGIST');
      } else if (paramValue == "pathoArchived") {
        this.activeTab = "pathoArchived";
        this.observable = this.studyListingService.getStudylisting(25, 'PATHOLOGIST');
      }
      this.observable.subscribe((response: any) => {
        if (paramValue == 'myStudy' && this.config?.webAppConfig?.isForEp && this.listOfRandomizedStudy?.length) {
          response.data.forEach((item: any) => {
            // if (this.listOfRandomizedStudy.includes(item.studyGuid)) {
            //   item.isRandomized = true;
            // }

            for (let a = 0; a < this.listOfRandomizedStudy?.length; a++) {
              if (item.studyGuid == this.listOfRandomizedStudy[a].studyName) {
                item.isRandomized = true;
              }
            }

          })
        }
        // response.dataisUserAddi
        this.pathologistDataArr = response.data;
        this.tableData = [...response.data];
        this.tableDataBackup = response.data.map(d => {
          d['filterStatus'] = (d.status === 'CREATION_DONE' || d.status === 'CREATION_ONGOING') ? 'CREATED' : d['status'];
          d['filterStudyType'] = d.isTCRStudy ? 'tcr' : 'tox';
          return d;
        });

        this.studyDataSource = new MatTableDataSource(response.data.map(data => {
          let iconsHTML = '';
          data.slidesMapped = data.mappedImagesCount + " / " + (data.mappedImagesCount + data.unmappedImagesCount);
          data.studyNoDup = this.sanitizer.bypassSecurityTrustHtml(data.isRandomized ? data.studyNoDup = "<div id='studyDetailIcon' class='disabledStudy' title='" + data.studyNo + "' data-guid='" + data.studyGuid + "'>" + data.studyNo + "</div>" : data.studyNoDup = "<div id='studyDetailIcon' class='orangeText' title='" + data.studyNo + "' data-guid='" + data.studyGuid + "'>" + data.studyNo + "</div>");

          if (data.status === 'CREATION_DONE') {
            data.studyStatus = 'Created';
          } else if (data.status === 'CREATION_ONGOING') {
            data.studyStatus = 'Draft';
          } else if (data.status === 'IN_PROGRESS') {
            data.studyStatus = 'In Progress';
          } else {
            data.studyStatus = this.commonService.camelCase(data.status).replace("_", " ");
          }

          if (data.status == "IN_PROGRESS" && paramValue == "myStudy") {
            iconsHTML = "<a class='imgicontable' id='galleryIcon'><i id='openGalleryIcon' class='fa fa-picture-o imageicon' title='Open Gallery'></i></a>";
          } else if (paramValue == "pathoCompleted") {
            iconsHTML = "<a class='imgicontable' id='pathoInProgressSudyIcon'><i id='pathoInProgressSudyIcon' class='fa fa-tasks imageicon' title='In Progress Study'></i></a>";
          }

          data.icons = this.sanitizer.bypassSecurityTrustHtml(iconsHTML);

          data.misc = { contentType: 'iconsGroup', items: [], content: '' };
          if (this.appConfig?.config?.webAppConfig?.hasExternalShareForPathologist && data?.pathologist == this.commonService.ProjectLSData.currentUser?.email) {
            data.misc.items.push({ svgIcon: 'shareIcon', class: 'shareStudy', id: 'shareBtn', title: 'Share' });
          }
          // if (!data?.minimal) {
          data.misc.items.push({ svgIcon: 'viewRptIcon', class: 'viewReport', id: 'reportBtn', title: 'View Report' });
          // }
          return data;
        }));

        this.studyDisplayedColumns = [
          { key: 'icons', value: '', isVisible: true, width: '6%', cellType: 'template', isSortDisabled: true },
          { key: 'studyNoDup', value: 'Study No.', isVisible: true, width: '10%', cellType: 'template' },
          { key: 'studyDirector', value: 'Study Director', isVisible: true, width: '15%', cellType: 'normal' },
          { key: 'technician', value: 'Technician', isVisible: true, width: '15%', cellType: 'normal' },
          { key: 'slidesMapped', value: 'Slides Mapped', isVisible: true, width: '10%', cellType: 'normal' },
          { key: 'species', value: 'Species', isVisible: true, width: '10%', cellType: 'normal' },
          { key: 'studyStatus', value: 'Status', isVisible: true, width: '10%', cellType: 'normal' },
          { key: 'misc', value: '', width: '5%', isVisible: true, cellType: 'menu', isSortDisabled: true }
        ];
      }, (err) => {
        this.noTableData = true;
        this.showLoader = false;
      }, () => {
        if (this.tableData.length > 0) {
          this.noTableData = false;
        } else {
          this.noTableData = true;
        }
        this.showLoader = false;
      });
    }, (error: any) => {
      this.noTableData = true;
      this.showLoader = false;
    });
  }

  getTechnicianStudies() {
    this.techinicainStudyNos = [];
    this.showLoader = true;

    this.studyListingService
      .getTechnicianStudies()
      .subscribe((response: any) => {
        response.data.forEach(study => {
          if (study.thumbnailGenStatus) {
            this.techinicainStudyNos.push(study.studyGuid);
          }
        });
        this.technicianInterval = setInterval(() => {
          if (this.techinicainStudyNos.length > 0) {
            let data = { "studyGuids": this.techinicainStudyNos };
            this.studyListingService.getStudyThumbnailGenerationStatus(data).subscribe((res: any) => {
              if (res.data) {
                //let flag = false;
                for (const property in res.data) {
                  if (!res.data[property]) {
                    var studyData = this.matDataTable.dataSource?.data?.map((data: any) => {
                      return { ...data, studyGuid: property }
                    });
                    studyData[0].thumbnailGenStatus = res.data[property];
                    let index = this.techinicainStudyNos.indexOf(property);
                    this.techinicainStudyNos.splice(index);
                  }
                }
                /* if (flag) {
                  //this.getTechnicianStudies();
                } */
              }
            }, (err: any) => {
              // if (err.status != 401 && err.status != 400) {
              if (this.technicianInterval) {
                clearInterval(this.technicianInterval);
              }
              // }
            });
          }
        }, this.config?.galleryConfig?.getStudyThumbnailGenerationStatus ?? 50000);
        this.technicianStudyCount = response.data.length;
        this.tableData = [...response.data];
        this.tableDataBackup = response.data.map(d => {
          d['filterStatus'] = (d.status === 'CREATION_DONE' || d.status === 'CREATION_ONGOING') ? 'CREATED' : d['status'];
          d['filterStudyType'] = d.isTCRStudy ? 'tcr' : 'tox';
          return d;
        });

        this.studyDataSource = new MatTableDataSource(response.data.map(data => {
          let iconsHTML = '';
          data.slidesMapped = data.mappedImagesCount + " / " + (data.mappedImagesCount + data.unmappedImagesCount);
          data.studyNoDup = this.sanitizer.bypassSecurityTrustHtml("<div id='studyDetailIcon' class='orangeText' title='" + data.studyNo + "' data-guid='" + data.studyGuid + "'>" + data.studyNo + "</div>");

          if (data.status === 'CREATION_DONE') {
            data.studyStatus = 'Created';
            iconsHTML += "<a class='imgicontable' id='deleteStudyIcon' style='order:3'><i id='deleteIcon' class='fa fa-trash imageicon' title='Delete'></i></a>";
          } else if (data.status === 'CREATION_ONGOING') {
            data.studyStatus = 'Draft';
            iconsHTML += "<a class='imgicontable' id='deleteStudyIcon' style='order:3'><i id='deleteIcon' class='fa fa-trash imageicon' title='Delete'></i></a>";
          } else if (data.status === 'IN_PROGRESS') {
            data.studyStatus = 'In Progress';
            iconsHTML += "<a class='imgicontable' id='galleryIcon' style='order:1'><i id='openGalleryIcon' class='fa fa-picture-o imageicon' title='Open Gallery'></i></a>";
            // [{ contentType: hjyml, cvontent: innerhtml }, { ct: svg, content: 'imgicon' }]
          } else {
            data.studyStatus = this.commonService.camelCase(data.status).replace("_", " ");
          }

          if (data?.inActiveRoles?.length && (data.status === 'CREATION_ONGOING' || data.status === 'CREATION_DONE')) {
            iconsHTML += "<a class='imgicontable' id='editStudyIcon' style='order:1'><i id='editIcon' class='fa fa-warning imageicon warningIcon' title='Action Required'></i></a>";
          } else if (data.status === 'CREATION_ONGOING' || data.status === 'CREATION_DONE') {
            iconsHTML += "<a class='imgicontable editIcon' id='editStudyIcon' style='order:1'><i id='editIcon' style='font-size: 1.15vw' class='fa fa-edit imageicon' title='Edit'></i></a>";
          }

          data.icons = this.sanitizer.bypassSecurityTrustHtml(iconsHTML);

          if (data.unmappedImagesCount === 0 && data.mappedImagesCount === 0) {
            data.misc = { contentType: 'template', items: [], content: '' };
          } else if (data.unmappedImagesCount === 0) {
            data.misc = { contentType: 'iconsGroup', items: [{ svgIcon: 'viewRptIcon', class: 'viewReport', id: 'reportBtn', title: 'View Report' }], content: '' };
          } else {
            data.misc = { contentType: 'iconsGroup', items: [{ svgIcon: 'viewRptIcon', class: 'viewReport', id: 'reportBtn', title: 'View Report' }], content: '' };
            data.iconGroupContent = [{ svgIcon: 'imageMapIcon', class: 'mapImage', id: 'mapBtn', title: 'Sync Images', style: { order: 2 } }]
          }
          return data;
        }));

        this.studyDisplayedColumns = [
          { key: 'icons', value: '', isVisible: true, width: '6%', cellType: 'template', isSortDisabled: true },
          { key: 'studyNoDup', value: 'Study No.', isVisible: true, width: '11%', cellType: 'template' },
          { key: 'studyDirector', value: 'Study Director', isVisible: true, width: '18%', cellType: 'normal' },
          { key: 'pathologist', value: 'Pathologist', isVisible: true, width: '18%', cellType: 'normal' },
          { key: 'slidesMapped', value: 'Slides Mapped', isVisible: true, width: '9%', cellType: 'normal' },
          { key: 'species', value: 'Species', isVisible: true, width: '8%', cellType: 'normal' },
          { key: 'studyStatus', value: 'Status', isVisible: true, width: '7%', cellType: 'normal' },
          { key: 'misc', value: '', width: '5%', isVisible: true, cellType: 'menu', isSortDisabled: true }
        ];
      }, (err) => {
        this.noTableData = true;
        this.showLoader = false;
      }, () => {
        if (this.tableData.length > 0) {
          this.noTableData = false;
        } else {
          this.noTableData = true;
        }
        this.showLoader = false;
      });
  }

  handleMatDataTableClick(rowData: any) {
    if (this.activeRole?.toLowerCase() === 'technician') {
      this.handleTechnicianEvents(rowData);
    } else if (this.activeRole?.toLowerCase() === 'pathologist') {
      this.handlePathologistEvents(rowData);
    } else if (this.activeRole?.toLowerCase() === 'study_director' || this.activeRole?.toLowerCase() === 'application_admin') {
      this.handleDirectorOrAdminEvents(rowData);
    }
  }

  openGallery(rowData: any) {
    // if(rowData.currUserAdditionalPathologist){
    this.commonService.ProjectLSData.currUserAdditionalPathologist = !!rowData.currUserAdditionalPathologist;
    this.commonService.setProjectLSData();
    // }
    let galleryFilter = this.commonService.ProjectLSData.galleryFilters;
    if (galleryFilter.studyName != rowData.studyGuid) {
      galleryFilter.studyName = rowData.studyGuid;
      galleryFilter.isTCRStudy = rowData.isTCRStudy;
      galleryFilter.stains = [];
      galleryFilter.organ = [];
      galleryFilter.animalNo = [];
      galleryFilter.control = [];
    }
    this.commonService.ProjectLSData.galleryFilters = galleryFilter;
    this.commonService.ProjectLSData.isTCRStudy = rowData.isTCRStudy;
    this.commonService.setProjectLSData();
    if (this.commonService.ProjectLSData?.userActiveRole == 'TECHNICIAN') {
      this.router.navigate(["/dashboard/mapped/study/gallery"]);
    } else {
      if (this.commonService.ProjectLSData['randomisedStudies']?.length) {
        for (let a = 0; a < this.commonService.ProjectLSData['randomisedStudies']?.length; a++) {
          if (galleryFilter.studyName == this.commonService.ProjectLSData['randomisedStudies'][a].studyName) {
            this.commonService.ProjectLSData.isRandomisationStarted = true;
            // this.commonService.ProjectLSData.randomisedSlideIds = this.commonService.ProjectLSData['randomisedStudies'][a]?.selectedSlides;
            this.commonService.ProjectLSData.randomisedSlideIds = this.commonService.ProjectLSData['randomisedStudies'][a]?.selectedSlides.map((item: any) => {
              return item.slideId;
            });
          }
          else {
            this.commonService.ProjectLSData.isRandomisationStarted = false;
            this.commonService.ProjectLSData.randomisedSlideIds = [];
          }
        }
      }
      this.commonService.ProjectLSData.galleryFilters = galleryFilter;
      this.commonService.ProjectLSData.isTCRStudy = rowData.isTCRStudy;
      this.commonService.setProjectLSData();
      //this.commonService.changePlace("gallery");
      this.router.navigate(["/dashboard/user/study/gallery"]);
    }
    // this.router.navigate(["/dashboard/mapped/study/gallery"]);
  }

  handleDirectorOrAdminEvents(rowData: any) {
    switch (rowData.elementId) {
      case 'studyDetailIcon':
        // this.openGallery(rowData);
        this.showDetails(rowData);
        break;
      case 'editIcon':
        this.onEditStudyClick(rowData);
        break;
      case 'deleteIcon':
        this.showDeleteStudyModel(rowData);
        break;
      case 'saveReassignedUser':
        this.saveReassignedData(rowData);
        break;
      default:
        break;
    }
  }

  handlePathologistEvents(rowData: any) {
    switch (rowData.elementId) {
      case 'openGalleryIcon':
        this.openGallery(rowData);
        break;
      case 'studyDetailIcon':
        this.showDetails(rowData);
        break;
      case 'shareBtn':
        this.shareStudy(rowData);
        break;
      case 'reportBtn':
        this.commonService.ProjectLSData['studyId'] = rowData.id;
        this.commonService.setProjectLSData();
        if (this.activeRole.toLowerCase() === 'pathologist') {
          this.commonService.ProjectLSData['studyForReport'] = {
            'studyGuid': rowData?.studyGuid,
            'studyNo': rowData?.studyNo
          };
          this.commonService.setProjectLSData();
          this.router.navigate(['dashboard/report']);
        } else {
          this.mapReport(rowData);
        }
        break;
      case 'pathoInProgressSudyIcon':
        this.changeStudyStatus(rowData.studyGuid, 'IN_PROGRESS');
        break;
      default:
        break;
    }
  }

  handleTechnicianEvents(rowData: any) {
    switch (rowData.elementId) {
      case 'openGalleryIcon':
        this.openGallery(rowData);
        break;
      case 'editIcon':
        this.onEditStudyClick(rowData);
        break;
      case 'deleteIcon':
        this.showDeleteStudyModel(rowData);
        break;
      case 'studyDetailIcon':
        this.showDetails(rowData);
        break;
      case 'reportBtn':
        this.mapReport(rowData);
        break;
      case 'mapBtn':
        this.mapImage(rowData, "start");
        break;
      default:
        break;
    }
  }

  navigateScanned(data) {
    alert(data);
  }

  resetFilter() {
    this.selectedStatus = [];
    this.selectedStudyType = [];
    this.activeRole === 'STUDY_DIRECTOR' ? this.getStudyDirectorStudies() : this.getTechnicianStudies();
  }

  applyFilter() {
    const filteredData = this.tableDataBackup.filter(item => (this.selectedStatus.length === 0 || this.selectedStatus.includes(item.filterStatus)) && (this.selectedStudyType.length === 0 || this.selectedStudyType.includes(item.filterStudyType)));
    // if no filters are selected and user clicks on apply button, populate initially loaded data in the table
    if (this.selectedStatus.length === 0 && this.selectedStudyType.length === 0) {
      this.studyDataSource.data = [...this.tableDataBackup];
    } else {
      this.studyDataSource.data = [...filteredData];
    }

    this.showLoader = false;
    // on applying filters, if there is a search term present then programmatically apply search
    if (this.search) {
      this.matDataTable.applySearch(this.search);
    }
  }

  getStudyDirectorStudies(isInActive = false) {
    this.mainActiveTab = isInActive ? 'reassign_role' : 'study_listing';
    this.showLoader = true;

    if (isInActive) {
      this.observable = this.activeRole == 'APPLICATION_ADMIN' ? this.studyListingService.getStudyDirectorsStudy() : this.studyListingService.getInActiveStudyList();
    } else {
      this.observable = this.studyListingService.getStudylisting(null, this.activeRole);
    }

    this.observable.subscribe((response: any) => {
      this.tableData = [...response.data];
      this.tableDataBackup = response.data.map(d => {
        d['filterStatus'] = (d.status === 'CREATION_DONE' || d.status === 'CREATION_ONGOING') ? 'CREATED' : d['status'];
        d['filterStudyType'] = d.isTCRStudy ? 'tcr' : 'tox';
        return d;
      });
      this.shouldHideSearch = isInActive && !this.tableData.length;

      this.studyDataSource = new MatTableDataSource(response.data.map(data => {
        let iconsHTML = '';
        let escapeData: any = this.commonService.escapeOutput(data.studyNo);

        data.mappedImagesCount = data.mappedImagesCount ?? 0;
        data.unmappedImagesCount = data.unmappedImagesCount ?? 0;
        data.slidesMapped = data.mappedImagesCount + " / " + (data.mappedImagesCount + data.unmappedImagesCount);
        data.studyNoDup = this.sanitizer.bypassSecurityTrustHtml("<div id='studyDetailIcon' " + ((this.activeRole != 'APPLICATION_ADMIN') ? "class='orangeText' " : "") + "title='" + escapeData + "'>" + escapeData + "</div>");
        data.studyStatus = '';
        if (isInActive) {
          data.previousStudyDirector = data.user;
        }

        if (data.status === 'CREATION_DONE') {
          data.studyStatus = 'Created';
          iconsHTML += "<a class='imgicontable' id='deleteStudyIcon' style='order:2'><i id='deleteIcon' class='fa fa-trash imageicon' title='Delete'></i></a>";
        } else if (data.status === 'CREATION_ONGOING') {
          data.studyStatus = 'Draft';
          iconsHTML += "<a class='imgicontable' id='deleteStudyIcon' style='order:2'><i id='deleteIcon' class='fa fa-trash imageicon' title='Delete'></i></a>";
        } else if (data.status === 'IN_PROGRESS') {
          data.studyStatus = 'In Progress';
        }
        else if (data.status === 'COMPLETED') {
          data.studyStatus = 'Completed';
        }

        if (data?.inActiveRoles?.length && (data.status === 'CREATION_ONGOING' || data.status === 'CREATION_DONE' || data.status === 'IN_PROGRESS')) {
          iconsHTML += "<a class='imgicontable' id='editStudyIcon' style='order:1'><i id='editIcon' class='fa fa-warning imageicon warningIcon' title='Action Required'></i></a>";
        } else if (data.status === 'CREATION_ONGOING' || data.status === 'CREATION_DONE' || data.status === 'IN_PROGRESS') {
          iconsHTML += "<a class='imgicontable editIcon' id='editStudyIcon' style='order:1'><i id='editIcon' style='font-size: 1.15vw' class='fa fa-edit imageicon' title='Edit'></i></a>";
        }

        data.icons = this.sanitizer.bypassSecurityTrustHtml(iconsHTML);

        data.reassign = { label: 'Select User', options: [], appearance: 'outline' };
        if (data.role == 'PATHOLOGIST' || data.role == 'PRIMARY_INVESTIGATOR' || data.role == 'PEER_REVIEWER' || data.role == 'ADDITIONAL_PATHOLOGIST') {
          data.reassign.options = this.pathologists.map(p => { return { name: p.displayName, value: p.userName } });
        } else if (data.role == 'TECHNICIAN') {
          data.reassign.options = this.technicians.map(t => { return { name: t.displayName, value: t.userName } });
        } else if (data.role == 'STUDY_DIRECTOR') {
          data.reassign.options = this.studyDirectors.map(sd => { return { name: sd.displayName, value: sd.userName } });
        }

        data.misc = { contentType: 'template', items: [], content: this.sanitizer.bypassSecurityTrustHtml(`<button data-guid="${data.studyGuid}" class="btn-default searchbtn disableFunction" id="saveReassignedUser">Save</button>`) };

        return data;
      }));

      this.studyDisplayedColumns = [
        { key: 'icons', value: '', isVisible: this.activeRole !== 'APPLICATION_ADMIN', width: '6%', cellType: 'template', isSortDisabled: true },
        { key: 'studyNoDup', value: 'Study No.', isVisible: true, width: '11%', cellType: 'template' },
        { key: 'technician', value: 'Technician', isVisible: !isInActive, width: '18%', cellType: 'normal' },
        { key: 'previousStudyDirector', value: 'Previous Study Director', isVisible: isInActive, width: '15%', cellType: 'normal' },
        { key: 'pathologist', value: 'Pathologist', isVisible: !isInActive, width: '18%', cellType: 'normal' },
        { key: 'creationDate', value: 'Creation Date', isVisible: isInActive && this.activeRole != 'APPLICATION_ADMIN', width: '15%', cellType: 'normal' },
        { key: 'slidesMapped', value: 'Slides Mapped', isVisible: !isInActive, width: '9%', cellType: 'normal' },
        { key: 'species', value: 'Species', isVisible: !isInActive, width: '6%', cellType: 'normal' },
        { key: 'studyStatus', value: 'Status', isVisible: !isInActive, width: '7%', cellType: 'normal' },
        { key: 'reassign', value: 'Reassign', isVisible: this.activeRole === 'APPLICATION_ADMIN', width: '13%', cellType: 'form-field', isSortDisabled: true },
        { key: 'misc', value: '', isVisible: this.activeRole === 'APPLICATION_ADMIN', width: '5%', cellType: 'menu', isSortDisabled: true }
      ];
    }, (err) => {
      this.noTableData = true;
      this.showLoader = false;
    }, () => {
      if (this.tableData.length > 0) {
        this.noTableData = false;
      } else {
        this.noTableData = true;
      }
      this.showLoader = false;
    });
  }

  onFormFieldValueChange(data: any) {
    // handle reassignment case
    if (this.activeRole === 'APPLICATION_ADMIN') {
      // replace current user with selected user
      data.user = data.fieldValue;
      // modify the content of the button for the selected row because if kept unchanged then the button would get disabled again even after removing the class using js
      data.misc['content'] = this.sanitizer.bypassSecurityTrustHtml(`<button data-guid="${data.studyGuid}" class="btn-default searchbtn" id="saveReassignedUser">Save</button>`);
      document.querySelector(`[data-guid="${data.studyGuid}"]`)?.classList?.remove('disableFunction');
    }
  }

  showDetails(data: any) {
    this.commonService.ProjectLSData.isTCRStudy = data.isTCRStudy;
    this.commonService.ProjectLSData['studyId'] = '';
    this.commonService.ProjectLSData['studyId'] = data?.id;
    this.commonService.ProjectLSData['studyStaus'] = data?.status;
    this.commonService.setProjectLSData();
    this.commonService.changePlace("study/" + this.activeRole + "/details/" + data.studyGuid);
    this.router.navigate(["/dashboard/study/" + this.activeRole + "/details/" + data.studyGuid]);
  }

  mapImage(studydata, param) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    // dialogConfig.width = "35%";
    dialogConfig.width = "75%";
    dialogConfig.height = "80%";
    dialogConfig.data = { "locationMapping": param, "studyData": studydata }
    const dialogref = this.dialog.open(LocationMappingDialogComponent, dialogConfig);
    dialogref.componentInstance.onClose.subscribe((result: any) => {
      studydata.thumbnailGenStatus = true;
      // this.showLoader = true;
      this.showLoaderForMapping = true;
      // var parentTr = $(target).parents("tr");
      // this.dataTable.dataTable().fnUpdate(studydata, ($(parentTr)), undefined, false);
      setTimeout(() => {
        this.studyListingService.addLocatioMapping(result).subscribe((res: any) => {
          this.commonService.showToastMsg(res.message);
          // this.showLoader = false;
          this.showLoaderForMapping = false;
          studydata.mappedImagesCount = res.data.mappedSlideCount;
          studydata.unmappedImagesCount = res.data.unMappedSlideCount;
          //studydata.thumbnailGenStatus = true;
          studydata.status = res.data.studyStatus;
          this.techinicainStudyNos.push(studydata.studyGuid);
          this.getTechnicianStudies();
          // this.dataTable.dataTable().fnUpdate(studydata, ($(parentTr)), undefined, false);
        },
          e => {
            this.showLoaderForMapping = false;
            this.showLoader = false;
            // this.dataTable.dataTable().fnUpdate(studydata, ($(parentTr)), undefined, false);
          });
      }, 100);
    })

  }

  mapReport(studydata: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "60%";
    dialogConfig.data = { "studyData": studydata }
    this.dialog.open(MappingReportDialogComponent, dialogConfig);
  }

  shareStudy(studydata: any) {
    this.projectService.shareAndStopsharing(studydata.id, this.ngOnInit.bind(this), 'STUDY');
  }

  saveReassignedData(dataObj) {
    // delete manually created sanitized html to prevent the system to send it to the server
    Object.keys(dataObj).forEach((key) => {
      if (['icons', 'studyNoDup', 'misc', 'reassign', 'elementId', 'filterStudyType'].includes(key)) {
        delete dataObj[key];
      }
    });

    this.studyListingService.reassignUser(dataObj).subscribe((res: any) => {
      this.commonService.showToastMsg(res.message);
      this.getStudyDirectorStudies(true);
    })
  }

  changeStudyStatus(studyGuid, status) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: this.translate.instant("GENERIC_MSG.STUDY.STATUS_CHANGE_HEADER"),
      confirmMsg: this.translate.instant("GENERIC_MSG.STUDY.STATUS_CHANGE"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Ok"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        this.studyListingService.changeStudyStatus(studyGuid, status).subscribe((res: any) => {
          if (this.activeRole.toLowerCase() == "pathologist") {
            this.getPathologistStudies(this.activeTab);
          } else {
            this.getStudyDirectorStudies();
          }
        });
      }
    });
  }

  openCreateStudyDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "35%";
    dialogConfig.data = { "showAutoCreate": true }
    const dialogref = this.dialog.open(AutoCreateDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result) {
        this.commonService.studyCreateData = result;
        this.commonService.ProjectLSData.creationType = 'auto';
        this.commonService.setProjectLSData();
        this.commonService.changePlace("study/" + this.activeRole + "/create");
        this.router.navigate(["/dashboard/study/" + this.activeRole + "/create"]);
      }
    });
  }

  clearSearchText() {
    this.search = '';
    this.matDataTable.applySearch(this.search);
  }

  ngOnDestroy() {
    if (this.technicianInterval) {
      clearInterval(this.technicianInterval);
    }
  }
}
