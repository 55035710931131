import { CommonService } from '../common.service';
import { SidebarService } from './sidebar.service';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']
})
export class SidebarComponent implements OnInit {

  places: any[] = [];
  selectedRole: any;
  currentModule: any;
  viewerOpenPlace: any;
  constructor(public sidebarService: SidebarService, private commonService: CommonService, public router: Router) { }

  ngOnInit() {
    this.commonService.currentRole.subscribe(role => {
      if (role !== this.selectedRole) {
        this.selectedRole = role;
        this.getRolePlaces(this.selectedRole);
      }
    });
    this.commonService.currentPlace.subscribe(module => {
      this.currentModule = module;
    });
    this.commonService.ViewerOpen.subscribe(module => {
      this.viewerOpenPlace = module;
    });
    this.viewerOpenPlace = this.commonService.getViewerOpenedFrom();
  }


  getRolePlaces(role: any) {
    this.places = [];
    this.sidebarService.getRolePlaces(role).subscribe((response: any) => {
      response.data.places.forEach(place => {
        if (place === 'Image Repository') {
          this.places.push({
            'displayName': 'Image Repository',
            'moduleName': 'Images'
          });
        } else if (place == 'Consultation') {
          this.places.push({
            'displayName': 'Meeting',
            'moduleName': place
          });
        } else if (place == "DigitalSignature") {
          this.places.push({
            displayName: "Digital Signature",
            moduleName: "DigitalSignature",
          });
        } else if (place == "Reassign") {
          this.places.push({
            displayName: "Reassign",
            moduleName: "Study",
          });
        } else if (place === 'Upload') {
          this.places.push({
            'displayName': place,
            'moduleName': place
          });
        } else {
          if (place != 'Gallery') {
            this.places.push({
              'displayName': place,
              'moduleName': place
            });
          }
        }
      });

      let activePlace = this.commonService.ProjectLSData.userActivePlace;
      if (activePlace) {
        this.changePlace(activePlace);
      } else {
        this.changePlace(this.places[0].moduleName);
      }
    });

  }

  changePlace(place: any, isUserClicked: boolean = false) {
    let role = this.commonService.ProjectLSData.userActiveRole;
    var p = place.includes('/') ? place : (place.toLowerCase());
    this.commonService.changePlace(p);
    if (p == 'study') {
      if (!isUserClicked) {
        let routeArr = this.router.url.split('/');
        if (role == 'TECHNICIAN') {
          if (routeArr[2] == 'mapped') {
            this.router.navigate(['dashboard/mapped/study/gallery']);
          } else {
            this.router.navigate(['dashboard/' + p + '/' + role]);
          }
        } else if (role == 'STUDY_DIRECTOR') {
          this.router.navigate(['dashboard/' + p + '/' + role]);
        } else {
          if (routeArr[routeArr?.length - 1] == 'gallery' && routeArr[2] == 'user') {
            this.router.navigate(['dashboard/user/study/gallery']);
          } else {
            this.router.navigate(['dashboard/' + p + '/' + role]);
          }
        }
      } else {
        delete this.commonService.ProjectLSData['studySelectionLevel'];
        delete this.commonService.ProjectLSData['selectedStudyName'];
        delete this.commonService.ProjectLSData['selectedStudyId'];
        delete this.commonService.ProjectLSData['activeUserPermission'];
        delete this.commonService.ProjectLSData['studyManualFields'];
        this.commonService.setProjectLSData();
        this.router.navigate(['dashboard/' + p + '/' + role]);
      }
    } else if (p == 'project') {
      delete this.commonService.ProjectLSData['studySelectionLevel'];
      delete this.commonService.ProjectLSData['selectedStudyName'];
      delete this.commonService.ProjectLSData['selectedStudyId'];
      delete this.commonService.ProjectLSData['activeUserPermission'];
      delete this.commonService.ProjectLSData['studyManualFields'];
      this.commonService.setProjectLSData();
      if (this.router.url.includes('level') && !isUserClicked) {
        this.router.navigate([this.router.url]);
        // this.router.navigate(['dashboard/' + p +'/list']);
      } else {
        this.router.navigate(['dashboard/' + p + '/list']);
      }
    } else {
      delete this.commonService.ProjectLSData['studySelectionLevel'];
      delete this.commonService.ProjectLSData['selectedStudyName'];
      delete this.commonService.ProjectLSData['selectedStudyId'];
      delete this.commonService.ProjectLSData['activeUserPermission'];
      delete this.commonService.ProjectLSData['studyManualFields'];
      delete this.commonService.ProjectLSData['studyForReport'];
      this.commonService.setProjectLSData();
      // if (role?.toLowerCase() === 'technician' && p?.toLowerCase() === 'configure') {
      //   this.router.navigate(['dashboard/TECHNICIAN' + p]);
      // } else {

      this.router.navigate(['dashboard/' + p + ((role == 'TECHNICIAN' && p == 'configure') ? ('/' + role) : '')]);
      // }
    }

    delete this.commonService.ProjectLSData?.selectionLevel;
    delete this.commonService.ProjectLSData?.mappingProjectId;
    delete this.commonService.ProjectLSData?.mappingProjectName;
    delete this.commonService.ProjectLSData?.mappingFolderId;
    delete this.commonService.ProjectLSData?.mappingFolderName;
    delete this.commonService.ProjectLSData?.mappingFolder;
    this.commonService.setProjectLSData();
  }
}
