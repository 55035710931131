import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AuditReviewService } from './audit-review.service';
import { DatePipe } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import { formatDate } from '@angular/common';
import { finalize } from 'rxjs';
import { AppConfigService } from '../app-config.service';
import { error } from 'console';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-audit-review',
  templateUrl: './audit-review.component.html',
  styleUrls: ['./audit-review.component.less'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AuditReviewComponent implements OnInit {
  @Input() maxDate: any;
  today = new Date();
  selectedAuditForTable = '';
  showFilterView: boolean = false;
  isSubHeaderContainerHidden: boolean = false;
  expandedElement: any = null;
  selection = new SelectionModel(true, []);
  isAllRowSelected: boolean = false;
  rowSelectedCount: any = 0;
  statePreserve = {
    user: []
  };
  filterPopupHeader: any = '';
  loader: boolean = false;
  isViewRequested: boolean = false;
  emptyState: boolean = false;
  customisedTable: boolean = false;
  annotationView: boolean = false;
  slideView: boolean = false;
  auditView: boolean = false;
  loaderState: boolean = false;
  color = '';
  newRowGradingColor: any = '';
  noAuditData: boolean = false;
  filterDisplayName = '';
  downloadEnabled: boolean = false;
  isSelectAllChecked: boolean = false;
  matCheckBoxColor: string = 'primary';

  dataSource: any = new MatTableDataSource([
    // { "reportType": "FieldConfiguration", "timeStamp": "12-10-2022 16:22:24", "updatedTime": { "seconds": 1665571944, "nanos": 0 }, "actionBy": "sujeet.kumar@airamattrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Routes", "previous": "Disabled", "updated": "Enabled" }, { "field": "CRO", "previous": "Disabled", "updated": "Enabled" }, { "field": "Test Item", "previous": "Disabled", "updated": "Enabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "12-10-2022 16:14:09", "updatedTime": { "seconds": 1665571449, "nanos": 0 }, "actionBy": "sujeet.kumar@airamattrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Routes", "previous": "Enabled", "updated": "Disabled" }, { "field": "CRO", "previous": "Enabled", "updated": "Disabled" }, { "field": "Test Item", "previous": "Enabled", "updated": "Disabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "19-09-2022 12:41:15", "updatedTime": { "seconds": 1663571475, "nanos": 0 }, "actionBy": "edward.miller@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Test Item", "previous": "Disabled", "updated": "Enabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "19-09-2022 12:41:13", "updatedTime": { "seconds": 1663571473, "nanos": 0 }, "actionBy": "edward.miller@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "CRO", "previous": "Disabled", "updated": "Enabled" }, { "field": "Test Item", "previous": "Enabled", "updated": "Disabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "19-09-2022 12:40:44", "updatedTime": { "seconds": 1663571444, "nanos": 0 }, "actionBy": "edward.miller@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Test Item", "previous": "Disabled", "updated": "Enabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "19-09-2022 12:40:15", "updatedTime": { "seconds": 1663571415, "nanos": 0 }, "actionBy": "edward.miller@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "CRO", "previous": "Enabled", "updated": "Disabled" }, { "field": "Test Item", "previous": "Enabled", "updated": "Disabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "15-09-2022 13:09:13", "updatedTime": { "seconds": 1663227553, "nanos": 0 }, "actionBy": "sujeet.kumar@airamattrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Routes", "previous": "Disabled", "updated": "Enabled" }, { "field": "CRO", "previous": "Disabled", "updated": "Enabled" }, { "field": "Test Item", "previous": "Disabled", "updated": "Enabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "15-09-2022 13:01:39", "updatedTime": { "seconds": 1663227099, "nanos": 0 }, "actionBy": "sujeet.kumar@airamattrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Routes", "previous": "Enabled", "updated": "Disabled" }, { "field": "CRO", "previous": "Enabled", "updated": "Disabled" }, { "field": "Test Item", "previous": "Enabled", "updated": "Disabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "06-09-2022 16:07:41", "updatedTime": { "seconds": 1662460661, "nanos": 0 }, "actionBy": "edward.miller@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Sacrifice", "previous": "Disabled", "updated": "Enabled" }, { "field": "Routes", "previous": "Disabled", "updated": "Enabled" }, { "field": "CRO", "previous": "Disabled", "updated": "Enabled" }, { "field": "Test Item", "previous": "Disabled", "updated": "Enabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "06-09-2022 16:07:23", "updatedTime": { "seconds": 1662460643, "nanos": 0 }, "actionBy": "edward.miller@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Sacrifice", "previous": "Enabled", "updated": "Disabled" }, { "field": "Routes", "previous": "Enabled", "updated": "Disabled" }, { "field": "CRO", "previous": "Enabled", "updated": "Disabled" }, { "field": "Test Item", "previous": "Enabled", "updated": "Disabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "30-08-2022 16:01:28", "updatedTime": { "seconds": 1661855488, "nanos": 0 }, "actionBy": "edward.miller@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Primary Investigator", "previous": "Disabled", "updated": "Enabled" }, { "field": "Test Item", "previous": "Disabled", "updated": "Enabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "03-08-2022 10:32:35", "updatedTime": { "seconds": 1659502955, "nanos": 0 }, "actionBy": "dinesh.joshi@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Primary Investigator", "previous": "Enabled", "updated": "Disabled" }, { "field": "Test Item", "previous": "Enabled", "updated": "Disabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "02-08-2022 10:33:41", "updatedTime": { "seconds": 1659416621, "nanos": 0 }, "actionBy": "dinesh.joshi@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Project No", "previous": "Disabled", "updated": "Enabled" }, { "field": "CRO", "previous": "Disabled", "updated": "Enabled" }, { "field": "Test Item", "previous": "Disabled", "updated": "Enabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "01-08-2022 18:15:27", "updatedTime": { "seconds": 1659357927, "nanos": 0 }, "actionBy": "dinesh.joshi@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Routes", "previous": "Disabled", "updated": "Enabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "01-08-2022 18:15:16", "updatedTime": { "seconds": 1659357916, "nanos": 0 }, "actionBy": "dinesh.joshi@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Duration", "previous": "Disabled", "updated": "Enabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "01-08-2022 17:55:17", "updatedTime": { "seconds": 1659356717, "nanos": 0 }, "actionBy": "dinesh.joshi@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Primary Investigator", "previous": "Disabled", "updated": "Enabled" }, { "field": "Study Domain", "previous": "Disabled", "updated": "Enabled" }, { "field": "Duration", "previous": "Enabled", "updated": "Disabled" }, { "field": "CRO", "previous": "Enabled", "updated": "Disabled" }, { "field": "Test Item", "previous": "Enabled", "updated": "Disabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "01-08-2022 17:54:45", "updatedTime": { "seconds": 1659356685, "nanos": 0 }, "actionBy": "dinesh.joshi@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Project No", "previous": "Enabled", "updated": "Disabled" }, { "field": "Study Domain", "previous": "Enabled", "updated": "Disabled" }, { "field": "Routes", "previous": "Enabled", "updated": "Disabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "01-08-2022 17:54:30", "updatedTime": { "seconds": 1659356670, "nanos": 0 }, "actionBy": "dinesh.joshi@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Accession No", "previous": "Disabled", "updated": "Enabled" }, { "field": "Test Item", "previous": "Disabled", "updated": "Enabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "01-08-2022 13:34:43", "updatedTime": { "seconds": 1659341083, "nanos": 0 }, "actionBy": "sujeet.kumar@airamattrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Project No", "previous": "Disabled", "updated": "Enabled" }, { "field": "CRO", "previous": "Disabled", "updated": "Enabled" }, { "field": "Test Item", "previous": "Enabled", "updated": "Disabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "19-07-2022 11:35:03", "updatedTime": { "seconds": 1658210703, "nanos": 0 }, "actionBy": "edward.miller@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Primary Investigator", "previous": "Enabled", "updated": "Disabled" }, { "field": "Treatment", "previous": "Disabled", "updated": "Enabled" }, { "field": "Sacrifice", "previous": "Disabled", "updated": "Enabled" }, { "field": "CRO", "previous": "Enabled", "updated": "Disabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "19-07-2022 11:34:40", "updatedTime": { "seconds": 1658210680, "nanos": 0 }, "actionBy": "edward.miller@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Duration", "previous": "Disabled", "updated": "Enabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "19-07-2022 11:34:32", "updatedTime": { "seconds": 1658210672, "nanos": 0 }, "actionBy": "edward.miller@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Project No", "previous": "Enabled", "updated": "Disabled" }, { "field": "Accession No", "previous": "Enabled", "updated": "Disabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "19-07-2022 11:34:18", "updatedTime": { "seconds": 1658210658, "nanos": 0 }, "actionBy": "edward.miller@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Additional Pathologist", "previous": "Disabled", "updated": "Enabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "07-07-2022 13:26:10", "updatedTime": { "seconds": 1657180570, "nanos": 0 }, "actionBy": "edward.miller@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Duration", "previous": "Enabled", "updated": "Disabled" }, { "field": "Additional Pathologist", "previous": "Enabled", "updated": "Disabled" }] },
    // { "reportType": "FieldConfiguration", "timeStamp": "07-07-2022 13:26:05", "updatedTime": { "seconds": 1657180565, "nanos": 0 }, "actionBy": "edward.miller@airamatrix.com", "roles": [], "description": "", "umMapcount": 0, "mapCount": 0, "colour": "", "code": "", "totalImageCount": 0, "imageCount": 0, "details": [{ "field": "Duration", "previous": "Disabled", "updated": "Enabled" }, { "field": "Additional Pathologist", "previous": "Disabled", "updated": "Enabled" }] }
  ]);
  // displayedColumns: any =['expand','actionBy', 'details', 'timeStamp'];   //default column 
  displayedColumns: any = ['expand', 'actionBy', 'fieldName', 'fieldData', 'details', 'timeStamp'];
  searchFields: any = {
    searchAuditType: '',
    searchUser: '',
    searchStudyName: '',
    searchSpecies: '',
    searchSlide: '',
    searchTissue: ''
  };
  updatedColor: any = '#ff8100';
  previousColor: any = 'lightgray';
  rolesArr: any = ['Appliaction Admin', 'Study Director', 'Technician', 'Study director'];
  appAdminAudits: any = ['Field Configuration', 'Data Cinfiguration', 'Folder Location', 'User'];
  studyDirectorAudits: any = ['Study Creation'];
  technicianAudits: any = ['Image Mapping'];
  pathologistAudits: any = ['Annotation', 'Slide Status', 'Slide Review'];
  headerFields: any = {
    selectedAuditType: null,
    selectedTimeStamp: null,
    selectedTimeStampStartDate: null,
    selectedTimeStampEndDate: null,
    selectedUser: [],
    selectedStudyName: null,
    selectedFieldName: null,
    selectedAction: [],
    selectedRole: null,
    selectedStatus: null,
    selectedTemplateType: null,
    selectedSpecies: null,
    selectedAnnotation: null,
    selectedSlides: null,
    selectedFindings: null,
    selectedTissue: null,
    selectedRow: [],
    chipSetUser: [],
    chipSetAction: []
  };
  headerFieldsOptions: any = {
    tableFieldDisplay: {
      FieldConfiguration: ['expand', 'actionBy', 'details', 'timeStamp', 'dsComment'],
      DataConfiguration: ['expand', 'actionBy', 'fieldName', 'action', 'fieldData', 'timeStamp', 'dsComment'],
      StudyMappingStructure: ['expand', 'actionBy', 'action', 'templateType', 'templateName', 'templateDesc', 'timeStamp', 'dsComment'],
      FolderLocation: ['expand', 'actionBy', 'action', 'folderName', 'folderLocation', 'description', 'timeStamp', 'dsComment'],
      UsersAudit: ['expand', 'fullName', 'EmailId', 'action', 'createdBy', 'status', 'Role', 'timeStamp', 'dsComment'],
      SlideStatus: ['expand', 'actionBy', 'slideNo', 'status', 'timeStamp', 'dsComment'],
      SlideReview: ['expand', 'studyName', 'slideId', 'actionBy', 'action', 'finding', 'tissue', 'timeStamp', 'dsComment'],
      Annotation: ['expand', 'actionBy', 'action', 'slideId', 'annotation', 'timeStamp', 'dsComment'],
      ImageMapping: ['expand', 'actionBy', 'action', 'study', 'count', 'timeStamp', 'dsComment'],
      StudyCreation: ['expand', 'studyNo', 'studyType', 'actionBy', 'action', 'species', 'pathologist', 'technician', 'timeStamp', 'dsComment']
    },

    tableFieldDisplay1: {
      "FieldConfiguration": [{ columnName: 'expand', displayName: '', value: '', disabled: '' },
      { columnName: 'actionBy', displayName: 'Username', value: '', disabled: '' },
      { columnName: 'details', displayName: 'Action', value: '', disabled: '' },
      { columnName: 'timeStamp', displayName: 'Timestamp', value: '', disabled: '' }],
      "DataConfiguration": [{ columnName: 'expand', displayName: '', value: '', disabled: '' },
      { columnName: 'actionBy', displayName: 'Username', value: '', disabled: '' },
      { columnName: 'fieldName', displayName: 'Field Name', value: '', disabled: '' },
      { columnName: 'action', displayName: 'Action', value: '', disabled: '' },
      { columnName: 'fieldData', displayName: 'Field Data', value: '', disabled: '' },
      { columnName: 'timeStamp', displayName: 'Timestamp', value: '', disabled: '' }],
      "StudyMappingStructure": [{ columnName: 'expand', displayName: '', value: '', disabled: '' },
      { columnName: 'actionBy', displayName: 'Username', value: '', disabled: '' },
      { columnName: 'action', displayName: 'Action', value: '', disabled: '' },
      { columnName: 'templateType', displayName: 'Template type', value: '', disabled: '' },
      { columnName: 'templateName', displayName: 'Name', value: '', disabled: '' },
      { columnName: 'timeStamp', displayName: 'Timestamp', value: '', disabled: '' }],
      "FolderLocation": [{ columnName: 'expand', displayName: '', value: '', disabled: '' },
      { columnName: 'actionBy', displayName: 'Username', value: '', disabled: '' },
      { columnName: 'action', displayName: 'Action', value: '', disabled: '' },
      { columnName: 'folderName', displayName: 'Folder Name', value: '', disabled: '' },
      { columnName: 'folderLocation', displayName: 'Folder Location', value: '', disabled: '' },
      { columnName: 'timeStamp', displayName: 'Timestamp', value: '', disabled: '' }],
      "UsersAudit": [{ columnName: 'expand', displayName: '', value: '', disabled: '' },
      { columnName: 'firstName', displayName: 'First Name', value: '', disabled: '' },
      { columnName: 'lastName', displayName: 'Last Name', value: '', disabled: '' },
      { columnName: 'EmailId', displayName: 'Email', value: '', disabled: '' },
      { columnName: 'action', displayName: 'Action', value: '', disabled: '' },
      { columnName: 'createdBy', displayName: 'Creacted By', value: '', disabled: '' },
      { columnName: 'Role', displayName: 'Role', value: '', disabled: '' },
      { columnName: 'timeStamp', displayName: 'Timestamp', value: '', disabled: '' }],
      "SlideStatus": [{ columnName: '', displayName: '', value: '', disabled: '' },
      { columnName: 'expand', displayName: '', value: '', disabled: '' },
      { columnName: 'actionBy', displayName: 'Username', value: '', disabled: '' },
      { columnName: 'slideNo', displayName: ' Slide Name', value: '', disabled: '' },
      { columnName: 'timeStamp', displayName: 'Timestamp', value: '', disabled: '' }],
      "SlideReview": [{ columnName: 'expand', displayName: '', value: '', disabled: '' },
      { columnName: 'studyName', displayName: ' Study Name', value: '', disabled: '' },
      { columnName: 'slideId', displayName: 'Slide Id', value: '', disabled: '' },
      { columnName: 'actionBy', displayName: 'Username', value: '', disabled: '' },
      { columnName: 'action', displayName: 'Action', value: '', disabled: '' },
      { columnName: 'finding', displayName: 'Finding', value: '', disabled: '' },
      { columnName: 'tissue', displayName: 'Tissue', value: '', disabled: '' },
      ],
      "Annotation": [
        { columnName: 'expand', displayName: '', value: '', disabled: '' },
        { columnName: 'actionBy', displayName: 'Username', value: '', disabled: '' },
        { columnName: 'action', displayName: 'Action', value: '', disabled: '' },
        { columnName: 'slideId', displayName: 'Slide Id', value: '', disabled: '' },
        { columnName: 'annotation', displayName: 'Annnotation', value: '', disabled: '' },
        { columnName: 'timeStamp', displayName: 'Timestamp', value: '', disabled: '' }],
      "ImageMapping": [{ columnName: '', displayName: '', value: '', disabled: '' },
      { columnName: 'expand', displayName: '', value: '', disabled: '' },
      { columnName: 'actionBy', displayName: 'Username', value: '', disabled: '' },
      { columnName: 'action', displayName: 'Action', value: '', disabled: '' },
      { columnName: 'study', displayName: 'Study', value: '', disabled: '' },
      { columnName: 'count', displayName: 'Count', value: '', disabled: '' },
      { columnName: 'timeStamp', displayName: 'Timestamp', value: '', disabled: '' }],
      "StudyCreation": [{ columnName: 'expand', displayName: '', value: '', disabled: '' },
      { columnName: 'studyNo', displayName: ' Study No.', value: '', disabled: '' },
      { columnName: 'studyType', displayName: ' Study Type', value: '', disabled: '' },
      { columnName: 'actionBy', displayName: 'Username', value: '', disabled: '' },
      { columnName: 'action', displayName: 'Action', value: '', disabled: '' },
      { columnName: 'species', displayName: 'Species', value: '', disabled: '' },
      { columnName: 'pathologist', displayName: 'Pathalogist', value: '', disabled: '' },
      { columnName: 'technician', displayName: 'Technician', value: '', disabled: '' },
      { columnName: 'timeStamp', displayName: 'Timestamp', value: '', disabled: '' }],
    },

    // headerFieldsOptions.tableFieldDisplay.[headerFields.selectedAuditType].displayedColumns
    auditTypeDataArr: [
      { auditType: "FieldConfiguration", auditTypeDispName: "Field Configuration" },
      { auditType: "DataConfiguration", auditTypeDispName: "Data Configuration" },
      { auditType: "StudyMappingStructure", auditTypeDispName: "Study Mapping Structure" },
      { auditType: "FolderLocation", auditTypeDispName: "Folder Location" },
      { auditType: "UsersAudit", auditTypeDispName: "User" },
      { auditType: "StudyCreation", auditTypeDispName: "Study Creation" },
      { auditType: "ImageMapping", auditTypeDispName: "Image Mapping" },
      { auditType: "Annotation", auditTypeDispName: "Annotation" },
      { auditType: "SlideStatus", auditTypeDispName: "Slide Status" },
      { auditType: "SlideReview", auditTypeDispName: "Slide Review" }
    ],
    searchedAuditTypeDataArr: [],
    auditUsersArr: [],
    searchedAuditUsersArr: [],
    auditStudyArr: [],
    auditStudyObj: {},
    searchedAuditStudyArr: [],
    // comment user selection drop down for field configuration and data configuration ...
    selectedAuditFields: {
      "FieldConfiguration": [
        { fieldName: 'timeStamp', fieldDispName: 'Timestamp', fieldType: 'dateRange', selectedValue: 'selectedTimeStamp', dependentOn: '' },
        // { fieldName: 'userName', fieldDispName: 'Username', fieldType: 'selectBox', selectedValue: 'selectedUser', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchUser', searchFieldDispName: 'search User', isMulti: true, filterChipName: 'userName', dependentOn: 'timeStamp' },
        { fieldName: 'action', fieldDispName: 'Action', fieldType: 'selectBox', selectedValue: 'selectedAction', isDefaultOptionsArr: true, optionArr: 'ActionDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'timeStamp' }],
      "DataConfiguration": [
        { fieldName: 'timeStamp', fieldDispName: 'Timestamp', fieldType: 'dateRange', selectedValue: 'selectedTimeStamp', dependentOn: '' },
        // { fieldName: 'userName', fieldDispName: 'Username', fieldType: 'selectBox', selectedValue: 'selectedUser', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchUser', searchFieldDispName: 'search User', isMulti: true, filterChipName: 'userName', dependentOn: 'timeStamp' },
        { fieldName: 'fieldName', fieldDispName: 'Field Name', fieldType: 'selectBox', selectedValue: 'selectedFieldName', isDefaultOptionsArr: true, optionArr: 'FieldNameDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'timeStamp' },
        { fieldName: 'action', fieldDispName: 'Action', fieldType: 'selectBox', selectedValue: 'selectedAction', isDefaultOptionsArr: true, optionArr: 'ActionDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'timeStamp' }],
      // fieldName fieldData
      "StudyMappingStructure": [
        { fieldName: 'timeStamp', fieldDispName: 'Timestamp', fieldType: 'dateRange', selectedValue: 'selectedTimeStamp', dependentOn: '' },
        { fieldName: 'userName', fieldDispName: 'Username', fieldType: 'selectBox', selectedValue: 'selectedUser', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchUser', searchFieldDispName: 'search User', isMulti: true, filterChipName: 'userName', dependentOn: 'timeStamp' },
        { fieldName: 'templateType', fieldDispName: 'Template Type', fieldType: 'selectBox', selectedValue: 'selectedTemplateType', isDefaultOptionsArr: true, optionArr: 'TemplateTypeDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'timeStamp' },
        { fieldName: 'action', fieldDispName: 'Action', fieldType: 'selectBox', selectedValue: 'selectedAction', isDefaultOptionsArr: true, optionArr: 'ActionDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'timeStamp' }],
      // 	Template Type	Template Name	Action
      "FolderLocation": [
        { fieldName: 'timeStamp', fieldDispName: 'Timestamp', fieldType: 'dateRange', selectedValue: 'selectedTimeStamp', dependentOn: '', key: 'timeStamp' },
        { fieldName: 'userName', fieldDispName: 'Username', fieldType: 'selectBox', selectedValue: 'selectedUser', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchUser', searchFieldDispName: 'search User', isMulti: true, filterChipName: 'userName', dependentOn: 'timeStamp', key: 'actionBy' },
        { fieldName: 'action', fieldDispName: 'Action', fieldType: 'selectBox', selectedValue: 'selectedAction', isDefaultOptionsArr: true, optionArr: 'ActionDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'timeStamp' },
        // FolderName FolderLocatio
      ],
      // FolderLocation  :-->  location   ,  FolderName -->name
      "UsersAudit": [
        { fieldName: 'timeStamp', fieldDispName: 'Timestamp', fieldType: 'dateRange', selectedValue: 'selectedTimeStamp', dependentOn: '' },
        { fieldName: 'userName', fieldDispName: 'Username', fieldType: 'selectBox', selectedValue: 'selectedUser', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchUser', searchFieldDispName: 'search User', isMulti: true, filterChipName: 'userName', dependentOn: 'timeStamp' },
        { fieldName: 'role', fieldDispName: 'Role', fieldType: 'selectBox', selectedValue: 'selectedRole', isDefaultOptionsArr: true, optionArr: 'RoleDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'timeStamp' },
        { fieldName: 'status', fieldDispName: 'Status', fieldType: 'selectBox', selectedValue: 'selectedStatus', isDefaultOptionsArr: true, optionArr: 'StatusDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'timeStamp' },
        { fieldName: 'action', fieldDispName: 'Action', fieldType: 'selectBox', selectedValue: 'selectedAction', isDefaultOptionsArr: true, optionArr: 'ActionDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'timeStamp' },
      ],

      // first Name   LastName   Email Id   created by    stateTimeStamp Role 
      "StudyCreation": [
        { fieldName: 'timeStamp', fieldDispName: 'Timestamp', fieldType: 'dateRange', selectedValue: 'selectedTimeStamp', dependentOn: '' },
        { fieldName: 'studyName', fieldDispName: 'Study Name', fieldType: 'selectBox', selectedValue: 'selectedStudyName', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchStudyName', searchFieldDispName: 'Search Study Name', isMulti: false, filterChipName: 'default', dependentOn: 'timeStamp' },
        { fieldName: 'userName', fieldDispName: 'Username', fieldType: 'selectBox', selectedValue: 'selectedUser', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchUser', searchFieldDispName: 'search User', isMulti: true, filterChipName: 'userName', dependentOn: 'timeStamp' },
        { fieldName: 'templateType', fieldDispName: 'Template Type', fieldType: 'selectBox', selectedValue: 'selectedTemplateType', isDefaultOptionsArr: true, optionArr: 'TemplateTypeDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'timeStamp' },
        { fieldName: 'species', fieldDispName: 'Species', fieldType: 'selectBox', selectedValue: 'selectedSpecies', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchSpecies', searchFieldDispName: 'Search Species', isMulti: true, filterChipName: 'species', dependentOn: 'timeStamp' },
        { fieldName: 'action', fieldDispName: 'Action', fieldType: 'selectBox', selectedValue: 'selectedAction', isDefaultOptionsArr: true, optionArr: 'ActionDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'timeStamp' },
      ],
      // study NO    study Type userName species pathologist technicial action timestamp
      "ImageMapping": [
        { fieldName: 'timeStamp', fieldDispName: 'Timestamp', fieldType: 'dateRange', selectedValue: 'selectedTimeStamp', dependentOn: '' },
        { fieldName: 'studyName', fieldDispName: 'Study Name', fieldType: 'selectBox', selectedValue: 'selectedStudyName', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchStudyName', searchFieldDispName: 'Search Study Name', isMulti: false, filterChipName: 'default', dependentOn: 'timeStamp' },
        { fieldName: 'userName', fieldDispName: 'Username', fieldType: 'selectBox', selectedValue: 'selectedUser', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchUser', searchFieldDispName: 'search User', isMulti: true, isSelectAll: true, filterChipName: 'userName', dependentOn: 'timeStamp' },
        { fieldName: 'action', fieldDispName: 'Action', fieldType: 'selectBox', selectedValue: 'selectedAction', isDefaultOptionsArr: true, optionArr: 'ActionDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'timeStamp' }],
      //  studyMapping:-> username templateType Action timestamp 
      "Annotation": [
        { fieldName: 'studyName', fieldDispName: 'Study Name', fieldType: 'selectBox', selectedValue: 'selectedStudyName', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchStudyName', searchFieldDispName: 'Search Study Name', isMulti: false, filterChipName: 'default', dependentOn: '' },
        { fieldName: 'slide', fieldDispName: 'Slide', fieldType: 'selectBox', selectedValue: 'selectedSlides', isDefaultOptionsArr: false, optionArr: 'searchedSlideArr', isOptionSearchable: true, searchField: 'searchSlide', searchFieldDispName: 'Search Slide', isMulti: true, filterChipName: 'default', dependentOn: '' },
        { fieldName: 'timeStamp', fieldDispName: 'Timestamp', fieldType: 'dateRange', selectedValue: 'selectedTimeStamp', dependentOn: '' },
        { fieldName: 'userName', fieldDispName: 'Username', fieldType: 'selectBox', selectedValue: 'selectedUser', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchUser', searchFieldDispName: 'search User', isMulti: true, filterChipName: 'userName', dependentOn: 'studyName' },
        { fieldName: 'annotation', fieldDispName: 'Annotation', fieldType: 'selectBox', selectedValue: 'selectedAnnotation', isDefaultOptionsArr: true, optionArr: 'annotationShapeArr', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'studyName' },
        { fieldName: 'action', fieldDispName: 'Action', fieldType: 'selectBox', selectedValue: 'selectedAction', isDefaultOptionsArr: true, optionArr: 'ActionDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'studyName' },
      ],
      // userName slide annotation action timestamp
      "SlideStatus": [
        { fieldName: 'studyName', fieldDispName: 'Study Name', fieldType: 'selectBox', selectedValue: 'selectedStudyName', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchStudyName', searchFieldDispName: 'Search Study Name', isMulti: false, filterChipName: 'default', dependentOn: '' },
        { fieldName: 'slide', fieldDispName: 'Slide', fieldType: 'selectBox', selectedValue: 'selectedSlides', isDefaultOptionsArr: true, optionArr: 'searchedSlideArr', isOptionSearchable: true, searchField: 'searchSlide', searchFieldDispName: 'Search Slide', isMulti: true, filterChipName: 'default', dependentOn: 'studyName' },
        { fieldName: 'userName', fieldDispName: 'Username', fieldType: 'selectBox', selectedValue: 'selectedUser', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchUser', searchFieldDispName: 'search User', isMulti: true, filterChipName: 'userName', dependentOn: 'studyName' },
        { fieldName: 'action', fieldDispName: 'Action', fieldType: 'selectBox', selectedValue: 'selectedAction', isDefaultOptionsArr: true, optionArr: 'ActionDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'studyName' },
      ],
      // slideNo  status timestamp userName
      "SlideReview": [
        { fieldName: 'studyType', fieldDispName: 'Study Type', fieldType: 'selectBox', selectedValue: 'selectedStudyType', isDefaultOptionsArr: true, optionArr: 'studyTypeDataObj', isOptionSearchable: false, isMulti: false, filterChipName: 'default', dependentOn: '' },
        { fieldName: 'studyName', fieldDispName: 'Study Name', fieldType: 'selectBox', selectedValue: 'selectedStudyName', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchStudyName', searchFieldDispName: 'Search Study Name', isMulti: false, filterChipName: 'default', dependentOn: '' },
        { fieldName: 'slide', fieldDispName: 'Slide', fieldType: 'selectBox', selectedValue: 'selectedSlides', isDefaultOptionsArr: true, optionArr: 'searchedSlideArr', isOptionSearchable: true, searchField: 'searchSlide', searchFieldDispName: 'Search Slide', isMulti: true, filterChipName: 'default', dependentOn: 'studyName' },
        { fieldName: 'userName', fieldDispName: 'Username', fieldType: 'selectBox', selectedValue: 'selectedUser', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchUser', searchFieldDispName: 'search User', isMulti: true, filterChipName: 'userName', dependentOn: 'studyName' },
        { fieldName: 'tissue', fieldDispName: 'Tissue', fieldType: 'selectBox', selectedValue: 'selectedTissue', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchTissue', searchFieldDispName: 'Search Tissue', isMulti: true, filterChipName: 'tissue', dependentOn: 'studyName' },
        { fieldName: 'action', fieldDispName: 'Action', fieldType: 'selectBox', selectedValue: 'selectedAction', isDefaultOptionsArr: true, optionArr: 'ActionDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'studyName' },
      ],
      "SlideReviewTCR": [
        { fieldName: 'studyType', fieldDispName: 'Study Type', fieldType: 'selectBox', selectedValue: 'selectedStudyType', isDefaultOptionsArr: true, optionArr: 'studyTypeDataObj', isOptionSearchable: false, isMulti: false, filterChipName: 'default', dependentOn: '' },
        { fieldName: 'studyName', fieldDispName: 'Study Name', fieldType: 'selectBox', selectedValue: 'selectedStudyName', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchStudyName', searchFieldDispName: 'Search Study Name', isMulti: false, filterChipName: 'default', dependentOn: '' },
        { fieldName: 'slide', fieldDispName: 'Slide', fieldType: 'selectBox', selectedValue: 'selectedSlides', isDefaultOptionsArr: true, optionArr: 'searchedSlideArr', isOptionSearchable: true, searchField: 'searchSlide', searchFieldDispName: 'Search Slide', isMulti: true, filterChipName: 'default', dependentOn: 'studyName' },
        { fieldName: 'userName', fieldDispName: 'Username', fieldType: 'selectBox', selectedValue: 'selectedUser', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchUser', searchFieldDispName: 'search User', isMulti: true, filterChipName: 'userName', dependentOn: 'studyName' },
        { fieldName: 'tissue', fieldDispName: 'Tissue', fieldType: 'selectBox', selectedValue: 'selectedTissue', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchTissue', searchFieldDispName: 'Search Tissue', isMulti: true, filterChipName: 'tissue', dependentOn: 'studyName' },
        { fieldName: 'action', fieldDispName: 'Action', fieldType: 'selectBox', selectedValue: 'selectedAction', isDefaultOptionsArr: true, optionArr: 'ActionDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'studyName' },
      ],
      "SlideReviewTOX": [
        { fieldName: 'studyType', fieldDispName: 'Study Type', fieldType: 'selectBox', selectedValue: 'selectedStudyType', isDefaultOptionsArr: true, optionArr: 'studyTypeDataObj', isOptionSearchable: false, isMulti: false, filterChipName: 'default', dependentOn: '' },
        { fieldName: 'studyName', fieldDispName: 'Study Name', fieldType: 'selectBox', selectedValue: 'selectedStudyName', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchStudyName', searchFieldDispName: 'Search Study Name', isMulti: false, filterChipName: 'default', dependentOn: '' },
        { fieldName: 'slide', fieldDispName: 'Slide', fieldType: 'selectBox', selectedValue: 'selectedSlides', isDefaultOptionsArr: true, optionArr: 'searchedSlideArr', isOptionSearchable: true, searchField: 'searchSlide', searchFieldDispName: 'Search Slide', isMulti: true, filterChipName: 'default', dependentOn: 'studyName' },
        { fieldName: 'userName', fieldDispName: 'Username', fieldType: 'selectBox', selectedValue: 'selectedUser', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchUser', searchFieldDispName: 'search User', isMulti: true, filterChipName: 'userName', dependentOn: 'studyName' },
        { fieldName: 'findings', fieldDispName: 'Findings', fieldType: 'selectBox', selectedValue: 'selectedFindings', isDefaultOptionsArr: true, optionArr: 'findingsArr', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'studyName' },
        { fieldName: 'tissue', fieldDispName: 'Tissue', fieldType: 'selectBox', selectedValue: 'selectedTissue', isDefaultOptionsArr: false, isOptionSearchable: true, searchField: 'searchTissue', searchFieldDispName: 'Search Tissue', isMulti: true, filterChipName: 'tissue', dependentOn: 'studyName' },
        { fieldName: 'action', fieldDispName: 'Action', fieldType: 'selectBox', selectedValue: 'selectedAction', isDefaultOptionsArr: true, optionArr: 'ActionDataObj', isOptionSearchable: false, isMulti: true, filterChipName: 'default', dependentOn: 'studyName' },
      ]

    },
    //  headerFieldsOptions.selectedAuditFields.SlideReview
    selectedAuditVisibleFields: {
      "FieldConfiguration": ['timeStamp', 'userName', 'action'],
      "DataConfiguration": ['timeStamp', 'userName', 'fieldName', 'action'],
      "FolderLocation": ['timeStamp', 'userName', 'action'],
      "UsersAudit": ['timeStamp', 'userName', 'role', 'status'],
      "StudyMappingStructure": ['timeStamp', 'userName', 'templateType', 'action'],
      "StudyCreation": ['timeStamp', 'studyName', 'userName', 'templateType', 'species', 'action'],
      "ImageMapping": ['timeStamp', 'studyName', 'userName', 'action'],
      "Annotation": ['timeStamp', 'studyName', 'slide', 'userName', 'annotation', 'action'],
      "SlideStatus": ['timeStamp', 'studyName', 'slide', 'userName', 'action'],
      "SlideReview": ['timeStamp', 'studyType', 'studyName', 'slide', 'userName', 'findings', 'tissue', 'action'],
    },
    FieldNameDataObj: {
      "DataConfiguration": ["Study Domain", "Treatment", "Study Type", "Species", "Route", "Stain", "Tissues", "Dosage", "Severity Grading", "Intensity", "Frequency"],
    },
    ActionDataObj: {
      "FieldConfiguration": ["Enabled", "Disabled"],
      "DataConfiguration": ["Created", "Deleted"],
      "FolderLocation": ["Created", "Deleted"],
      "StudyMappingStructure": ["Created", "Edited", "Deleted"],
      "StudyCreation": ["Created", "Edited", "Deleted"],
      "ImageMapping": ["Unmapped", "Mapped"],
      "Annotation": ["Created", "Edited", "Deleted"],
      "SlideStatus": ["Mark as Read", "Mark as Unread", "Review Later", "Review Done"],
      "SlideReview": ["Created", "Edited", "Deleted"],
      "UsersAudit": ["Created", "Edited"],
      "studyType": ['TCR', 'TOX']
    },
    RoleDataObj: {
      "UsersAudit": ["Admin", "Study Director", "Technician", "Pathologist"]
    },
    StatusDataObj: {
      "UsersAudit": ["Active", "Inactive"]
    },
    TemplateTypeDataObj: {
      "StudyMappingStructure": ["TCR", "TOX"],
      "StudyCreation": ["TCR", "TOX"],
    },
    studyTypeDataObj: {
      "SlideReview": ["TOX", "TCR"]
    },
    speciesArr: [],
    speciesObj: {},
    searchedSpeciesArr: [],
    slideArr: [],
    searchedSlideArr: [],
    annotationShapeArr: ["Rectangle", "Circle", "Ellipse", "Open Freeform", "Closed Freeform", "Filled Freeform", "Ruler", "Arrow", "Angle"],
    findingsArr: ["WNL", "Not WNL"],
    tissueArr: [],
    tissueObj: {},
    searchedTissueArr: [],
  }
  hiddenElemCount: any = 0;
  isTableDisplayed: boolean = false;

  constructor(private auditReviewService: AuditReviewService, public datepipe: DatePipe, public appConfig: AppConfigService, private commonService: CommonService) {
    this.headerFieldsOptions.searchedAuditTypeDataArr = this.headerFieldsOptions.auditTypeDataArr;
    if (!this.appConfig.config?.webAppConfig?.isTCR) {
      this.headerFieldsOptions.ActionDataObj["studyType"] = ['TOX'];
      this.headerFieldsOptions.TemplateTypeDataObj = {
        "StudyMappingStructure": ["TOX"],
        "StudyCreation": ["TOX"],
      };
      this.headerFieldsOptions.studyTypeDataObj["SlideReview"] = ['TOX'];
      this.headerFieldsOptions.FieldNameDataObj = {
        "DataConfiguration": ["Study Domain", "Treatment", "Study Type", "Species", "Route", "Stain", "Tissues", "Dosage", "Severity Grading"],
      }
    }

  }

  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.today.setDate(this.today.getDate());
    this.emptyState = true;
    this.auditReviewService.getAllUsers().subscribe((res: any) => {
      this.headerFieldsOptions.searchedAuditUsersArr = this.headerFieldsOptions.auditUsersArr = res.data?.ACTIVE.concat(res.data?.INACTIVE);
      this.sortByName(this.headerFieldsOptions.searchedAuditUsersArr);
      for (let x = 0; x < this.headerFieldsOptions.auditUsersArr.length; x++) {
        this.headerFieldsOptions.auditStudyObj[this.headerFieldsOptions.auditUsersArr[x].emailId] = this.headerFieldsOptions.auditUsersArr[x];
      }
      this.statePreserve.user = [...this.headerFieldsOptions?.auditUsersArr]

      this.headerFields.selectedAuditType = '';
      this.headerFields.selectedTimeStamp = new Date();
      this.chipSetDecoration();

    });

    this.auditReviewService.getAllSpecies().subscribe((res: any) => {
      this.headerFieldsOptions.searchedSpeciesArr = this.headerFieldsOptions.speciesArr = res.data;

      for (let a = 0; a < res.data.length; a++) {
        this.headerFieldsOptions.speciesObj[res.data[a].speciesId] = res.data[a].species;
      }
    });

    this.auditReviewService.getAllOrgans().subscribe((res: any) => {
      this.headerFieldsOptions.searchedTissueArr = this.headerFieldsOptions.tissueArr = res.data;
      for (let a = 0; a < res.data.length; a++) {
        this.headerFieldsOptions.tissueObj[res.data[a].organId] = res.data[a].organ;
      }
    });

  }

  changeGradingColor(color) {
    this.newRowGradingColor = color;
  }


  auditSelected(type: any) {
    this.headerFields.selectedAuditType = type;
  }

  auditSelection(option: any) {
    this.headerFields.selectedAuditType = option;
  }


  chipSetDecoration(arg?: any) {
    if (arg == 'selectedStudyType') {
      this.auditReviewService.getPathoStudies(this.headerFields.selectedStudyType).subscribe((res: any) => {
        this.headerFieldsOptions.searchedAuditStudyArr = this.headerFieldsOptions.auditStudyArr = res;
      })
      if (this.headerFields.selectedStudyType == 'TOX') {
        this.headerFieldsOptions.selectedAuditFields.SlideReview = [...this.headerFieldsOptions.selectedAuditFields.SlideReviewTOX];
      } else {
        this.headerFieldsOptions.selectedAuditFields.SlideReview = [...this.headerFieldsOptions.selectedAuditFields.SlideReviewTCR];
      }
    }
    if ((this.headerFields.selectedAuditType == 'SlideReview' && arg == 'selectedStudyName') || (this.headerFields.selectedAuditType == 'SlideStatus' && arg == 'selectedStudyName')
      || (this.headerFields.selectedAuditType == 'Annotation' && arg == 'selectedStudyName')) {
      this.auditReviewService.getslideNo(this.headerFields.selectedStudyName).subscribe(((res: any) => {
        this.headerFieldsOptions.searchedSlideArr = this.headerFieldsOptions.slideArr = [];
        for (let i = 0; i < res.length; i++)
          this.headerFieldsOptions.searchedSlideArr[i] = this.headerFieldsOptions.slideArr[i] = res[i].name;
        this.headerFields.selectedSlides = [];
      }))
    }
    setTimeout(() => {
      let elem = $(".chipsetSection .indvChip");
      this.hiddenElemCount = 0;
      for (let x = 0; x < elem.length; x++) {
        let offsetTop = $(elem[x]).offset().top - $(elem[x]).parent().offset().top;
        if (offsetTop > 4) {
          this.hiddenElemCount++;
        }
      }
    }, 0);
  }

  onKeyPressSearch(val, field) {
    if (field == 'auditType') {
      this.headerFieldsOptions.searchedAuditTypeDataArr = this.headerFieldsOptions.auditTypeDataArr.filter((option) => {
        return option.auditTypeDispName.toLowerCase().includes(val.toLowerCase());
      });
    } else if (field == 'userName') {
      this.headerFieldsOptions.searchedAuditUsersArr = this.headerFieldsOptions.auditUsersArr.filter((option: any) => {
        return (option.firstName?.toLowerCase().includes(val.toLowerCase()) || option.lastName?.toLowerCase().includes(val.toLowerCase()));

      });
    } else if (field == 'studyName') {
      this.headerFieldsOptions.searchedAuditStudyArr = this.headerFieldsOptions.auditStudyArr.filter((option) => {
        return option.studyNo.toLowerCase().includes(val.toLowerCase())
      });
    } else if (field == 'species') {
      this.headerFieldsOptions.searchedSpeciesArr = this.headerFieldsOptions.speciesArr.filter((option) => {
        return option.species.toLowerCase().includes(val.toLowerCase())
      });
    } else if (field == 'slide') {
      this.headerFieldsOptions.searchedSlideArr = this.headerFieldsOptions.slideArr.filter((option) => {
        return option.toLowerCase().includes(val.toLowerCase())
      });
    } else if (field == 'tissue') {
      this.headerFieldsOptions.searchedTissueArr = this.headerFieldsOptions.tissueArr.filter((option) => {
        return option.organ.toLowerCase().includes(val.toLowerCase())
      });
    }
  }

  auditHeaderSelectPopupClosed(field: any) {
    if (field == 'auditType') {
      this.headerFieldsOptions.searchedAuditTypeDataArr = this.headerFieldsOptions.auditTypeDataArr;
      this.searchFields.searchAuditType = '';
    } else if (field == 'userName') {
      this.headerFieldsOptions.searchedAuditUsersArr = this.headerFieldsOptions.auditUsersArr;
      this.searchFields.searchUser = '';
    } else if (field == 'studyName') {
      this.headerFieldsOptions.searchedAuditStudyArr = this.headerFieldsOptions.auditStudyArr;
      this.searchFields.searchStudyName = '';
    } else if (field == 'species') {
      this.headerFieldsOptions.searchedSpeciesArr = this.headerFieldsOptions.speciesArr;
      this.searchFields.searchSpecies = '';
    } else if (field == 'slide') {
      this.headerFieldsOptions.searchedSlideArr = this.headerFieldsOptions.slideArr;
      this.searchFields.searchSlide = '';
    } else if (field == 'tissue') {
      this.headerFieldsOptions.searchedTissueArr = this.headerFieldsOptions.tissueArr;
      this.searchFields.searchTissue = '';
    } else if (field == 'studyType') {
      this.headerFieldsOptions.searchedStudyTypeArr = [];
    }
  }

  removeHeaderField(index: any, dataField: any, resetChipDecoration: boolean = true) {
    if (typeof this.headerFields[dataField] == "string") {
      this.headerFields[dataField] = null;
    } else {
      this.headerFields[dataField] = this.headerFields[dataField].filter(function (value, ind, arr) {
        return index != ind;
      });
      this.hiddenElemCount = this.hiddenElemCount > 0 && dataField == 'selectedUser' ? --this.hiddenElemCount : this.hiddenElemCount;
    }

  }

  toggleSubHeaderContainer() {
    this.isSubHeaderContainerHidden = !this.isSubHeaderContainerHidden;
  }

  clearAllFilter(flag: any) {
    this.emptyState = true;
    if (flag == 'clearFilters') {
      this.dataSource = null;
      this.isTableDisplayed = false;
      this.downloadEnabled = false;
      this.headerFields.selectedRow = [];
      this.headerFields.selectedTimeStamp = new Date();
      this.headerFields.selectedTimeStampStartDate = null;
      this.headerFields.selectedTimeStampEndDate = null;
      this.headerFields.selectedTimeStamp = null;
    }
    this.downloadEnabled = false;
    this.headerFields.selectedUser = null;
    this.headerFields.selectedStudyName = null;
    this.headerFields.selectedFieldName = null;
    this.headerFields.selectedAction = null;
    this.headerFields.selectedRole = null;
    this.headerFields.selectedStatus = null;
    this.headerFields.selectedTemplateType = null;
    this.headerFields.selectedSpecies = null;
    this.headerFields.selectedAnnotation = null;
    this.headerFields.selectedSlides = null;
    this.headerFields.selectedFindings = null;
    this.headerFields.selectedTissue = null;
    this.headerFields.selectedStudyType = null;
    this.showFilterView = false;
    this.chipSetDecoration();
  }

  getAuditTypeName() {
    let tempAuditType = this.headerFields.selectedAuditType;
    this.filterPopupHeader = tempAuditType?.replace(/([a-z])([A-Z])/g, "$1 $2");
  }

  auditTypeSelectionChanged(auditType: any) {
    this.filterDisplayName = this.headerFields.selectedAuditType;
    this.headerFieldsOptions.searchedAuditStudyArr = [];
    this.auditView = false;
    this.downloadEnabled = false;
    this.annotationView = false;
    this.slideView = false;
    this.isTableDisplayed = false;
    this.noAuditData = false;
    this.headerFields.selectedRow = [];
    this.clearAllFilter('auditChange');
    this.getAuditTypeName();
    let atPathologist = false;
    if (auditType == 'Annotation') {
      this.annotationView = true;
      atPathologist = true;
    } else if (auditType == 'SlideStatus') {
      this.slideView = true;
      atPathologist = true;
    } else if (auditType == 'StudyCreation') {
      this.auditView = true;
      this.auditReviewService.getAllStudiesForStudy().subscribe((res: any) => {
        this.headerFieldsOptions.searchedAuditStudyArr = this.headerFieldsOptions.auditStudyArr = res;
      });
    } else if (auditType == 'ImageMapping') {
      this.auditView = true;
      this.auditReviewService.getAllStudiesForImageMapping().subscribe((res: any) => {
        this.headerFieldsOptions.searchedAuditStudyArr = this.headerFieldsOptions.auditStudyArr = res.data;
      });
    } else if (auditType == 'SlideReview') {
      this.auditView = false;
    }
    else {
      this.auditView = true;
    }
    if (atPathologist == true) {
      this.auditReviewService.getPathoStudies().subscribe((res: any) => {
        this.headerFieldsOptions.searchedAuditStudyArr = this.headerFieldsOptions.auditStudyArr = res;
      });
    }
  }

  getTypeOf(variable: any) {
    return typeof variable;
  }

  fetchAuditData() {
    this.downloadEnabled = false;
    this.showFilterView = false;
    this.loaderState = true;
    this.emptyState = false;
    this.headerFields.chipSetUser = this.headerFields.selectedUser?.length ? [...this.headerFields.selectedUser] : [];
    this.headerFields.chipSetAction = this.headerFields.selectedAction?.length ? [...this.headerFields.selectedAction] : [];
    this.isViewRequested = (this.headerFields.selectedUser?.length || this.headerFields.selectedAction?.length) ? true : false;
    this.chipSetDecoration();
    this.selectedAuditForTable = this.headerFields.selectedAuditType;
    let sDate = this.datepipe.transform(this.headerFields.selectedTimeStampStartDate, 'yyyy-MM-dd');
    let eDate = this.datepipe.transform(this.headerFields.selectedTimeStampEndDate, 'yyyy-MM-dd');
    let startDate = (sDate != null) ? this.datepipe.transform(this.headerFields.selectedTimeStampStartDate, 'yyyy-MM-dd') : '';
    let endDate = (eDate != null) ? this.datepipe.transform(this.headerFields.selectedTimeStampEndDate, 'yyyy-MM-dd') : '';
    let users = this.headerFields.selectedUser?.length ? this.headerFields.selectedUser.join(',') : '';
    let auditType = (this.headerFields.selectedAuditType == 'StudyCreation' || this.headerFields.selectedAuditType == 'ImageMapping') ? 'Study' : this.headerFields.selectedAuditType;
    let study_event = this.headerFields.selectedAuditType == 'ImageMapping' ? "StudyMappingandUnmapping" : this.headerFields.selectedAuditType;
    let payload = {
      studyEvent: study_event,
      tissue: this.headerFields.selectedTissue?.length ? this.headerFields.selectedTissue.join(',') : '',
      slide: this.headerFields.selectedSlides?.length ? this.headerFields.selectedSlides.join(',') : '',
      name: this.headerFields.selectedStudyName ? this.headerFields.selectedStudyName : '',
      subject: '',
      dosageId: '',
      antigen: '',
      cassette: ''
    }
    let fieldConfigService = this.auditReviewService.getFieldConfigAPIData(startDate, endDate, users, auditType, { ...payload, ...this.headerFields });
    fieldConfigService.subscribe((res: any) => {
      if (!res.length) {
        this.noAuditData = true;
      }
      this.headerFields.selectedRow = [];
      this.isAllRowSelected = false;
      if (res.length) {
        for (let i = 0; i < res.length; i++) {
          res[i].position = i
        }
      }
      this.loaderState = false;
      this.emptyState = res.length ? false : true;
      this.isTableDisplayed = res.length ? true : false;
      this.downloadEnabled = res.length ? true : false;
      this.dataSource = new MatTableDataSource(res);
    }, error => {
      this.loaderState = false;
      this.commonService.showToastMsg(error?.error?.message ?? 'Error', 'error');
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  selectAllRows(event: any) {
    this.headerFields.selectedRow = []
    if (event.checked) {
      this.isAllRowSelected = true;
      this.dataSource.filteredData.forEach((indvItem, ind) => {
        indvItem.isChecked = event.checked;
        this.headerFields.selectedRow.push(indvItem.position);
      });
    } else {
      this.isAllRowSelected = false;
      this.dataSource.filteredData.forEach((indvItem, ind) => {
        indvItem.isChecked = event.checked;
      });
    }
  }

  openCustomisedTablePopup() {
    this.customisedTable = true;
  }

  toggleRowSelection(event: any, indvElem: any) {
    // indvElem.isChecked = event.checked;
    // this.rowSelectedCount = 0;
    // this.dataSource.filteredData.forEach((indvItem, ind) => {
    //   if (indvItem.isChecked) {
    //     this.rowSelectedCount += 1;
    //   }
    // });
    if (event.checked) {
      this.headerFields.selectedRow.push(indvElem.position);
      indvElem.isChecked = event.checked;
    } else {
      let index = this.headerFields.selectedRow.findIndex(((p: any) => { return p == indvElem.position }));
      this.headerFields.selectedRow.splice(index, 1);
      indvElem.isChecked = event.checked;
    }
    this.isAllRowSelected = (this.dataSource.filteredData.length == this.headerFields.selectedRow.length);
    // this.isAllRowSelected = (this.dataSource.length == selectedRows) ? 'allRowSelected' : ((selectedRows > 0) ? 'partialRowSelected' : 'noRowSelected');
  }

  headerCheckBoxStatus(statusStr: string = '') {
    if (statusStr == 'checked') {
      return (this.dataSource.filteredData[0]?.length == this.rowSelectedCount);
    } else if (statusStr == 'indeterminate') {
      return ((this.rowSelectedCount < this.dataSource.filteredData[0]?.length) && (this.rowSelectedCount > 0));
    }
  }

  checkboxLabel(row: any = null): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  downloadReport() {
    let sDate = formatDate(new Date(this.headerFields.selectedTimeStampStartDate), 'yyyy-MM-dd', 'en_US');
    let eDate = formatDate(new Date(this.headerFields.selectedTimeStampEndDate), 'yyyy-MM-dd', 'en_US');
    let startDate = (sDate != null) ? this.datepipe.transform(this.headerFields.selectedTimeStampStartDate, 'yyyy-MM-dd') : '';
    let endDate1 = (eDate != null) ? this.datepipe.transform(this.headerFields.selectedTimeStampEndDate, 'yyyy-MM-dd') : '';
    let obj = {
      actions: this.headerFields.selectedAction?.length ? this.headerFields.selectedAction?.join(',') : '',
      annotation: this.headerFields.selectedAnnotation?.length ? this.headerFields.selectedAnnotation.join(',') : '',
      antigen: "",
      casette: "",
      dosage: "",
      field_name: this.headerFields.selectedFieldName?.length ? this.headerFields.selectedFieldName?.join(',') : '',
      // fromDate: formatDate(new Date(this.headerFields.selectedTimeStampStartDate), 'yyyy-MM-dd', 'en_US'),
      // toDate: formatDate(new Date(this.headerFields.selectedTimeStampEndDate), 'yyyy-MM-dd', 'en_US'),
      fromDate: startDate,
      toDate: endDate1,
      name: this.headerFields.selectedStudyName ? this.headerFields.selectedStudyName : "",                //only string       
      report_type: this.headerFields.selectedAuditType ? this.headerFields.selectedAuditType : "",         //only string
      roles: this.headerFields.selectedRole?.length ? this.headerFields.selectedRole.join(',') : '',
      selected_data: this.headerFields.selectedRow.join(','),
      slide: this.headerFields.selectedSlides?.length ? this.headerFields.selectedSlides.join(',') : '',
      species: this.headerFields.selectedSpecies?.length ? this.headerFields.selectedSpecies.join(',') : '',
      status: this.headerFields.selectedStatus?.length ? this.headerFields.selectedStatus.join(',') : '',
      subject: "",
      template_type: this.headerFields.selectedTemplateType?.length ? this.headerFields.selectedTemplateType.join(',') : '',
      tissue: this.headerFields.selectedTissue?.length ? this.headerFields.selectedTissue.join(',') : '',
      user_names: this.headerFields.selectedUser?.length ? this.headerFields.selectedUser.join(',') : '',
      findings: this.headerFields.selectedFindings?.length ? this.headerFields.selectedFindings.join(',') : ''
    }
    this.auditReviewService.downloadReport(obj).subscribe(res => {
      saveAs(res);
    })
  }

  sortByName(inputArray: any) {
    inputArray.sort((a, b) => {
      a = a.firstName?.toLowerCase() + " " + a.lastName?.toLowerCase();
      b = b.firstName?.toLowerCase() + " " + b.lastName?.toLowerCase()
      if (a < b) {
        return -1;
      }
      if (b > a) {
        return 1;
      }
      return 0;
    }
    )
  }

  selectAllSlides(event: any) {
    if (event.checked) {
      this.headerFields.selectedSlides = [...this.headerFieldsOptions.slideArr];
    } else {
      this.headerFields.selectedSlides = [];
    }
  }
}


