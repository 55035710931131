import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public finalImgFiles: any = [];
  public uploadIndex: any = 0;
  private storedDsToken: any;
  private config: any;
  public isDsTokenStored: boolean = false;
  public dsComment: any;
  public userActiveRole: any;
  refreshPopIsOpened: boolean = false;
  timerSetTimeOut: any;
  public filesWithMetaData: any = [];
  public uploadDisabled: boolean = true;
  public folderId: any;
  projectId: any;
  projectName: any;
  constructor(
    private http: HttpClient,
    private configService: AppConfigService
  ) {
    this.config = configService.config;
  }

  createUser(userData: any) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}user/create`, userData);
  }

  editUser(userData: any) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/makeUserInactive`, userData);
  }
  updateUser(userData: any) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/updateUser`, userData);
  }

  activateUser(emailId: any) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/makeUserActive`, emailId);
  }

  getAllRoles() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}user/roles/all`);
  }

  getAllUsers() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}user`);
  }
  setDsToken(dsToken: any) {
    this.storedDsToken = dsToken;
  }
  getDsToken() {
    return this.storedDsToken;
  }

  getNotifications() {

    return this.http.get(`${this.config?.apiConfig?.apiUrl}study/notification`);
  }

  deleteNotification(notificationIdsList: any) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/notification/delete`, notificationIdsList);
  }

  setActiveUser(role: any) {
    this.userActiveRole = role;
  }

  getActiveUserRole() {
    return this.userActiveRole;
  }

  getImageMetaData(imageid: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/metadata?type=IMAGE&id=${imageid}`);
  }

  downloadImages(imageid: string, imageName: string): Observable<any> {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/image/download-image?imageIds=${imageid}`, { responseType: 'blob', reportProgress: true, observe: 'events', });
  }

  checkImagesForUpload(imageNames: any) {
    let imageFile = imageNames;
    let key = "imageFile";
    let imagesData = {
      [key]: imageFile
    };
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/image/uploadcheck`, imagesData);
  }

  uploadImages(images: any): Observable<HttpEvent<any>> {
    const req = new HttpRequest('POST', `${this.config.apiConfig.apiUrl}slide-image/image/upload`, images, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  uploadImage(callBack: any, reloadList: any) {
    if (this.uploadIndex < this.finalImgFiles.length) {
      if (!this.finalImgFiles[this.uploadIndex].isFileExist || this.finalImgFiles[this.uploadIndex].alreadySelected) {
        this.finalImgFiles[this.uploadIndex].hideRemoveIcon = true;
        this.uploadIndex += 1;
        this.uploadImage(callBack, reloadList);
        return;
      }
      const frmData = new FormData();
      if (this.finalImgFiles[this.uploadIndex]?.metaDataFiles?.length) {
        frmData.append("files", this.finalImgFiles[this.uploadIndex]);
        for (let a = 0; a < this.finalImgFiles[this.uploadIndex]?.metaDataFiles.length; a++) {
          frmData.append("imageMetadata", this.finalImgFiles[this.uploadIndex]?.metaDataFiles[a]);
        }
        frmData.append("imageSource", 'local');
      } else {
        frmData.append("files", this.finalImgFiles[this.uploadIndex]);
        frmData.append("imageSource", 'local');
      };
      if (this.projectId) {
        frmData.append("projectId", this.projectId);
      };

      if (this.folderId) {
        frmData.append("folderId", this.folderId);
      };

      this.uploadImages(frmData).subscribe((item: any) => {
        if (item.type === HttpEventType.UploadProgress) {
          this.uploadDisabled = true;
          this.finalImgFiles[this.uploadIndex].hideRemoveIcon = true;
          this.finalImgFiles[this.uploadIndex].progressValue = Math.round(100 * item.loaded / item.total);
        }
        else {
          if (item instanceof HttpResponse) {
            if (item.status == 200) {
              this.finalImgFiles[this.uploadIndex].completeStatus = true;
              this.finalImgFiles[this.uploadIndex].fileId = item.body.data[0]?.imageId;
              this.finalImgFiles[this.uploadIndex].isFileUploadCompleted = true;
              this.finalImgFiles[this.uploadIndex].hideRemoveIcon = true;
            }
            if (callBack) {
              callBack(this.finalImgFiles[this.uploadIndex]);
            }
            if (reloadList) {
              reloadList('upload');
            }
            this.uploadIndex += 1;
            this.uploadImage(callBack, reloadList);
          }
        }
      }, (err: any) => {
        this.finalImgFiles[this.uploadIndex].progressValue = 0;
        this.finalImgFiles[this.uploadIndex].completeStatus = false;
        this.finalImgFiles[this.uploadIndex].fileId = '';
        this.finalImgFiles[this.uploadIndex].isFileUploadCompleted = false;
        this.finalImgFiles[this.uploadIndex].hideRemoveIcon = true;
        if (this.finalImgFiles[this.uploadIndex]?.metaDataFiles?.length) {
          this.finalImgFiles[this.uploadIndex].hideMetadataAddition = true;
        }
        this.uploadIndex += 1;
        this.uploadImage(callBack, reloadList);
      });
    }
    else {
      this.uploadDisabled = true;
    }
  }
}