import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './../../common.service';
import { UsersService } from './../users.service';
import { ConfirmDialogComponent } from './../../common/confirm-dialog/confirm-dialog.component';
import { UserCreateComponent } from './../user-create/user-create.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { DisplayedColumns, MatDataTableComponent } from 'src/app/shared/mat-data-table/mat-data-table.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-user-listing',
  templateUrl: './user-listing.component.html',
  styleUrls: ['./user-listing.component.less']
})
export class UserListingComponent implements OnInit {
  isAciveUser: string = 'Active';
  activeUserArr = [];
  activeUserCount: number = 0;
  inactiveUserCount: number = 0;
  inactiveUserArr = [];
  loggedInUserCount: number = 0;
  loggedInUserArr = [];
  dtOptions: any;
  userSearchText: any = '';
  roleShortText: any = {
    "APPLICATION_ADMIN": "A",
    "STUDY_DIRECTOR": "SD",
    "TECHNICIAN": "T",
    "PATHOLOGIST": "P",
    "EXTERNAL_PATHOLOGIST": "EP"
  };

  @ViewChild('matDataTable') matDataTable: MatDataTableComponent;

  usersDataSource: MatTableDataSource<any>;
  usersDisplayedColumns: DisplayedColumns[];
  hasUserDataLoaded = false;

  constructor(
    private dialog: MatDialog,
    private usersService: UsersService,
    public commonService: CommonService,
    public datepipe: DatePipe,
    public translate: TranslateService,
    private sanitizer: DomSanitizer
  ) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit() {
    this.rerender('Active');
  }

  onMatDataTableLoaded() {
    $('#userSearch').on('keyup click', (e) => {
      this.matDataTable.applySearch($(e.currentTarget).val() as string);
    });
  }

  rerender(paramValue) {
    this.usersService.getAllUsers().subscribe((res: any) => {
      this.activeUserArr = res.data.ACTIVE;
      this.activeUserCount = this.activeUserArr.length;
      this.inactiveUserArr = res.data.INACTIVE;
      this.inactiveUserCount = this.inactiveUserArr.length;
      this.loggedInUserArr = res.data.LOGGEDIN;
      this.loggedInUserCount = this.loggedInUserArr.length;
      if (paramValue == 'Active') {
        this.isAciveUser = 'Active';
        this.getActiveUsers();
      } else if (paramValue == 'Inactive') {
        this.isAciveUser = 'Inactive';
        this.getInactiveUsers();
      }
    })
  }

  onRowClick(rowData: any) {
    switch(rowData.elementId) {
      case 'imageicon':
        this.showCreateUserModal("Edit", rowData);
        break;
      case 'deactivateuser':
        this.showDeactivateUserModal(rowData);
        break;
      case 'activateuser':
        this.showActivateUserModal(rowData);
        break;
      default:
        break;
    }
  }

  getActiveUsers() {
    this.hasUserDataLoaded = false;
    this.usersDataSource = new MatTableDataSource(this.activeUserArr.map(user => {
      if (user.creationDate) {
        user.creationDate = this.datepipe.transform(user.creationDate, 'dd/MM/yyyy');
      }

      if (user.roles) {
        let roles = []
        for (let i = 0; i < user.roles?.length; i++) {
          let roleFullText: any = this.commonService.camelCase(user.roles[i]).replace("_", " ");
          let roleText: any = this.roleShortText[user.roles[i]];
          roles.push("<div class='indvRole' title='" + roleFullText + "'>" + roleText + "</div>");
        }
        user.rolesTemplate = roles.join(" ");
      }

      user.icons = this.sanitizer.bypassSecurityTrustHtml("<a class='imgicontable' id='editUserIcon'><i id='imageicon' class='fa fa-edit imageicon' title='Edit'></i></a>&nbsp;<a class='imgicontable' id='deActivateUserIcon'><i id='deactivateuser' class='fa fa-user-o deactivateuser' imageicon aria-hidden='true' title='Deactivate User'></i></a>");

      return user;
    }));

    this.usersDisplayedColumns = [
      { key: 'icons', value: '', isVisible: true, width: '6%', cellType: 'template', isSortDisabled: true },
      { key: 'firstName', value: 'First Name', isVisible: true, width: '16%', cellType: 'normal' },
      { key: 'lastName', value: 'Last Name', isVisible: true, width: '16%', cellType: 'normal' },
      { key: 'emailId', value: 'Email ID', isVisible: true, width: '20%', cellType: 'normal' },
      { key: 'creationDate', value: 'Creation Date', isVisible: true, width: '12%', cellType: 'normal' },
      { key: 'rolesTemplate', value: 'Roles', isVisible: true, width: '30%', cellType: 'template', isSortDisabled: true },
    ];
    setTimeout(() => this.hasUserDataLoaded = true, 500);
  }

  getInactiveUsers() {
    this.hasUserDataLoaded = false;
    this.usersDataSource = new MatTableDataSource(this.inactiveUserArr.map(user => {
      if (user.creationDate) {
        user.creationDate = this.datepipe.transform(user.creationDate, 'dd/MM/yyyy');
      }

      if (user.roles) {
        let roles = []
        for (let i = 0; i < user.roles?.length; i++) {
          let roleFullText: any = this.commonService.camelCase(user.roles[i]).replace("_", " ");
          let roleText: any = this.roleShortText[user.roles[i]];
          roles.push("<div class='indvRole' title='" + roleFullText + "'>" + roleText + "</div>");
        }
        user.rolesTemplate = roles.join(" ");
      }

      user.icons = this.sanitizer.bypassSecurityTrustHtml("<a class='imgicontable' id='activateUserIcon'><i id='activateuser' class='fa fa-user-o' imageicon aria-hidden='true' title='Activate User'></i></a>");

      return user;
    }));

    this.usersDisplayedColumns = [
      { key: 'icons', value: '', isVisible: true, width: '6%', cellType: 'template', isSortDisabled: true },
      { key: 'firstName', value: 'First Name', isVisible: true, width: '16%', cellType: 'normal' },
      { key: 'lastName', value: 'Last Name', isVisible: true, width: '16%', cellType: 'normal' },
      { key: 'emailId', value: 'Email ID', isVisible: true, width: '20%', cellType: 'normal' },
      { key: 'creationDate', value: 'Creation Date', isVisible: true, width: '12%', cellType: 'normal' },
      { key: 'rolesTemplate', value: 'Roles', isVisible: true, width: '30%', cellType: 'template', isSortDisabled: true },
    ];
    setTimeout(() => this.hasUserDataLoaded = true, 500);
  }

  showCreateUserModal(param, userData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.data = {
      userType: param,
      userData: userData
    }
    const dialogref = this.dialog.open(UserCreateComponent, dialogConfig);
    dialogref.componentInstance.onClose.subscribe(result => {
      if (result) {
        this.rerender(this.isAciveUser)
      }
    });
  }

  showActivateUserModal(userData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: "Activate User",
      confirmMsg: this.translate.instant("GENERIC_MSG.USER.ACTIVATE_USER"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        let editData = {
          "email": userData.emailId,
          "roles": userData.roles,
          "firstName": userData.firstName,
          "lastName": userData.lastName,
        }
        // this.usersService.editUser(editData).subscribe((res: any) => { */
        this.usersService.activateUser(editData).subscribe((res: any) => {
          this.commonService.showToastMsg(res.message);
          this.rerender(this.isAciveUser);
        });
      }
    });
  }

  showDeactivateUserModal(userData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "30%";
    dialogConfig.data = {
      headerTitle: "Deactivate User",
      confirmMsg: this.translate.instant("GENERIC_MSG.USER.DEACTIVATE_USER"),
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes"
    };
    const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
    dialogref.afterClosed().subscribe(result => {
      if (result && result != 'btnClose') {
        let editData = {
          "email": userData.emailId,
          "roles": userData.roles,
          "active": false,
          "firstName": userData.firstName,
          "lastName": userData.lastName
        }
        this.usersService.editUser(editData).subscribe((res: any) => {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.USER.USER_DEACTIVATED"));
          this.rerender(this.isAciveUser);
        });
      }
    });
  }

  clearSearchText() {
    this.userSearchText = '';
    $('#userSearch').val('');
    this.matDataTable.applySearch('');
  }
}