<div class="galleryPageDiv">
  <div class="dialog-header modal-header" *ngIf="data && data.showHeader">
    <div class="headerTitle">
      <div class="dialog-title">Gallery</div>
    </div>
    <div class="headerIcon float-right dn">
      <i class="fa fa-times" (click)="closeDialog()"></i>
    </div>
  </div>

  <div class="galleryMainDiv">
    <div class="responsiveFolderImagesDiv">
      <div class="gallerySelectionDiv">
        <div class="gallerySelectionLeftDiv">
          <!-- <mat-form-field class="commonMatFormField myStudyDropDown1 myStudyDropDown2" appearance="outline"
          *ngIf="((activeMode == 'gallery') || (activeMode == 'mapped') || (activeMode == 'image-viewer') || (activeMode == 'consultation') || (activeMode == 'consultation-viewer'))">
          <mat-select [(ngModel)]="selectedStudyProj" (selectionChange)="changeStudyProject('change')"
            panelClass="myPanelClass"
            [disabled]="activeMode == 'image-viewer'&& dialogData && (dialogData.dialogType == 'compare' || dialogData.dialogType == 'filter')">
            <mat-option *ngFor="let studyProj of studyProjectList" [value]="studyProj.guid">
              {{studyProj.studyNo}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
          <div class="studyListingNavigation">
            <span class="listingNavigationText" (click)="backToStudyListing()">Study Listing</span>
            <mat-icon class="customMatIcon navigationIcon">chevron_right</mat-icon>
            <span class="activeStudyNameText"
              [ngClass]="(studySelectionLevel?.length ? 'listingNavigationText' : 'activeStudyNameText')"
              (click)="selectOption(activeMode, 'navigation')">{{selectedStudyName}}</span>
            <ng-container *ngFor="let folder of studySelectionLevel; let i = index">
              <mat-icon *ngIf="studySelectionLevel?.length"
                class="customMatIcon navigationIcon">chevron_right</mat-icon>
              <span [title]="folder" class="activeStudyNameText">{{(groupBy == '2') ? displaySexTitle(folder) :
                folder}}</span>
            </ng-container>
          </div>


        </div>
        <ng-container *ngIf="!validateMetataObj?.algorithm?.algorithmId">
          <div class="gallerySelectionRightDivTop">
            <ng-container *ngIf="!galleryService.isStudyRandomised">
              <div class="studyFilterDivTop">

                <button type="button" class="btn-default searchbtn" aria-label="applybtn"
                  *ngIf="!studySelectionLevel?.length && commonService.ProjectLSData['activeUserPermission']?.[0]?.role  === 'PATHOLOGIST'"
                  (click)="changeStudyStatus()">Complete</button>

                <div class="viewByDiv" style="margin-left: 0.25vw !important;" *ngIf="!studySelectionLevel?.length">
                  <mat-form-field
                    *ngIf="((activeMode == 'gallery') || (activeMode == 'mapped') || (activeMode == 'image-viewer' && quickFilterView == 'viewerCustom') || (activeMode == 'consultation')|| (activeMode == 'consultation-viewer'))"
                    class=" commonMatFormField_selectdropdown" appearance="outline">
                    <mat-label class="labelbgViewBy">View By</mat-label>
                    <mat-select [(ngModel)]="groupBy" (selectionChange)="getAccordionData(true)"
                      [disabled]="!hasSlideAccordionData">
                      <mat-option value="3">Tissue</mat-option>
                      <mat-option value="5" *ngIf="!hiddenOptions?.includes('subjectId')">Subject ID</mat-option>
                      <mat-option *ngIf="!galleryFilter?.isTCRStudy" value="1">Dosage</mat-option>
                      <mat-option *ngIf="!galleryFilter?.isTCRStudy && !hiddenOptions?.includes('cassetteId')"
                        value="0">Cassette</mat-option>
                      <mat-option value="2" *ngIf="!hiddenOptions?.includes('sex')">Sex</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <mat-icon class="bookmarkIconSvgIcon cursorPointer dn" title="Bookmarked Images"
                  *ngIf="studySelectionLevel?.length" [ngClass]="{'activeBookMarkSvg' : isBookmarkActive}"
                  svgIcon="bookmarkIcon" (click)="bookmarkToggle()"></mat-icon>

                <div class="tabordion" style="margin-right: 0.75vw; margin-left: .7vw;"
                  *ngIf="((activeMode == 'gallery' || activeMode == 'mapped') && studySelectionLevel?.length)">
                  <section id="section1">
                    <mat-icon class="mainFilterSvgIcon cursorPointer" title="Filter" [ngClass]="{'mainFilterActiveSvgIcon': isFilterApplied || this.commonService.ProjectLSData.galleryFilters?.filterBySeverityGrading != 'Show All' ||
                      this.commonService.ProjectLSData.galleryFilters?.readReviewFlag
                      , 'disableGalleryFiltersClass' : !hasSlideAccordionData}" (click)="toggleFilter()"
                      [matMenuTriggerFor]="galleryFilterPopOver" svgIcon="mainGalleryFilterIcon"></mat-icon>
                    <mat-menu #galleryFilterPopOver="matMenu" class="galleryFilterPopOver">
                      <div class="galleryFilterSpacing" *ngIf="activeMode == 'gallery'"></div>
                      <div class="galleryHeaderFilterPopOverRowDiv dn"
                        *ngIf="((activeMode == 'gallery') && appConfig?.webAppConfig?.showSeverityGradingFilter)"
                        (click)="$event.stopPropagation();">
                        <mat-form-field class="commonMatFormField_selectdropdown" appearance="outline">
                          <mat-label class="labelbg">Severity Grade</mat-label>
                          <mat-select [(ngModel)]="filterBySeverityGrading" (click)="$event.stopPropagation();"
                            (selectionChange)="(this.studySelectionLevel?.length) ? getPageWiseData(this.studySelectionLevel[0] , 'init') : getAccordionData(true)">
                            <mat-option value="Show All">Show All</mat-option>
                            <mat-option *ngFor="let indvGradingGrade of currentStudyGradingGrade"
                              [value]="indvGradingGrade.severityCode">
                              {{indvGradingGrade.severityCode + ' - ' +indvGradingGrade.description}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="galleryHeaderFilterPopOverRowDiv dn" *ngIf="activeMode == 'gallery'"
                        (click)="$event.stopPropagation();">
                        <mat-form-field class="commonMatFormField_selectdropdown " appearance="outline">
                          <mat-label class="labelbg">Status</mat-label>
                          <mat-select [(ngModel)]="readReviewFlag" (click)="$event.stopPropagation();"
                            (selectionChange)="(this.studySelectionLevel?.length) ? getPageWiseData(this.studySelectionLevel[0] , 'init') : getAccordionData(true)">
                            <mat-option value="">Show All</mat-option>
                            <!-- <mat-option value="READ">Read</mat-option>
                            <mat-option value="UNREAD">Unread</mat-option> -->
                            <mat-option value="LATER">Review Later</mat-option>
                            <mat-option value="DONE">Review Done</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>

                      <ng-container *ngIf="studySelectionLevel?.length">
                        <div class="galleryFilterDivider dn" *ngIf="activeMode == 'gallery' && (studyOrganList?.length || studyAnimalList?.length || studyDosageList?.length || 
                        folderOrganList?.length  || folderAnimalList?.length || folderDosageList?.length)">
                        </div>

                        <div class="galleryHeaderFilterPopOverRowDiv"
                          *ngIf="(studyDosageList?.length || folderDosageList?.length) && false "
                          [ngClass]="{'disableGalleryFiltersClass' : studySelectionLevel?.length && commonService.ProjectLSData.galleryFilters?.viewByG == '1'}"
                          (click)="$event.stopPropagation();">
                          <div class="galleryToggleDiv" (click)="$event.stopPropagation()">
                            <fieldset class="filterFieldSet">
                              <legend class="statusTitle">Dosage</legend>
                              <mat-button-toggle-group class="galleryFilterGrp" name="filterGrp" [(ngModel)]="dosageF"
                                aria-label="Dosage Filter" multiple>
                                <mat-button-toggle
                                  *ngFor="let animal of (!studySelectionLevel?.length) ?  studyDosageList : folderDosageList"
                                  [title]="animal.dosageName" [value]="animal.dosageCode">{{ animal.dosageName
                                  }}</mat-button-toggle>
                              </mat-button-toggle-group>
                            </fieldset>
                          </div>
                        </div>

                        <div class="galleryHeaderFilterPopOverRowDiv" (click)="$event.stopPropagation();"
                          *ngIf="(studyDosageList?.length || folderDosageList?.length)"
                          [ngClass]="{'disableGalleryFiltersClass' :studySelectionLevel?.length &&  commonService.ProjectLSData.galleryFilters?.viewByG == '1'}">
                          <mat-form-field class="commonMatFormField_selectdropdown" appearance="outline">
                            <mat-label class="labelbg">Dosage</mat-label>
                            <mat-select multiple [(ngModel)]="dosageF" (click)="$event.stopPropagation();">
                              <mat-option
                                *ngFor="let animal of (!studySelectionLevel?.length) ? studyDosageList : folderDosageList"
                                [title]="animal.dosageName" [value]="animal.dosageCode">
                                {{ animal.dosageName }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <div class="galleryHeaderFilterPopOverRowDiv" *ngIf="studyStainList?.length > 1"
                          (click)="$event.stopPropagation();">
                          <div class="galleryToggleDiv" (click)="$event.stopPropagation()">
                            <fieldset class="filterFieldSet">
                              <legend class="statusTitle">Stain</legend>
                              <mat-button-toggle-group class="galleryFilterGrp" name="filterGrp" [(ngModel)]="stainsF"
                                aria-label="Stain Filter" multiple>
                                <mat-button-toggle *ngFor="let stain of  studyStainList " [title]="stain.stain"
                                  [value]="stain.stain">{{ stain.stain }}</mat-button-toggle>
                              </mat-button-toggle-group>
                            </fieldset>
                          </div>
                        </div>
                        <div class="galleryHeaderFilterPopOverRowDiv" (click)="$event.stopPropagation();"
                          *ngIf="!hiddenOptions?.includes('subjectId') && (studyAnimalList?.length || folderAnimalList?.length)"
                          [ngClass]="{'disableGalleryFiltersClass' :studySelectionLevel?.length &&  commonService.ProjectLSData.galleryFilters?.viewByG == '5'}">
                          <mat-form-field class="commonMatFormField_selectdropdown" appearance="outline">
                            <mat-label class="labelbg">Subject ID</mat-label>
                            <mat-select multiple [(ngModel)]="animalsF" (click)="$event.stopPropagation();">
                              <mat-option
                                *ngFor="let animal of (!studySelectionLevel?.length) ? studyAnimalList : folderAnimalList"
                                [title]="animal.animalNo" [value]="animal.animalNo">
                                {{ animal.animalNo }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="galleryHeaderFilterPopOverRowDiv" (click)="$event.stopPropagation();"
                          *ngIf="studyOrganList?.length || folderOrganList?.length"
                          [ngClass]="{'disableGalleryFiltersClass' :studySelectionLevel?.length &&  commonService.ProjectLSData.galleryFilters?.viewByG == '3'}">
                          <mat-form-field class="commonMatFormField_selectdropdown" appearance="outline">
                            <mat-label class="labelbg">Tissue</mat-label>
                            <mat-select multiple [(ngModel)]="organsF" (click)="$event.stopPropagation();">
                              <mat-option
                                *ngFor="let organ of (!studySelectionLevel?.length) ? studyOrganList : folderOrganList"
                                [title]="organ.organ" [value]="organ.organ">{{organ.organ}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                        <div class="galleryHeaderFilterPopOverRowDiv" *ngIf="galleryFilter?.isTCRStudy"
                          (click)="$event.stopPropagation();">
                          <mat-form-field class="commonMatFormField_selectdropdown" appearance="outline">
                            <mat-label class="labelbg">Type</mat-label>
                            <mat-select multiple [(ngModel)]="controlF" (click)="$event.stopPropagation();">
                              <mat-option *ngFor="let control of slideControl" [title]="control.optionId"
                                [value]="control.optionId">
                                {{ control.optionName }}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>

                        <div class="galleryFilterBtnDiv" *ngIf="studyOrganList?.length || studyAnimalList?.length || studyDosageList?.length 
                      || folderOrganList?.length  || folderAnimalList?.length || folderDosageList?.length">
                          <button type="button" class="btn-default" aria-label="cancelbtn"
                            (click)="toggleFilter()">Close</button>
                          <button type="button" class="btn-default" aria-label="resetbtn"
                            (click)="resetFilter($event)">Reset</button>
                          <button type="button" class="btn-default searchbtn" aria-label="applybtn"
                            (click)="applyFilter($event)">Apply</button>
                        </div>
                      </ng-container>

                    </mat-menu>
                  </section>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <ng-container *ngIf="!loader else showSpinner">
        <div class="bodyWrapperDiv">
          <ng-container *ngIf="!validateMetataObj?.algorithm?.algorithmId">
            <div class="folderImagesWrapper" [ngStyle]="{'width': (imagePreview.state ? '75%' : '100%')}">
              <div class="gallerySelectionRightDiv"
                *ngIf="studySelectionLevel?.length ? slidesArr?.length : studyListingData?.length">
                <ng-container *ngIf="!galleryService.isStudyRandomised">
                  <div class="studyFilterDiv">
                    <ng-container *ngIf="slidesArr?.length && studySelectionLevel?.length">
                      <div class="selectAllDiv">
                        <mat-checkbox [(ngModel)]="isSelectAllActive" (change)="selectAllImages($event)"
                          aria-label="Select All" color="primary">
                          Select All
                        </mat-checkbox>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="studyListingData?.length && !studySelectionLevel?.length">
                      <div class="selectAllDiv">
                        <mat-checkbox [(ngModel)]="isSelectAllActiveForFolder" (change)="selectAllFolders($event)"
                          aria-label="Select All" color="primary">
                          Select All
                        </mat-checkbox>
                      </div>
                    </ng-container>

                    <div class="imageActionsDiv">
                      <ng-container
                        *ngIf="appConfig?.galleryConfig?.removeResultsIcon && (galleryService.selectedSlidesArr?.length || selectedFolder?.length)">
                        <mat-icon class="removeResultsIcons cursorPointer" style="margin-right: 0.5vw;"
                          title="Remove Results" [matMenuTriggerFor]="algoMenuT">leak_remove</mat-icon>
                        <mat-menu #algoMenuT="matMenu" xPosition="before">
                          <div class="galleryAnalyseMenu">
                            <ng-container *ngIf="activeMode=='mapped'">
                              <ng-container *ngFor="let alogo of algorithmList">
                                <ng-container *ngFor="let algoName of alogo?.algorithms">
                                  <div *ngIf="alogo?.algorithmType == 'QC'" class="galleryAlgoName"
                                    (click)="removeAlgorithmsResults(algoName)">
                                    {{algoName?.name}}
                                  </div>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                            <ng-container *ngIf="activeMode=='gallery'">
                              <ng-container *ngFor="let alogo of algorithmList">
                                <div class="galleryAlogoType">
                                  {{(alogo?.algorithmType == 'ORGAN_TOXICITY') ? 'ORGAN TOXICITY' :
                                  alogo?.algorithmType}}
                                </div>
                                <ng-container *ngFor="let algoName of alogo?.algorithms">
                                  <div class="galleryAlgoName" (click)="removeAlgorithmsResults(algoName)">
                                    {{algoName?.name}}
                                  </div>
                                </ng-container>
                              </ng-container>
                            </ng-container>
                          </div>
                        </mat-menu>
                      </ng-container>

                      <button type="button " class="btn-default searchbtnStudyGallery filterBarItems "
                        aria-label="splitbtn " *ngIf="galleryService.selectedSlidesArr?.length && false"
                        (click)="checkSelection() ">
                        Check
                      </button>

                      <!-- As told by HM - hiding Generate Thumbnail Btn -->
                      <ng-container
                        *ngIf="(galleryService.selectedSlidesArr?.length || selectedFolder?.length) && appConfig.galleryConfig.generateThumbnail">
                        <button type="button " class="btn-default searchbtnStudyGallery filterBarItems "
                          aria-label="splitbtn " *ngIf="activeMode=='mapped' && !thumbnailGenStatus"
                          (click)="generateNewThumbnails() ">
                          Generate Thumbnail
                        </button>
                      </ng-container>
                      <button *ngIf="thumbnailGenStatus && appConfig.galleryConfig.generateThumbnail"
                        class="btn-default searchbtnStudyGallery filterBarItems" type="button" disabled>
                        <span class="spinner-border spinner-border-sm spinner-class" role="status"
                          aria-hidden="true"></span>
                        <span>Generating Thumbnail...</span>
                      </button>

                      <ng-container
                        *ngIf="(appConfig.galleryConfig.enableImageDownload && commonService.ProjectLSData['activeUserPermission']?.[0]?.downloadPermission && galleryService.selectedSlidesArr?.length )">
                        <button type="button " class="btn-default searchbtnStudyGallery filterBarItems "
                          [ngClass]="{ 'searchbtnStudyGalleryDisabled' : selectedImagesWithReview?.length || selectedFolderWithReview?.length}"
                          aria-label="splitbtn " (click)="DownloadZip()">Download</button>
                      </ng-container>

                      <ng-container *ngIf="(galleryService.selectedSlidesArr?.length || selectedFolder?.length)">
                        <button type="button " class="btn-default searchbtnStudyGallery filterBarItems "
                          [ngClass]="{ 'searchbtnStudyGalleryDisabled' : selectedImagesWithReview?.length || selectedFolderWithReview?.length}"
                          aria-label="splitbtn " *ngIf="activeMode=='mapped' && !unmappingStatus"
                          (click)="openBulkUnMapDialog() ">Reject
                          Images</button>
                      </ng-container>
                      <button *ngIf="unmappingStatus" class="btn-default searchbtnStudyGallery filterBarItems"
                        type="button" disabled>
                        <span class="spinner-border spinner-border-sm spinner-class" role="status"
                          aria-hidden="true"></span>
                        <span>Rejecting Images</span>
                      </button>

                      <button type="button" class="reviewBtnClass searchbtnStudyGalleryForIcon filterBarItems dn"
                        aria-label="splitbtn"
                        [ngClass]="{ 'disablebtn_func' : !this.galleryService?.selectedSlidesArr?.length}"
                        *ngIf="activeMode == 'gallery' && !galleryService.isStudyRandomised && studySelectionLevel?.length && (galleryService.selectedSlidesArr?.length || selectedFolder?.length)"
                        [matMenuTriggerFor]="reviewMenu">
                        <mat-icon class="iconflip" title="Status">reply</mat-icon>
                      </button>
                      <mat-menu #reviewMenu="matMenu" xPosition="before">
                        <!-- <div class="galleryAlgoName" (click)="changeReadStatus('READ')">
                          Mark as Read
                        </div>
                        <div class="galleryAlgoName" (click)="changeReadStatus('UNREAD')">
                          Mark as Unread
                        </div> -->
                        <div class="galleryAlgoName" (click)="changeReviewStatus('LATER')">
                          Review Later
                        </div>
                        <div class="galleryAlgoName" (click)="changeReviewStatus('DONE')">
                          Review Done
                        </div>
                      </mat-menu>

                      <button type="button" class="btn-default searchbtnStudyGallery filterBarItems"
                        aria-label="splitbtn" [ngClass]="{ 'disableAnalysisBtn' : !algorithmList?.length}"
                        *ngIf="!galleryService.isStudyRandomised && (galleryService.selectedSlidesArr?.length || selectedFolder?.length)"
                        [matMenuTriggerFor]="algoMenu" style="margin-right: 0 !important;">
                        Analyse
                      </button>
                      <mat-menu #algoMenu="matMenu" xPosition="before">
                        <div class="galleryAnalyseMenu">
                          <ng-container *ngIf="activeMode=='mapped'">
                            <ng-container *ngFor="let alogo of algorithmList">
                              <ng-container *ngFor="let algoName of alogo?.algorithms">
                                <div *ngIf="alogo?.algorithmType == 'QC'" class="galleryAlgoName"
                                  (click)="triggerAnalysis(algoName, alogo?.algorithmType)">
                                  {{algoName?.name}}
                                </div>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="activeMode=='gallery'">
                            <ng-container *ngFor="let alogo of algorithmList">
                              <div class="galleryAlogoType">
                                {{(alogo?.algorithmType == 'ORGAN_TOXICITY') ? 'ORGAN TOXICITY' :
                                alogo?.algorithmType}}
                              </div>
                              <ng-container *ngFor="let algoName of alogo?.algorithms">
                                <div class="galleryAlgoName"
                                  (click)="(alogo?.algorithmType == 'QC')  ? triggerAnalysis(algoName, alogo?.algorithmType) :  validateMetadata(algoName, alogo?.algorithmType) ">
                                  {{algoName?.name}}
                                </div>
                              </ng-container>
                            </ng-container>
                          </ng-container>
                        </div>
                      </mat-menu>

                      <ng-container *ngIf="appConfig?.webAppConfig?.isRandomizationEnabled">
                        <button type="button" class="btn-default searchbtnStudyGallery filterBarItems"
                          (click)="startRandomization()"
                          *ngIf="(activeMode == 'gallery') && !galleryService.isStudyRandomised"
                          [ngClass]="{ 'disablebtn_func' : (galleryService.selectedSlidesArr.length == 0)}">Start
                          Randomisation
                          ({{galleryService.selectedSlidesArr.length}})
                        </button>
                        <button type="button" class="btn-default searchbtnStudyGallery filterBarItems"
                          (click)="stopRandomization()"
                          *ngIf="(activeMode == 'gallery') && galleryService.isStudyRandomised">Stop
                          Randomisation
                        </button>
                        <button type="button" class="btn-default searchbtnStudyGallery filterBarItems"
                          (click)="openRandomizedImaeDetailPopup()"
                          *ngIf="(activeMode == 'gallery') && galleryService.isStudyRandomised">Verify
                        </button>
                      </ng-container>
                    </div>

                  </div>
                </ng-container>
              </div>
              <ng-container *ngIf="!studySelectionLevel?.length">
                <ng-container *ngIf="studyListingData?.length">
                  <div class="studyListingGridDiv">
                    <div class="stidyListingContent">
                      <ng-container *ngFor="let study of studyListingData ;let i=index">
                        <div class="indvStudy">
                          <div class="checkboxDiv responsiveCheckBox">
                            <mat-checkbox (change)="folderSelection(study, $event)" [ngModel]="study.selection"
                              color="primary" disableRipple></mat-checkbox>
                          </div>
                          <div class="folderIconDiv" [title]="study?.attribute"
                            (click)="getPageWiseData(study?.attribute, 'navigation',this.galleryFilter.viewByG)">
                            <mat-icon class="folderSvg" svgIcon="folderSvgIcon"></mat-icon>
                          </div>
                          <div class="imageName" [title]="study?.attribute"
                            (click)="getPageWiseData(study?.attribute, 'navigation',this.galleryFilter.viewByG)">
                            {{(groupBy == '2') ? displaySexTitle(study?.attribute) : study?.attribute}}
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="!studyListingData?.length">
                  <div class="noDataToDisplayDivStudyGallery">
                    <img src="../../../assets/images/viewer_icon/no_study_project.png" />
                  </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="studySelectionLevel?.length">
                <ng-container *ngIf="slidesArr?.length">
                  <ng-container *ngIf="galleryFilter?.viewType== 'grid'">
                    <div class="studyListingGridDiv" (scroll)="onGridScroll()" #uiElement>
                      <div class="stidyListingContent">
                        <ng-container *ngFor="let slide of slidesArr; let i = index">
                          <div class="indvImage slideDiv"
                            [ngClass]="(imagePreview.currItemSelected?.slidePk == slide.slidePk)?'currentSlidePreviewDiv':'indvImage'">
                            <div class="checkboxDiv responsiveCheckBox">
                              <mat-checkbox (change)="imageSelection(slide, $event)" color="primary" disableRipple
                                [checked]="slide.selection"></mat-checkbox>
                            </div>
                            <div class="topIconsDiv">
                              <div class="statusIconsDiv" *ngIf="slide.analysisStatus == 'IN_PROGRESS'">
                                <mat-spinner class="saveSpinner" color="white"
                                  [title]="slide['analysisProgress'] ? slide['analysisProgress'] + '%' : 'Inprogress'"
                                  [diameter]="commonService.vwTOpx('1vw')"></mat-spinner>
                              </div>
                              <div class="statusIconsDiv" *ngIf="slide.analysisStatus == 'QUEUED'">
                                <mat-icon svgIcon="queuedForAnalysis" title="Queued" class="queuedIcon"></mat-icon>
                              </div>
                              <div class="statusIconsDiv" *ngIf="slide.bookmark && userActiveRole  === 'PATHOLOGIST'">
                                <mat-icon svgIcon="bookmarkIcon" title="Bookmarked"
                                  class="bookmarkIconSvgIconForGrid"></mat-icon>
                              </div>
                            </div>
                            <div *ngIf="appConfig?.galleryConfig?.showThumbnail" class="thumbnailDiv cursorPointer"
                              (click)="openViewer(slide, i, slide.key)">
                              <img class="thumbnail" [defaultImage]="defaultImagePath" #refEl1
                                [ngStyle]="{'transform': 'rotate('+appConfig?.galleryConfig?.thumbnailRotation+'deg)'}"
                                [lazyLoad]="slide.thumbnailPath"
                                (onStateChange)="commonService.reloadFailedLazyLoadImg($event, slide, refEl1, 'thumbnailPath', false)">
                            </div>

                            <div *ngIf="!appConfig?.galleryConfig?.showThumbnail" class="gridImgMainDiv"
                              (click)="openViewer(slide, i, slide.key)">
                              <ng-container *ngIf="slide.isShowMacroOnly">
                                <div class="ImgMacroOnlyDiv">
                                  <img [defaultImage]="defaultImagePath" #refEl2 [lazyLoad]="slide?.macroPath"
                                    (onStateChange)="commonService.reloadFailedLazyLoadImg($event, slide, refEl2, 'macroPath', false)"
                                    [ngStyle]="{'transform': 'rotate('+slide.macroRotation+'deg)'}"
                                    class="img-responsive animal_img macroImg cursorPointer">
                                </div>
                              </ng-container>
                              <ng-container *ngIf="!slide.isShowMacroOnly">
                                <div class="ImgMacroLabelDiv">
                                  <img [defaultImage]="defaultImagePath" #refEl3 [lazyLoad]="slide.labelPath"
                                    (onStateChange)="commonService.reloadFailedLazyLoadImg($event, slide, refEl3, 'labelPath', false)"
                                    *ngIf="!appConfig?.galleryConfig?.anonymisedLabel"
                                    [ngStyle]="{'transform': 'rotate('+appConfig?.galleryConfig?.labelRotation+'deg)'}"
                                    [ngClass]="{'dn': !appConfig?.galleryConfig?.customImgFormatThumbnailRotateDegree}"
                                    class="img-responsive animal_img labelImg cursorPointer combineImagTag"
                                    alt="labelPath">
                                  <img class="img-responsive animal_img dummyQRCode cursorPointer qrImagTag"
                                    *ngIf="appConfig?.galleryConfig?.anonymisedLabel"
                                    [ngClass]="(appConfig?.galleryConfig?.customImgFormatThumbnailRotateDegree) ? '' : 'dn'"
                                    [src]="'../../../' + appConfig?.webAppConfig?.appBaseName + 'assets/images/defaultLable_qr.png'" />
                                  <div class="topImgDiv">
                                    <img [defaultImage]="defaultImagePathMacro" #refEl4 [lazyLoad]="slide?.macroPath"
                                      (onStateChange)="commonService.reloadFailedLazyLoadImg($event, slide, refEl4, 'macroPath', false)"
                                      [ngStyle]="{'transform': 'rotate('+slide.macroRotation+'deg)'}"
                                      class="img-responsive animal_img macroImg cursorPointer imgTag">
                                  </div>
                                </div>
                              </ng-container>
                            </div>

                            <!-- <div class="imageName" title="{{slide.slidename}}">
                              {{slide.slidename}}
                            </div> -->

                            <div class="imageName"
                              [title]="!hiddenOptions?.includes('subjectId') ? slide.dosageCode +', '+ slide.animalNo : slide.dosageCode + ', ' + subjectIdPrefix + slide.animalNo">
                              {{!hiddenOptions?.includes('subjectId') ? slide.dosageCode +', ' + slide.animalNo :
                              slide.dosageCode + ', ' + subjectIdPrefix + slide.animalNo }}
                            </div>

                            <div class=" imageIconsDiv">
                              <mat-icon class="galleryPreviewIcon cursorPointer" title="Show Preview"
                                (click)="togglePreviewPanel(slide,i, '')"
                                [ngClass]="(imagePreview.currItemSelected?.slidePk == slide.slidePk)?'activePreviewEye':'normalPreviewIcon'">remove_red_eye
                              </mat-icon>
                              <mat-icon *ngIf="slide?.reviewStatus == 'DONE' && userActiveRole  === 'PATHOLOGIST'"
                                class="newSvgIconsDONE newSvgIcons" title="Review Done" svgIcon="slideReviewIcon">
                              </mat-icon>
                              <mat-icon *ngIf="slide?.reviewStatus == 'LATER'  && userActiveRole  === 'PATHOLOGIST'"
                                class="newSvgIconsNONE newSvgIcons" title="Review Later" svgIcon="slideReviewIcon">
                              </mat-icon>
                              <!-- <mat-icon *ngIf="slide?.reviewStatus == 'NONE' && userActiveRole  === 'PATHOLOGIST'"
                                class=" newSvgIconsLATER newSvgIcons" title="No Review" svgIcon="slideReviewIcon">
                              </mat-icon> -->
                              <!-- <mat-icon *ngIf="slide?.readStatus == 'UNREAD' && userActiveRole  === 'PATHOLOGIST'"
                                class="newSvgIconsUNREAD newSvgIcons" title="Unread" svgIcon="slideReadIcon">
                              </mat-icon>
                              <mat-icon *ngIf="slide?.readStatus == 'READ' && userActiveRole  === 'PATHOLOGIST'"
                                class="newSvgIconsREAD newSvgIcons" title="Read" svgIcon="slideReadIcon">
                              </mat-icon> -->
                              <mat-icon class="previewListIcons" svgIcon="qcResultIcon"
                                [title]="slide?.autoQcStatus ?? 'Completed'"
                                [ngClass]="{'redQCIcon' : slide?.autoQcStatus != null && slide?.autoQcStatus?.toLowerCase() != 'accepted'}"
                                (click)="togglePreviewPanel(slide,i, 'QC')"
                                *ngIf="slide?.qcStatus == 'COMPLETED' || slide?.qcStatus == 'FAILED'">
                              </mat-icon>
                              <mat-icon class="previewListAbnormalityIcons" svgIcon="abnormalityResult"
                                (click)="togglePreviewPanel(slide,i, 'Abnormality')"
                                *ngIf="(slide?.abnormalityStatus == 'COMPLETED' || slide?.abnormalityStatus == 'FAILED' || slide?.abnormalityStatus == 'BOTH')  && userActiveRole  === 'PATHOLOGIST'"
                                title="Abnormality Quantification">
                              </mat-icon>

                              <mat-icon class="corruptImageSVGIcon" *ngIf="slide?.corrupt" title="Corrupt Image"
                                svgIcon="corruptImageIcon">
                              </mat-icon>

                              <!-- download image -->

                              <ng-container
                                *ngIf="appConfig.galleryConfig.enableImageDownload && commonService.ProjectLSData['activeUserPermission']?.[0]?.downloadPermission && !slide.downloProgressValue">
                                <mat-icon class="cursorPointer downloadSpinnerInnerIcon customMatIcon hoverClass"
                                  [ngClass]="(slide.downloProgressValue == 100)?'downloadComplete':''"
                                  (click)="downaloadImage(slide)">get_app</mat-icon>
                              </ng-container>

                              <div class="downloadSpinner"
                                *ngIf="appConfig.galleryConfig.enableImageDownload && commonService.ProjectLSData['activeUserPermission']?.[0]?.downloadPermission && slide.downloProgressValue">
                                <mat-progress-spinner class="cursorPointer matProgSpinner"
                                  [value]="slide.downloProgressValue" [diameter]="commonService.vwTOpx('1.57vw')">
                                </mat-progress-spinner>
                                <mat-icon class="cursorPointer downloadSpinnerInnerIcon customMatIcon"
                                  [ngClass]="(slide.downloProgressValue == 100)?'downloadComplete':''">get_app</mat-icon>
                              </div>

                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="galleryFilter?.viewType== 'list'">
                    <div class="reprocessTableContent">
                      <section class="reprocessTableConentWrapper" #uiElement1>
                        <table *ngIf="slidesArr?.length" mat-table [dataSource]="slidesArr"
                          [ngStyle]="{'width': (imagePreview.state ? '100%' : '100%')}">
                          <ng-container matColumnDef="iconsColumn">
                            <th mat-header-cell *matHeaderCellDef class="activateuser"></th>
                            <td mat-cell *matCellDef="let element;let i = index">
                              <div class="rowSlideStatus responsiveTableCheckBox">

                              </div>
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="imageName">
                            <th mat-header-cell *matHeaderCellDef>Name</th>
                            <td mat-cell *matCellDef="let element">
                              <span class="imageNameSpan cursorPointer">Name</span>
                            </td>

                          </ng-container>

                          <ng-container matColumnDef="dosageColumn">
                            <th mat-header-cell *matHeaderCellDef>
                              Dosage</th>
                            <td mat-cell *matCellDef="let element">Dosage</td>
                          </ng-container>

                          <ng-container matColumnDef="sexColumn">
                            <th mat-header-cell *matHeaderCellDef>
                              Sex</th>
                            <td mat-cell *matCellDef="let element">Sex</td>
                          </ng-container>

                          <ng-container matColumnDef="tissueColumn">
                            <th mat-header-cell *matHeaderCellDef>Tissue</th>
                            <td mat-cell *matCellDef="let element">Tissue</td>
                          </ng-container>


                          <ng-container matColumnDef="empty-row">
                            <td mat-cell *matCellDef="let element">No records found</td>
                          </ng-container>

                          <tr mat-header-row *matHeaderRowDef="imagesTableHeaders; sticky: true"></tr>
                          <ng-container>
                            <tr mat-row
                              *matRowDef="let row; columns: slidesArr?.length > 0 ? imagesTableHeaders : ['empty-row'];">
                            </tr>
                          </ng-container>
                        </table>
                      </section>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="!slidesArr?.length">
                  <div class="noDataToDisplayDivStudyGallery">
                    <img src="../../../assets/images/viewer_icon/no_study_project.png" />
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <ng-container *ngIf="imagePreview.state">
              <div class="imagePreviewMianDiv" [ngStyle]="{'width': (imagePreview.state ? '25%' : '0%')}">
                <div class="imagePreviewTopDiv">
                  <div class="previewToggleDiv">
                    <!-- <mat-icon *ngIf="activePreviewState == 'Slide'" (click)="chnagePreviewImage('Label')"
                      [ngClass]="{'galleryHideComponents' : noLabelText}" class=" cursorPointer"
                      title="Switch to Label View" svgIcon="imageLabelPreview"></mat-icon>
                    <mat-icon *ngIf="activePreviewState == 'Label'" (click)="chnagePreviewImage('Slide')"
                      class=" cursorPointer" title="Switch to Slide View" svgIcon="slideLabelPreview"></mat-icon> -->
                    <ng-container *ngFor="let indvIconInfo of imagePreview.previewToggleBtns; let i=index;">
                      <div class="previewToggleBtn" *ngIf="indvIconInfo.isImgExist">
                        <mat-icon (click)="changePreviewImage(indvIconInfo)" class="cursorPointer"
                          [title]="indvIconInfo.title + ' Image'"
                          svgIcon="{{((!activePreviewState.isSchemaFetching && (imagePreview.currItemSelected.selectedIcon == indvIconInfo.iconName))?'selected':'')+indvIconInfo.iconType}}PreviewIcon"></mat-icon>
                      </div>
                    </ng-container>
                  </div>
                  <div class="navROIDiv"
                    *ngIf="((activePreviewState.iconType == 'roi') && (activePreviewState?.totalROIs > 1))">
                    <mat-icon class="navRoiBtn" (click)="navigateROI('prev')"
                      [ngClass]="{'disableROIBtn':(activePreviewState.selectedROI == 1)}">navigate_before</mat-icon>
                    <div class="roiDispText">{{activePreviewState.selectedROI +' / '+activePreviewState.totalROIs}}
                    </div>
                    <mat-icon class="navRoiBtn" (click)="navigateROI('next')"
                      [ngClass]="{'disableROIBtn':(activePreviewState.selectedROI == activePreviewState.totalROIs)}">navigate_next</mat-icon>
                  </div>
                  <div class="previewCloseDiv">
                    <!-- <span class="barcodeTextDiv" *ngIf="noBarCodeText">
                      {{noBarCodeText?'Barcode not found':(noLabelText ? 'Label not found':'Barcode not found')}}
                    </span> -->
                    <ng-container *ngIf="(activePreviewState.iconType == 'label')">
                      <span class="barcodeTextDiv" *ngIf="noBarCodeText">Barcode not detected</span>
                      <span class="barcodeTextDiv" *ngIf="noBarFoundText">Barcode not found</span>
                      <span class="barcodeTextDiv" *ngIf="noLabelText">Label not found</span>
                    </ng-container>
                    <mat-icon class="customMatIcon previewCloseIcon" (click)="closePreviewPanel()">close</mat-icon>
                  </div>
                </div>
                <div class="imageDisplayDiv">
                  <ng-container *ngIf="activePreviewState.iconType == 'label'">
                    <img class="previewThumbnail cursorPointer" [defaultImage]="defaultImagePath" #refEl5
                      [ngStyle]="{'transform': 'rotate('+appConfig?.galleryConfig?.labelRotation+'deg)'}"
                      *ngIf="!appConfig?.galleryConfig?.anonymisedLabel"
                      [lazyLoad]="imagePreview.currItemSelected?.labelPath"
                      (onStateChange)="commonService.reloadFailedLazyLoadImg($event, imagePreview.currItemSelected, refEl5, 'labelPath', true)"
                      (click)="openViewer(imagePreview.currItemSelected, imagePreview.currItemSelectedIndex, studySelectionLevel[0])" />
                    <img class="previewThumbnail cursorPointer" *ngIf="appConfig?.galleryConfig?.anonymisedLabel"
                      [src]="'../../../assets/images/defaultLable_qr.png'" />
                  </ng-container>
                  <ng-container *ngIf="activePreviewState.iconType == 'macro'">
                    <div class="macroImgDiv">
                      <img class="cursorPointer thumbnailView" #refEl6
                        [lazyLoad]="imagePreview.currItemSelected?.macroPath"
                        (onStateChange)="commonService.reloadFailedLazyLoadImg($event, imagePreview.currItemSelected, refEl6, 'macroPath', true)"
                        [defaultImage]="defaultImagePath"
                        (click)="openViewer(imagePreview.currItemSelected, imagePreview.currItemSelectedIndex, studySelectionLevel[0])">
                    </div>
                  </ng-container>
                  <ng-container *ngIf="activePreviewState.iconType == 'thumbnail'">
                    <div class="macroImgDiv">
                      <img class="cursorPointer thumbnailView" #refEl7
                        [lazyLoad]="imagePreview.currItemSelected?.thumbnailPath"
                        (onStateChange)="commonService.reloadFailedLazyLoadImg($event, imagePreview.currItemSelected, refEl7, 'thumbnailPath', true)"
                        [defaultImage]="defaultImagePath"
                        (click)="openViewer(imagePreview.currItemSelected, imagePreview.currItemSelectedIndex, studySelectionLevel[0])">
                    </div>
                  </ng-container>
                  <ng-container *ngIf="activePreviewState.iconType == 'roi'">
                    <div class="macroImgDiv">
                      <img class="cursorPointer thumbnailView" #refEl8
                        [lazyLoad]="imagePreview.currItemSelected?.roiPath"
                        (onStateChange)="commonService.reloadFailedLazyLoadImg($event, imagePreview.currItemSelected, refEl8, 'roiPath', true)"
                        [defaultImage]="defaultImagePath"
                        (click)="openViewer(imagePreview.currItemSelected, imagePreview.currItemSelectedIndex, studySelectionLevel[0])">
                    </div>
                  </ng-container>
                </div>
                <div class="imgnavigation">
                  <button mat-icon-button [disabled]="disablePreviewNavIcon.prevNavIcon" (click)="navSlide('prev')">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                  </button>
                  <span class="slideName"
                    title="{{imagePreview.currItemSelected?.slidename}}">{{imagePreview.currItemSelected?.slidename}}</span>
                  <button mat-icon-button [disabled]="disablePreviewNavIcon.nextNavIcon" (click)="navSlide('next')">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                  </button>
                </div>
                <div class="previewDetailsDiv"
                  [ngClass]="(imagePreview.currItemSelected?.qcStatus == 'COMPLETED' || imagePreview.currItemSelected?.qcStatus == 'FAILED' || imagePreview.currItemSelected?.abnormalityStatus == 'COMPLETED' || imagePreview.currItemSelected?.abnormalityStatus == 'FAILED' || imagePreview.currItemSelected?.abnormalityStatus == 'BOTH')?'bothTabsActive':'onlyOneTabActive'">
                  <mat-tab-group animationDuration="0ms" class="slidedet_tab"
                    [(selectedIndex)]="slideDetTabSelectedIndex"
                    (selectedTabChange)="swithcPreviewTabs(slideDetTabSelectedIndex)">
                    <mat-tab label="Analysis Result"
                      *ngIf="imagePreview.currItemSelected?.qcStatus == 'COMPLETED'|| imagePreview.currItemSelected?.qcStatus == 'FAILED' || imagePreview.currItemSelected?.abnormalityStatus == 'COMPLETED' || imagePreview.currItemSelected?.abnormalityStatus == 'FAILED' || imagePreview.currItemSelected?.abnormalityStatus == 'BOTH'">
                      <div class="previewResultMainDiv"
                        *ngIf="algorithmList?.length && (qcResults[0]?.quantificationResult?.featureList?.length || imagePreview.currItemSelected?.qcStatus == 'FAILED' || abnormalityResults[0]?.quantificationResult?.featureList?.length || imagePreview.currItemSelected?.abnormalityStatus == 'FAILED' || imagePreview.currItemSelected?.abnormalityStatus == 'BOTH')">
                        <div class="resultIconsDiv">
                          <mat-icon class="previewResultIcons resultIconsBlock" svgIcon="qcResultIcon"
                            [title]="imagePreview.currItemSelected?.autoQcStatus ?? 'Completed'"
                            (click)="switchPreviewResult('QC')"
                            [ngClass]="{'activeResultIcon' : activeResult == 'QC', 'redQCIcon' : imagePreview.currItemSelected?.autoQcStatus != null && imagePreview.currItemSelected?.autoQcStatus?.toLowerCase() != 'accepted'}"
                            *ngIf="imagePreview.currItemSelected?.qcStatus == 'COMPLETED' || imagePreview.currItemSelected?.qcStatus == 'FAILED'">
                          </mat-icon>
                          <mat-icon class="previewResultAbnormalityIcons resultIconsBlock" svgIcon="abnormalityResult"
                            (click)="switchPreviewResult('Abnormality')"
                            [ngClass]="{'activeResultIcon' : activeResult != 'QC'}"
                            *ngIf="(imagePreview.currItemSelected?.abnormalityStatus == 'COMPLETED' || imagePreview.currItemSelected?.abnormalityStatus == 'FAILED' || imagePreview.currItemSelected?.abnormalityStatus == 'BOTH') && this.userActiveRole == 'PATHOLOGIST'"
                            title="Abnormality Quantification">
                          </mat-icon>
                          <div class="multiResultsDiv"
                            *ngIf="activeResult && (activeResult=='QC' ) ? qcResults?.length > 1 : abnormalityResults?.length || imagePreview.currItemSelected?.abnormalityStatus == 'FAILED' || imagePreview.currItemSelected?.abnormalityStatus == 'BOTH'">
                            <!-- [ngClass]="{'disableSelection': abnormalityAlgoResultsList?.length < 2}" -->
                            <mat-form-field *ngIf="activeResult !='QC'"
                              class="commonMatFormField_selectdropdown multiResultsAlgoSelection" appearance="outline">
                              <mat-label class="labelbg">Organ</mat-label>
                              <mat-select [(ngModel)]="selectedAbnormalityAlgo"
                                (selectionChange)="chnageAlgoResultDisplay()">
                                <mat-option *ngFor="let item of abnormalityAlgoResultsList" [value]="item">
                                  {{item?.algorithmName}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <ng-container *ngIf="(activeResult == 'QC') ? qcResults?.length  : algoROIArr?.length ">
                              <mat-form-field class="commonMatFormField_selectdropdown multiResultROIselection"
                                appearance="outline">
                                <mat-label class="labelbg">ROI</mat-label>
                                <mat-select [(ngModel)]="selectedROIResult">
                                  <mat-option *ngFor="let item of (activeResult == 'QC') ? qcResults  : algoROIArr"
                                    [value]="item?.roi">
                                    {{item?.roi + 1}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </ng-container>
                            <!-- <ng-container
                              *ngIf="(activeResult == 'QC') ? qcResults?.length  : abnormalityResults?.length || imagePreview.currItemSelected?.abnormalityStatus == 'FAILED' || imagePreview.currItemSelected?.abnormalityStatus == 'BOTH'">
                              <mat-form-field class="commonMatFormField_selectdropdown multiResultROIselection"
                                appearance="outline">
                                <mat-label class="labelbg">ROI</mat-label>
                                <mat-select [(ngModel)]="selectedROIResult">
                                  <mat-option
                                    *ngFor="let item of (activeResult == 'QC') ? qcResults  : abnormalityResults"
                                    [value]="item?.roi">
                                    {{item?.roi + 1}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </ng-container> -->
                          </div>
                        </div>
                        <div class="previewResultDiv">
                          <div class="resultDiv"
                            *ngIf="algorithmList?.length && qcResults?.length && qcResults[returnQCRoiIndex(selectedROIResult)]?.quantificationResult?.result?.length ">
                            <ng-container *ngIf="activeResult == 'QC'">
                              <div class="indvResult"
                                *ngFor="let string of qcResults[returnQCRoiIndex(selectedROIResult)]?.quantificationResult?.result">
                                {{string}}
                              </div>
                            </ng-container>
                            <ng-container *ngIf="activeResult == 'Abnormality' && this.userActiveRole == 'PATHOLOGIST'">
                              <div class="indvResult"
                                *ngFor="let string of abnormalityResults[returnAbnormalityRoiIndex(selectedROIResult)]?.quantificationResult?.result">
                                {{string}}
                              </div>
                            </ng-container>
                          </div>
                          <ng-container
                            *ngIf="algorithmList?.length && ((activeResult == 'QC') ? (qcResults?.length && qcResults[returnQCRoiIndex(selectedROIResult)]?.quantificationResult?.featureList?.length) : 
                                  (abnormalityResults?.length && abnormalityResults[returnAbnormalityRoiIndex(selectedROIResult)]?.quantificationResult?.featureList?.length && selectedAbnormalityAlgo?.analysisStatus != 'FAILED'))">
                            <table mat-table class="resultFeatureTable"
                              [ngClass]="(activeResult == 'QC') ? 'qcResultTable' : 'abnormalityResultTable'"
                              [dataSource]="(activeResult == 'QC' ? qcResults[returnQCRoiIndex(selectedROIResult)]?.quantificationResult?.featureList : abnormalityResults[returnAbnormalityRoiIndex(selectedROIResult)]?.quantificationResult?.featureList )">
                              <ng-container matColumnDef="color">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <mat-cell *matCellDef="let element;let i = index">
                                  <mat-icon *ngIf="element.name !== 'Tissue Missing'"
                                    [style]="{'color' : element.color}">stop</mat-icon>
                                </mat-cell>
                              </ng-container>

                              <ng-container matColumnDef="featureName">
                                <mat-header-cell *matHeaderCellDef>Feature</mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                  <span class="imageNameSpan" title="{{element.name}}">{{element.name}}</span>
                                </mat-cell>
                              </ng-container>

                              <ng-container matColumnDef="foci">
                                <mat-header-cell *matHeaderCellDef
                                  [ngClass]="(activeResult == 'QC') ? 'displayNone' : ''">Foci</mat-header-cell>
                                <mat-cell *matCellDef="let element"
                                  [ngClass]="(activeResult == 'QC') ? 'displayNone' : ''">
                                  <ng-container *ngFor="let item of element.propertyMap | keyvalue">
                                    <div class="indvPropertyMapItem" *ngIf="item?.key == 'Count'" title="{{item.key}}">
                                      <div class="elipsisApplied mentionValue" *ngIf="item.value"
                                        title="{{returnValue(item.value, false)}}">
                                        {{returnValue(item.value, false) }}
                                      </div>
                                    </div>
                                  </ng-container>
                                  <div class="indvPropertyMapItem" *ngIf="!element.propertyMap?.Count">
                                    <div class="elipsisApplied mentionValue">-</div>
                                  </div>
                                </mat-cell>
                              </ng-container>

                              <ng-container matColumnDef="area">
                                <mat-header-cell *matHeaderCellDef>Area</mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                  <ng-container *ngFor="let item of element.propertyMap | keyvalue">
                                    <div class="indvPropertyMapItem" *ngIf="item?.key != 'Count'">
                                      <ng-container *ngIf="item.key !== 'Area'">
                                        <div class="elipsisApplied mentionValue"
                                          title="{{returnValue(item.value, true) +''+  (returnUnitSign(item.value)? returnUnitSign(item.value) : '')}}">
                                          {{returnValue(item.value, true) + "" + (returnUnitSign(item.value)?
                                          returnUnitSign(item.value) : '')}}</div>
                                      </ng-container>
                                    </div>

                                  </ng-container>
                                  <div class="indvPropertyMapItem" *ngIf="!element.propertyMap?.Percentage"
                                    style="text-align: center;">
                                    <div class="elipsisApplied mentionValue">-</div>
                                  </div>
                                </mat-cell>
                              </ng-container>
                              <ng-container matColumnDef="empty-row">
                                <mat-cell *matCellDef="let element">No records found</mat-cell>
                              </ng-container>

                              <mat-header-row *matHeaderRowDef="previewTableHeaders"></mat-header-row>
                              <mat-row
                                *matRowDef="let row; columns: ((activeResult == 'QC') ? qcResults[returnQCRoiIndex(selectedROIResult)]?.quantificationResult?.featureList?.length > 0 : abnormalityResults[returnAbnormalityRoiIndex(selectedROIResult)]?.quantificationResult?.featureList?.length > 0) ? previewTableHeaders : ['empty-row'];"></mat-row>
                            </table>
                          </ng-container>
                          <ng-container
                            *ngIf="algorithmList?.length && ((activeResult == 'QC') ? imagePreview.currItemSelected?.qcStatus == 'FAILED' : selectedAbnormalityAlgo?.analysisStatus == 'FAILED')">
                            <div class="failedResultsDiv" *ngIf="activeResult == 'QC'">
                              QC Failed
                            </div>
                            <div class="failedResultsDiv" *ngIf="activeResult != 'QC'">
                              Analysis Failed
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="noPreviewResultDiv"
                        *ngIf="!allResults[0]?.quantificationResult?.featureList?.length && imagePreview.currItemSelected?.abnormalityStatus != 'BOTH' && imagePreview.currItemSelected?.abnormalityStatus != 'FAILED'">
                        <div class="noResultMessage">
                          No data
                        </div>
                      </div>
                    </mat-tab>
                    <mat-tab label="Image Details">
                      <div class="slideDetTab">
                        <mat-accordion class="previewDetialsAccordian">
                          <mat-expansion-panel class="previewDetailsExpansion" togglePosition='before'
                            [expanded]="true">
                            <mat-expansion-panel-header class="previewDetailsExpansionHeader">
                              <mat-panel-title>
                                Details
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="slidePrimaryDetailsDiv">
                              <div class="slideDetailsDiv">
                                <div class="slideDetailsName">Image Name:</div>
                                <div class="slideDetailsValue">
                                  {{imagePreview.currItemSelected?.slidename}}
                                </div>
                              </div>
                              <div class="slideDetailsDiv">
                                <div class="slideDetailsName">Location:</div>
                                <div class="slideDetailsValue">
                                  {{imagePreview.currItemSelected?.physicalLoc ?? '-'}}
                                </div>
                              </div>
                              <div class="slideDetailsDiv" *ngIf="imagePreview.currItemSelected?.creationDate">
                                <div class="slideDetailsName">Mapped Date:</div>
                                <div class="slideDetailsValue">
                                  {{imagePreview.currItemSelected?.creationDate ?? '-'}}
                                </div>
                              </div>
                              <div class="metaDataDetailsDiv">
                                <div class="metaDataFieldDiv">
                                  <div class="metaDataFieldName">Tissue:</div>
                                  <ng-container *ngIf="imagePreview.currItemSelected?.organ?.length">
                                    <div class="metaDataFieldValue"
                                      *ngFor="let organ of imagePreview.currItemSelected?.organ">
                                      {{organ}}
                                    </div>
                                  </ng-container>
                                  <ng-container *ngIf="!imagePreview.currItemSelected?.organ?.length">
                                    <div class="metaDataFieldValue">
                                      -
                                    </div>
                                  </ng-container>
                                </div>
                                <div class="metaDataFieldDiv" *ngIf="imagePreview.currItemSelected?.stain">
                                  <div class="metaDataFieldName">Stain:</div>
                                  <div class="metaDataFieldValue">
                                    {{imagePreview.currItemSelected?.stain}}
                                  </div>
                                </div>
                                <div class="metaDataFieldDiv"
                                  *ngIf="imagePreview.currItemSelected?.animalNo && !hiddenOptions?.includes('subjectId')">
                                  <div class="metaDataFieldName">Subject ID:</div>
                                  <div class="metaDataFieldValue">
                                    {{imagePreview.currItemSelected?.animalNo ?? '-'}}
                                  </div>
                                </div>
                                <div class="metaDataFieldDiv" *ngIf="imagePreview.currItemSelected?.dosageCode">
                                  <div class="metaDataFieldName">Dosage Group:</div>
                                  <div class="metaDataFieldValue">
                                    {{imagePreview.currItemSelected?.dosageCode ?? '-'}}
                                  </div>
                                </div>
                                <div class="metaDataFieldDiv" *ngIf="imagePreview.currItemSelected?.dose">
                                  <div class="metaDataFieldName">Dose:</div>
                                  <div class="metaDataFieldValue">
                                    {{imagePreview.currItemSelected?.dose ?? '-'}}
                                  </div>
                                </div>
                                <div class="metaDataFieldDiv" *ngIf="imagePreview.currItemSelected?.dosageUnit">
                                  <div class="metaDataFieldName">Dose Units:</div>
                                  <div class="metaDataFieldValue">
                                    {{imagePreview.currItemSelected?.dosageUnit ?? '-'}}
                                  </div>
                                </div>
                                <div class="metaDataFieldDiv" *ngIf="imagePreview.currItemSelected?.scanMagnification">
                                  <div class="metaDataFieldName">Magnification:</div>
                                  <div class="metaDataFieldValue">
                                    {{imagePreview.currItemSelected?.scanMagnification ?? '-'}}
                                  </div>
                                </div>
                              </div>
                              <div class="slideDetailsDiv barcodeDivSeparator"
                                *ngIf="selectedSlideForPreview?.barcodeInfo && selectedSlideForPreview?.barcodeInfo?.decoded_text">
                                <div class="slideDetailsName">Barcode Details:</div>
                                <div class="slideDetailsValue">
                                  <span class="barcodeTextTitle">Text:
                                  </span>{{selectedSlideForPreview?.barcodeInfo?.decoded_text}}
                                </div>
                              </div>
                            </div>
                          </mat-expansion-panel>
                          <mat-expansion-panel class="reviewExpansion" togglePosition='before'
                            *ngIf="previewOrgan?.length && userActiveRole  === 'PATHOLOGIST' && slideReviewData[0]?.userFk != null">
                            <mat-expansion-panel-header class="reviewExpansionHeader">
                              <mat-panel-title>
                                Review
                              </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="slideReviewDiv">
                              <div class="targetTissueSelectionDiv">
                                <mat-form-field class="commonMatFormField_selectdropdown organReviewDropdown"
                                  *ngIf="imagePreview.currItemSelected?.organ?.length > 1" appearance="outline"
                                  style="width: 12vw;">
                                  <mat-label class="labelbg">Organ</mat-label>
                                  <mat-select (selectionChange)="getSlideReview()" [(ngModel)]="previewOrgan">
                                    <mat-option *ngFor="let organ of imagePreview.currItemSelected.organ"
                                      [value]="organ">
                                      {{organ}}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                              <div class="slideReviewDivider" *ngIf="imagePreview.currItemSelected?.organ?.length > 1">
                              </div>
                              <ng-container *ngIf="slideReviewData[0]?.userFk != null">
                                <div class="slideReviewDetailsDiv">
                                  <div class="slideReviewDetails" *ngFor="let review of slideReviewData">
                                    <span class="userEmail">{{review.userFk}}</span>
                                    <div class="nonTcrReview" *ngIf="!galleryFilter.isTCRStudy">
                                      <div class="reviewrow">
                                        <label>Summary: </label>
                                        <!-- Remarkable/Not Remarkable terminology exists only for Merck 
                                             For rest of the places, we use WNL/NOT WNL 
                                        -->
                                        <p class="reviewoValue">{{ review?.summary?.toLowerCase() === 'wnl' ?
                                          'Remarkable' : 'Not Remarkable' }}</p>
                                      </div>
                                      <div class="reviewrow">
                                        <label>Observation: </label>
                                        <ng-container *ngIf="review?.observations?.length">
                                          <ng-container *ngFor="let obs of review?.observations">
                                            <p class="reviewoValue" [title]="obs?.description">{{obs?.description}} -
                                              <span><b>{{obs?.gradingCode}}</b></span>
                                            </p>
                                          </ng-container>
                                        </ng-container>
                                        <ng-container *ngIf="!review?.observations?.length">
                                          <p class="reviewoValue">
                                            <span><b>-</b></span>
                                          </p>
                                        </ng-container>
                                        <!-- <ul class="reviewobv">
                                        <li *ngFor="let obs of review.observations">
                                          <p>{{obs.description}} - <span><b>{{obs.gradingCode}}</b></span></p>
                                        </li>
                                      </ul> -->
                                      </div>
                                    </div>
                                    <div class="nonTcrReview" *ngIf="galleryFilter?.isTCRStudy">
                                      <div class="reviewrow">
                                        <label>Intensity: </label>
                                        <p class="reviewoValue">{{review?.intensity}} -
                                          {{intensityObj[review.intensity]}}
                                        </p>
                                      </div> <br />
                                      <div class="reviewrow">
                                        <label>Frequency: </label>
                                        <p class="reviewoValue">{{review?.frequency}} -
                                          {{frequencyObj[review.frequency]}}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="commentsDiv" *ngIf="review?.comments?.length">
                                      <label>Comments: </label>
                                      <span class="commentsText" [title]="review?.comments">{{review?.comments?.length ?
                                        review?.comments :
                                        '-'}}</span>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                              <ng-container *ngIf="slideReviewData[0]?.userFk == null">
                                <div class="noDataForPreview">
                                  No data available
                                </div>
                              </ng-container>
                            </div>
                          </mat-expansion-panel>
                        </mat-accordion>
                      </div>
                    </mat-tab>
                  </mat-tab-group>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="validateMetataObj?.algorithm?.algorithmId">
            <div class="validateMetadataDiv">
              <div class="metadataValidateAccordianDiv">
                <mat-accordion class="metadataValidateAccordian" multi>
                  <mat-expansion-panel class="metadataValidateExpansion" togglePosition='before'
                    [expanded]="!validateMetataObj['inValidImages']?.length"
                    *ngIf="validateMetataObj['validImages']?.length">
                    <mat-expansion-panel-header class="metadataValidateExpansionHeader">
                      <mat-panel-title>
                        Images with Valid Metadata ({{validateMetataObj['validImages']?.length}})
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="metadataValidateDetailsDiv">
                      <table class="metadataValidateDetailsTable" *ngIf="validateMetataObj['validImages']?.length"
                        mat-table [dataSource]="validateMetataObj['validImages']">
                        <ng-container matColumnDef="imageName">
                          <mat-header-cell *matHeaderCellDef>
                            Image Name</mat-header-cell>
                          <mat-cell *matCellDef="let element">{{element?.imageName}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="species">
                          <mat-header-cell mat-header-cell *matHeaderCellDef>
                            Species</mat-header-cell>
                          <mat-cell *matCellDef="let element">{{element?.species}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="tissue">
                          <mat-header-cell mat-header-cell *matHeaderCellDef>
                            Tissue</mat-header-cell>
                          <mat-cell *matCellDef="let element"> {{element?.tissues[0]}}
                          </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="stain">
                          <mat-header-cell mat-header-cell *matHeaderCellDef>
                            Stain</mat-header-cell>
                          <mat-cell *matCellDef="let element">{{element?.stain}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="empty-row">
                          <mat-cell *matCellDef="let element">No records found</mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="validateMetadataColums; sticky: true"></mat-header-row>
                        <ng-container>
                          <mat-row
                            *matRowDef="let row; columns: validateMetataObj['validImages']?.length > 0 ? validateMetadataColums : ['empty-row'];">
                          </mat-row>
                        </ng-container>
                      </table>
                    </div>
                  </mat-expansion-panel>
                  <mat-expansion-panel class="metadataValidateExpansion" togglePosition='before'
                    [expanded]="validateMetataObj['inValidImages']?.length"
                    *ngIf="validateMetataObj['inValidImages']?.length">
                    <mat-expansion-panel-header class="metadataValidateExpansionHeader">
                      <mat-panel-title>
                        Images with Invalid Metadata ({{validateMetataObj['inValidImages']?.length}})
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="metadataValidateDetailsDiv">
                      <table class="metadataValidateDetailsTable" *ngIf="validateMetataObj['inValidImages']" mat-table
                        [dataSource]="validateMetataObj['inValidImages']">
                        <ng-container matColumnDef="imageName">
                          <mat-header-cell mat-header-cell *matHeaderCellDef>
                            Image Name</mat-header-cell>
                          <mat-cell *matCellDef="let element">{{element?.imageName}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="species">
                          <mat-header-cell mat-header-cell *matHeaderCellDef>
                            Species</mat-header-cell>
                          <mat-cell *matCellDef="let element"
                            [ngClass]="{'invalidMetadataValue':element?.invalidSpecies}">{{element?.species}}</mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="tissue">
                          <mat-header-cell mat-header-cell *matHeaderCellDef>
                            Tissue</mat-header-cell>
                          <mat-cell *matCellDef="let element"
                            [ngClass]="{'invalidMetadataValue':element?.invalidTissues}">
                            {{element?.tissues[0]}}
                          </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="stain">
                          <mat-header-cell mat-header-cell *matHeaderCellDef>
                            Stain</mat-header-cell>
                          <mat-cell *matCellDef="let element"
                            [ngClass]="{'invalidMetadataValue':element?.invalidStain}">{{element?.stain}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="empty-row">
                          <mat-cell mat-cell *matCellDef="let element">No records found</mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="validateMetadataColums; sticky: true"></mat-header-row>
                        <ng-container>
                          <mat-row
                            *matRowDef="let row; columns: validateMetataObj['inValidImages']?.length > 0 ? validateMetadataColums : ['empty-row'];">
                          </mat-row>
                        </ng-container>
                      </table>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <div class="validateDivButtons">
                <button type="button" class="btn-default" aria-label="cancelbtn"
                  (click)="clearMetadataValidate()">Cancel</button>
                <button type="button" class="btn-default searchbtn" aria-label="resetbtn"
                  [ngClass]="{ 'disablebtn_func' : !validateMetataObj['validImages']?.length}"
                  (click)="triggerAnalysis(validateMetataObj.algorithm, validateMetataObj.algorithm['algorithmType'], validateMetataObj['validImages'])">Analyse</button>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #showSpinner>
  <div class="studyGallerySpinnerDiv">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>