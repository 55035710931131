import { CommonService } from '../../common.service';
import { UsersService } from './../users.service';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialogRef, MatDialogModule, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Optional, Inject, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from 'src/app/common/confirm-dialog/confirm-dialog.component';
import { MustMatch, LengthValidator, CharacterValidator } from '../../Input.Validators';
import { AppConfigService } from 'src/app/app-config.service';
import { ChangePasswordService } from 'src/app/change-password/change-password.service';
export default class Validation {
  static match(controlName: string, checkControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[checkControlName];
      if (
        matchingControl.errors &&
        !matchingControl.errors['mustMatch']
      ) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
}
@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.less'],
  providers: [MatDialogModule]
})
export class UserCreateComponent implements OnInit {
  onClose = new EventEmitter();
  userFrom: FormGroup;
  cols = 1;
  rolesArr = [];
  rolesDisplayed = [];
  isFormValueChanged = false;
  hide = true;
  hide1 = true;
  savingStatus: boolean = false;
  config: any;
  passwordPolicyArr: any = [];

  constructor(@Optional() private dialogRef: MatDialogRef<UserCreateComponent>,
    private formbuilder: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    private usersService: UsersService,
    private commonService: CommonService,
    public dialog: MatDialog,
    private configService: AppConfigService,
    private changePasswordService: ChangePasswordService
  ) {
    this.config = this.configService.config;
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    dialogRef.disableClose = true;
    let roles = this.getAllRoles();
    roles.then((successData: any) => {
      this.rolesArr = [];
      this.rolesDisplayed = [];
      for (let i = 0; i < successData.data.length; i++) {
        this.rolesDisplayed.push(this.commonService.camelCase(successData.data[i]))
        this.rolesArr.push(successData.data[i]);
      }
      this.createRolesInput()
    });
  }

  ngOnInit() {
    if (this.data.userType == 'Edit') {
      this.userFrom = this.formbuilder.group({
        firstName: [{ value: this.data.userData.firstName, disabled: false }, Validators.required],
        lastName: [{ value: this.data.userData.lastName, disabled: false }],
        email: [{ value: this.data.userData.emailId, disabled: true }, [Validators.required, this.commonService.checkEmailPattern]],
        // password: [],
        roles: new FormArray([], this.minSelectedCheckboxes(1)),
        inactiveUser: [false]
      });
    } else {
      if (!this.config?.webAppConfig?.newUserPasswordRequired) {
        this.userFrom = this.formbuilder.group({
          firstName: ['', [Validators.required, this.noWhitespaceValidator, this.symbolsOnly]],
          lastName: ['', [Validators.required, this.noWhitespaceValidator, this.symbolsOnly]],
          email: ['', [Validators.required, this.commonService.checkEmailPattern]],
          // password: ['', [Validators.required, this.noWhitespaceValidator]],
          // confirmPassword: ['', [Validators.required, this.noWhitespaceValidator]],
          roles: new FormArray([], this.minSelectedCheckboxes(1)),
          inactiveUser: []
        });
        this.userFrom.get('lastName').clearValidators();
      } else {
        this.userFrom = this.formbuilder.group({
          firstName: ['', [Validators.required, this.noWhitespaceValidator, this.symbolsOnly]],
          lastName: ['', [Validators.required, this.noWhitespaceValidator, this.symbolsOnly]],
          email: ['', [Validators.required, this.commonService.checkEmailPattern]],
          newPwd: ['', [Validators.required, this.noWhitespaceValidator]],
          confirmPwd: ['', [Validators.required, this.noWhitespaceValidator]],
          roles: new FormArray([], this.minSelectedCheckboxes(1)),
          inactiveUser: []
        }, {
          validators: [Validation.match('newPwd', 'confirmPwd')]
        });
        this.userFrom.get('lastName').clearValidators();
        this.getPasswordPolicy();
      }

    }

  }

  getPasswordPolicy() {
    this.changePasswordService.getPassPolicy().subscribe((response: any) => {
      if (response.success) {
        this.passwordPolicyArr = response.data?.passwordPolicies?.filter((item: any) => {
          return item['regex'];
        });
        this.passwordPolicyArr.forEach((element: any) => {
          element['verified'] = false;
        });
        // this.updateValidators();
      }
    })
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  symbolsOnly(control: FormControl) {
    if (!/^[^`~!@#$%\^&*()_+={}|[\]\\:';"<>?,./]*$/.test(control.value)) {
      return { symbols: true };
    }
    return null;
  }

  private createRolesInput() {
    this.rolesArr.forEach((o, i) => {
      const control = new FormControl();
      (this.userFrom.controls.roles as FormArray).push(control);
    });
    if (this.data.userType == 'Edit') {
      this.data.userData.roles.forEach(element => {
        var index = this.rolesArr.map(function (e) {
          return e;
        }).indexOf(element)
        this.userFrom.get('roles')['controls'][index].setValue(true);
        // this.userFrom.get('roles')['controls'][index].disable();
      });
    }
  }

  private async getAllRoles() {
    return await (this.usersService.getAllRoles()).toPromise()
  }

  // convenience getter for easy access to form fields
  get f() { return this.userFrom.controls; }

  checkCreatePassword(group: FormGroup) {
    if (!group.controls.createPassword.value) {
      return { noPasswordValue: true }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  cleanForm = (formGroup: FormGroup) => {
    Object.keys(formGroup.controls).forEach((key) => {
      if (key != 'roles' && key != 'inactiveUser' && formGroup.get(key).value) {
        formGroup.get(key).setValue(formGroup.get(key).value.trim());
      }
    });
  }

  saveUserData() {
    // stop here if form is invalid
    // console.log(this.f.password.errors);
    if (this.userFrom.invalid) {
      Object.keys(this.f).forEach(control => {
        if (this.f[control].invalid) {
          this.f[control].markAsTouched();
        }
      });
    } else {
      this.savingStatus = true;
      this.cleanForm(this.userFrom);

      const selectedPreferences = this.userFrom.getRawValue().roles
        .map((checked, index) => checked ? this.rolesArr[index] : null)
        .filter(value => value !== null);
      this.userFrom.value.roles = selectedPreferences;

      if (this.data.userType == 'Edit') {
        let editData = {
          "email": this.userFrom.getRawValue().email,
          "roles": this.userFrom.value.roles,
          "firstName": this.userFrom.getRawValue().firstName,
          "lastName": this.userFrom.getRawValue().lastName,
          "active": true
        }
        this.usersService.updateUser(editData).subscribe((res: any) => {
          if (res.success) {
            this.commonService.showToastMsg(res.message);
            this.onClose.emit(true);
            this.dialogRef.close();
            this.usersService.isDsTokenStored = false;
          }
        }
//         ,error => {
//           if(error.status === 409){
//             this.usersService.isDsTokenStored =true;
//             const dialogConfig = new MatDialogConfig();
//             dialogConfig.width = "20%";
//             dialogConfig.data = 
//                 headerTitle: "Edit User",
//                 confirmMsg: error.error.message,
//                 cancelButtonText: "Cancel",
//                 confirmButtonText: "Yes",
//                 isAllowed:false
//             }
//           this.savingStatus = false;
//           const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
//           dialogref.afterClosed().subscribe((result:any)=>{
//           if(result){
//           editData['allowRevoke']=true;
//           this.usersService.editUser1(editData).subscribe((res: any)=>{  
//             this.commonService.showToastMsg(res.message);
//             this.onClose.emit(true); 
//             this.dialogRef.close();  
//     })
//   }
// })
//  }
//         }

        /* , (error: any) => {
          //this.commonService.showToastMsg(error.error.message);
        } */);
        this.usersService.isDsTokenStored = false;
      } else {
        this.usersService.createUser(this.userFrom.value).subscribe((response: any) => {
          if (response.success) {
            this.savingStatus = false;
            this.commonService.showToastMsg(response.message);
            this.onClose.emit(true);
            this.dialogRef.close();
          }
        }, (error: any) => {
          this.savingStatus = false;
          this.commonService.showToastMsg(error?.error?.message, 'error');
        });
      }
    }
  }

  checkFieldEnabled(role: any) {
    const selectedPreferences = this.userFrom.getRawValue().roles
      .map((checked, index) => checked ? this.rolesArr[index] : null)
      .filter(value => value !== null);
    if ((role != "EXTERNAL_PATHOLOGIST") && (selectedPreferences.indexOf("EXTERNAL_PATHOLOGIST") >= 0)) {
      return true;
    } else if ((role == "EXTERNAL_PATHOLOGIST") && (selectedPreferences.indexOf("EXTERNAL_PATHOLOGIST") < 0) && (selectedPreferences.length > 0)) {
      return true;
    }
    return false;
  }

}