export enum Icons {
    allShapeAnnotations = "allAnnotations",
    rectAnnotation = "rectangle",
    circleAnnotation = "circle",
    ellipseAnnotation = "ellipse",
    openFreeFormAnnotation = "openFreeForm",
    closedFreeFormAnnotation = "closedFreeForm",
    filledFreeFormAnnotation = "filledFreeForm",
    angleAnnotation = "angle",
    arrowAnnotation = "arrow",
    rulerAnnotation = "ruler",
    notification = "notification",
    mappedIcon = "sideBar/mapped",
    projectIcon = "sideBar/project",
    reportIcon = "sideBar/report",
    imagesIcon = "sideBar/images",
    studyIcon = "sideBar/study",
    uploadedIcon = "sideBar/image_upload",
    usersIcon = "sideBar/users",
    configureIcon = "sideBar/configure",
    consultationIcon = "sideBar/consultation",
    dashboardIcon = "sideBar/dashboard",
    galleryIcon = "sideBar/gallery",
    logoutIcon = "sideBar/logout",
    auditIcon = "sideBar/report",
    analysisIcon = "sideBar/analysis_v1",
    dsIcon = "ds_icon",
    digitalsignatureIcon = "sideBar/digitalSignature",
    editSvgIcon = 'edit_orange',
    deleteMetaRow = "deleteMetaRow",
    folderSvgIcon = "Gallery_folder",
    newSlidePreviewIcon = "eye_grey",
    previewLabelIcon = "Image Preview Icon-Active",
    previewMacroIcon = "Slide Label Icon-Active",
    qcResultIcon = "QC_Final_Thick",
    abnormalityResult = "abnormalityFinal",
    uploadIcon = 'sideBar/Group_upload',
    projOprtIcon = 'projOprtIcon',
    shareIcon = 'shareIcon',
    saveSettings = 'save_settings',
    closeSvgIcon = 'close_grey',
    saveOrange = 'save_settings_orange',
    imageLabelPreview = "imagepreview",
    slideLabelPreview = "slidelabel",
    moreOptionIcon = 'moreOption',
    folderIconSvg = "Gallery_folder",
    editMetadataIcon = "editMetadataIcon",
    saveMetaDataIcon = "saveMetaDataIcon",
    createFolderNew = 'createFolder',
    mappedImgIconNew = 'mapImages',
    accesibleLocation = "accessible",
    notAccesibleLocation = "not_accessible",
    completedIcon = "completedIcon",
    slideReviewIcon = "reviewSvgIcon",
    slideReadIcon = "readUnread",
    queuedForAnalysis = "loading_1",
    mainGalleryFilterIcon = "galleryFilterFunnleIcon",
    unmappedImageNew = 'unmappedImage',
    downloadIconNew = 'downloadIcon',
    unSelectIcon = 'unSelect',
    uploadIconNew = 'uploadIcon',
    corruptImageIcon = 'img_reject',
    validateIcon = 'validateIcon',
    viewRptIcon = 'reporticon',
    imageMapIcon = 'sync-image-photo-icon',
    macroPreviewIcon = 'togglePreview/Macro',
    labelPreviewIcon = 'togglePreview/Label',
    thumbnailPreviewIcon = 'togglePreview/Thumbnail',
    roiPreviewIcon = 'togglePreview/ROI',
    selectedmacroPreviewIcon = 'togglePreview/selectedMacro',
    selectedlabelPreviewIcon = 'togglePreview/selectedLabel',
    selectedthumbnailPreviewIcon = 'togglePreview/selectedThumbnail',
    selectedroiPreviewIcon = 'togglePreview/selectedROI',
    bookmarkIcon = "bookmark",
    anomalyGreenIcon = 'greenAnomalyFont',
    anomalyRedIcon = 'redAnomalyFont',
    anomalyGrayIcon = 'grayAnomalyFont'
}