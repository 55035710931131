import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmDialogComponent } from "src/app/common/confirm-dialog/confirm-dialog.component";
import { DigitalSignatureService } from "../digital-signature.service";
import { ModalDigitalSignatureComponent } from "../modal-digital-signature/modal-digital-signature.component";
import { SharedDataService } from "../shared-data.service";
import { CommonService } from "src/app/common.service";
import { TranslateService } from "@ngx-translate/core";

export interface Action {
  id: number;
  roles: string;
  topic: string;
  actions: string;
  dvenabled: boolean;
}

@Component({
  selector: "app-digital-signature",
  templateUrl: "./digital-signature.component.html",
  styleUrls: ["./digital-signature.component.less"],
  encapsulation: ViewEncapsulation.None,
})
export class DigitalSignatureComponent implements OnInit {
  activeRoute: any = [];
  newRoles: any = [];
  actions: any;
  selectedActions = {};
  originalArray: any;
  updatedArray: any;
  isDSEnable: boolean;
  toggleDSEnable: boolean;
  singleAnnotationData: any;
  singleAnnotation: boolean = true;

  constructor(
    private sharedDataService: SharedDataService,
    private dialog: MatDialog,
    private digitalService: DigitalSignatureService,
    private commonService: CommonService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) { 
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit(): void {
    this.selectedActions = {};
    this.activeRoute['role'] = this.commonService?.ProjectLSData?.userActiveRole == 'APPLICATION ADMIN' ? 'Admin' : this.commonService?.ProjectLSData?.userActiveRole;
    this.digitalService
      .getActionsByRole('All')
      .subscribe((data: Action[]) => {
        this.originalArray = data;
        this.updatedArray = this.originalArray.filter((e) => (e.id == 12 && e.dvenabled == true) || (e.id == 13 && e.dvenabled == true));
        this.singleAnnotationData = this.originalArray.filter((e) => (e.id == 38 && e.type == 'G'));
        this.singleAnnotation = this.singleAnnotationData.length >= 1 ? false : true;
        this.isDSEnable = this.updatedArray.length >= 1 ? true : false;
        this.toggleDSEnable = this.updatedArray.length >= 1 ? true : false;
        this.actions = this.mapData(this.originalArray);
        // console.warn(this.actions);
        this.getNewRoles(this.originalArray);
      });
  }
  openDialog(): void {
    let updates = Object.keys(this.selectedActions).reduce(
      (prev, next) => [...prev, ...this.selectedActions[next]],
      []
    );
    const singAnnoIndex = updates.findIndex(
      (item) => item.action === true
    );
    const annotationsFlag = updates.findIndex((e) => (e.id == 37 || e.id == 38 || e.id == 39));
    if (singAnnoIndex != -1 && annotationsFlag == -1) {
      let singAnnoIndexData = this.originalArray.filter((e) => (e.id == 37 || e.id == 38 || e.id == 39));
      // console.log(singAnnoIndexData);
      singAnnoIndexData.map((e: any) => e.type = (this.singleAnnotation == false ? 'G' : 'S'));
      singAnnoIndexData.forEach((e: any) => updates.push(e));
      updates.splice(singAnnoIndex, 1);
    } else {
      const createIndex = updates.findIndex(
        (item) => item.id == 37
      );
      const editIndex = updates.findIndex(
        (item) => item.id == 38
      );
      const deleteIndex = updates.findIndex(
        (item) => item.id == 39
      );
      if (createIndex != -1) {
        updates[createIndex].type = (this.singleAnnotation == false ? 'G' : 'S')
      } else {
        let createIndexData = this.originalArray.filter((e) => e.id == 37);
        createIndexData.map((e: any) => e.type = (this.singleAnnotation == false ? 'G' : 'S'));
        createIndexData.forEach((e: any) => updates.push(e));
      }
      if (editIndex != -1) {
        updates[editIndex].type = (this.singleAnnotation == false ? 'G' : 'S')
      } else {
        let editIndexData = this.originalArray.filter((e) => e.id == 38);
        editIndexData.map((e: any) => e.type = (this.singleAnnotation == false ? 'G' : 'S'));
        editIndexData.forEach((e: any) => updates.push(e));
      }
      if (deleteIndex != -1) {
        updates[deleteIndex].type = (this.singleAnnotation == false ? 'G' : 'S')
      } else {
        let deleteIndexData = this.originalArray.filter((e) => e.id == 39);
        deleteIndexData.map((e: any) => e.type = (this.singleAnnotation == false ? 'G' : 'S'));
        deleteIndexData.forEach((e: any) => updates.push(e));
      }
      if (singAnnoIndex != -1) {
        updates.splice(singAnnoIndex, 1);
      }
    }
    const configList = { 'configList': updates }

    this.updateActions(configList, '', '')
  }

  mapData(data: Action[]) {
    return data
      .map((item) => ({ ...item, topic: item.topic?.replace(":", ": ") }))
      .reduce((prev, next) => {
        let action = next.actions?.toLocaleLowerCase();
        if (next.topic) {
          let index = prev.findIndex((item) => item.topic === next.topic);
          if (index != -1) {
            prev[index].actions.push({
              ...next,
              dvenabled: this.isActionActive(next),
            });
          } else {
            prev.push({
              topic: next.topic,
              actions: [{ ...next, dvenabled: this.isActionActive(next) }],
            });
          }
        } else {
          let index = prev.findIndex((item) => item.topic === next.roles);
          if (index != -1) {
            prev[index].actions.push({
              ...next,
              dvenabled: this.isActionActive(next),
            });
          } else {
            prev.push({
              topic: next.roles,
              actions: [{ ...next, dvenabled: this.isActionActive(next) }],
            });
          }
        }
        return prev;
      }, []);
  }

  onClickAction(action: Action) {
    if (!this.selectedActions[this.activeRoute.role]) {
      this.selectedActions[this.activeRoute.role] = [
        { ...action, dvenabled: !action.dvenabled },
      ];
    } else {
      const index = this.selectedActions[this.activeRoute.role].findIndex(
        (item) => item.id === action.id
      );
      if (index != -1) {
        this.selectedActions[this.activeRoute.role].splice(index, 1);
      } else {
        this.selectedActions[this.activeRoute.role].push({
          ...action,
          dvenabled: !action.dvenabled,
        });
      }
    }
    const findIndex = this.actions.findIndex(
      (item) => item.topic == action.topic
    );
    if (findIndex != -1) {
      const index = this.actions[findIndex].actions.findIndex(
        (item) => item.id == action.id
      );
      if (index != -1) {
        this.actions[findIndex].actions[index] = {
          ...action,
          dvenabled: !action.dvenabled,
        };
      }
    }
    // console.log(this.selectedActions)
    this.sharedDataService.setUpatedActions(this.selectedActions);
  }

  onClickAnnotation(action: any) {
    if (!this.selectedActions[this.activeRoute.role] || (this.selectedActions[this.activeRoute.role].length == 0)) {
      this.selectedActions[this.activeRoute.role] = [
        { action: true }
      ]
    } else {
      const signAnnoIndex = this.selectedActions[this.activeRoute.role].findIndex(
        (item) => item.action === true
      );
      if (signAnnoIndex != -1) {
        this.selectedActions[this.activeRoute.role].splice(signAnnoIndex, 1);
      }
      const index1 = this.selectedActions[this.activeRoute.role].findIndex(
        (item) => item.action === action[0].id
      );
      const index2 = this.selectedActions[this.activeRoute.role].findIndex(
        (item) => item.id === action[1].id
      );
      const index3 = this.selectedActions[this.activeRoute.role].findIndex(
        (item) => item.id === action[2].id
      );
      if (index1 != -1 && index2 != -1 && index3 != -1) {
        this.selectedActions[this.activeRoute.role][index1].type = (action[0].type == 'S' ? 'G' : 'S');
        this.selectedActions[this.activeRoute.role][index2].type = (action[1].type == 'S' ? 'G' : 'S');
        this.selectedActions[this.activeRoute.role][index3].type = (action[2].type == 'S' ? 'G' : 'S');
      }
      if (index1 != -1) {
        this.selectedActions[this.activeRoute.role][index1].type = (action[0].type == 'S' ? 'G' : 'S');
      } if (index2 != -1) {
        this.selectedActions[this.activeRoute.role][index2].type = (action[1].type == 'S' ? 'G' : 'S');
      } if (index3 != -1) {
        this.selectedActions[this.activeRoute.role][index3].type = (action[2].type == 'S' ? 'G' : 'S');
      }
    }
    const findIndex = this.actions.findIndex(
      (item) => item.topic == action[0].topic
    );
    if (findIndex != -1) {
      const index1 = this.actions[findIndex].actions.findIndex(
        (item) => item.id == action[0]?.id
      );
      const index2 = this.actions[findIndex].actions.findIndex(
        (item) => item.id == action[1]?.id
      );
      const index3 = this.actions[findIndex].actions.findIndex(
        (item) => item.id == action[2]?.id
      );
      if (index1 != -1 && index2 != -1 && index3 != -1) {
        this.actions[findIndex].actions[index1] = { ...action[0], type: (action[0].type == 'S' ? 'G' : 'S') };
        this.actions[findIndex].actions[index2] = { ...action[1], type: (action[1].type == 'S' ? 'G' : 'S') };
        this.actions[findIndex].actions[index3] = { ...action[2], type: (action[2].type == 'S' ? 'G' : 'S') };
      } else if (index1 != -1) {
        this.actions[findIndex].actions[index1] = { ...action[0], type: (action[0].type == 'S' ? 'G' : 'S') };
      } else if (index2 != -1) {
        this.actions[findIndex].actions[index2] = { ...action[1], type: (action[1].type == 'S' ? 'G' : 'S') };
      } else {
        this.actions[findIndex].actions[index3] = { ...action[2], type: (action[2].type == 'S' ? 'G' : 'S') };
      }
    }

    this.singleAnnotation = !this.singleAnnotation;

    this.sharedDataService.setUpatedActions(this.selectedActions);
  }

  isActionActive(action: Action) {
    const find: Action = (
      this.selectedActions[this.activeRoute.role] || []
    ).find((item) => item.id == action.id);
    if (find) {
      return find.dvenabled;
    }
    return action.dvenabled;
  }

  updateActions(actions: any, token, updateFlag) {
    this.digitalService.updateMultipleActionsRoles(actions, token, updateFlag).subscribe(
      (_: Action[]) => {
        this.digitalService
          // .getActionsByRole(this.activeRoute.role)
          .getActionsByRole('All')
          .subscribe((data: Action[]) => {
            this.actions = this.mapData(data);
            this.getNewRoles(data);
          });
        this.selectedActions = {};
        this.sharedDataService.setUpatedActions({});
      }, (error) => {
        if (error.url.includes('bulk-update') && error.status == 202) {
          // this.snackBar.open('Configuration updated successfully', '', {
          //   duration: 5000,
          //   verticalPosition: 'top'
          // });
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.CONFIGURATION.CONFIGURATION_UPDATED_SUCCESSFULLY"), 'success');
        }
        this.digitalService
          // .getActionsByRole(this.activeRoute.role)
          .getActionsByRole('All')
          .subscribe((data: Action[]) => {
            this.actions = this.mapData(data);
            this.getNewRoles(data);
          });
        this.selectedActions = {};
        this.sharedDataService.setUpatedActions({});
      }
    );
  }

  getLength() {
    return Object.keys(this.selectedActions).reduce((prev, next) => {
      prev += (this.selectedActions[next] || [])?.length
      return prev;
    }, 0)
  }

  getNewRoles(rolesArray: any) {
    this.newRoles = [];
    // console.log(rolesArray)
    rolesArray.forEach((e: any) => {
      if (!this.newRoles.includes(e.roles)) {
        this.newRoles.push(e.roles)
      }
    })
  }
  enableDigitalSignature() {
    this.isDSEnable = !this.isDSEnable;
    // console.log(this.isDSEnable)
    let checkedActions = [];
    if (this.isDSEnable) {
      checkedActions = this.originalArray.filter((e) => e.id == 12)
      checkedActions[0].dvenabled = this.isDSEnable;
      this.updateActions(checkedActions, '', 'global');
      this.toggleDSEnable = true;
    } else {
      // this.isDSEnable = !this.isDSEnable;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = "30%";
      dialogConfig.data = {
        headerTitle: "Confirm",
        confirmMsg: "You are about to disable the Digital Signature module. Are you sure you want to proceed ahead?",
        cancelButtonText: "Cancel",
        confirmButtonText: "Save"
      };
      const dialogref = this.dialog.open(ConfirmDialogComponent, dialogConfig);
      dialogref.afterClosed().subscribe(result => {
        if (result && result != 'btnClose') {
          this.toggleDSEnable = false;
          checkedActions = this.originalArray.filter((e: any) => e.id == 12 || e.id == 13)
          checkedActions[0].dvenabled = this.isDSEnable;
          checkedActions[1].dvenabled = this.isDSEnable;
          this.updateActions(checkedActions, '', 'global');
        }
        else {
          this.isDSEnable = !this.isDSEnable;
        }
      })
    }
    // console.warn(checkedActions)
  }
}
