import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuditReviewService {

  private config: any;
  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.config = configService.config;
  }

  getAllUsers() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}user`);
  }

  // getAllStudies() {
  //   // return this.http.post(`${this.config.apiUrl}study/list?study=myStudy`, 'PATHOLOGIST');
  //   return this.http.post(`${this.config.apiUrl}study/list?status=5`, 'STUDY_DIRECTOR');
  // }

  getAllStudiesForStudy() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}api/audit-service/filters_list?report_type=AllStudy`);
  }

  getAllStudiesForImageMapping() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}study/allStudy`);
  }

  getPathoStudies(arg?:any) {

    return this.http.get(`${this.config?.apiConfig?.apiUrl}api/audit-service/filters_list?report_type=StudyAtPathologist&study_type=${arg}`);
  }

  getAllSpecies() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}study/data/configuration/species`);
  }

  getAllOrgans() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}slide/refData/all?classcode=tissues.class`);

  }

  getFieldConfigAPIData(start: any, end: any, users: any, auditType: any, body: any) {
    // headerField.selectedAuditType= headerField.selectedAuditType =='StudyCreation'? 'Study':headerField.selectedAuditType;
    return this.http.post(`${this.config?.apiConfig?.apiUrl}api/audit-service/list?report_type=${auditType}&start=${start}&end=${end}&userName=${users}`, body);
  }
  downloadReport(body: any) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}api/audit-service/report_list`, body, { responseType: 'blob' },
    );
  }
  getslideNo(studyName: any) {
    // //172.28.42.66:9070/airadhi/api/audit-service/filters_list
    return this.http.get(`${this.config?.apiConfig?.apiUrl}api/audit-service/filters_list?report_type=Slide&study_pk=${studyName}`);
    // return this.http.get(`172.28.42.66:9070/airadhi/api/audit-service/filters_list?report_type=Slide&study_pk=${studyName}`)
  }

}
