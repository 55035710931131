import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../common.service';
import { StudyService } from './../study.service';
import { StudyMappingStructureService } from './../../study-mapping-structure/study-mapping-structure.service';
import { FileReaderService } from './../../FileReader.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Component, Optional, Inject } from '@angular/core';
import { Study } from "../models/study.model";
import { LoaderComponent } from './../../common/loader/loader.component';
import { AppConfigService } from 'src/app/app-config.service';

@Component({
  selector: 'app-auto-create-dialog',
  templateUrl: './auto-create-dialog.component.html',
  styleUrls: ['./auto-create-dialog.component.less']
})
export class AutoCreateDialogComponent {

  studylFileName: String = 'Upload File';
  file: any = null;
  FieldMappingArr = [];
  rowNo: number = null;
  fileData = [];
  mappingFileArr = [];
  selectedStudyType: any = 'TOX';
  selectedTemplateId: number = null;
  selectedTemplatePlaceHolder: string = '';
  dialogConfig = new MatDialogConfig();
  isUploadClicked: boolean = false;
  tcrNoTissueCount: number = 0;
  studyTypeList: StudyType[] = [
    { name: 'TOX', type: 'Study' },
    { name: 'TCR', type: 'Study' }
  ];
  DOWNLOAD_PATH = '';
  templateName: string;
  manualFields: string[] = [];
  hasError = false;
  error = 'Error';
  labelConfigModels: any[] = [];

  createStudy = 'Create Study';
  isMinimalTemplate = true;
  isMinimalTemplateCheckboxDisabled = false;

  constructor(
    @Optional() public dialogRef: MatDialogRef<AutoCreateDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private fileReaderService: FileReaderService,
    private studyMappingStructureService: StudyMappingStructureService,
    private studyService: StudyService,
    private commonService: CommonService,
    private dialog: MatDialog,
    public translate: TranslateService,
    private appConfig: AppConfigService
  ) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.onStudyTypeChange({ value: 'TOX' });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  uploadStudyDataFile(e) {
    this.studylFileName = e.target.files[0].name;
    this.file = e.target.files[0];
    this.hasError = false;
    /* this.fileReaderService.readFile(e.target.files[0], (fileData: any) => {
      this.fileData = fileData;
    }, this.rowNo); */
  }

  removeFile() {
    if (this.file) {
      this.file = null;
      this.studylFileName = 'Upload File';
      this.hasError = false;
    }
  }

  uploadStudyData() {
    if (this.file) {
      this.isUploadClicked = true;
      this.studyService.getTemplateStructure(this.selectedTemplateId).subscribe((res: any) => {
        this.fileReaderService.readFile(this.file, (fileData: any[]) => {
          this.rowNo = fileData.findIndex(file => {
            let imageNameClientColumnName = res.data.headerInfo.labelConfigModels.find(label => label.code === 'imageName')['clientColumn'];
            let extension = file[imageNameClientColumnName]?.trim().match(/[^.]+$/)[0];
            return this.appConfig.config?.webAppConfig?.allowedImageExtensions.includes(extension);
          });
          this.fileData = fileData.slice(this.rowNo, fileData.length);
          this.labelConfigModels = [...res.data.headerInfo.labelConfigModels];

          let systemLabels = res.data.headerInfo.labelConfigModels.map(label => label.fieldName);
          this.commonService.systemLabels = systemLabels;

          if (this.checkForMandatoryFieldMissingData()) {
            this.setCommonServiceDataForStudyCreate();
          } else {
            this.isUploadClicked = false;
            this.error = this.translate.instant("GENERIC_MSG.STUDY.MANDATORY_FIELD_DATA_MISSING");
            this.hasError = true;
          }
        });
      });
    } else {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.UPLOAD_FILE"), 'warn');
    }
  }

  setCommonServiceDataForStudyCreate() {
    let obj = this.fieldMappingObj(this.labelConfigModels);
    this.fileData.forEach((element) => {
      var keys = Object.keys(element);
      for (const key of keys) {
        if (obj[key] && key != obj[key]) {
          element[obj[key]] = element[key];
          delete element[key];
        }
      }
    });
    this.commonService.ProjectLSData.studyCreationType = this.isMinimalTemplate ? 'TOX_MINIMAL' : (this.selectedStudyType === 'TCR') ? "TCR" : "TOX";
    this.commonService.setProjectLSData();
    this.dialogRef.close(this.generateDataObj(this.fileData));
  }

  checkForMandatoryFieldMissingData() {
    // A user might not enter values for every col, that is an excel can have missing cell
    // in that case we need to find a row with values entered for every col
    let clientHeaders = this.fileData.map(elem => Object.keys(elem))?.sort((a, b) => b.length - a.length)[0]?.sort();

    this.commonService.isMinimalTemplate = this.isMinimalTemplate;

    const mandatoryColumns = this.labelConfigModels?.filter(label => label.mandatory)?.map(label => label.clientColumn);

    // if client headers contains all the mandatory columns headers than let the user proceed
    // else show an error message and do not let the user create the study
    return (mandatoryColumns.every(col => clientHeaders?.includes(col)) && this.fileData.every(data => mandatoryColumns.every(col => data[col])));
  }

  fieldMappingObj(mappingArr) {
    let mappingObj = {};
    mappingArr.forEach(element => {
      if (element.clientColumn && !mappingObj[element.clientColumn]) {
        mappingObj[element.clientColumn] = element.code;
      }
    });

    return mappingObj;
  }

  generateDataObj(fileData) {
    this.dialogConfig.width = "12vw";
    this.dialogConfig.height = "auto";
    this.dialogConfig.id = 'loaderDialog';
    this.dialogConfig.disableClose = true;
    this.dialogConfig.data = {
      'Msg': "Fetching Data From Excel..."
    };
    let excelDataRef = this.dialog.open(LoaderComponent, this.dialogConfig);
    this.dialogConfig.id = null;

    let dataObj = {
      studyInfo: new Study(),
      cassetteInfo: {},
      dosageInfo: {},
      slideInfo: [],
      loaderDialog: excelDataRef,
      manualFields: ''
    };
    fileData.forEach((element, i) => {
      if (i == 0) {
        dataObj.studyInfo.studyNo = element.studyNo;
        dataObj.studyInfo.studyTitle = element.studyTitle;
        dataObj.studyInfo.projectNo = element.projectNo;
        dataObj.studyInfo.accessionNo = element.accessionNo;
        dataObj.studyInfo.studyDomain = element.studyDomain;
        dataObj.studyInfo.treatment = element.treatment;
        dataObj.studyInfo.sacrifice = element.sacrifice;
        dataObj.studyInfo.studyType = element.studyType;
        dataObj.studyInfo.species = element.species ? element.species.toString().trim() : null;
        dataObj.studyInfo.strain = element.strain;
        dataObj.studyInfo.route = element.routes;
        dataObj.studyInfo.fromDate = element.startDate;
        dataObj.studyInfo.toDate = element.endDate;
        dataObj.studyInfo.pathologist = element.pathologist;
        dataObj.studyInfo.peerReviewer = element.peerReviewer;
        dataObj.studyInfo.primaryInvestigator = element.primaryInvestigator;
        dataObj.studyInfo.technician = element.technician;
        dataObj.studyInfo.cro = element.cro;
        dataObj.studyInfo.testItem = element.testItem;
      }

      // In case of multiple values separated via ';', trim whitespaces in case of blank values
      Object.keys(element).forEach(key => element[key] = element[key]?.split(';')?.filter(v => v.trim()).join(';'));

      element.cassette = element.cassette ? element.cassette : null;

      if (!element.stain) {
        element.stain = 'HE'; // default stain to HE
        !this.manualFields.includes('stain') && this.manualFields.push('stain');
      }

      // auto generate cassette id in case not provided
      if (element.cassette === null) {
        element.cassette = element.stain?.split(';').join('_').trim() + "_" + element.tissues?.split(';').sort().join('_').trim();
        !this.manualFields.includes('cassetteId') && this.manualFields.push('cassetteId');
      }

      element.dosage = element.dosage ? element.dosage : 'temp';

      // auto generate subject id in case its not provided
      if (!element.subjectId) {
        element.subjectId = element.dosage + '_' + i;
        !this.manualFields.includes('subjectId') && this.manualFields.push('subjectId');
      }

      if (!dataObj.cassetteInfo[element.cassette]) {
        dataObj.cassetteInfo[element.cassette] = {
          cassetteId: element.cassette,
          imageName: element.imageName,
          stains: [],
          organs: []
        }
      }

      if (element.stain) {
        let stainArr = element.stain.split(';');
        stainArr.forEach(stainElem => {
          if (!dataObj.cassetteInfo[element.cassette].stains.includes(stainElem.toString().trim())) {
            dataObj.cassetteInfo[element.cassette].stains.push(stainElem.toString().trim());
          }
        });
      }

      if (element.tissues) {
        let organArr = element.tissues.split(';');
        organArr.forEach(organElem => {
          if (!dataObj.cassetteInfo[element.cassette].organs.includes(organElem.toString().trim())) {
            dataObj.cassetteInfo[element.cassette].organs.push(organElem.toString().trim());
          }
        });
      }

      if (!dataObj.dosageInfo[element.dosage]) {
        dataObj.dosageInfo[element.dosage] = {
          dosageId: element.dosage,
          maleAnimalIds: [],
          femaleAnimalIds: [],
          notKnownAnimalIds: [],
          dose: element.dose,
          doseUnit: element.doseUnits,
          controlGroup: element.controlGroup
        }
      }

      if (element.subjectId) {
        let animalArr = element.subjectId.split(';');
        if (element.sex) {
          if ((element.sex).toLowerCase() == 'm' || (element.sex).toLowerCase() == 'male') {
            animalArr.forEach(maleElem => {
              if (!dataObj.dosageInfo[element.dosage].maleAnimalIds.includes(maleElem.toString().trim())) {
                dataObj.dosageInfo[element.dosage].maleAnimalIds.push(maleElem.toString().trim());
              }
            });
          } else if ((element.sex).toLowerCase() == 'f' || (element.sex).toLowerCase() == 'female') {
            animalArr.forEach(femaleElem => {
              if (!dataObj.dosageInfo[element.dosage].femaleAnimalIds.includes(femaleElem.toString().trim())) {
                dataObj.dosageInfo[element.dosage].femaleAnimalIds.push(femaleElem.toString().trim());
              }
            });
          } else {
            animalArr.forEach(animalElm => {
              if (!dataObj.dosageInfo[element.dosage].notKnownAnimalIds.includes(animalElm.toString().trim())) {
                dataObj.dosageInfo[element.dosage].notKnownAnimalIds.push(animalElm.toString().trim());
              }
            });
          }
        } else {
          animalArr.forEach(animalElm => {
            if (!dataObj.dosageInfo[element.dosage].notKnownAnimalIds.includes(animalElm.toString().trim())) {
              dataObj.dosageInfo[element.dosage].notKnownAnimalIds.push(animalElm.toString().trim());
            }
          });
        }
      }

      let indvSlideInfo = {
        slideName: element.imageName ? element.imageName.toString().trim().slice(0, 250) : null,
        slideId: element.slideId ? element.slideId.toString().trim().slice(0, 250) : i.toString(),
        studyNo: element.studyNo ? element.studyNo.toString().trim().slice(0, 15) : null,
        cassetteId: element.cassette?.toString().trim(),
        stain: element.stain?.toString().trim(),
        animalId: element.subjectId?.toString().trim(),
        dosageGroup: element.dosage?.toString().trim(),
        sex: element.sex?.toString().trim(),
        scanMagnification: element.scanMagnification?.toString().trim(),
        scanDate: element.scanDate,
        instrument: element.instrument ? element.instrument.toString().trim().slice(0, 250) : null,
        tissueId: element.tissues?.toString().trim(),
        tissueSection: element.tissueSection?.toString().trim(),
        comments: element.comment ? element.comment.toString().trim().slice(0, 255) : null,
        IHC_ExperimentNo: element.ihcExperimentNumber ? element.ihcExperimentNumber.toString().trim().slice(0, 250) : null,
        targetAntigen: element.targetAntigen ? element.targetAntigen.toString().trim().slice(0, 250) : null,
        primaryAntibodyId: element.primaryAntibodyID ? element.primaryAntibodyID.toString().trim().slice(0, 250) : null,
        primaryAntibodyConcentration: element.primaryAntibodyConcentration ? element.primaryAntibodyConcentration.toString().trim().slice(0, 250) : null,
        secondaryAntibodyId: element.secondayAntibodyID ? element.secondayAntibodyID.toString().trim().slice(0, 250) : null,
        detectionMethod: element.detectionMethod ? element.detectionMethod.toString().trim().slice(0, 250) : null,
        chromogen: element.chromogen ? element.chromogen.toString().trim().slice(0, 250) : null,
        slideControl: element.slideControl ? element.slideControl.toString().trim().slice(0, 250) : null,
        primaryAntibodyConcentrationUnit: element.primaryAntibodyConcentrationUnit ? element.primaryAntibodyConcentrationUnit.toString().trim().slice(0, 250) : null,
        stainDate: element.stainDate
      }

      if (!(this.commonService.ProjectLSData.studyCreationType == 'TCR' && !element.tissues)) {
        dataObj.slideInfo.push(indvSlideInfo);
      }
      else {
        this.tcrNoTissueCount++;
      }
    });

    if (this.tcrNoTissueCount > 0) {
      if (this.tcrNoTissueCount == fileData.length) {
        if (this.commonService.ProjectLSData.studyCreationType == 'TCR') {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.NOTISSUESINSLIDEDATA"), 'warn');
        } else {
          this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.NOSLIDEDATA"), 'warn');
        }
      } else {
        this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.STUDY.NOTISSUECOUNT") + this.tcrNoTissueCount, 'warn');
      }
    }

    if (this.manualFields.length) {
      dataObj.manualFields = this.manualFields.join(',');
    }

    return dataObj;
  }

  inputValueChanged(e) {
    if (e.target.value == '') {
      e.target.value = 2
    }
  }

  onStudyTypeChange(e: any) {
    this.selectedTemplateId = null;
    // this.isMinimalTemplate = false;
    this.isMinimalTemplateCheckboxDisabled = e.value === 'TCR';

    this.mappingFileArr = [];
    if (e.value == "Project") {
      this.createStudy = 'Create Project';
      this.selectedTemplatePlaceHolder = 'Select Template';
      this.templateName = null;
      this.studyMappingStructureService.getShowStudyMappingStructureValue().subscribe((value) => {
        this.data.showAutoCreate = !value;
        let x = 0;
      });
    } else {
      this.createStudy = 'Create Study';
      this.selectedTemplatePlaceHolder = 'Select Mapping File';
      this.templateName = (e.value == "TCR") ? 'Default_Template_TCR.xlsx' : 'Default_Template_TOX.xlsx';

      this.studyMappingStructureService.getShowStudyMappingStructureValue().subscribe((value) => {
        this.data.showAutoCreate = !value;
        if (this.data.showAutoCreate) {
          this.studyService.getAllStructureMappingTemaplates((e.value == "TCR")).subscribe((res: any) => {
            this.mappingFileArr = res.data;
          });
        }
      });
    }
  }

  downloadTemplate() {
    this.studyService.getTemplateStructure(this.selectedTemplateId).subscribe((res: any) => {
      if (res.success) {
        // let systemLabels = res.data.limsLabels.replaceAll(';#', ',');
        // let limsLabels = res.data.limsLabels.replaceAll(';#', ',');
        // let systemLabels = [];
        let limsLabels = [];
        for (let x = 0; x < res.data.headerInfo.labelConfigModels.length; x++) {
          let indvColumnInfo = res.data.headerInfo.labelConfigModels[x];
          limsLabels.push(indvColumnInfo.clientColumn);
          // systemLabels.push(indvColumnInfo.fieldName);
        }
        // let strFinalHeader = limsLabels.join() + '\n' + systemLabels.join();
        this.commonService.downloadCSV(limsLabels.join(), res.data.studyTemplate);
      }
    });
  }
}

interface StudyType {
  name: string,
  type: string
}