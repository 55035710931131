<div class="modal-header dialog-header">
  <div class="headerTitle">
    <div class="dialog-title">{{ createStudy }}</div>
  </div>
  <div class="headerIcon float-right dn" [style.cursor]="'pointer'">
    <i class="fa fa-times" (click)="closeDialog()"></i>
  </div>
</div>
<div class="dialog-container modal-body" [style.padding-bottom]="hasError ? 0 : '0.5vw'">
  <div class="autoCreateDiv" *ngIf="data.showAutoCreate">
    <div [style.padding]="0" class="select-type-grid" [ngClass]="{'noDownloadBtn': (selectedStudyType == 'Project')}">
      <!-- <div>
        <mat-select [(ngModel)]="selectedStudyType" placeholder="Select Type" class="form-control myStudyDropDown1"
          (selectionChange)="onStudyTypeChange($event)">
          <mat-option *ngFor="let studyType of studyTypeList" [value]="studyType.name">
            {{ studyType.name }}
          </mat-option>
        </mat-select>
      </div> -->
      <div>
        <mat-select [(ngModel)]="selectedTemplateId" id="mapping-select" [placeholder]="selectedTemplatePlaceHolder"
          class="form-control myStudyDropDown1" [disabled]="selectedStudyType === null"  (ngModelChange)="hasError=false">
          <mat-option *ngFor="let template of mappingFileArr" [value]="template.id">
            {{ template.name }}
          </mat-option>
        </mat-select>
      </div>
      <!-- <div class="minimal-template-checkbox">
        <mat-slide-toggle class="slide-toggle" color="primary" [disabled]="isMinimalTemplateCheckboxDisabled" [(ngModel)]="isMinimalTemplate">Is Minimal?</mat-slide-toggle>
      </div> -->
      <div class="downloadTemplateButtondiv" *ngIf="selectedStudyType != 'Project'">
        <button type="button" class="btn-default download-button" (click)="downloadTemplate()"
          [disabled]="selectedTemplateId === null" title="Download Study Mapping Structure Template"><i
            class="fa fa-download"></i></button>
      </div>
    </div>
    <div *ngIf="selectedStudyType != 'Project'">
      <div class="form-group">
        <div class="custom-file">
          <input #inputUpload type="file" class="custom-file-input myStudyDropDown1" id="customFile"
          (change)="uploadStudyDataFile($event)" accept=".xlsx, .xls, .csv" placeholder="Upload File">
          <label class="custom-file-label inputHeight fileInputLabel" for="customFile">
            {{studylFileName}}
          </label>
          <div class="label-icons">
            <button (click)="inputUpload.click()" class="upload-icon">
              <mat-icon ngxMatFileInputIcon>attach_file</mat-icon>
            </button>
            <button (click)="removeFile()" *ngIf="file !== null" class="cancel-upload">
              <i class="fa fa-times-circle"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedStudyType == 'Project'">
      <input #inputUpload type="text" placeholder="Project Name" class="form-control inputHeight">
    </div>
  </div>
  <div class="createMappingStructureDiv" *ngIf="!data.showAutoCreate">
    <app-study-mapping-structure></app-study-mapping-structure>
  </div>
  <div *ngIf="hasError" class="error">
    <span>{{ error }}</span>
  </div>
</div>
<div class="actions dialog-actions modal-footer" *ngIf="data.showAutoCreate && !isUploadClicked && !hasError">
  <button class="btn-default" (click)="dialogRef.close(null)">Cancel</button>
  <button class="searchbtn_add" (click)="uploadStudyData()"
    [disabled]="selectedStudyType === null || selectedTemplateId === null || file === null">Create</button>
</div>
<div *ngIf="isUploadClicked && !hasError" class="actions dialog-actions modal-footer">
  <button class="btn-default searchbtn" type="button" disabled>
    <span class="spinner-border spinner-border-sm" style="margin-right: 0.5vw" role="status"
      aria-hidden="true"></span>
    <span>Uploading...</span>
  </button>
</div>
<div class="actions dialog-actions modal-footer" *ngIf="hasError" [style.gap]="'0.5vw'">
  <button class="btn-default" (click)="dialogRef.close(null)">Close</button>
  <button class="btn-default searchbtn" (click)="setCommonServiceDataForStudyCreate()" cdkFocusInitial [disabled]="hasError">Continue</button>
</div>