<div class="changePasswordMainDiv">
  <div class="modal-header dialog-header">
    <div class="headerTitle">
      <div *ngIf="!passChngSucs" class="dialog-title">Change Password</div>
      <div *ngIf="passChngSucs" class="dialog-title">Password Changed</div>
    </div>
  </div>
  <div *ngIf="!passChngSucs" class="container dialog-container modal-body changepwd_div">
    <div class="float-left">
      <app-password-generation [passwordFormGroup]="changePwdform" [passwordPolicyArr]="passwordPolicyArr"
        [isOldPasswordRequired]="true"></app-password-generation>
      <div *ngIf="!passChngSucs" class="actions dialog-actions modal-footer changePasswordButtonDiv">
        <button *ngIf="!showChangepasswordLoader" class="btn-default btn-block loginbtn changePasswordButton"
          [ngClass]="{ 'disableFunction' : changePwdform.invalid } " (click)="changePaswrd()">Change Password</button>
        <button *ngIf="showChangepasswordLoader" class="btn-default btn-block loginbtn">
          <span class="myspinner spinner-border spinner-border-sm" role="status" aria-hidden="true"
            style="margin-right: 0.5vw"></span>
          Changing Password</button>
        <button *ngIf="!passChngSucs" class="btn-default changePasswordCancelBtn"
          (click)="closeDialog()">Cancel</button>
      </div>
    </div>
  </div>
  <div *ngIf="passChngSucs" class="pwdsuccess">
    <img [src]="'../' + appConfig.config?.webAppConfig?.appBaseName + 'assets/images/viewer_icon/tickIcon.svg'" />
    <div class="changeSuccessText">
      <span class="successMessageText">{{msg}}</span>
      <span class="successMessageText">Redirecting to login in
        {{logoutTimer}} seconds.</span>
    </div>
  </div>
</div>