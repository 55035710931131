import { FormGroup, FormControl, ValidatorFn, AbstractControl, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { of } from 'rxjs';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function LengthValidator(control: FormControl) {
    const lengthSeven = (control.value)?.trim().length < 7;
    const isValid = !lengthSeven;
    return isValid ? null : { 'lengthSeven': true };
}

export function CharacterValidator(control: FormControl) {
    let hasNumber = /\d/.test(control.value);
    let hasUpper = /[A-Z]/.test(control.value);
    //let hasLower = /[a-z]/.test(control.value);
    let hasSpecialCharacter = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(control.value);
    // console.log('Num, Upp, Low', hasNumber, hasUpper, hasLower);
    const isValid = hasNumber && hasUpper /* && hasLower */ && hasSpecialCharacter;
    return isValid ? null : { 'character': true };
}



export function regexValidator(regexArray: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control?.value) {
            for (let rule of regexArray) {
                rule['verified'] = false;
            }
            return null; // Skip validation if no value is provided
        }
        let errorMessage: string | null = null;

        for (let rule of regexArray) {
            const regex = new RegExp(rule.regex);

            // Set the 'verified' flag based on whether the regex passes validation
            rule.verified = regex.test(control.value);

            // If the regex fails, set the error message and break the loop
            if (!rule.verified) {
                errorMessage = rule.displayText; // Capture the error message for the first failed regex
                // break;  // Exit the loop once the first invalid regex is found
            }
        }

        // If any validation fails, return the error message
        if (errorMessage) {
            return { regexError: errorMessage };
        }
        return null; // No errors, validation passed
    };
}



