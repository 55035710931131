<div class="loginMainDiv">
  <header class="loginHeader">
    <img [src]="'../../' + config?.webAppConfig?.appBaseName + 'assets/images/am_logo_2.svg'" alt="AIRA Matrix"
      aria-label="AIRA Matrix" class="headerLogo" />

  </header>
  <div class="logbg loginBodyDiv">
    <div class="loginbg"
      [ngClass]="{'resetPasswordMainDiv':isResetPassword, 'externalUserMainDiv':externalUserObj?.isExternal}">

      <div *ngIf="meetingData?.meeting?.title" class="meetingTitle"><b>Title:</b>
        <p>{{meetingData?.meeting?.title}}</p>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onLogin()"
        *ngIf="!isForgotPassword && !isResetPassword && !externalUserObj?.isExternal">
        <img [src]="'../../' + config?.webAppConfig?.appBaseName + 'assets/images/AIRADHI_logo.png'"
          class="airadhilogo">
        <mat-form-field class="form-element commonMatFormField mar_right" appearance="outline"
          *ngIf="meetingData?.isExternal">
          <mat-label class="labelbg">Display Name</mat-label>
          <input matInput formControlName="displayName" id="exampleInputDisplayName" aria-describedby="DisplayName">
        </mat-form-field>
        <mat-form-field class="form-element commonMatFormField  mar_right" appearance="outline"
          *ngIf="!meetingData?.isExternal">
          <input matInput formControlName="email" id="exampleInputEmail1" aria-describedby="emailHelp"
            placeholder="Email ID">
        </mat-form-field>
        <!-- <div *ngIf="submitted && f.email.errors && !meetingData?.isExternal">
          <p class="errormsgs">{{f.email.errors | inputError:'email' | translate}}</p>
        </div> -->
        <mat-form-field class="form-element commonMatFormField  labelplacement password-field" appearance="outline">
          <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" placeholder="Password">
          <mat-icon matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        <!-- <div *ngIf="submitted && f.password.errors">
          <p class="errormsgs">{{f.password.errors | inputError:'password' | translate}}</p>
        </div> -->
        <button *ngIf="!meetingData?.meeting?.title" type="submit" class="btn-default btn-block loginbtn">Login</button>
        <button *ngIf="meetingData?.meeting?.title" type="submit" class="btn-default btn-block loginbtn">Join
          Meeting</button>
      </form>
      <div class="form-group forgotpwd"
        *ngIf="!isForgotPassword && !isResetPassword && !externalUserObj?.isExternal && config?.webAppConfig?.showForgetPassword && !config?.webAppConfig?.newUserPasswordRequired">
        <span (click)="showForgetPassowrd()">Forgot Password?</span>
      </div>

      <!-- Existing User Reset Password -->
      <form [formGroup]="formForgotPassword"
        *ngIf="isForgotPassword && !isResetPassword && !externalUserObj?.isExternal" class="formForgotPassword">
        <div class="clearfix">
          <i class="fa fa-times float-right closeFrgtPswd cursorPointer" (click)="closeForgetPassword()"></i>
          <span class="float-left font-weight-bold"> {{header}} </span>
        </div>
        <div style="display: inline-flex;">
          <img style="margin: 0vw 0.5vw;"
            [src]="'../' + config?.webAppConfig?.appBaseName + 'assets/images/viewer_icon/tickIcon.svg'"
            *ngIf="showSuccess && !otpAttemptsLimit" />
          <img style="margin: 0vw 0.5vw;"
            [src]="'../' + config?.webAppConfig?.appBaseName + 'assets/images/viewer_icon/error_cross.svg'"
            *ngIf="showSuccess && otpAttemptsLimit" />
          <p style="padding: 0.5vw 0vw;font-size: .85vw;">{{message}}</p>
        </div>
        <mat-form-field class="form-element commonMatFormField  recoveryEmail" appearance="outline"
          *ngIf="!isMailSend && !showSuccess">
          <input matInput formControlName="recoveryEmail" aria-describedby="emailHelp" placeholder="Email ID">
        </mat-form-field>

        <mat-form-field class="form-element commonMatFormField  recoveryEmail" appearance="outline"
          *ngIf="isMailSend && !isVerificationSend && !otpAttemptsLimit">
          <input matInput formControlName="verficationCode" placeholder="Verification Code">
        </mat-form-field>

        <button *ngIf="!isMailSend && !showSuccess && !showLoader" type="button" class="btn-default btn-block loginbtn"
          [ngClass]="{'disableFunction': forgotPassFrm.recoveryEmail.invalid}" (click)="sendEmail()">Send Verification
          Code</button>

        <button *ngIf="showLoader" class="btn-default btn-block loginbtn disableFunction" type="button" disabled>
          <span class="myspinner spinner-border spinner-border-sm" role="status" aria-hidden="true"
            style="margin-right: 0.5vw"></span>
          Sending Email...
        </button>
        <button *ngIf="showSuccess && !showLoader && !isMailSend && !otpAttemptsLimit" type="button"
          class=" btn-default btn-block loginbtn" (click)="proceed()">Proceed</button>

        <button *ngIf="showSuccess && !showLoader && otpAttemptsLimit" type="button"
          class=" btn-default btn-block loginbtn disableFunction" (click)="proceed()">Proceed</button>

        <button *ngIf="isMailSend && !isVerificationSend && !otpAttemptsLimit" type="button"
          class="btn-default btn-block loginbtn" [ngClass]="{'disableFunction': forgotPassFrm.verficationCode.invalid}"
          (click)="sendVerificationCode()">Next</button>
      </form>

      <ng-container *ngIf="isForgotPassword && !isResetPassword && !externalUserObj?.isExternal && isVerificationSend">
        <app-password-generation [passwordFormGroup]="formForgotPassword"
          [passwordPolicyArr]="passwordPolicyArr"></app-password-generation>

        <button *ngIf="isVerificationSend && !otpAttemptsLimit" type="button" class="btn-default btn-block loginbtn"
          [ngClass]="{'disableFunction': forgotPassFrm.newPwd.invalid || forgotPassFrm.confirmPwd.invalid }"
          (click)="sendNewPassword()">Next</button>
      </ng-container>

      <!-- New User Reset Password -->
      <form [formGroup]="formResetPassword" *ngIf="!isForgotPassword && isResetPassword && !externalUserObj?.isExternal"
        class="formResetPassword">
        <img [src]="'../../' + config?.webAppConfig?.appBaseName + 'assets/images/AIRADHI_logo.png'"
          class="airadhilogo">
        <mat-form-field class="commonMatFormField  recoveryEmail marginBottom2vw" appearance="outline">
          <input matInput formControlName="userName" type="text" readonly>
        </mat-form-field>
      </form>
      <ng-container *ngIf="!isForgotPassword && isResetPassword && !externalUserObj?.isExternal">
        <app-password-generation [passwordFormGroup]="formResetPassword"
          [passwordPolicyArr]="passwordPolicyArr"></app-password-generation>
        <button type="button" class="btn-default btn-block loginbtn btnResetPassword"
          [ngClass]="{'disableFunction': resetPassFrm.newPwd.invalid || resetPassFrm.confirmPwd.invalid }"
          (click)="sendNewPassword(true)">Set Password</button>
      </ng-container>


      <!-- External User  Password -->
      <form [formGroup]="formExternalUser" *ngIf="!isForgotPassword && !isResetPassword && externalUserObj?.isExternal"
        class="formExternalUser">
        <img [src]="'../../' + config?.webAppConfig?.appBaseName + 'assets/images/AIRADHI_logo.png'"
          class="airadhilogo">

        <mat-form-field class="commonMatFormField userEmailId" appearance="outline">
          <input matInput formControlName="userName" type="text" readonly>
        </mat-form-field>

        <div class="nameInfo">
          <mat-form-field class="commonMatFormField userFirstName" appearance="outline">
            <input matInput formControlName="userFirstName" type="text" placeholder="First Name">
          </mat-form-field>
          <mat-form-field class="commonMatFormField userLastName" appearance="outline">
            <input matInput formControlName="userLastName" type="text" placeholder="Last Name">
          </mat-form-field>
        </div>
      </form>
      <ng-container *ngIf="!isForgotPassword && !isResetPassword && externalUserObj?.isExternal">
        <app-password-generation [passwordFormGroup]="formExternalUser"
          [passwordPolicyArr]="passwordPolicyArr"></app-password-generation>
        <button type="button" class="btn-default btn-block loginbtn btnSetPassword"
          [ngClass]="{'disableFunction': externalUserFrm.newPwd.invalid || externalUserFrm.confirmPwd.invalid }"
          (click)="updateUserProfile()">Set Password</button>
      </ng-container>

      <div class="alert alert-success" role="alert" *ngIf="loginSuccess">
        Login Successful.
      </div>
      <div class="alert alert-danger" role="alert" *ngIf="loginServerError">
        {{loginServerError}}
      </div>
    </div>
  </div>
</div>