<div class="modal-header dialog-header">
    <div class="headerTitle">
        <div class="dialog-title" *ngIf="!data.isTemplateListingPage">Add Metadata</div>
        <div class="dialog-title" *ngIf="data.isTemplateListingPage">Add Template</div>
    </div>
    <div class="headerIcon float-right dn">
        <!-- <i class="fa fa-times" (click)="closeDialog()"></i> -->
        <mat-icon class="cursorPointer iconClose" (click)="closePopUp()">close</mat-icon>
    </div>
</div>


<ng-container [ngTemplateOutlet]="defaultTemplate">

</ng-container>
<ng-template #defaultTemplate>
    <ng-container *ngIf="!data.isTemplateListingPage">
        <div class="dialogBodyContainer">

            <div class="customFiedldDiv" *ngIf="data.canCreateCustom">
                <div class="customFieldHeader">
                    <div class="customfieldTitle">Metadata</div>
                </div>
                <div class="customFieldBody">
                    <mat-form-field class="commonMatFormField" appearance="outline">
                        <mat-label class="mappinlabel labelbg">Field Name </mat-label>
                        <input matInput [(ngModel)]="newFieldName">
                    </mat-form-field>

                    <mat-form-field class="commonMatFormField_selectdropdown" appearance="outline">
                        <mat-label class="mappinlabel labelbg">Field Type </mat-label>
                        <mat-select matInput [(ngModel)]="newFieldType" class="matSelectInput"
                            panelClass="metaDataFeildOptionPanel">
                            <mat-option value="Number" class="metaDataDropDownOption">Number</mat-option>
                            <mat-option value="Text" class="metaDataDropDownOption">Text</mat-option>
                            <mat-option value="Date" class="metaDataDropDownOption">Date</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <div class="actions dialog-actions modal-footer">
                    <button mat-flat-button type="button" class="btn-default cursorPointer"
                        (click)="newFieldName= '' ; newFieldValue = ''">Cancel</button>
                    <button mat-flat-button type="button" class="searchbtn cursorPointer" cdkFocusInitial
                        style="margin-right:0 !important" [disabled]="!newFieldName?.trim()"
                        (click)="addCustomFieldInMetaData()">Add</button>
                </div>

            </div>

            <div class="metaDataTempDiv">
                <div class="templateHeader">
                    <div class="tempTitle">Field Layout</div>
                    <!-- <div class="editIcon" [style.visibility]="'hidden'">

                        <mat-icon svgIcon="editMetadataIcon" title="edit Metadata"></mat-icon>
                    </div> -->
                </div>
                <div class="scrollDiv">
                    <div class="metaDataFieldWrapper">
                        <ng-container *ngFor="let f of defaultTemplateData">

                            <ng-container *ngIf="f.type != 'Dropdown'">
                                <!-- <div class="tempfields">
                            <mat-form-field class="metaDataFieldInputBox" id="Control_{{f.id}}">
                                <mat-label class="mappinlabel labelbg">{{f.name}}
                    
                                </mat-label>
                                <input matInput type="text" (change)="getFieldValue(f, f.type,  $event)"
                                    [value]="f.selectedValue?.toString()">
                            </mat-form-field>
                        </div> -->
                                <div class="tempfields">
                                    <mat-form-field class="metaDataFieldInputBox">
                                        <mat-label class="mappinlabel labelbg">{{f.name}}

                                        </mat-label>
                                        <input *ngIf="f.type != 'Date'" matInput type="{{f.type| lowercase}}"
                                            id="{{f.name + f.position}}" (change)="getFieldValue(f, f.type,  $event)"
                                            maxlength="60" [(ngModel)]="f.selectedValue">
                                        <input *ngIf="f.type == 'Date'" matInput type="{{f.type| lowercase}}"
                                            id="{{f.name + f.position}}" (change)="getFieldValue(f, f.type,  $event)"
                                            (keydown)="false" [(ngModel)]="f.selectedValue">
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <ng-container *ngIf=" f.type=='Dropdown'">
                                <div class="tempfields">
                                    <mat-form-field class="metaDataFieldInputBox commonMatSelect MFF">
                                        <mat-label [class.mandatoryLabel]="f.mandatory">{{f.name}}

                                        </mat-label>
                                        <mat-select placeholder="Field Type" class="matSelectInput"
                                            [(ngModel)]="f.selectedValue"
                                            (selectionChange)="getFieldValue(f, f.type, $event)" multiple="true">

                                            <div class="countLimitMsgDiv" *ngIf="f.maxSelectionCount">
                                                <span> {{f.name + " "+ 'selected' + ": " + (f.selectedValue?.length?
                                                    f.selectedValue?.length: "0") + '/' +f.maxSelectionCount}}
                                                </span>
                                            </div>
                                            <mat-option class="metaDataDropDownOption" *ngFor="let value of f.values"
                                                [disabled]="optionDisabledCheck(f,value)"
                                                [value]="value">{{value}}</mat-option>

                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngFor="let field of metaDataObj | keyvalue">
                            <div class="cumtomFinalDiv tempfields">
                                <ng-container *ngIf="returnTypeOfCustomField(field.key) == 'Text'">
                                    <mat-form-field class="metaDataFieldInputBox commonMatSelect MFF">
                                        <mat-label>{{field.key}}</mat-label>

                                        <input matInput [(ngModel)]="field.value" (change)="dispVal(field)">
                                    </mat-form-field>
                                </ng-container>

                                <ng-container *ngIf="returnTypeOfCustomField(field.key) == 'Number'">
                                    <mat-form-field class="metaDataFieldInputBox commonMatSelect MFF">
                                        <mat-label>{{field.key}}</mat-label>

                                        <input matInput [(ngModel)]="field.value" (change)="dispVal(field)"
                                            type="number">
                                    </mat-form-field>
                                </ng-container>

                                <ng-container *ngIf="returnTypeOfCustomField(field.key) == 'Date'">
                                    <mat-form-field class="metaDataFieldInputBox commonMatSelect MFF">
                                        <mat-label class="mandatoryLabel">{{field.key}}</mat-label>
                                        <input matInput [(ngModel)]="field.value" (change)="dispVal(field)" type="date">
                                    </mat-form-field>
                                </ng-container>
                                <div class="deleteIconDiv">
                                    <mat-icon class="iconimg" (click)="deleteFieldInMetaData(field.key)"
                                        style="width: 0.8vw !important;  height: 0.8vw !important; font-size: 0.8vw !important">clear</mat-icon>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="data.isTemplateListingPage">
        <div class="dialogBodyContainer">

            <div class="customFiedldDiv">
                <div class="customFieldHeader">
                    <div class="customfieldTitle">Template List</div>

                    <!-- <div class=""></div> -->
                </div>
                <div class="templateListDiv">
                    <div class="indvTemplate ellipsisApplied" [class.selectedTemp]="selectedTemplate.id == blankTemplate.id"
                        (click)="getTemplateField(blankTemplate)">
                        {{blankTemplate.name}}
                    </div>
                    <div *ngFor="let template of data.templateListing" class="indvTemplate ellipsisApplied"
                        [class.selectedTemp]="selectedTemplate.id == template.id" (click)="getTemplateField(template)">
                        {{template.name}}</div>
                </div>
            </div>
            <div class="metaDataTempDiv">
                <div class="templateHeader">
                    <div class="tempTitle">Template Preview</div>
                    <!-- <div class="customFieldPermssiionDisp" *ngIf="selectedTemplate.id != blankTemplate.id">
                        <mat-slide-toggle [color]="'primary'" mat-flat-button
                            [(ngModel)]="selectedTemplate.canCreateCustom" [disabled]="true"
                            class="metaChk customeChk matResponsiveToggle "></mat-slide-toggle>
                        <span class="subText leftGap">Allow to create custom fields</span>
                    </div> -->

                </div>
                <ng-container *ngIf="selectedTemplate.id != blankTemplate.id">
                    <div class="scrollDiv">
                        <div class="metaDataFieldWrapper">
                            <ng-container *ngFor="let f of selectedTemplate.fields">
                                <!-- <span class="fieldTitle">
            {{f.name}}     
        </span> -->
                                <ng-container *ngIf="f.type != 'Dropdown'">
                                    <div class="tempfields">
                                        <mat-form-field class="metaDataFieldInputBox">

                                            <input *ngIf="f.type != 'Date'" matInput type="{{f.type| lowercase}}"
                                                id="{{f.name + f.position}}"
                                                (change)="getFieldValue(f, f.type,  $event)" maxlength="60"
                                                [disabled]="true" [placeholder]="f.name">
                                            <input *ngIf="f.type == 'Date'" matInput type="{{f.type| lowercase}}"
                                                id="{{f.name + f.position}}"
                                                (change)="getFieldValue(f, f.type,  $event)" (keydown)="false"
                                                [disabled]="true" [placeholder]="f.name">
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf=" f.type=='Dropdown'">
                                    <div class="tempfields">
                                        <mat-form-field class="metaDataFieldInputBox commonMatSelect MFF">

                                            <mat-select [placeholder]="f.name" class="matSelectInput" [disabled]="true">
                                                <!-- <mat-option value="" class="metaDataDropDownOption" selected="selected">Select
                            Values</mat-option> -->
                                                <!-- <mat-option class="metaDataDropDownOption" *ngFor="let value of f.values"
                                        [value]="value">{{value}}</mat-option> -->
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                            </ng-container>

                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="selectedTemplate.id == blankTemplate.id">
                    <div class="messageDiv">
                        <div>No template fields are available, user can add only custom field.</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

</ng-template>
<div class="section4" *ngIf="!isTemplateTurnOff && isFolderLevel">
    <span class="mainBody">
        <div class="templateFields cursorPointer" *ngFor="let f of defaultTemplateData"
            (click)="stageorUnstageControl(f)" id="Field_{{f.id}}">
            <mat-label class="mappinlabel labelbg">{{f.name}}
            </mat-label>
            <!-- <span *ngIf="f.type != 'Dropdown'" class="fieldBody">
                <mat-form-field class="commonMatFormField">
                    <mat-label class="mappinlabel labelbg">{{f.name}}
                    </mat-label>
                    <input matInput type="{{f.type| lowercase}}" readonly="true">
                </mat-form-field>
            </span>
            <span *ngIf=" f.type=='Dropdown'" class=" fieldBody">
                <mat-form-field class="commonMatFormField commonMatSelect">
                    <mat-label class="mandatoryLabel">{{f.name}}
                    </mat-label>
                    <mat-select placeholder="Field Type" class="matSelectInput">
                    </mat-select>
                </mat-form-field>
            </span> -->
        </div>
        <hr>
        <div class="fields folderMetaData cursorPointer" *ngFor="let data of folderMetaData; let i=index"
            (click)="addtoOverRide(data)" >
            <!-- <mat-icon svgIcon="addMetaFieldIcon" title="Add to override" class="customMatIcon cursorPointer"
                (click)="addtoOverRide(data)"></mat-icon>
            <mat-icon svgIcon="deleteMetaRow" title="Remove Field" class="customMatIcon cursorPointer"
                (click)="deleteTemplateField(data, i)"></mat-icon> -->
            <span *ngIf="data.type != 'Dropdown'" class="fieldBody">
                <mat-form-field class="metaDataFieldInputBox" id="overRideControl_{{data.id}}">
                    <mat-label class="mappinlabel labelbg">{{data.name}}
                        <!-- <span *ngIf="f.mandatory"
                            class="mandatory">*</span> -->
                    </mat-label>
                    <input matInput type="{{data.type| lowercase}}" [value]="data.value == undefined? '': data.value"
                        id="{{data.name + data.position}}" (change)="getFieldValue(data, data.type,  $event)">
                </mat-form-field>
            </span>
            <span *ngIf=" data.type=='Dropdown'" class=" fieldBody">
                <mat-form-field class="metaDataFieldInputBox commonMatSelect " id="overRideControl_{{data.id}}">
                    <mat-label class="mandatoryLabel">{{data.name}}
                        <!-- <span *ngIf="f.mandatory"
                            class="mandatory">*</span> -->
                    </mat-label>
                    <mat-select placeholder="Field Type" class="matSelectInput" id="{{data.name + data.position}}"
                        [value]="data.value" (selectionChange)="getFieldValue(data, data.type, $event)">
                        <mat-option value="" class="metaDataDropDownOption">Select
                            Values</mat-option>
                        <mat-option class="metaDataDropDownOption" *ngFor="let val of data.values"
                            [value]="val">{{val}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </span>
        </div>
    </span>
    <span class="customFieldsDiv">
        <div class="inputDiv cursorPointer" *ngFor="let field of metaDataObj | keyvalue" (click)="addtoOverRide(field)"
            >
            <!-- <div class="input-label">{{field.fieldLabel}}</div> -->
            <!-- <mat-icon svgIcon="addMetaFieldIcon" title="Add to override" class="customMatIcon cursorPointer"
                (click)="addtoOverRide(field)"></mat-icon> -->
            <div class="cumtomFinalDiv">
                <mat-form-field appearance="outline" id="overRideControl_{{field.key}}">
                    <mat-label class="mandatoryLabel">{{field.key}}</mat-label>
                    <input matInput placeholder="{{field.key}}" [(ngModel)]="field.value" class="inputFieldInMetaData"
                        (change)="dispVal(field)">
                </mat-form-field>
                <div class="deleteIconDiv">
                    <mat-icon class="iconimg" (click)="deleteFieldInMetaData(field.key)">clear</mat-icon>
                </div>
            </div>

        </div>
    </span>
    <div>
        <ng-container *ngIf="customField.length>=0">
            <div class="labelDiv" *ngFor="let field of customField, let i=index">
                <div class="inputDiv customInput">
                    <mat-form-field appearance="outline">
                        <mat-label class="mandatoryLabel">Field Name</mat-label>
                        <input matInput placeholder="Enter Field Name" [(ngModel)]="field.fieldLabel"
                            (keypress)="validateLabel($event,field.fieldLabel)" class="inputFieldInMetaData"
                            maxlength="60">
                    </mat-form-field>
                </div>
                <div class="ProjectFilter commonHeightField section3-DropDown customLabelDiv">
                    <!-- <mat-form-field appearance="outline" class="customLabelOptions">
                <mat-label class="mandatoryLabel">Enter Type</mat-label>
                <mat-select (selectionChange)="onDropDownselect(field,$event)" class="matSelectInput">
                    <mat-option value="textbox" class="metaDataDropDownOption">Text</mat-option>
                    <mat-option value="Number" class="metaDataDropDownOption">Number</mat-option>
                    <mat-option value="Date" class="metaDataDropDownOption">Date</mat-option>
                </mat-select>

            </mat-form-field> -->
                    <mat-form-field appearance="outline">
                        <mat-label class="mandatoryLabel">Value</mat-label>
                        <input matInput placeholder="Enter label" [(ngModel)]="field.fieldValue"
                            class="inputFieldInMetaData" maxlength="50" [disabled]="field.fieldLabel == ''">
                    </mat-form-field>
                    <div class="iconDiv">

                        <button mat-raised-button class="commonButtons" (click)="addFieldToMetaData(field,i)"><mat-icon
                                class="cursorPointer customMatIcon">add</mat-icon></button>
                        <button mat-raised-button class="commonButtons" (click)="deleteCustomField(field)"><mat-icon
                                class="cursorPointer customMatIcon">clear</mat-icon></button>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="addFieldButton commonButtons">
            <button mat-button class="cursorPointer" (click)="addCustomField()">
                <mat-icon>add</mat-icon>Add Field </button>
        </div>

    </div>
    <div class="customAllow">
        <mat-checkbox color="primary" mat-flat-button [checked]="false" [(ngModel)]="allowOverRideFolderData"
            class="metaChk customeChk" (ngModelChange)="allowAlltoOverrideOnFields()">
            Allow to override folder data to all images
        </mat-checkbox>
    </div>
    <hr>
    <div class="fields">
        <span *ngFor="let overRide of overRidefolderMetaData; let i=index" class="overRideBlk">
            <mat-icon svgIcon="deleteMetaRow" title="Remove Field" class="customMatIcon cursorPointer"
                (click)="deleteOverRideField(i)"></mat-icon>
            {{overRide.name}}
        </span>
    </div>
</div>

<div class="actions dialog-actions modal-footer" *ngIf="!data.isTemplateListingPage">
    <button mat-flat-button type="button" class="btn-default cursorPointer" (click)="closePopUp()">Cancel</button>
    <button mat-flat-button type="button" class="searchbtn cursorPointer" cdkFocusInitial
        (click)="submitMetaData()">Add</button>
</div>

<div class="actions dialog-actions modal-footer" *ngIf="data.isTemplateListingPage">
    <button mat-flat-button type="button" class="btn-default cursorPointer" (click)="closePopUp()">Cancel</button>
    <button mat-flat-button type="button" class="searchbtn cursorPointer" cdkFocusInitial
        (click)="this.data.isForChange? changeTemplate() : submitTemplateId()"
        [disabled]="(isSelectedTemplateIsBlank  && selectedTemplate.id == -1) || data.templateId == selectedTemplate.id">Proceed</button>
</div>