<div id="downloadReportDiv"></div>

<div class="StudyDetailsMain">
    <div class="row sutitle_det" *ngIf="!(data && data.showHeader)">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 studydet_titlenm studyDetailsHeaderDiv">
            <!-- <p class="studydet_title">
                <a (click)="navigateBackToStudyListing()">
                    <i class="fa fa-chevron-left"></i>
                    Study Listing
                </a>
            </p> -->
            <span class="backtoListingTitle" (click)="navigateBackToStudyListing()">Study Listing</span>
            <mat-icon class="customMatIcon navigationIconInDetails">chevron_right</mat-icon>
            <span class="activeStudyTitleForDetails"> {{studyDetailData.studyNo}}</span>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 p_gallerydetdownload">
            <a *ngIf="activeRole=='STUDY_DIRECTOR' && studyStatus?.toLowerCase() != 'completed'"
                class="imgicontable" title="Edit Study" (click)="editStudy()"><i
                    class="fa fa-edit deletestudy_deticon faicon"></i></a>
            <a *ngIf="activeRole=='STUDY_DIRECTOR'" class="imgicontable" (click)="deleteStudy()"><i
                    class="fa fa-trash deletestudy_deticon faicon" style="display: none;"></i></a>
            <a *ngIf="activeRole=='PATHOLOGIST'" class='imgicontable' (click)="openGallery()" style="display: none;"><i
                    class='fa fa-picture-o openGalleryIcon faicon' title='Open Gallery'></i></a>
            <button type="button" *ngIf="!isDownloadBtnClicked" class="btn searchbtn" (click)="downloadReport()"
                style="display: none;">Download Report</button>

            <button class="btn searchbtn" *ngIf="isDownloadBtnClicked" type="button" disabled>
                <span class="spinner-border spinner-border-sm" style="margin-right: 0.5vw; width: 1vw; height: 1vw;"
                    role="status" aria-hidden="true"></span>
                <span>Downloading...</span>
            </button>
        </div>
    </div>
    <div class="dialog-header modal-header" *ngIf="data && data.showHeader">
        <div class="headerTitle">
            <div class="dialog-title">Details</div>
        </div>
        <div class="headerIcon float-right cursorPointer" *ngIf="data.showCancelIcon">
            <i class="fa fa-times" (click)="closeDialog()"></i>
        </div>
    </div>
    <div class="p_studydetail">
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>Study Details</mat-panel-title>
            </mat-expansion-panel-header>
            <!--  <div class="row panelRow">
                <p class="p_studydet_subtitle" *ngIf="studyDetailData.studyNo">Study No. : <span> {{studyDetailData.studyNo}}</span></p>
                <p class="p_studydet_subtitle" *ngIf="studyDetailData.studyTitle">Study Title : <span> {{studyDetailData.studyTitle}}</span></p>
                <p class="p_studydet_subtitle" *ngIf="studyDetailData.projectNo">Project No. : <span> {{studyDetailData.projectNo}}</span></p>

                <p class="p_studydet_subtitle"  *ngIf="studyDetailData.accessionNo">Accession No. : <span> {{studyDetailData.accessionNo}}</span></p>
                <p class="p_studydet_subtitle" *ngIf="studyDetailData.treatment">Treatment : <span> {{studyDetailData.treatment}}</span></p>
                <p class="p_studydet_subtitle" *ngIf="studyDetailData.studyDomain">Study Domain : <span> {{studyDetailData.studyDomain}}</span></p>

                <p class="p_studydet_subtitle" *ngIf="studyDetailData.studyType">Study Type : <span> {{studyDetailData.studyNo}}</span></p>
                <p class="p_studydet_subtitle" *ngIf="studyDetailData.startDate && studyDetailData.endDate">Duration : <span> {{studyDetailData.studyTitle}}</span></p>
                <p class="p_studydet_subtitle" *ngIf="studyDetailData.route">Route : <span> {{studyDetailData.route}}</span></p>

                <p class="p_studydet_subtitle" *ngIf="studyDetailData.studyDirector">Study Director : <span> {{studyDetailData.studyDirector}}</span></p>
                <p class="p_studydet_subtitle" *ngIf="studyDetailData.pathologist">Pathologist : <span> {{studyDetailData.pathologist}}</span></p>
                <p class="p_studydet_subtitle" *ngIf="studyDetailData.projectNo">Project No. : <span> {{studyDetailData.projectNo}}</span></p>
            </div> -->
            <div class="row panelRow">
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.studyNo">
                    <label class="p_studydet_subtitle">Study No. : </label><span> {{studyDetailData.studyNo}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.studyTitle">
                    <label class="p_studydet_subtitle">Study Title :</label><span> {{studyDetailData.studyTitle}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.projectNo">
                    <label class="p_studydet_subtitle">Project No. : </label><span> {{studyDetailData.projectNo}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.accessionNo">
                    <label class="p_studydet_subtitle">Accession No. : </label>
                    <span> {{studyDetailData.accessionNo}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.treatment">
                    <label class="p_studydet_subtitle">Treatment : </label>
                    <span> {{studyDetailData.treatment}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.studyDomain">
                    <label class="p_studydet_subtitle">Study Domain : </label>
                    <span> {{studyDetailData.studyDomain}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.studyType">
                    <label class="p_studydet_subtitle">Study Type : </label>
                    <span> {{studyDetailData.studyType}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1"
                    *ngIf="studyDetailData.startDate && studyDetailData.endDate">
                    <label class="p_studydet_subtitle">Duration : </label>
                    <span> {{studyDetailData.startDate | date}} To {{studyDetailData.endDate | date}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.route">
                    <label class="p_studydet_subtitle">Route : </label>
                    <span> {{studyDetailData.route}}</span>
                </div>
                <!-- <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.studyDirector">
                    <label class="p_studydet_subtitle">Created By : </label>
                    <span> {{studyDetailData.studyDirector}}</span>
                </div> -->
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.studyDirector">
                    <label class="p_studydet_subtitle">Study Director : </label>
                    <span> {{studyDetailData.studyDirector}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.pathologist">
                    <div *ngIf="studyDetailData.reAssignedpathologistArr.length">
                        <label class="p_studydet_subtitle">Reassigned : </label>
                        <ul>
                            <li *ngFor="let primPath of studyDetailData.reAssignedpathologistArr">
                                <span> {{primPath}}</span>
                            </li>
                        </ul>
                    </div>
                    <label class="p_studydet_subtitle">Pathologist : </label>
                    <span> {{studyDetailData.pathologist}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.technician">
                    <div *ngIf="studyDetailData.reAssignedTechnicianArr.length">
                        <label class="p_studydet_subtitle">Reassigned : </label>
                        <ul>
                            <li *ngFor="let tech of studyDetailData.reAssignedTechnicianArr">
                                <span> {{tech}}</span>
                            </li>
                        </ul>
                    </div>
                    <label class="p_studydet_subtitle">Technician : </label>
                    <span> {{studyDetailData.technician}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.peerReviewer">
                    <div *ngIf="studyDetailData.reAssignedPeerReviewerArr.length">
                        <label class="p_studydet_subtitle">Reassigned : </label>
                        <ul>
                            <li *ngFor="let peer of studyDetailData.reAssignedPeerReviewerArr">
                                <span> {{peer}}</span>
                            </li>
                        </ul>
                    </div>
                    <label class="p_studydet_subtitle">Peer Reviewer : </label>
                    <span> {{studyDetailData.peerReviewer}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.primaryInvestigator">
                    <div *ngIf="studyDetailData.reAssignedPrimaryInvestigatorArr.length">
                        <label class="p_studydet_subtitle">Reassigned : </label>
                        <ul>
                            <li *ngFor="let primaryinvest of studyDetailData.reAssignedPrimaryInvestigatorArr">
                                <span> {{primaryinvest}}</span>
                            </li>
                        </ul>
                    </div>
                    <label class="p_studydet_subtitle">Primary Investigator :</label>
                    <span> {{studyDetailData.primaryInvestigator}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.sacrifice">
                    <label class="p_studydet_subtitle">Sacrifice : </label>
                    <span> {{studyDetailData.sacrifice}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.species">
                    <label class="p_studydet_subtitle">Species : </label>
                    <span> {{studyDetailData.species}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.strain">
                    <label class="p_studydet_subtitle">Strain : </label>
                    <span> {{studyDetailData.strain}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.cro">
                    <label class="p_studydet_subtitle">CRO : </label>
                    <span> {{studyDetailData.cro}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1" *ngIf="studyDetailData.testItem">
                    <label class="p_studydet_subtitle">TestItem : </label>
                    <span> {{studyDetailData.testItem}}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1 dn"
                    *ngIf="studyDetailData.additionalPathologist.length">
                    <label class="p_studydet_subtitle">Share Annotations & Reviews : </label>
                    <span> {{ studyDetailData.reviewSharable ? 'Yes' : 'No' }}</span>
                </div>
                <div class="col-xs-4 col-md-4 col-xl-4 mtop_1 mbot_1"
                    *ngIf="studyDetailData.additionalPathologist.length">
                    <div *ngIf="studyDetailData.reAssignedAdditionalPathologistArr.length">
                        <label class="p_studydet_subtitle">Reassigned : </label>
                        <ul>
                            <li *ngFor="let addPath of studyDetailData.reAssignedAdditionalPathologistArr">
                                <span> {{addPath}}</span>
                            </li>
                        </ul>
                    </div>
                    <label class="p_studydet_subtitle">Additional Pathologists : </label>
                    <ul class="p_studydet_path">
                        <li *ngFor="let path of studyDetailData.additionalPathologist">
                            <span> {{path}}</span>
                        </li>
                    </ul>
                </div>

            </div>
        </mat-expansion-panel>

        <ng-container>
            <mat-expansion-panel [expanded]="true" *ngIf="!isTCRStudy && !studyDetailData?.minimal">
                <mat-expansion-panel-header>
                    <mat-panel-title>Cassette Details</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panelRow metadataDetialsTable">
                    <app-mat-data-table [dataSource]="cassetteDataSource" [displayedColumns]="cassetteDisplayedColumns">
                    </app-mat-data-table>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true" *ngIf="!isTCRStudy && !studyDetailData?.minimal">
                <mat-expansion-panel-header>
                    <mat-panel-title>Dosage Details</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panelRow metadataDetialsTable">
                    <app-mat-data-table [dataSource]="dosageDataSource" [displayedColumns]="dosageDisplayedColumns">
                    </app-mat-data-table>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="true" *ngIf="!isTCRStudy">
                <mat-expansion-panel-header>
                    <mat-panel-title>Slide Details</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panelRow slideDetailsTable"
                    [ngClass]="studyDetailData?.minimal ? 'minimalStudyDetailsHeight' : 'normalStudyDetailsHeight'"
                    (scroll)="onScrollLoadData()" #matTableElement>
                    <app-mat-data-table [dataSource]="minimalStudyDataSource"
                        [displayedColumns]="minimalStudyDisplayedColumns">
                    </app-mat-data-table>
                </div>
            </mat-expansion-panel>
        </ng-container>
        <ng-template #minimal>
            <mat-expansion-panel [expanded]="true" *ngIf="!isTCRStudy">
                <mat-expansion-panel-header>
                    <mat-panel-title>Slide Details</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panelRow">
                    <app-mat-data-table [dataSource]="minimalStudyDataSource"
                        [displayedColumns]="minimalStudyDisplayedColumns">
                    </app-mat-data-table>
                </div>
            </mat-expansion-panel>
        </ng-template>
    </div>
</div>