import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { regexValidator } from 'src/app/Input.Validators';

@Component({
  selector: 'app-password-generation',
  templateUrl: './password-generation.component.html',
  styleUrls: ['./password-generation.component.less']
})
export class PasswordGenerationComponent implements OnInit, OnChanges {
  @Input() passwordFormGroup: FormGroup;
  @Input() passwordPolicyArr: any[];
  @Input() isOldPasswordRequired: boolean = false;
  @Input() isFromUserCreation: boolean = false;
  hideOldPassword: boolean = true;
  hideNewPassword: boolean = true;
  hideConfirmPassword: boolean = true;
  passwordCheckMsg: any;
  newPasswordCheckMsg: any;

  constructor(public translate: TranslateService) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit(): void {
    this.passwordCheckMsg = this.translate.instant("ERROR.NEWPWD.NEWPASSD_OLDPASSD_CHECK");
    this.newPasswordCheckMsg = this.translate.instant("ERROR.CONFIRMPWD.MUSTMATCH");
  }

  ngOnChanges(change: SimpleChanges) {
    if (change['passwordPolicyArr']?.currentValue) {
      this.updateValidators();
    }
  }


  updateValidators(): void {
    // Update the validator with the new regex array from API
    const currentControl = this.passwordFormGroup.get('newPwd');

    // Clear any existing custom validators first (optional)
    currentControl?.clearValidators();

    // Set the new custom validator dynamically
    currentControl?.setValidators([
      Validators.required,
      regexValidator(this.passwordPolicyArr)
    ]);

    // Re-run validation to reflect changes
    currentControl?.updateValueAndValidity();
  }

  get username() {
    return this.passwordFormGroup.get('newPwd');
  }


}
