import { AppConfigService } from './../app-config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

//const api = 'http://172.28.43.142:9070/airadhi/';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private config: any;

  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.config = configService.config;
  }

  /* Field Configuration starts here */
  getFieldConfiguration() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}study/field/configuration/latest`);
  }

  addFieldConfiguration(fieldData) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/field/configuration`, fieldData);
  }

  /* Field Configuration ends here */

  /* Data Configuration starts here */

  /* Study Domain*/
  getAllStudyDomains() {
    // return this.http.get(`${this.config?.apiConfig?.apiUrl}study/data/configuration/study-domain`);
    return this.http.get(`${this.config?.apiConfig?.apiUrl}slide/refData/all?classcode=study_Domain.class`)
  }

  addStudyDomain(studyDomainObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/data/configuration/study-domain`, studyDomainObj);
  }

  deleteStudyDomain(studyDomainId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}study/data/configuration/study-domain/${studyDomainId}`);
  }

  /* Study Type*/
  getAllStudyTypes() {
    // return this.http.get(`${this.config?.apiConfig?.apiUrl}study/data/configuration/study-type`);
    return this.http.get(`${this.config?.apiConfig?.apiUrl}slide/refData/all?classcode=study_Type.class`);
  }

  addStudyType(studyTypeObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/data/configuration/study-type`, studyTypeObj);
  }

  deleteStudyType(studyTypeId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}study/data/configuration/study-type/${studyTypeId}`);
  }

  /*Species*/
  getAllSpecies() {
    // species.class
    return this.http.get(`${this.config?.apiConfig?.apiUrl}slide/refData/all?classcode=species.class`);
  }

  addSpecies(speciesObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/data/configuration/species`, speciesObj);
  }

  deleteSpecies(speciesId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}study/data/configuration/species/${speciesId}`);
  }

  addStrain(speciesId, strainObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/data/configuration/species/${speciesId}/strain`, strainObj);
  }

  deleteStrain(speciesId, strainId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}study/data/configuration/species/${speciesId}/strain/${strainId}`);
  }

  /* Stain */
  getAllStains() {
    // return this.http.get(`${this.config?.apiConfig?.apiUrl}study/data/configuration/stain`);
    return this.http.get(`${this.config?.apiConfig?.apiUrl}slide/refData/all?classcode=stain.class`);

  }

  addStain(stainObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/data/configuration/stain`, stainObj);
  }

  deleteStain(stainId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}study/data/configuration/stain/${stainId}`);
  }

  /* Treatment */
  getAllTreatments() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}study/data/configuration/treatment`);
  }

  addTreatment(treatmentObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/data/configuration/treatment`, treatmentObj);
  }

  deleteTreatment(treatmentId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}study/data/configuration/treatment/${treatmentId}`);
  }

  /* Route */
  getAllRoutes() {
    // return this.http.get(`${this.config?.apiConfig?.apiUrl}study/data/configuration/route`);
    return this.http.get(`${this.config?.apiConfig?.apiUrl}slide/refData/all?classcode=route.classRoute`);

  }

  addRoute(routeObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/data/configuration/route`, routeObj);
  }

  deleteRoute(routeId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}study/data/configuration/route/${routeId}`);
  }

  /* Organ List */
  getAllOrgans() {
    // return this.http.get(`${this.config?.apiConfig?.apiUrl}study/data/configuration/organ`);
    // tissues.class
    return this.http.get(`${this.config?.apiConfig?.apiUrl}slide/refData/all?classcode=tissues.class`);
  }

  addOrgan(organObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/data/configuration/organ`, organObj);
  }

  deleteOrgan(organId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}study/data/configuration/organ/${organId}`);
  }

  /* Dosage Definition */
  getAllDosages() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}}slide/refData/all?classcode=dosage.class`);

  }

  addDosage(dosageObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/data/configuration/dosage`, dosageObj);
  }

  deleteDosage(dosageId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}study/data/configuration/dosage/${dosageId}`);
  }

  /* Severity Grading */
  getAllGradings() {
    // return this.http.get(`${this.config?.apiConfig?.apiUrl}slide-image/data/configuration/grading`);
    return this.http.get(`${this.config?.apiConfig?.apiUrl}slide-image/refData/all?classcode=dosage.class`);
  }

  addGrading(gradingObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}slide-image/data/configuration/grading`, gradingObj);
  }

  deleteGrading(gradingId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}slide-image/data/configuration/grading/${gradingId}`);
  }

  /* Intensity */
  getAllIntensities() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}study/data/configuration/intensity`);
  }

  getAllRandomizationScrot() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}study/data/configuration/score `);
  }

  addIntensity(intensityObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/data/configuration/intensity`, intensityObj);
  }

  deleteIntensity(intensityId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}study/data/configuration/intensity/${intensityId}`);
  }

  deleteRandomizationScore(scoreId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}study/data/configuration/score/ ${scoreId}`);
  }
  /* Frequency */
  getAllFrequencies() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}study/data/configuration/frequency`);
  }

  addFrequency(frequencyObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/data/configuration/frequency`, frequencyObj);
  }
  addRandomizationScore(randomizationScorObj: any) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}study/data/configuration/score`, randomizationScorObj);
  }
  deleteFrequency(frequencyId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}study/data/configuration/frequency/${frequencyId}`);
  }

  /* Folder Location */
  getAllFolderLocation() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}slide-image/data/configuration/imagefolder`);
  }

  getAllFolderLocationNonEmpty() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}slide-image/data/configuration/imagefolder/nonempty`);
  }

  addFolderLocation(folderLocationObj) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}slide-image/data/configuration/imagefolder`, folderLocationObj);
  }

  deleteFolderLocation(folderLocationId) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}slide-image/data/configuration/imagefolder/${folderLocationId}`);
  }

  getAllDataConfigurationDetails() {
    return this.http.get(`${this.config?.apiConfig?.apiUrl}slide-image/dataConfigField?withRefData=true`);
  }

  addNewDataConfiguration(dataConfigField: any) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}slide-image/dataConfigField`, dataConfigField);
  }

  deleteDataConfiguration(dataConfigFieldid: any) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}slide-image/dataConfigField/${dataConfigFieldid}`);
  }

  addDataConfigurationDetails(dataConfigObj: any) {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}slide-image/refData`, dataConfigObj);
  }

  deleteDataConfigurationDetails(dataConfigId: any) {
    return this.http.delete(`${this.config?.apiConfig?.apiUrl}slide-image/refData/${dataConfigId}`);
  }

  getDownloadPermission() {
    return this.http.get(`${this.config.apiConfig.apiUrl}myproject/settings`);
  }

  setDownloadPermission(payload: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}myproject/settings`, payload);
  }

  getQCConfig() {
    return this.http.get(`${this.config.apiConfig.apiUrl}analysis/qcConfig`);
  }

  setQCConfigData(payLoad: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}analysis/qcConfig`, payLoad);
  }
}
