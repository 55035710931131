<header>
    <div class="appheader">
        <div class="leftHeader">
            <img [src]="'../../' + appConfig.config?.webAppConfig?.appBaseName + 'assets/images/am_logo_2.svg'"
                alt="AIRA Matrix" aria-label="AIRA Matrix" class="headerLogo" />

                <!-- <button mat-stroked-button (click)="showLoginPopup()">show login Popup</button> -->
        </div>
        <div class="rightHeader">
            <div class="showDownloads" *ngIf="downloadProgressValue?.length">
                <mat-icon class="iconimg cursorPointer" [matMenuTriggerFor]="downloadProgress"
                    title="Show download">get_app</mat-icon>
                <mat-menu #downloadProgress="matMenu" class="downloadProgress">
                    <div class="indvprogresSet" *ngFor="let indvProgress of downloadProgressValue; let i=index"
                        (click)="$event.stopPropagation();">
                        <div class="zipProgressBarDiv">
                            <div class="downloadFileName"
                                [ngClass]="indvProgress.isCancelled? 'cancelDownloadFileName' : ''">
                                {{indvProgress.fileName}}</div>
                            <ng-container *ngIf="!indvProgress.isDownloaded">
                                <div class="downloadSpinner">
                                    <!-- *ngIf="!indvProgress.isDownloaded && !indvProgress.isCancelled" -->
                                    <mat-progress-spinner [value]="indvProgress.value" mode="determinate"
                                        role="progressbar" style="top:-0.4vh !important;" aria-valuemin="0"
                                        aria-valuemax="100" class="matProgSpinner "
                                        *ngIf="!indvProgress.isDownloaded && !indvProgress.isCancelled" [diameter]="commonService.vwTOpx('1.57vw')">
                                    </mat-progress-spinner>
                                    <mat-icon (click)="cancelDownload(indvProgress)" class="imgIcon clearIconDiv"
                                        *ngIf="!indvProgress.isDownloaded && !indvProgress.isCancelled">clear</mat-icon>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </mat-menu>
            </div>
            <mat-form-field class="selectrole">
                <mat-select [(ngModel)]="userRole" (selectionChange)="changeRole()">
                    <mat-option *ngFor="let userRole of roles" [value]="userRole.role">
                        {{userRole.roleDisplayText}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- notification code starts -->
            <div class="headerPageNotification">
                <button mat-icon-button [disabled]="!notifications.length" [matMenuTriggerFor]="menu"><mat-icon
                        class="iconimg notificationIcon" svgIcon="notification"
                        matBadge="{{notifications.length > 99 ? '99+' : notifications.length}}"
                        [matBadgeHidden]="!notifications.length" matBadgeColor="warn"> </mat-icon></button>
                <mat-menu #menu="matMenu" class="topbar-mat-menu">
                    <div class="headerNotificationDiv">
                        <div class="headerTitle">Notifications</div>
                        <button mat-button class="clearAllNotificationBtn" color="warn" (click)="clearNotification()">Clear
                            All</button>
                        <!-- <span class="clearAllSpan" >Clear All</span> -->
                    </div>
                    <div class="notificationScrollDiv">
                        <div class="notificationsDiv" *ngFor="let notification of notifications">
                            <div class="clearIndvdiv">
                                <mat-icon class="clearIndvNotification cursorPointer"
                                    (click)="clearIndvNotification(notification)">clear</mat-icon>
                            </div>
                            <div class="notificationMsg">{{notification.msg}}</div>
                            <div class="notify_time">{{notification.createdAt}}</div>
                        </div>
                    </div>
                </mat-menu>
                <!-- <mat-icon matBadge="15" matBadgeColor="warn">home</mat-icon> -->
                <!-- <span class="cdk-visually-hidden">
                    Example with a home icon with overlaid badge showing the number 15
                </span> -->
            </div>

            <!-- notificataion code ends here -->


            <div class="circle " [ngStyle]="{'background-color': circleColor}">
                <!--[matMenuTriggerFor]="menu"-->
                <!-- <div class="circle float-right " [ngStyle]="{'background-color': circleColor}"> -->
                <div class="initials">{{initials}}</div>
            </div>
            <div class="headerAvatarText usernm_header"> Hello {{firstName | titlecase}} {{lastName | titlecase}} </div>
            <button mat-icon-button [matMenuTriggerFor]="beforeMenu">
                <img [src]="'../../' + appConfig.config?.webAppConfig?.appBaseName + 'assets/images/viewer_icon/Logout_Icon-Primary.svg'"
                    alt="Logout" aria-label="Logout" class="logouticon " />
                <mat-menu #beforeMenu="matMenu" xPosition="before" class="logoutOptionPanel">
                    <button mat-menu-item (click)="showChangePasswordDialog()">Change Password</button>
                    <button mat-menu-item (click)="logout()">Logout</button>
                </mat-menu>
            </button>
            <!-- <mat-menu #menu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item (click)="showChangePasswordDialog()" class="changePasswordItem">
                    <mat-icon class="headerIcon">lock_open</mat-icon>
                    <span>Change Password</span>
                </button>
                <button mat-menu-item  (click)="logout()">
                    <mat-icon class="headerIcon">power_settings_new</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu> -->
        </div>


    </div>
</header>