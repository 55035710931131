import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { CommonService } from 'src/app/common.service';
import { ImagesService } from 'src/app/images.service';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.less']
})
export class ConfirmDialogComponent implements OnInit {
  showData: any
  currentSlideIdData: any;
  savedDsComment: any;
  selectedSlideData: any;
  files: File[] = [];
  loading = false;
  
  // constructor(@Optional() private dialogRef: MatDialogRef<ConfirmDialogComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
  // showData: any;
  constructor(
    @Optional() private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private http: HttpClient,
    private imagesService: ImagesService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.files = [];
    /* this.showData = {
      'headerTitle': this.data.headerTitle ? this.data.headerTitle: 'Title',
      'confirmMsg': this.data.confirmMsg ? this.data.confirmMsg:'Are You Sure You want to delete this?',
      'cancelButtonText': this.data.cancelButtonText ? this.data.cancelButtonText:'Cancel',
      'confirmButtonText': this.data.confirmButtonText ? this.data.confirmButtonText:'Ok'
    } */
  }

  closeDialog(msg: any = 'btnClose') {
    this.files = [];
    this.dialogRef.close(msg);
  }

  confirmAction() {
    if (this.data?.confirmActionData?.currentPage == "deleteImages") {
      this.imagesService.deleteUnmappedImages(this.data.imageIdArr).subscribe((res: any) => {
        if (res) {
          this.commonService.showToastMsg(res.message);
          this.data.onSubmitAction('delete', this.data.that);
          this.data.reloadPreview(this.data.that);
          this.dialogRef.close('yes');
        }
      });
    } else if (this.data?.confirmActionData?.currentPage == "directMapping") {
      this.loading = true;
      this.imagesService.sendMapData(this.data.mappingData).pipe(finalize(()=>{this.loading=true})).subscribe((e: any) => {
        this.loading = false;
        if (e.message == 'Image Already Exist') {
          this.commonService.showToastMsg(e.message, 'error');
          this.loading = false;
          this.dialogRef.close('yes');
        } else if (e.message == 'Image Mapped Successfully') {
          this.loading = false;
          this.commonService.showToastMsg(e.message, 'success');
          this.dialogRef.close('yes');
        }

      }, (err: any) => {
        this.commonService.showToastMsg(err.message, 'error');
        this.loading = false;
        this.dialogRef.close('yes');
      });

    } else if (this.data?.confirmActionData?.currentPage == "onDeleteMetaRow") {
      this.data.confirmActionData.configChangeAction(this.dialogRef, this.data.confirmActionData.configData, this.data.confirmActionData.that);
    }else{
      this.dialogRef.close(true);
    }
   
  }

  onSelect(event) {
    // console.log(event);
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }

  uploadAnnoFile() {
    let frmData = new FormData();
    frmData.append("file", this.files[0]);
    frmData.append("slideId", this.data.slideId);

    /* const req = new HttpRequest('POST', `http://172.28.43.137:9070/airadhi/slide-image/annotationV1/import`, frmData, {
      reportProgress: true,
      responseType: 'json'
    });

    this.http.request(req).subscribe((item: any) => {
      this.files = [];
      let x = 0;
    }); */

    /* this.imageViewerService.uploadAnnoFile(frmData).subscribe((item: any) => {
      this.closeDialog("success");
    }, (err: any) => {
      this.closeDialog("error");
    }); */
  }
  updateDsComment() {
    this.commonService.ProjectLSData.deleteDsComment = this.savedDsComment;
    delete this.commonService.ProjectLSData.selectedSlideData;
    this.commonService.setProjectLSData();
  }
  onConfirm() {
    if (this.showData.headerTitle == "Edit User") {
      this.data.isAllowed = true;
      this.dialogRef.close(this.data);
    }
  }

}
