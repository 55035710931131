<div class="page-content userListingPage">
  <div class="myStudybar tabbar">
    <div class="indvTab" (click)="rerender('Active')" [ngClass]="{'active': (isAciveUser == 'Active')}">
      <h6 class="tabliketitle">Active ({{activeUserCount}})</h6>
    </div>
    <div class="indvTab" (click)="rerender('Inactive')" [ngClass]="{'active': (isAciveUser == 'Inactive')}">
      <h6 class="tabliketitle">Inactive ({{inactiveUserCount}})
      </h6>
    </div>
    <div class="filterinlineview"> 
      
      <div class="studySearchMainDiv">
        <mat-form-field appearance="outline" class="searchBox" >
          <input matInput placeholder="Search" id="userSearch">       
          
          <mat-icon matPrefix class="">search</mat-icon>
          <mat-icon matSuffix *ngIf="userSearchText" (click)="clearSearchText()" class="cursorPointer">close</mat-icon>
        </mat-form-field>


       <!--  <input type="text" class="tags" id="userSearch" placeholder="Search" /> -->
      </div>
      <button type="button" class="btn-default searchbtn_add" (click)="showCreateUserModal('Create','')" title="Add User">
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
  <div class="tabledata userListingTableData">
    <div class="user-listing-mat-table" [ngClass]="{ 'd-flex': !hasUserDataLoaded }">
      <app-mat-data-table
        *ngIf="hasUserDataLoaded; else loader"
        #matDataTable
        [dataSource]="usersDataSource"
        [displayedColumns]="usersDisplayedColumns"
        (rowClick)="onRowClick($event)"
        (matDataTableLoaded)="onMatDataTableLoaded()">
      </app-mat-data-table>

      <ng-template #loader>
        <mat-spinner></mat-spinner>
      </ng-template>
    </div>
  </div>
</div>