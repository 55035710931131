import { MatDialog } from '@angular/material/dialog';
import { AppConfigService } from './../app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { catchError, mapTo, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private config: any;
  projectName: any;
  ProjectLSData: any = {};
  setProjectLSData: any;

  private readonly VIEWER_SLIDES = 'VIEWER_SLIDES';
  private readonly PARENT_INFO = 'PARENT_INFO';
  private readonly VIEWER_BASE_NAME = 'VIEWER_BASE_NAME';
  private readonly URL_PARENT = 'URL_PARENT';
  private readonly LAST_PARENT_APP_URL = 'LAST_PARENT_APP_URL';
  private readonly PARENT_PROJECT = 'PARENT_PROJECT';
  private readonly IMPORT_EXPORT_PERMISSION = 'IMPORT_EXPORT_PERMISSION';
  private readonly DEFAULT_IMAGE_PREVIEW = 'DEFAULT_IMAGE_PREVIEW';
  private readonly CURR_USER = 'CURR_USER';

  constructor(private http: HttpClient, private cookieService: CookieService, private configService: AppConfigService, private dialog: MatDialog,) {
    this.config = this.configService?.config;
    this.projectName = this.config?.projectInfo?.projectName;
  }

  login(user: { email: string, password: string }): Observable<string> {
    this.storeAccessToken('');
    return this.http.post<any>(`${this.config?.apiConfig?.apiUrl}user/login`, user/*dataObj*/)
      .pipe(
        tap(tokens => {
          this.storeTokens(tokens);
          this.storeLoginData(tokens);
        }),
        mapTo("true"),
        catchError(error => {
          if ((error.error.status == 409) && (error.error.error == "Conflict")) {
            return of('resetPassword');
          } else {
            return of(error.error.status == 401 ? "invalid" : "valid");
          }
        }));
  }

  logout() {
    let logoutData = {
      "refreshToken": this.getRefreshToken(),
      "sessionId": this.getSessionId()
    }
    return this.http.post<any>(`${this.config?.apiConfig?.apiUrl}user/logout`, logoutData).pipe(
      tap(() => {
        this.doLogoutUser()
      }),
      mapTo(true),
      catchError(error => {
        this.doLogoutUser()
        //alert(error.error);
        return of(false);
      }));
  }

  logoutAPICall() {
    let logoutData = {
      "refreshToken": this.getRefreshToken(),
      "sessionId": this.getSessionId()
    }
    return this.http.post<any>(`${this.config?.apiConfig?.apiUrl}user/logout`, logoutData)
  }

  refreshToken() {
    this.storeAccessToken('');
    const requestData = {
      'refreshToken': this.getRefreshToken(),
      'sessionId': this.getSessionId()
    }
    return this.http.post(`${this.config?.apiConfig?.apiUrl}user/token/refresh`, requestData)
      .pipe(tap((tokens: any) => {
        this.storeTokens(tokens);
      }));
  }

  getAccessToken() {
    // return this.cookieService.get(this.ACCESS_TOKEN);
    if ((Object.keys(this.ProjectLSData).length == 0) && localStorage[this.projectName]) {
      this.ProjectLSData = JSON.parse(localStorage[this.projectName]);
    }
    return this.ProjectLSData['accessToken'];
  }

  getSessionId() {
    // return this.cookieService.get(this.SESSION_ID);
    if ((Object.keys(this.ProjectLSData).length == 0) && localStorage[this.projectName]) {
      this.ProjectLSData = JSON.parse(localStorage[this.projectName]);
    }
    return this.ProjectLSData['sessionId'];
  }

  doLogoutUser() {
    this.removeTokens();
    this.removeLoginData();
    this.clearLocalStorage();
    this.closeAllOpenDialog();
  }

  closeAllOpenDialog() {
    if (this.dialog.openDialogs.length > 0) {
      this.dialog.closeAll();
      return false;
    }
    return true;
  }

  getRefreshToken() {
    // return this.cookieService.get(this.REFRESH_TOKEN);
    if ((Object.keys(this.ProjectLSData).length == 0) && localStorage[this.projectName]) {
      this.ProjectLSData = JSON.parse(localStorage[this.projectName]);
    }
    return this.ProjectLSData['refreshToken'];
  }

  private storeAccessToken(access: string) {
    // this.cookieService.set(this.ACCESS_TOKEN, access, 0, '/');
    if ((Object.keys(this.ProjectLSData).length == 0) && localStorage[this.projectName]) {
      this.ProjectLSData = JSON.parse(localStorage[this.projectName]);
    }
    this.ProjectLSData['accessToken'] = access;

    if (!this.setProjectLSData) {
      localStorage[this.projectName] = JSON.stringify(this.ProjectLSData);
    } else {
      this.setProjectLSData();
    }
  }

  storeTokens(tokens: any) {
    // this.cookieService.set(this.ACCESS_TOKEN, tokens./*access_token*/data.accessToken, 0, '/');
    // this.cookieService.set(this.REFRESH_TOKEN, tokens./*refresh_token*/data.refreshToken, 0, '/');
    // this.cookieService.set(this.SESSION_ID, tokens./*refresh_token*/data.sessionId, 0, '/');
    // let ProjectLSData: any = {};
    if (!(Object.keys(this.ProjectLSData).length == 0) && localStorage[this.projectName]) {
      this.ProjectLSData = JSON.parse(localStorage[this.projectName]);
    }
    this.ProjectLSData['accessToken'] = tokens.data.accessToken;
    this.ProjectLSData['refreshToken'] = tokens.data.refreshToken;
    this.ProjectLSData['sessionId'] = tokens.data.sessionId;
    if (!this.setProjectLSData) {
      localStorage[this.projectName] = JSON.stringify(this.ProjectLSData);
    } else {
      this.setProjectLSData();
    }
  }

  private storeLoginData(resp: any) {
    // let ProjectLSData: any = {};
    if ((Object.keys(this.ProjectLSData).length == 0) && localStorage[this.projectName]) {
      this.ProjectLSData = JSON.parse(localStorage[this.projectName]);
    }
    this.ProjectLSData.isfirstLogin = resp.data.isFirstTimeLogin ? resp.data.isFirstTimeLogin : false;
    this.ProjectLSData.isSessionReplaced = resp.data.sessionReplaced ? resp.data.sessionReplaced : false;
    this.ProjectLSData.chooseSession = resp.data.chooseSession ? resp.data.chooseSession : false;

    if (!this.setProjectLSData) {
      localStorage[this.projectName] = JSON.stringify(this.ProjectLSData);
    } else {
      this.setProjectLSData();
    }
  }

  removeTokens() {
    if ((Object.keys(this.ProjectLSData).length == 0) && localStorage[this.projectName]) {
      this.ProjectLSData = JSON.parse(localStorage[this.projectName]);
    }
    delete this.ProjectLSData['accessToken'];
    delete this.ProjectLSData['refreshToken'];
    delete this.ProjectLSData['sessionId'];
    this.setProjectLSData();
  }

  removeLoginData() {
    if ((Object.keys(this.ProjectLSData).length == 0) && localStorage[this.projectName]) {
      this.ProjectLSData = JSON.parse(localStorage[this.projectName]);
    }
    delete this.ProjectLSData['isfirstLogin'];
    delete this.ProjectLSData['isSessionReplaced'];
    delete this.ProjectLSData['chooseSession'];
    this.setProjectLSData();
  }

  clearLocalStorage() {
    this.ProjectLSData = {};
    // localStorage.clear();
    delete localStorage[this.projectName];
  }

  activateSession() {
    return this.http.post(`${this.config?.apiConfig?.apiUrl}user/session/activate`, { sessionId: this.getSessionId() });
  }

  storeViewerSlides(slidesArr: string) {
    this.cookieService.set(this.VIEWER_SLIDES, slidesArr, 0, '/');
  }

  storeLastParentAppUrl(parentAppUrl: any) {
    this.cookieService.set(this.LAST_PARENT_APP_URL, parentAppUrl, 0, '/');
  }

  storeUrlApi(urlParent: any) {
    this.cookieService.set(this.URL_PARENT, urlParent, 0, '/');
  }

  storeWebViewerBaseName(baseName: any) {
    this.cookieService.set(this.VIEWER_BASE_NAME, baseName, 0, '/');
  }

  storeParentInfo(parentInfo: any) {
    this.cookieService.set(this.PARENT_INFO, parentInfo, 0, '/');
  }

  public storeParentProject(ParentProject: any) {
    this.cookieService.set(this.PARENT_PROJECT, ParentProject, 0, '/');
  }

  public storeImgImportExportPermission(impExpPermission: any) {
    this.cookieService.set(this.IMPORT_EXPORT_PERMISSION, impExpPermission, 0, '/');
  }

  public storeDefaultImgPreview(defaultImagePreview: any) {
    this.cookieService.set(this.DEFAULT_IMAGE_PREVIEW, defaultImagePreview, 0, '/');
  }

  storeCurrentUser(userInfo: any) {
    this.cookieService.set(this.CURR_USER, userInfo, 0, '/');
  }
}