<table mat-table matSort [dataSource]="dataSource" class="table-container" #matTable>
    <ng-container *ngFor="let col of displayedColumns; let colIndex = index" matColumnDef="{{ col['key'] }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="col['isSortDisabled'] ?? false" [style]="{ 'width': col['width'], 'max-width': col['width'] }">
            {{ col['value'] }}
        </th>
        <td mat-cell *matCellDef="let row" [style]="{ 'width': col['width'], 'max-width': col['width'] }">
            <ng-container [ngSwitch]="col['cellType']">
                <ng-container *ngSwitchCase="'normal'">
                    <span [title]="row[col['key']] ?? ''">{{ row[col['key']] }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="'template'">
                    <div (click)="onRowClick($event, row)" class="template-div" [style]="{ 'display': 'flex' }">
                        <div [outerHTML]="row[col['key']]"></div>
                        <ng-container *ngIf="row.iconGroupContent?.length && col.key === 'icons'">
                            <mat-icon *ngFor="let item of row.iconGroupContent" [svgIcon]="item.svgIcon" [title]="item.title" [style]="{ 'cursor': 'pointer', 'order': item.style?.order }" [class]="item.class" [id]="item.id" (click)="onGroupIconClick(item.id,row); $event.stopPropagation()"></mat-icon>
                        </ng-container>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'form-field'">
                    <!-- Currently only mat select is supported -->
                    <mat-form-field class="commonMatFormField myStudyDropDown1" [appearance]="row[col['key']].appearance">
                        <mat-label class="labelbg">{{ row[col['key']].label }}</mat-label>
                        <mat-select (selectionChange)="onFormFieldChange($event.value, row)">
                          <mat-option *ngFor="let opt of row[col['key']].options" [value]="opt.value">
                            {{ opt.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </ng-container>
                <ng-container *ngSwitchCase="'menu'">
                    <ng-container [ngSwitch]="row[col['key']]?.contentType">
                        <ng-container *ngSwitchCase="'template'">
                            <div (click)="onRowClick($event, row)" class="misc-template-div" [innerHTML]="row[col['key']].content"></div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'menuItems'">
                            <i [matMenuTriggerFor]="menu" class="menu-icon fa fa-ellipsis-v"></i>
                            <mat-menu #menu="matMenu">
                                <button id="item.id" (click)="onMenuItemClick(item.id, row)" mat-menu-item *ngFor="let item of row[col['key']].items">
                                    {{ item.name }}
                                </button>
                            </mat-menu>
                        </ng-container>
                        <ng-container *ngSwitchCase="'iconsGroup'">
                            <mat-icon *ngFor="let item of row[col['key']].items" [svgIcon]="item.svgIcon" [title]="item.title" [style.cursor]="'pointer'" [class]="item.class" [id]="item.id" (click)="onGroupIconClick(item.id,row)"></mat-icon>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="_columnsToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: _columnsToDisplay"></tr>
    <tr *matNoDataRow>
        <td [attr.colspan]="_columnsToDisplay.length" id="no-data-td">{{ NO_DATA_TO_DISPLAY }}</td>
    </tr>
</table>

<mat-paginator *ngIf="hasPagination" 
    [length]="paginationData.length" 
    [pageSize]="paginationData.pageSize"
    [pageSizeOptions]="paginationData.pageSizeOptions">
</mat-paginator>