<!--Sidebar Start-->
<div class="menuimg_sidebar">
    <a *ngFor="let place of places">
        <div class="indvPlaces" (click)="changePlace(place.moduleName, true)" [ngClass]="{'active': ((router.url.includes(place.moduleName.toLowerCase()) && place.moduleName.toLowerCase() != 'dashboard') || 
        (router.url.includes('image-viewer') && place.moduleName.toLowerCase() == viewerOpenPlace)) }">
            <!-- <div class="{{place.moduleName.toLowerCase()}} placeIcons">
                <img class="iconimg onNormalIcon" src="../../assets/images/viewer_icon/{{place.moduleName.toLowerCase()}}_Icon-Primary.svg">
                <img class="iconimg onHoverIcon" src="../../assets/images/viewer_icon/{{place.moduleName.toLowerCase()}}_Icon-Hover.svg">
                <img class="iconimg onActiverIcon" src="../../assets/images/viewer_icon/{{place.moduleName.toLowerCase()}}_Icon-Active.svg">
            </div> -->
            <!-- <div class="{{place.moduleName.toLowerCase()}} placeIcons"> -->
                <!-- <mat-icon class="iconimg" *ngIf="place.moduleName =='Reassignment'">assignment</mat-icon> -->
                <mat-icon class="iconimg" svgIcon="{{place.moduleName.toLowerCase()}}Icon" > </mat-icon>
                <p>{{place.displayName}}</p>
            <!-- </div> -->
        </div>
    </a>
</div>
<!--Sidebar Ends-->