import { FullScreenMessageComponent } from './common/full-screen-message/full-screen-message.component';
import { CommonGuard } from './auth/common.guard';
import { AuthGuard } from './auth/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuditReviewComponent } from './audit-review/audit-review.component';
import { WebViewerFrameComponent } from './web-viewer-frame/web-viewer-frame.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/login' },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'message',
    component: FullScreenMessageComponent,
  },
  // add modules as childern of dashboard. By default we will land on dashboard as per ux. 
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [CommonGuard],
    children: [
      {
        path: 'study/:role',
        loadChildren: () => import('./study/study.module').then(m => m.StudyModule)
      },
      {
        path: 'audit',
        component: AuditReviewComponent,
      },
      {
        path: 'user/study/gallery',
        loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule)
      },
      {
        path: 'analysis',
        loadChildren: () => import('./job-status/job-status.module').then(m => m.JobStatusModule)
      },
      {
        path: 'configure/:role',
        loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'configure',
        loadChildren: () => import('./configuration/configuration.module').then(m => m.ConfigurationModule)
      },
      {
        path: 'report',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'project',
        loadChildren: () => import('./project/project.module').then(m => m.ProjectModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'uploaded',
        loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule)
      },
      {
        path: 'mapped/study/gallery',
        loadChildren: () => import('./gallery/gallery.module').then(m => m.GalleryModule)
      },
      {
        path: 'digitalsignature',
        loadChildren: () => import('./digital-signature/digital-signature.module').then(m => m.DigitalSignatureModule)
      },
      {
        path: 'images',
        loadChildren: () => import('./image-repo/image-repo.module').then(m => m.ImageRepoModule)
      },
      {
        path: 'upload',
        loadChildren: () => import('./image-repo/image-repo.module').then(m => m.ImageRepoModule)
      }
    ]
  },
  {
    // path: 'WebViewer/:id',
    path: 'WebViewer',
    component: WebViewerFrameComponent
  },
  { path: 'jobStatus', loadChildren: () => import('./job-status/job-status.module').then(m => m.JobStatusModule) }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }