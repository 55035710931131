import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {
  private config: any;
  subject = new Subject;
  public isMapImage = new BehaviorSubject(false);
  constructor(private http: HttpClient, private configService: AppConfigService) {
    this.config = configService.config;
  }


  allLocalImages(pageIndex: any): Observable<any> {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/image/list?imageSource=local&pageNumber=${pageIndex}&pageSize=${this.config.webAppConfig.pageSize}`);
  }

  getImagesId(id: any) {
    return this.subject.next(id);
  }

  imageRepoList() {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/accessibleImageFolders`);
  }

  getRepoFolderData(location: any) {
    let data = {
      "location": location
    }
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/accessibleFolderImages`, data);
  }

  mappedFolderData(id: any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/accessibleFolderImages/${id}`);
  }

  projectsList() {
    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/project`);
  }

  foldersList(id: string) {

    return this.http.get(`${this.config.apiConfig.apiUrl}slide-image/project/${id}`);
  }

  sendMapData(data: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/image/map-image`, data);
  }

  createFolder(folderInfo: any) {
    return this.http.post(`${this.config.apiConfig.apiUrl}slide-image/imagefolder`, folderInfo);
  }

  onImageMapp(data: boolean) {
    this.isMapImage.next(data);
  }

  getRepoImage(imageName: any, loaction: any) {
    let data = {
      location: loaction,
      imageName: imageName
    }
    return this.http.post(`${this.config.apiConfig.apiUrl}tiles/thumbnailLiveImage`, data, { responseType: 'blob' });
  }

  deleteUnmappedImages(images: any) {
    let data = {
      imageIds: images
    }
    return this.http.delete(`${this.config.apiConfig.apiUrl}slide-image/image`, ({
      body: data
    }));
  }

  getImageQuantificationResult(payload:any) {
    return this.http.get(`${this.config.apiConfig.apiUrl}myproject/analysis/results`, { params: payload});
  }
}