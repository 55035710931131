import { StudyService } from './study.service';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { StudyComponent } from './study.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudyListingComponent } from './study-listing/study-listing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StudyDetailsComponent } from './study-details/study-details.component';
import { StudyRoutingModule } from './study-routing.module';
// import { DataTablesModule } from 'angular-datatables';
import { StudyCreateComponent } from './study-create/study-create.component';
import { AppMaterialModule } from '../app-material/app-material.module';
import { InputErrorPipe } from './models/input-error.pipe';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe } from '@angular/common';
import { AutoCreateDialogComponent } from './auto-create-dialog/auto-create-dialog.component'
import { StudyMappingStructureModule } from '../study-mapping-structure/study-mapping-structure.module';
import { LocationMappingDialogComponent } from './location-mapping-dialog/location-mapping-dialog.component';
import { MappingReportDialogComponent } from './mapping-report-dialog/mapping-report-dialog.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MissingStainTissueComponent } from './missing-stain-tissue/missing-stain-tissue.component';
import { SharedModule } from '../shared/shared.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    StudyListingComponent,
    StudyComponent,
    StudyDetailsComponent,
    StudyCreateComponent,
    InputErrorPipe,
    AutoCreateDialogComponent,
    LocationMappingDialogComponent,
    MappingReportDialogComponent,
    MissingStainTissueComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    StudyRoutingModule,
    ScrollingModule,
    // DataTablesModule,
    StudyMappingStructureModule,
    AppMaterialModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
    }
    }),
    SharedModule
  ],
  providers: [DatePipe,StudyService],
})
export class StudyModule { }
