<div class="modal-header dialog-header">
  <div class="headerTitle">
    <!-- <div class="dialog-title" *ngIf="data.locationMapping == 'start'">Start Mapping</div> -->
    <div class="dialog-title" *ngIf="data.locationMapping == 'start'">Sync Images</div>
  </div>
  <div class="headerIcon float-right dn">
    <i class="fa fa-times" (click)="closeDialog()"></i>
  </div>
</div>

<div class="modal-body locationMappingLisitngDiv">
  <div class="locationMappingBody">
    <div class="locationMappingRightBody">
      <div class="mappingRightDivHeader">
        <mat-form-field appearance="outline" class="mappingDivSearchBox searchBox commonInputBox">
          <input matInput placeholder="Search" [(ngModel)]="filterSearchText" (input)="searchLocations()" maxlength="30"
            [disabled]="!folderLocationArr?.length && filterSearchText?.length < 1">
          <mat-icon matPrefix class="">search</mat-icon>
          <mat-icon matSuffix *ngIf="filterSearchText?.length" (click)="clearSearchText()"
            class="cursorPointer">close</mat-icon>
        </mat-form-field>
      </div>
      <ng-container *ngIf="!loader else showSpinner">
        <div class="mappingRightBody">
          <ng-container *ngFor="let location of folderLocationArr">
            <div class="indvMappingLocation" *ngIf="location.accessPermission"
              [ngClass]="{'activeIndvMappingLocation' : activeFolderLocationId == location?.id}">
              <div class="mappingCheckBoxDiv">
                <mat-checkbox color="primary" [(ngModel)]="location.selection"
                  (change)="folderSelection(location,$event, true)"></mat-checkbox>
              </div>
              <div class="locationDetailsDiv" (click)="activeFolderLocation(location)">
                <div class="locationDetailsUpperDiv">
                  <mat-icon svgIcon="folderSvgIcon" class="locationFolderIcon"></mat-icon>
                  <span class="mappingTitle ellipsisApplied" [title]="location?.name">{{location?.name}}</span>
                </div>
                <div class="locationDetailsLowerDiv ellipsisApplied">
                  <span [title]="location?.folderLocation">{{location?.folderLocation}}</span>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!folderLocationArr?.length">
            <div class="noFolderLocationAvailable">
              <img src="../../assets/images/no_data_final.png" />
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="locationMappingLeftBody">
      <ng-container *ngIf="activeFolderLocationId">
        <div class="mappingLeftDivHeader">
          <!-- dir="rtl" -->
          <div class="leftHeaderDiv">
            <span class="listingNavigationText firstTitle" [title]="activeLocation?.name"
              (click)="activeFolderLocation(activeLocation, true)">{{activeLocation?.name}}</span>
            <ng-container *ngIf="locationMappingSelectionLevel?.length < 4">
              <ng-container *ngFor="let folder of locationMappingSelectionLevel; let i = index">
                <mat-icon *ngIf="locationMappingSelectionLevel?.length" class="navigationIcon">chevron_right</mat-icon>
                <span [title]="folder?.name" class="activeStudyNameText"
                  (click)="(i < (locationMappingSelectionLevel?.length - 1)) ? navBackToParentFolder(folder, i) : ''"
                  [ngClass]="{'listingNavigationText' : i < locationMappingSelectionLevel?.length -1}">{{folder?.name}}</span>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="locationMappingSelectionLevel?.length > 3">
              <mat-icon class="navigationIcon">chevron_right</mat-icon>
              <span [title]="locationMappingSelectionLevel[0]?.name" class="listingNavigationText"
                (click)="navBackToParentFolder(locationMappingSelectionLevel[0], 0)">{{locationMappingSelectionLevel[0]?.name}}</span>
              <mat-icon class="navigationIcon">chevron_right</mat-icon>
              <span class="folderLevelMenuSpan" [matMenuTriggerFor]="listingMenu">...</span>
              <mat-menu #listingMenu="matMenu" class="locationMappingFolderLevelMenu"  yPosition="below" xPosition="before">
                <ng-container *ngFor="let inFolder of locationMappingSelectionLevel; let i = index">
                  <div *ngIf="i != 0 && i != locationMappingSelectionLevel?.length - 1 && i != locationMappingSelectionLevel?.length - 2" [title]="inFolder?.name" class="listingNavigationTextForMenu"
                    (click)="(i < (locationMappingSelectionLevel?.length - 1)) ? navBackToParentFolder(inFolder, i) : ''">{{inFolder?.name}}</div>
                </ng-container>
              </mat-menu>
              <mat-icon class="navigationIcon">chevron_right</mat-icon>
              <span [title]="locationMappingSelectionLevel[0]?.name" class="listingNavigationText"
                (click)="navBackToParentFolder(locationMappingSelectionLevel[locationMappingSelectionLevel?.length -2], locationMappingSelectionLevel?.length -2)">{{locationMappingSelectionLevel[locationMappingSelectionLevel?.length
                -2]?.name}}</span>
              <mat-icon class="navigationIcon">chevron_right</mat-icon>
              <span [title]="locationMappingSelectionLevel[0]?.name" class="activeStudyNameText"
                (click)="navBackToParentFolder(locationMappingSelectionLevel[locationMappingSelectionLevel?.length -1], locationMappingSelectionLevel?.length - 1)">
                {{locationMappingSelectionLevel[locationMappingSelectionLevel?.length - 1 ]?.name}}</span>
              {{locationMappingSelectionLevel?.length}}
            </ng-container>
          </div>
          <div class="mappingFolderSearchDiv"
            *ngIf="(locationMappingSelectionLevel?.length) ? locationMappingSelectionLevel[locationMappingSelectionLevel?.length -1 ]?.['displayDataBackup']?.length : activeLocation?.['displayDataBackup']?.length">
            <mat-form-field appearance="outline" class="searchBox commonInputBoxLocationMapping">
              <input matInput id="studysearch" placeholder="Search" [(ngModel)]="search"
                (input)="searchLocationFolder()" maxlength="30">
              <mat-icon matPrefix class="searchIcon cursorPointer">search</mat-icon>
              <mat-icon matSuffix *ngIf="search " (click)="clearSearchForFolder()"
                class="cursorPointer">close</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <ng-container *ngIf="!loader1 else showSpinner">
          <div class="mappingLeftBody">
            <div class="mappingLeftScrollBody">
              <ng-container
                *ngFor="let item of (locationMappingSelectionLevel?.length) ? locationMappingSelectionLevel[locationMappingSelectionLevel?.length -1 ]?.['displayData'] : activeLocation?.['displayData']">
                <div class="indvMappingItem"
                  [ngClass]="{'indvMappingFolderClass' :item?.type != 'slide', 'disabledClass' :item?.type == 'slide', 'currentOpenedFolder' : (lastOpenedFolder['name'] ==  item?.name && item?.type == 'folder') }">
                  <div class="indvMappingIconDiv">
                    <mat-checkbox color="primary" *ngIf="item?.type == 'folder'" [(ngModel)]="item.selection"
                      (change)="folderSelection(item,$event)"></mat-checkbox>
                    <mat-icon svgIcon="folderSvgIcon" *ngIf="item?.type == 'folder'"
                      class="locationFolderIcon"></mat-icon>
                    <a *ngIf="item?.type == 'slide'" class='imgicontableNew' id='galleryIcon'><i
                        class='fa fa-picture-o imageicon'></i></a>
                  </div>
                  <div class="indvMappingItemName" [title]="item?.type == 'folder'? item?.name : item?.slideName"
                    (click)="getChildFolderDetails(item)">
                    {{item?.type == 'folder'? item?.name : item?.slideName}}
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="(locationMappingSelectionLevel?.length) ? !locationMappingSelectionLevel[locationMappingSelectionLevel?.length -1]?.['displayData']?.length : !activeLocation?.['displayData']?.length">
                <div class="noFolderDataAvailable">
                  <img src="../../assets/images/no_data_final.png" />
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!activeFolderLocationId">
        <div class="noFolderLocationDiv">
          Please select a location
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div class="actions dialog-actions modal-footer">
  <button class="btn-default" (click)="closeDialog()">Cancel</button>
  <button [ngClass]="{ 'disablebtn_func' : !selectedFolderLocations?.length }" class="btn-default mapBtn"
    *ngIf="data.locationMapping == 'start'" (click)="mapLocationFolder()">Sync</button>
</div>

<ng-template #showSpinner>
  <div class="locationMappingSpinnerDiv">
    <mat-spinner class="dataLoadingSpinner"></mat-spinner>
  </div>
</ng-template>