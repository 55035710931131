import { HttpClient, HttpBackend, HttpClientModule } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'protractor';
@Injectable({
    providedIn: 'root'
})
export class AnomalyResult {

    data: Config | undefined;

    constructor(private http: HttpBackend) { }

    loadAnomalyResult() {
        return new HttpClient(this.http)
            .get<Config>('./assets/anomalyResult/folderAnomayResult.json')
            .toPromise()
            .then(result => {
                this.data = result;
            });
    }
}
