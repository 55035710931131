import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './common.service';
import { Injectable } from '@angular/core';
import * as XLSX from "xlsx";
import { Papa } from 'ngx-papaparse';

@Injectable()
export class FileReaderService {
  file: File;

  constructor(private papa: Papa, private commonService: CommonService,
    public translate: TranslateService) {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  readFile(fileName, callBack: any) {
    let regex = new RegExp('[^.]+$');
    let extension = fileName.name.match(regex)[0];
    if (extension == "xlsx" || extension == "xls") {
      this.readXLSXFile(fileName, (data: any) => {
        callBack(data);
      });
    } else if (extension == "csv") {
      this.readCSVFile(fileName, (data: any) => {
        callBack(data);
      });
    } else {
      this.commonService.showToastMsg(this.translate.instant("GENERIC_MSG.FILE_READER.VALID_FILE"), 'error');
      callBack([{}]);
    } // add new cases below 

  }

  writeFile(format: String, data: any, fileName: String) {
    if (format == 'csv') {
      this.writeCSVFile(data, fileName);
    }
  }

  private readXLSXFile(name, getData: any) {
    let readFile = new FileReader();
    readFile.onload = () => {
      let storeData: any = readFile.result;
      let data = new Uint8Array(storeData);
      let arr = new Array();
      for (let i = 0; i != data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      let bstr = arr.join("");
      let workbook = XLSX.read(bstr, { type: "binary" });
      let first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];
      let fileData = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: "", blankrows: false });
      fileData.forEach((rows) => {
        Object.keys(rows).forEach((key) => {
          if (key.length != key.trim().length) {
            rows[key.trim()] = rows[key];
            delete rows[key];
          }
          if (key.includes("EMPTY")) {
            delete rows[key]
          }
        })
      });
      getData(fileData);
    }
    readFile.readAsArrayBuffer(name);
  }

  private readCSVFile(fileName, getData: any) {
    this.papa.parse(fileName, {
      complete: (result) => {
        let csvData = [];
        for (let i = 1; i <= result.data.length; i++) {
          let headers = result.data[0];
          let jsonObj = {};
          if (result.data[i] && result.data[i].length > 1) {
            for (let x = 0; x < result.data[i].length; x++) {
              headers[x] = headers[x]?.trim();
              jsonObj[headers[x]] = result.data[i][x]?.trim();
            }
            csvData.push(jsonObj);
          }
        }
        getData(csvData);
      }
    });
  }

  private writeCSVFile(dataToWrite: any, fileName: String) {
    let jsonArr = dataToWrite;
    let headers = Object.keys(jsonArr[0]);
    // headers = headers.map((header) => { return header.toUpperCase()});
    let allDataArray = [];
    allDataArray.push(headers)
    jsonArr.forEach((data: any) => {
      let eachData = [];
      for (let i = 0; i < headers.length; i++) {
        let value = data[headers[i]];
        if (typeof value == "object") { // this condition is added for annotation export only, it has no spl significance
          if (value.length) {
            let temp = ""
            value.forEach((val: any) => {
              val = JSON.stringify(val).replace("{", "").replace("}", "").replace(/["']/g, "").concat("\n");
              temp = temp + val;
            });
            value = temp;
          } else {
            let keys = Object.keys(value);
            let temp = "";
            for (let j = 0; j < keys.length; j++) {
              temp = temp + value[keys[j]];
              temp.replace(";", "").toString();
            }
            value = temp;
          }
        }
        eachData.push(value);
      }
      allDataArray.push(eachData);
    });

    let csv = this.papa.unparse(allDataArray);
    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator["msSaveBlob"]) { // IE 10+
      navigator["msSaveBlob"](blob, fileName + ".csv");
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", fileName + ".csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}